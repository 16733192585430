import { FormLabel } from '@mui/material';
import Button from 'components/Button/Button';
import { CustomListType } from '__generated__/graphql';
import { Autocomplete } from 'components/Autocomplete/Autocomplete';
import { useQueryGetCustomList } from 'hooks/queries/useQueryGetCustomList/useQueryGetCustomList';
import { SetStateAction, useMemo } from 'react';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { DeviationCodesToApply } from '../PricingModal';
import { css, styled } from '@mui/material/styles';

const ButtonContainer = styled('div')(
  () => css`
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
  `
);

type Props = {
  index: number;
  deviationCodesToApply: DeviationCodesToApply;
  setDeviationCodesToApply: React.Dispatch<SetStateAction<DeviationCodesToApply>>;
};

export const DeviationCodeInput = ({ index, deviationCodesToApply, setDeviationCodesToApply }: Props) => {
  const { updateItemField } = useQuoteContext();

  const { data: deviationCodeListData } = useQueryGetCustomList({ id: CustomListType.DeviationCode });

  const deviationCodeOptions = useMemo(
    () =>
      deviationCodeListData?.getCustomList?.customValueList?.customValue?.map((opt) => ({
        label: opt.value,
        id: `${opt.valueId}`,
      })) ?? [],
    [deviationCodeListData]
  );

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDeviationCodesToApply((prev) => ({ ...prev, [index]: e.target.value ?? '' }));
  };

  const handleApply = () => {
    updateItemField(index, 'deviationCode', deviationCodesToApply[index]);
  };

  return (
    <div>
      <FormLabel>Deviation Code</FormLabel>
      <Autocomplete
        name='deviationCode'
        value={deviationCodesToApply[index]}
        options={deviationCodeOptions}
        onChange={handleChange}
      />
      <ButtonContainer>
        <Button color='primary' variant='outlined' onClick={handleApply}>
          Apply
        </Button>
      </ButtonContainer>
    </div>
  );
};
