import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { useMemo } from 'react';
import { SEND_EMAIL } from 'graphql/email/email.gql';
import { SendQuoteEmailMutation, SendQuoteEmailMutationVariables } from '__generated__/graphql';

type Data = SendQuoteEmailMutation;
type Variables = SendQuoteEmailMutationVariables;

function useMutationSendQuoteEmail(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(() => ({ ...options }), [options]);
  return useMutation(SEND_EMAIL, opts);
}

export default useMutationSendQuoteEmail;
