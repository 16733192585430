import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { css, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ColumnFiltersState,
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import Flex from 'components/Flex/Flex';
import {
  AnchoredTh,
  IconWrapper,
  LeftActionCell,
  RightActionCell,
  Row,
  RowActionsWrapperLeft,
  RowActionsWrapperRight,
  Spacer,
  StyledLaunchIcon,
  Table,
  TableCell,
  TableHeaderCell,
} from 'components/TableComponents/TableComponents';
import { Alert, AlertTitle, Button, Grid, MenuItem, Select, Stack } from '@mui/material';
import { useQueryListQuotes } from 'hooks/queries/useQueryListQuotes/useQueryListQuotes';
import { seoFriendly, getPage } from 'utils/functions';
import { GLOBAL_FILTERS_KEY } from 'utils/constants';
import { QuoteStatusBackgroundColorMap, useQuotesContext } from 'contexts/QuotesContext/QuotesContext';
import { useUserContext } from 'contexts/UserContext/UserContext';
import OwnerAvatars from 'components/OwnerAvatars/OwnerAvatars';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import { Quote } from '__generated__/graphql';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { Action } from 'utils/constants';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { formatCurrency } from 'utils/functions';
import { Link } from 'react-router-dom';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { QUOTE_STATUS } from 'utils/types';
import TransactionSearchBar, { TransactionType } from 'components/TransactionSearchBar/TransactionSearchBar';
import { useBreakpoints } from 'hooks/util/useBreakpoints';

const TableContainer = styled('div')(() => css``);

const StyledTableCell = styled(TableCell)(
  ({ theme }) => css`
    border-right-width: 0px;
    border-left-color: ${theme.colors.darkBoundary};
    text-decoration: none;
    padding: 0;
  `
);

const QuotesTable = () => {
  const { deleteQuote, duplicateQuote, quotesList, setQuotesList } = useQuotesContext();
  const { showSnackbar, snackbarMessage, setShowSnackbar, setSnackbarMessage } = useQuoteContext();
  const theme = useTheme();

  //TODO: Save for later if we are implementing cell phone styles
  //const isCellPhone = useMediaQuery(theme.breakpoints.down(471));
  const savedGlobalFilters = window.localStorage.getItem(GLOBAL_FILTERS_KEY) || '';

  const [rowActionsVisible, setrowActionsVisible] = useState<{ [key: string]: boolean }>({});
  const columnHelper = createColumnHelper<Quote>();
  const [sorting, setSorting] = React.useState<SortingState>([{ id: 'createdDate', desc: true }]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState<string>(savedGlobalFilters);
  const [pagination, setPagination] = useState<PaginationState>({ pageSize: 10, pageIndex: 0 });
  const [pages, setPages] = useState<number>(0);
  const [debouncedGlobalFilter, setDebouncedGlobalFilter] = useState<string>('');
  const [quotes, setQuotes] = useState<Quote[]>([]);

  const { can } = useAuthContext();
  const { user } = useUserContext();
  const restrictQuotes = !can('Quotation', Action.ViewAll);
  const userName = restrictQuotes && user ? user.fullName : undefined;

  const canViewQuotes = useMemo(() => {
    return can('Quotation', Action.View);
  }, [can]);

  const canEditQuotes = useMemo(() => {
    return can('Quotation', Action.Edit);
  }, [can]);

  const statusFilter = columnFilters?.find((filter) => filter.id === 'statusSearch')?.value as string;
  const clientFilter = columnFilters?.find((filter) => filter.id === 'client')?.value as string;
  const clientTierFilter = columnFilters?.find((filter) => filter.id === 'clientTierSearch')?.value as string;
  const salesRepFilter = restrictQuotes
    ? userName
    : (columnFilters?.find((filter) => filter.id === 'salesRepSearch')?.value as string);
  const salesCoordFilter = restrictQuotes
    ? userName
    : (columnFilters?.find((filter) => filter.id === 'salesCoordSearch')?.value as string);
  const marketFilter = restrictQuotes
    ? undefined
    : (columnFilters?.find((filter) => filter.id === 'marketSearch')?.value as string);
  const supervisorFilter = restrictQuotes
    ? undefined
    : (columnFilters?.find((filter) => filter.id === 'supervisorSearch')?.value as string);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedGlobalFilter(globalFilter);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }, 500);
    return () => clearTimeout(timeout);
  }, [globalFilter]);

  const {
    data: quoteData,
    refetch,
    error,
    loading,
  } = useQueryListQuotes(
    {
      input: {
        status: statusFilter,
        client: clientFilter,
        salesRepId: salesRepFilter,
        salesCoordinator: salesCoordFilter,
        marketId: marketFilter,
        supervisorId: supervisorFilter,
        textSearch: debouncedGlobalFilter,
        clientTier: clientTierFilter,
        sort: sorting[0]?.id,
        sortAscending: sorting[0]?.desc ? '' : 'yes',
        page: pagination.pageIndex,
        perPage: pagination.pageSize,
      },
    },
    { notifyOnNetworkStatusChange: true }
  );

  useEffect(() => {
    if (error?.message.includes('sync')) {
      setShowSnackbar(true);
      setSnackbarMessage('A quote was incomplete, getting updated information.');
      refetch();
    } else if (quoteData?.listQuotes?.quotes) {
      setPages(quoteData.listQuotes.pages);
      setQuotes(quoteData.listQuotes.quotes as Quote[]);
      setQuotesList(quoteData.listQuotes.quotes as Quote[]);
    }
  }, [error, refetch, loading, quoteData, setQuotesList, setShowSnackbar, setSnackbarMessage]);
  const canGetNextPage = useMemo(() => pagination.pageIndex < pages - 1, [pages, pagination.pageIndex]);
  const canGetPrevPage = useMemo(() => pagination.pageIndex > 0, [pagination.pageIndex]);

  const arrayExcludesFilter: FilterFn<Quote> = (row, columnId, filterValue) => {
    return filterValue.indexOf(row.getValue(columnId)) === -1;
  };

  const arrayIncludesFilter: FilterFn<Quote> = (row, columnId, filterValue) => {
    return filterValue.indexOf(row.getValue(columnId)) > -1;
  };

  const ownerSearchTerms = (quote: Quote) => {
    return [quote.salesRep, quote.salesCoordinator]
      .map((owner) => {
        if (!owner) {
          return null;
        }
        const initials = (owner?.name ?? '')
          .split(' ')
          .map((part) => part[0])
          .join('');
        return `${owner.name} ${initials}`;
      })
      .join(' ');
  };

  const setColumnFiltersAndPagination: Dispatch<SetStateAction<ColumnFiltersState>> = (
    filters: SetStateAction<ColumnFiltersState>
  ) => {
    setColumnFilters(filters);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor((quote) => quote.client?.name ?? 'unknown', {
        id: 'client',
        cell: (info) => info.getValue(),
        header: () => <span>Client</span>,
      }),
      columnHelper.accessor((quote) => quote.quoteNumber, {
        id: 'quote',
        cell: (info) => (
          <span style={{ color: theme.colors.hoverBlue, cursor: 'pointer', textDecoration: 'none' }}>
            {info.getValue()}
          </span>
        ),
        header: () => <span>Quote #</span>,
      }),
      columnHelper.accessor(
        (quote) => {
          return {
            salesRep: quote.salesRep,
            salesCoordinator: quote.salesCoordinator,
            id: quote.id,
          };
        },
        {
          id: 'owner',
          cell: (info) =>
            canEditQuotes ? (
              <OwnerAvatars salesRep={info.getValue().salesRep} salesCoordintor={info.getValue().salesCoordinator} />
            ) : (
              <OwnerAvatars salesRep={info.getValue().salesRep} salesCoordintor={info.getValue().salesCoordinator} />
            ),
          header: () => <span>Owner</span>,
        }
      ),
      columnHelper.accessor((quote) => quote.projectName, {
        id: 'projectName',
        cell: (info) => info.getValue(),
        header: () => <span>Project</span>,
      }),
      columnHelper.accessor((quote) => quote.contact, {
        id: 'clientContact',
        cell: (info) => `${info.getValue()?.name}`,
        header: () => <span>Client Contact</span>,
      }),
      columnHelper.accessor((quote) => quote, {
        id: 'quoteTotal',
        cell: (info) => {
          const quote = info.getValue();
          if (!quote) {
            return null;
          }
          const total = (quote.items || []).reduce((total, item) => {
            const calculateTotal = (quantity: number, adjCost: number) => {
              return Math.round(quantity * adjCost * 100) / 100;
            };
            return total + calculateTotal(item.quantity || 0, item.adjCost || 0);
          }, 0);
          return <span>{formatCurrency(total)}</span>;
        },
        header: () => <span>Quote Total</span>,
      }),
      columnHelper.accessor((quote) => quote.memo, {
        id: 'notes',
        cell: (info) => info.getValue(),
        header: () => <span>Notes</span>,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor((quote) => quote.followUpDate, {
        id: 'followUpDate',
        cell: (info) => {
          const followUpDate = info.getValue();
          return followUpDate ? dayjs(followUpDate).format('YYYY-MM-DD') : null;
        },
        header: () => <span>Follow Up Date</span>,
      }),
      columnHelper.accessor((quote) => quote.messageSent, {
        id: 'messageSent',
        cell: (info) => info.getValue() && 'Yes',
        header: () => <span>Email sent</span>,
      }),
      /* Hidden Search Columns */
      columnHelper.accessor((quote) => quote.quoteNumber, {
        id: 'quoteNumberSearch',
        filterFn: arrayExcludesFilter,
      }),
      columnHelper.accessor((quote) => quote.status, {
        id: 'statusSearch',
        filterFn: arrayIncludesFilter,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor((quote) => ownerSearchTerms(quote), {
        id: 'ownerSearch',
      }),
      /* End Hidden Search Columns */
    ],
    [canEditQuotes, columnHelper, theme.colors.hoverBlue]
  );

  const { isSmall, isMedium, isTablet, isLarge, isExtraLarge } = useBreakpoints();

  const quotesTable = useReactTable({
    columns,
    data: quotesList,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    filterFns: {
      arrayExcludes: arrayExcludesFilter,
      arrayIncludes: arrayIncludesFilter,
    },
    initialState: {
      columnVisibility: { quoteNumberSearch: false, statusSearch: false, ownerSearch: false },
    },
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    state: {
      columnVisibility: {
        followUpDate: isLarge,
        messageSent: isExtraLarge,
        notes: isLarge,
        quoteTotal: isTablet,
        clientContact: isMedium,
        projectName: isMedium,
        owner: isSmall,

        // Always Hidden
        quoteNumberSearch: false,
        statusSearch: false,
        ownerSearch: false,
      },
      pagination,
      columnFilters,
      globalFilter,
      sorting,
    },
  });

  if (!canViewQuotes) {
    return (
      <Alert color='error'>
        <AlertTitle>You do not have permission to view this page</AlertTitle>
      </Alert>
    );
  }

  return (
    <>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <TransactionSearchBar
        transactionType={TransactionType.Quote}
        setSorting={setSorting}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFiltersAndPagination}
        setGlobalFilter={setGlobalFilter}
      />
      <TableContainer>
        <div />
        <Table>
          <thead>
            {quotesTable.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <AnchoredTh />
                {headerGroup.headers.map((header, i) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <TableHeaderCell $start={i === 0} $end={i === headerGroup.headers.length - 1}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHeaderCell>
                      )}
                    </th>
                  );
                })}
                <RightActionCell />
              </tr>
            ))}
          </thead>
          <tbody>
            {loading && (
              <Row>
                <td colSpan={9} align='center'>
                  <LoadingSpinner loading={loading} />
                </td>
              </Row>
            )}
            {!loading &&
              quotesTable.getRowModel().rows.map((row) => {
                return (
                  <Row
                    key={row.id}
                    onMouseEnter={() => setrowActionsVisible({ ...rowActionsVisible, [row.id]: true })}
                    onMouseLeave={() => setrowActionsVisible({ ...rowActionsVisible, [row.id]: false })}
                  >
                    <LeftActionCell>
                      <RowActionsWrapperLeft sx={{ marginTop: '1rem' }}>
                        <Flex column h100 styles={{ justifyContent: 'space-around' }}>
                          <Tooltip title='View Quote Details'>
                            <IconWrapper
                              component={Link}
                              to={`/quote/${seoFriendly(row.original.client?.name ?? '')}-${
                                row.original.client?.id ?? ''
                              }/${row.original.quoteNumber}`}
                            >
                              <StyledLaunchIcon $visible={!isMedium || rowActionsVisible[row.id]} />
                            </IconWrapper>
                          </Tooltip>
                        </Flex>
                      </RowActionsWrapperLeft>
                    </LeftActionCell>
                    {row.getVisibleCells().map((cell, i) => {
                      const isQuoteCol = cell.column.id === 'quote';
                      const statusColor = QuoteStatusBackgroundColorMap[row.original.status?.name ?? QUOTE_STATUS.OPEN];
                      return (
                        <StyledTableCell $start={i === 0} $end={i === row.getVisibleCells().length - 1} key={cell.id}>
                          {isQuoteCol && (
                            <Tooltip enterDelay={1000} title={`Status: ${row.original.status?.name ?? ''}`}>
                              <Spacer
                                $borderLeftColor={statusColor}
                                component={Link}
                                to={`/quote/${seoFriendly(row.original.client?.name ?? '')}-${
                                  row.original.client?.id ?? ''
                                }/${row.original.quoteNumber}`}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </Spacer>
                            </Tooltip>
                          )}
                          {!isQuoteCol && <Spacer>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Spacer>}
                        </StyledTableCell>
                      );
                    })}
                    <RightActionCell>
                      <RowActionsWrapperRight>
                        {/*<Flex column h100 styles={{ justifyContent: 'space-around' }}>
                          <IconWrapper>
                            <ActionMenu
                              objectName='Quote'
                              visible={!isMedium || rowActionsVisible[row.id]}
                              onDuplicate={() => duplicateQuote(row.original)}
                            />
                          </IconWrapper>
                        </Flex> */}
                      </RowActionsWrapperRight>
                    </RightActionCell>
                  </Row>
                );
              })}
          </tbody>
        </Table>
        <div />
        <Grid container rowSpacing={2}>
          <Grid item xs={0} lg={4} />
          <Grid item xs={12} lg={4} alignItems='center'>
            <Stack direction={'row'} spacing={2} justifyContent='center'>
              <Button
                variant='outlined'
                onClick={() => setPagination((cur) => ({ ...cur, pageIndex: 0 }))}
                disabled={!canGetPrevPage}
              >
                {'<<'}
              </Button>
              <Button
                variant='outlined'
                onClick={() => setPagination((cur) => ({ ...cur, pageIndex: cur.pageIndex - 1 }))}
                disabled={!canGetPrevPage}
              >
                {'< Prev'}
              </Button>
              <Button
                variant='outlined'
                onClick={() => setPagination((cur) => ({ ...cur, pageIndex: cur.pageIndex + 1 }))}
                disabled={!canGetNextPage}
              >
                {'Next >'}
              </Button>
              <Button
                variant='outlined'
                onClick={() => setPagination((cur) => ({ ...cur, pageIndex: pages - 1 }))}
                disabled={!canGetNextPage}
              >
                {'>>'}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Stack direction='row-reverse' justifyContent='center' spacing={2}>
              <span>
                <div>Page</div>
                <strong>
                  {quotesTable.getState().pagination.pageIndex + 1} of {pages}
                </strong>
              </span>
              <span>
                <span style={{ marginRight: '.75rem' }}>Go to page:</span>
                <Select
                  value={quotesTable.getState().pagination.pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    setPagination((current) => ({ ...current, pageIndex: page }));
                  }}
                >
                  {Array.from(Array(pages), (_, index) => index + 1).map((page) => (
                    <MenuItem key={page} value={page}>
                      {page}
                    </MenuItem>
                  ))}
                </Select>
              </span>
              <Select
                value={quotesTable.getState().pagination.pageSize}
                onChange={(e) => {
                  setPagination((cur) => ({
                    pageIndex: getPage(cur.pageSize, cur.pageIndex, Number(e.target.value)),
                    pageSize: Number(e.target.value),
                  }));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <MenuItem key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
        </Grid>
      </TableContainer>
    </>
  );
};

export default QuotesTable;
