import {
  GetTransactionsForClientDocument,
  GetTransactionsForClientQuery,
  GetTransactionsForClientQueryVariables,
  QueryGetTransactionsForClientArgs,
} from '__generated__/graphql';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

export const useQueryGetTransactionsForClient = (
  input: QueryGetTransactionsForClientArgs,
  options?: QueryHookOptions<GetTransactionsForClientQuery, GetTransactionsForClientQueryVariables>
): QueryResult<GetTransactionsForClientQuery, GetTransactionsForClientQueryVariables> => {
  return useQuery(GetTransactionsForClientDocument, {
    skip: !input.input.clientId,
    ...options,
    variables: input,
  });
};
