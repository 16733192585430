import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  PaginationState,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { ItemPurchaseRecord, PurchaseHistoryClient } from '__generated__/graphql';
import { useQueryItemPurchaseHistory } from 'hooks/queries/useQueryItemPurchaseHistory/useQueryItemPurchaseHistory';
import { formatCurrency, seoFriendly } from 'utils/functions';
import { Item } from 'utils/types';
import Pagination from 'components/Pagination/Pagination';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { TableCell, Row, Spacer, Table, TableHeaderCell } from 'components/TableComponents/TableComponents';
import { Container } from '@mui/material';
import FilterButton from 'components/FilterButton/FilterButton';
import FilterMenu from 'components/FilterMenu/FilterMenu';
import { useQueryGetTransactionTypes } from 'hooks/queries/useQueryGetTransactionTypes/useQueryGetTransactionTypes';
import { useQueryGetClientsForItemHistory } from 'hooks/queries/useQueryGetClientsForItemHistory/useQueryGetClientsForItemHistory';
import { useQueryGetDepartments } from 'hooks/queries/useQueryGetDepartments/useQueryGetDepartments';

const TransactionLink = styled(Link)(
  () => css`
    display: block;
    height: 100%;
    padding: 1rem;
    color: unset;
    text-decoration: none;
  `
);

const TableTitleContainer = styled(Container)(
  () => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
  `
);

const StyledTableHeader = styled(TableHeaderCell)(
  ({ theme }) => css`
    &:hover {
      cursor: pointer;
      background-color: ${theme.colors.hoverGray};
      border-radius: 0.25rem;
      box-shadow: 0 0.5rem 0.8125rem ${theme.colors.blackOpacity55};
    }
  `
);

const defaultHistoryFilters = {
  transactionType: '',
  clientId: '',
  market: '',
};

const defaultSortOptions: ItemHistorySortOptions = {
  sortBy: 'date',
  sortDirection: 'DESC',
};

type ItemHistorySortOptions = {
  sortBy: string;
  sortDirection: 'ASC' | 'DESC';
};

type ItemHistoryFilters = {
  transactionType: string;
  clientId: string;
  market: string;
};

type Props = {
  item: Item;
};

export function PurchaseHistoryTable({ item }: Props) {
  const columnHelper = createColumnHelper<ItemPurchaseRecord>();
  const [pagination, setPagination] = useState<PaginationState>({ pageSize: 10, pageIndex: 0 });
  const [pages, setPages] = useState<number>(0);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [sortOptions, setSortOptions] = useState<ItemHistorySortOptions>(defaultSortOptions);
  const [filters, setFilters] = useState<ItemHistoryFilters>(defaultHistoryFilters);
  const [appliedFilters, setAppliedFilters] = useState<ItemHistoryFilters>(defaultHistoryFilters);

  const handlePageChange = useCallback(
    (newPage: number) => setPagination((cur) => ({ ...cur, pageIndex: newPage })),
    []
  );

  const { data, loading, error } = useQueryItemPurchaseHistory(
    {
      getItemPurchaseHistoryInput: {
        itemId: item?.id ?? '',
        page: 0,
        ...sortOptions,
        ...appliedFilters,
      },
    },
    {
      skip: !item,
    }
  );
  const purchaseRecords: ItemPurchaseRecord[] = useMemo(
    () => data?.itemPurchaseHistory?.purchaseRecords as ItemPurchaseRecord[],
    [data?.itemPurchaseHistory?.purchaseRecords]
  );

  const filtersApplied = useMemo(() => {
    return Object.keys(appliedFilters).some(
      (key) =>
        appliedFilters[key as keyof ItemHistoryFilters] !== defaultHistoryFilters[key as keyof ItemHistoryFilters]
    );
  }, [appliedFilters]);

  // Create the market options from departments data
  // A client's market is a department's parent.name + name
  const { data: departmentsData } = useQueryGetDepartments();
  const markets = useMemo(
    () =>
      departmentsData?.getDepartments
        .map((department) => {
          return {
            name: department.parent?.name ? `${department.parent.name} : ${department.name}` : `${department.name}`,
            internalId: department.id,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    [departmentsData]
  );

  const { data: clientData } = useQueryGetClientsForItemHistory(item?.id ?? '');
  const clientList: PurchaseHistoryClient[] = useMemo(
    () => clientData?.getClientsForItemHistory ?? [],
    [clientData?.getClientsForItemHistory]
  );

  const { data: transactionTypesData } = useQueryGetTransactionTypes();
  const transactionTypeList: string[] = useMemo(
    () => transactionTypesData?.getTransactionTypes ?? [],
    [transactionTypesData?.getTransactionTypes]
  );

  useEffect(() => {
    if (purchaseRecords?.length > 0) {
      setPages(Math.ceil(purchaseRecords.length / pagination.pageSize));
    }
  }, [purchaseRecords?.length, pagination.pageSize]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.date, {
        id: 'date',
        cell: (info) => <span style={{ whiteSpace: 'nowrap' }}>{dayjs(info.getValue()).format('YYYY-MM-DD')}</span>,
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.transactionType, {
        id: 'type',
        cell: (info) => {
          return info.getValue() === 'Estimate'
            ? 'Quote'
            : info
                .getValue()
                .split(/(?=[A-Z])/)
                .join(' ');
        },
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord, {
        id: 'documentNumber',
        cell: (info) => {
          const { clientName, clientId, transactionNumber, transactionType } = info.getValue();
          return (
            <TransactionLink
              to={
                transactionType === 'Estimate' && clientName && clientId
                  ? `/quote/${seoFriendly(clientName)}-${clientId}/${transactionNumber}`
                  : ''
              }
            >
              {transactionNumber}
            </TransactionLink>
          );
        },
        header: 'Document Number',
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.clientName, {
        id: 'name',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.item.quantity, {
        id: 'quantity',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.item.adjCost, {
        id: 'salePrice',
        cell: (info) => formatCurrency(info.getValue() ?? 0),
        header: 'Sale Price',
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.item.total, {
        id: 'amount',
        cell: (info) => formatCurrency(info.getValue() ?? 0),
      }),
    ],
    [columnHelper]
  );

  const table = useReactTable({
    columns,
    data: purchaseRecords || [],
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: { pagination },
  });

  const handleFilterChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleAppliedFiltersChange = () => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    setAppliedFilters(filters);
  };

  const handleSortChange = (header: string) => {
    const numericColumnHeaders = ['date', 'quantity', 'salePrice', 'amount'];
    let newSortDirection: 'ASC' | 'DESC' = numericColumnHeaders.includes(header) ? 'DESC' : 'ASC';

    if (sortOptions.sortBy === header && sortOptions.sortDirection === 'ASC') {
      newSortDirection = 'DESC';
    }
    if (sortOptions.sortBy === header && sortOptions.sortDirection === 'DESC') {
      newSortDirection = 'ASC';
    }

    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    setSortOptions({
      sortBy: header,
      sortDirection: newSortDirection,
    });
  };

  const restorePreviouslySelectedFilters = () => {
    setFilters({ ...appliedFilters });
  };

  return (
    <>
      <TableTitleContainer>
        <FilterButton onClick={() => setIsFilterDrawerOpen((prev) => !prev)} isFilterApplied={filtersApplied} />
      </TableTitleContainer>
      <FilterMenu
        openDrawer={isFilterDrawerOpen}
        setOpenDrawer={setIsFilterDrawerOpen}
        clearFilters={() => setFilters((prev) => ({ ...defaultHistoryFilters }))}
        handleTransactionTypeChange={handleFilterChange}
        transactionTypeFilter={filters.transactionType}
        transactionTypeList={transactionTypeList}
        handleClientFilterChange={handleFilterChange}
        clientFilter={filters.clientId}
        clientList={clientList}
        handleAppliedFiltersChange={handleAppliedFiltersChange}
        handleMarketFilterChange={handleFilterChange}
        markets={markets}
        marketFilter={filters.market}
        restorePreviouslySelectedFilters={restorePreviouslySelectedFilters}
      />
      <Table>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, i) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <StyledTableHeader
                      $start={i === 0}
                      $end={header.id === 'amount'}
                      $noMarginBottom
                      onClick={() => handleSortChange(header.id)}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </StyledTableHeader>
                  )}
                </th>
              );
            })}
          </tr>
        ))}

        {loading && (
          <Row>
            <td colSpan={8} align='center'>
              <LoadingSpinner loading={loading} />
            </td>
          </Row>
        )}

        {!loading && !error && purchaseRecords?.length === 0 && (
          <Row>
            <td colSpan={8} align='center'>
              No transactions found
            </td>
          </Row>
        )}

        {!loading &&
          !error &&
          table.getRowModel().rows.map((row) => {
            return (
              <Row key={row.id}>
                {row.getVisibleCells().map((cell, i) => {
                  return (
                    <TableCell
                      $start={i === 0}
                      $end={cell.column.id === 'amount'}
                      key={cell.id}
                      $padding={cell.column.id === 'documentNumber' ? '0' : null}
                    >
                      <Spacer>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Spacer>
                    </TableCell>
                  );
                })}
              </Row>
            );
          })}
      </Table>
      <Pagination
        currentPage={pagination.pageIndex}
        pages={pages}
        onPageChange={handlePageChange}
        perPage={pagination.pageSize}
        onPerPageChange={(pageSize) => {
          setPagination({ pageIndex: 0, pageSize });
        }}
      />
    </>
  );
}
