import React from 'react';
import ButtonBase from '@mui/material/ButtonBase/ButtonBase';
import Popover from 'components/Popover/Popover';
import {
  QuoteStatusBackgroundColorMap,
  QuoteStatusActiveForegroundColorMap,
} from 'contexts/QuotesContext/QuotesContext';
import { QUOTE_STATUS } from 'utils/types';
import ActionPill from 'components/ActionPill/ActionPill';
import { quoteStatusToLookup } from 'utils/functions';
import { StitchRelationInput } from '__generated__/graphql';

type Props = {
  status: string;
  trigger: React.ReactNode;
  statusFilter?: QUOTE_STATUS[];
  statusChanged: (status: StitchRelationInput) => void;
  columns?: number;
};

const disabledStatuses = ['Processed', 'Expired'];

const QuoteStatusPopover: React.FC<Props> = ({
  status: selectedStatus,
  trigger,
  statusChanged,
  columns,
  statusFilter,
}) => {
  return (
    <Popover offset='0' trigger={trigger} width={columns ? `${columns * 8.25}rem` : ''}>
      {Object.values(QUOTE_STATUS)
        .filter((value: QUOTE_STATUS) => !statusFilter?.includes(value) || !statusFilter)
        .map((status: QUOTE_STATUS) => {
          const netsuiteStatus = quoteStatusToLookup(status);
          return (
            <ButtonBase
              key={status}
              onClick={() => statusChanged(netsuiteStatus)}
              disabled={disabledStatuses.includes(status)}
            >
              <ActionPill
                backgroundColor={QuoteStatusBackgroundColorMap[status]}
                activeForegroundColor={QuoteStatusActiveForegroundColorMap[status]}
                selected={status === selectedStatus}
                disabled={disabledStatuses.includes(status)}
              >
                {status}
              </ActionPill>
            </ButtonBase>
          );
        })}
    </Popover>
  );
};

export default QuoteStatusPopover;
