import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useSelectedThemeContext } from 'contexts/SelectedThemeContext/SelectedThemeContext';
import darkTheme from 'darkTheme';
import lightTheme from 'lightTheme';

type SwitchableThemeProviderProps = {
  children: React.ReactNode;
};

const SwitchableThemeContextProvider: React.FC<SwitchableThemeProviderProps> = ({ children }) => {
  const { lightMode } = useSelectedThemeContext();

  return <ThemeProvider theme={lightMode ? lightTheme : darkTheme}>{children}</ThemeProvider>;
};

export default SwitchableThemeContextProvider;
