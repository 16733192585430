import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetItemDocument, GetItemQuery, GetItemQueryVariables, QueryGetItemArgs } from '__generated__/graphql';

export const useQueryGetItem = (
  input: QueryGetItemArgs,
  options?: QueryHookOptions<GetItemQuery, GetItemQueryVariables>
): QueryResult<GetItemQuery, GetItemQueryVariables> => {
  return useQuery(GetItemDocument, {
    fetchPolicy: 'network-only',
    ...options,
    variables: input,
  });
};
