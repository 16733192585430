import React from 'react';
import { css, styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import LabeledSwitch from 'components/LabeledSwitch/LabeledSwitch';
import darkmode from 'assets/darkmode.svg';
import lightmode from 'assets/lightmode.svg';
import { opacity } from 'utils/functions';
import { useSelectedThemeContext } from 'contexts/SelectedThemeContext/SelectedThemeContext';

const Container = styled(Drawer)<{ $checked?: boolean }>(
  ({ theme, $checked }) => css`
    .MuiBackdrop-root {
      opacity: 0 !important;
    }

    .MuiPaper-root {
      transition-duration: 0ms !important;
      left: 6rem;
      top: calc(100vh - 8rem);
      height: 6.5rem;
      width: 12rem;
      padding: 1rem;
      border-radius: 0.5rem;
      border-top: 0.0625rem solid ${theme.colors.bevelTop};
      border-left: 0.0625rem solid ${theme.colors.bevelLeft};
      background-color: ${theme.colors.charcoal};
      box-shadow: -0.1875rem 0 0.5rem ${opacity(theme.colors.black, 0.2)};
      z-index: 1000;
      overflow: hidden;
    }

    .MuiSwitch-root {
      &:hover {
        .MuiSwitch-track {
          border: 0.0625rem solid transparent;
        }
      }

      .MuiSwitch-track {
        border: 0.0625rem solid transparent;
        padding-top: 0.375rem;
        background-color: ${theme.colors.lightBlack};

        &:hover {
          border: 0.0625rem solid transparent;
        }
      }

      .MuiSwitch-thumb {
        background-color: ${theme.colors.charcoal} !important;
      }
    }

    ${$checked &&
    css`
      .MuiSwitch-track {
        background-color: ${theme.colors.lighterInput} !important;
      }
    `}
  `
);

const Text = styled('p')(
  ({ theme }) => css`
    margin: 0 0 0.25rem 0;
    font-size: 0.75rem;
    font-weight: 600;
    color: ${theme.colors.whiteOpacity50};
    text-transform: uppercase;
  `
);

type Props = {
  show?: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const DarkLightModeToggle: React.FC<Props> = ({ show, setShow }) => {
  const { lightMode, setLightMode } = useSelectedThemeContext();

  return (
    <Container disableScrollLock anchor='left' open={show} $checked={lightMode} onClose={() => setShow(false)}>
      <Text>{lightMode ? 'Light' : 'Dark'} Mode</Text>
      <LabeledSwitch
        checked={lightMode}
        onChange={(e: React.BaseSyntheticEvent) => setLightMode(e.target.checked)}
        disableRipple
        checkedLabel={`url(${lightmode})`}
        uncheckedLabel={`url(${darkmode})`}
      />
    </Container>
  );
};

export default DarkLightModeToggle;
