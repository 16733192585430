import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { CreateClientDocument, CreateClientMutation, CreateClientMutationVariables } from '__generated__/graphql';
import { useMemo } from 'react';

type Data = CreateClientMutation;
type Variables = CreateClientMutationVariables;

function useMutationCreateClient(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(CreateClientDocument, opts);
}

export default useMutationCreateClient;
