import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Container, Grid } from '@mui/material';
import { AddressModel, CreateClientAddressInput, LookupType, UpdateClientAddressInput } from '__generated__/graphql';
import {
  createInitialValues,
  FormikInput,
  FormikCheckbox,
  FormikSelect,
  FormikValuesFrom,
  InputVariant,
} from 'utils/formik';
import { useQueryGetLookup } from 'hooks/queries/useQueryGetLookup/useQueryGetLookup';
import DataDrawer, { DataDrawerProps } from 'components/DataDrawer/DataDrawer';
import { useClientContext } from 'contexts/ClientContext/ClientContext';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Button from 'components/Button/Button';

export type CreateAddressModalProps = DataDrawerProps & {
  onSubmit: (CreateClientAddressInput: CreateClientAddressInput | UpdateClientAddressInput) => Promise<void>;
  values?: CreateClientAddressInput | UpdateClientAddressInput | AddressModel | null | undefined;
};

export const CreateAddressDrawer: React.FC<CreateAddressModalProps> = ({ open, onSubmit, onClose, title, values }) => {
  const { client } = useClientContext();
  const [saving, setSaving] = useState(false);

  const [stateLookupType, setStateLookupType] = useState<LookupType>(LookupType.StateCode);
  const { data: countryData } = useQueryGetLookup({ input: { type: LookupType.Country } });
  const { data: stateData, loading: stateDataLoading } = useQueryGetLookup({ input: { type: stateLookupType } });

  // Formik setup and configuration
  const formId = title.toLowerCase().replaceAll(' ', '-');
  const requiredFields = ['address1', 'city', 'state', 'zip', 'country'];
  const formFields = {
    country: 'Country',
    attention: 'Attention',
    addressee: 'Addressee',
    phone: 'Phone',
    address1: 'Address 1',
    address2: 'Address 2',
    city: 'City',
    state: 'State',
    zip: 'Zip',
    gstin: 'GSTIN',
    isDefaultBilling: 'Default Billing',
    isDefaultShipping: 'Default Shipping',
    isResidential: 'Residential Address',
    label: 'Label',
  };
  type FormikValues = FormikValuesFrom<typeof formFields, CreateClientAddressInput | UpdateClientAddressInput>;

  const toClientAddressInput = (values: FormikValues): CreateClientAddressInput | UpdateClientAddressInput => {
    return {
      ...values,
      isDefaultBilling: values.isDefaultBilling === true,
      isDefaultShipping: values.isDefaultShipping === true,
      client: { id: client!.id },
    };
  };

  const stateLookupTypes = useMemo((): { [key: string]: LookupType } => {
    return {
      US: LookupType.StateCode,
      CA: LookupType.CanadianProvince,
    };
  }, []);

  const countries = useMemo(() => {
    return countryData ? countryData.getLookup : {};
  }, [countryData]);

  const stateCodes = useMemo(() => {
    return stateData ? stateData.getLookup : {};
  }, [stateData]);

  const handleSubmit = (values: FormikValues) => {
    setSaving(true);
    onSubmit(toClientAddressInput(values)).then(() => setSaving(false));
  };

  const getInitialValues = () => {
    return { country: 'US', ...values };
  };

  return (
    <Formik initialValues={createInitialValues<FormikValues>(formFields, getInitialValues())} onSubmit={handleSubmit}>
      {({ values, setFieldValue, handleChange, handleSubmit, submitForm, resetForm }) => {
        const props = { handleChange, values, requiredFields, formFields, variant: InputVariant.Drawer };
        const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          if (stateLookupTypes[event.target.value]) {
            setStateLookupType(stateLookupTypes[event.target.value]);
          }
          setFieldValue('state', '');
          handleChange(event);
        };

        return (
          <DataDrawer
            open={open}
            onClose={() => {
              onClose();
              resetForm();
            }}
            title={title}
            width='42rem'
            headerButton={
              <LoadingSpinner loading={saving}>
                <Button variant='outlined' onClick={() => submitForm()}>
                  Save
                </Button>
              </LoadingSpinner>
            }
          >
            <form onSubmit={handleSubmit} id={formId}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <FormikSelect name='country' {...props} options={countries} handleChange={handleCountryChange} />
                  <FormikInput name='attention' {...props} />
                  <FormikInput name='addressee' {...props} />
                  <FormikInput name='phone' {...props} />
                  <FormikInput name='address1' {...props} />
                  <FormikInput name='address2' {...props} />
                  <FormikInput name='city' {...props} />
                </Grid>
                <Grid item xs={6}>
                  {stateLookupTypes[values.country] ? (
                    <FormikSelect disabled={stateDataLoading} name='state' {...props} options={stateCodes} />
                  ) : (
                    <FormikInput name='state' {...props} />
                  )}
                  <FormikInput name='zip' {...props} />
                  <FormikInput name='gstin' {...props} />
                  <FormikInput name='label' {...props} />
                  <Container sx={{ paddingTop: 2 }}>
                    <FormikCheckbox name='isDefaultBilling' {...props} />
                    <FormikCheckbox name='isDefaultShipping' {...props} />
                    <FormikCheckbox name='isResidential' {...props} />
                  </Container>
                </Grid>
              </Grid>
            </form>
          </DataDrawer>
        );
      }}
    </Formik>
  );
};

export const EditAddressDrawer: React.FC<CreateAddressModalProps> = ({ open, onSubmit, onClose, title, values }) => {
  return values ? <CreateAddressDrawer {...{ open, onSubmit, onClose, title, values }} /> : null;
};

export default CreateAddressDrawer;
