import React from 'react';
import { Dialog } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import { css, styled } from '@mui/material/styles';
import Flex from 'components/Flex/Flex';
import Button from 'components/Button/Button';

const DialogContentContainer = styled('div')`
  width: 21rem;
  padding: 2rem 2.5rem;
  border-radius: 1rem;
`;

const DialogTextWrapper = styled('div')`
  display: grid;
  grid-column-gap: 2.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 2rem 1fr;
  grid-auto-columns: 1fr;
  font-weight: 500;
  padding-bottom: 1rem;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 1rem;
  }
`;

const StyledReportIcon = styled(ReportIcon)`
  font-size: 3rem;
`;

const StyledButton = styled(Button)(
  () => css`
    width: 16rem;
  `
);

const ButtonWrapper = styled('div')`
  width: auto;
  padding: 0.65rem 0 0.5rem 0;
`;

type ConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm: () => void;
  message: string;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ open, onClose, onCancel, onConfirm, message }) => {
  function handleCancel() {
    if (onCancel) {
      onCancel();
    }
    onClose();
  }

  function handleConfirm() {
    onConfirm();
    onClose();
  }

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContentContainer>
        <DialogTextWrapper>
          <StyledReportIcon fontSize='large' />
          {message}
        </DialogTextWrapper>

        <ButtonWrapper>
          <Flex gap={1}>
            <StyledButton variant='outlined' onClick={handleCancel}>
              Cancel
            </StyledButton>
            <StyledButton variant='outlined' onClick={handleConfirm}>
              Confirm
            </StyledButton>
          </Flex>
        </ButtonWrapper>
      </DialogContentContainer>
    </StyledDialog>
  );
};

export default ConfirmationModal;
