import { useMemo } from 'react';
import { useQueryGetItemSkus } from 'hooks/queries/useQueryGetItemSkus/useQueryGetItemSkus';
import { useQueryGetItemSubSkus } from 'hooks/queries/useQueryGetItemSubSkus/useQueryGetItemSubSkus';
import { useDropdownOptions } from 'hooks/util/useDropdownOptions';
import { useQueryListVendors } from 'hooks/queries/useQueryListVendors/useQueryListVendors';

type Props = {
  sku: string;
};
type DropdownOption = {
  id: string;
  label: string;
};

export const useItemFilterDropdowns = ({ sku }: Props) => {
  const { data: itemSkusData } = useQueryGetItemSkus();
  const skuOptions = useMemo(() => {
    return itemSkusData?.getItemSkus.itemSkus.map((sku) => ({ id: sku, label: sku })) ?? [];
  }, [itemSkusData]);

  const { data: itemSubSkusData } = useQueryGetItemSubSkus(sku ?? '', {
    skip: !sku,
  });
  const subSkuOptions = useMemo(() => {
    return itemSubSkusData?.getItemSubSkus.itemSubSkus.map((subSku) => ({ id: subSku, label: subSku })) ?? [];
  }, [itemSubSkusData]);

  const { data: vendorData } = useQueryListVendors();
  const vendorOptions = useDropdownOptions(vendorData?.listVendors.vendors);

  const typeOptions: DropdownOption[] = [
    { id: 'DescriptionItem', label: 'Description Item' },
    { id: 'InventoryItem', label: 'Inventory Item' },
    { id: 'ItemGroup', label: 'Item Group' },
    { id: 'NonInventoryResaleItem', label: 'Non-inventory Resale Item' },
    { id: 'NonInventorySaleItem', label: 'Non-inventory Sale Item' },
    { id: 'OtherChargePurchaseItem', label: 'Other Charge Purchase Item' },
    { id: 'OtherChargeResaleItem', label: 'Other Change Resale Item' },
    { id: 'OtherChargeSaleItem', label: 'Other Charge Sale Item' },
    { id: 'ServiceSaleItem', label: 'Service Sale Item' },
    { id: 'ServiceResaleItem', label: 'Service Resale Item' },
  ];

  return {
    skuOptions,
    subSkuOptions,
    vendorOptions,
    typeOptions,
  };
};
