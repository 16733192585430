import introspection from '__generated__/introspection';

// Defining the rough shape the introspection data
interface IntrospectionFieldType {
  kind?: string | null;
  name?: string | null;
  ofType?: IntrospectionFieldType | null;
}
type IntrospectionType = {
  __schema: {
    types: {
      name: string;
      fields:
        | {
            name: string;
            type: IntrospectionFieldType;
          }[]
        | null;
      inputFields:
        | {
            name: string;
            type: IntrospectionFieldType;
          }[]
        | null;
    }[];
  };
};

// This allows casting as an Output Model to see the __typename property
type ModelType = {
  __typename: string;
};

let fieldNamesByType: Record<string, string[]>;
export function getFieldNamesByType(typeName: string) {
  // If we haven't already built the fieldNamesByType object, we'll build it now
  // Maps a type name to an array of field names
  if (!fieldNamesByType) {
    fieldNamesByType = introspection.__schema.types.reduce(
      (acc, type) => {
        if (type.fields) {
          acc[type.name] = type.fields.map((field) => field.name);
          acc[type.name] = ['__typename', ...acc[type.name]];
        } else if (type.inputFields) {
          acc[type.name] = type.inputFields.map((field) => field.name);
        }
        return acc;
      },
      {} as Record<string, string[]>
    );
  }
  return fieldNamesByType[typeName] || [];
}

// This gets the type name for an item in an array
export function getArrayType(typeName: string, fieldName: string) {
  const type = (introspection as IntrospectionType).__schema.types.find((type) => type.name === typeName);
  const fields = [...(type?.fields ?? []), ...(type?.inputFields ?? [])];
  if (fields) {
    const field = fields.find((field) => field.name === fieldName);
    if (field?.type.kind === 'LIST') {
      const getNestedType = (type: IntrospectionFieldType): IntrospectionFieldType => {
        if (type.ofType) {
          return getNestedType(type.ofType);
        }
        return type;
      };
      const baseType = getNestedType(field.type);
      if (baseType.kind === 'OBJECT' || baseType.kind === 'INPUT_OBJECT') {
        return baseType.name;
      } else if (baseType.kind === 'SCALAR') {
        return 'SCALAR';
      }
    }
    return null;
  }
}

// Get the type of a field
// For objects, it will return the type name so we can use it to get field names
export function getFieldType(typeName: string, fieldName: string) {
  const type = (introspection as IntrospectionType).__schema.types.find((type) => type.name === typeName);
  const fields = [...(type?.fields ?? []), ...(type?.inputFields ?? [])];
  if (fields.length > 0) {
    const field = fields.find((field) => field.name === fieldName);
    if (field) {
      const getNestedType = (type: IntrospectionFieldType): IntrospectionFieldType => {
        if (type.ofType) {
          return getNestedType(type.ofType);
        }
        return type;
      };
      const baseType = getNestedType(field.type);
      if (baseType.kind === 'LIST') {
        return 'LIST';
      } else if (baseType.kind === 'OBJECT' || baseType.kind === 'INPUT_OBJECT') {
        return baseType.name;
      } else if (baseType.kind === 'SCALAR') {
        return 'SCALAR';
      }
    }
  }
  return null;
}

// Check to see if the property is an integer scalar.
// This is needed to cast string data into number properties
export function isNumberType(typeName: string, fieldName: string) {
  const type = (introspection as IntrospectionType).__schema.types.find((type) => type.name === typeName);
  const fields = [...(type?.fields ?? []), ...(type?.inputFields ?? [])];
  if (fields.length > 0) {
    const field = fields.find((field) => field.name === fieldName);
    if (field) {
      const getNestedType = (type: IntrospectionFieldType): IntrospectionFieldType => {
        if (type.ofType) {
          return getNestedType(type.ofType);
        }
        return type;
      };
      const baseType = getNestedType(field.type);
      if (baseType.kind === 'SCALAR' && (baseType.name === 'Int' || baseType.name === 'Float')) {
        return true;
      }
    }
  }
  return false;
}

/*
  The purpose of this function is to turn any object into one of our GraphQL models.
  The source object does not need to be a model, but the expeceted use case is to convert an Object model into an Input model
  All of the casting of keys/types is because are absolutely certain what the object looks like from our introspection data
  but we don't have a way to enforce that in TypeScript.
*/
export function toModel<T>(source: object, targetModelName: string): T {
  function copyFields<U>(obj: object, modelName: string): U {
    const result: U = {} as U;
    const fieldNames = getFieldNamesByType(modelName);

    for (const fieldName of fieldNames) {
      // Casting this way to avoid TypeScript errors
      const objKey = fieldName as keyof typeof obj;
      if (fieldName in obj) {
        // Output types will have __typename, but input types will not
        // Set it to the class name so we don't copy over from the source object
        if (objKey === '__typename') {
          (result as ModelType).__typename = modelName;
        } else if (Array.isArray(obj[objKey])) {
          const arrayType = getArrayType(modelName, fieldName);
          if (arrayType === 'SCALAR') {
            result[objKey] = obj[objKey];
          } else if (arrayType) {
            // Recursively copy each item in the array
            (result[objKey] as object[]) = (obj[objKey] as object[]).map((item) => {
              return copyFields(item, arrayType);
            });
          }
        } else if (typeof obj[objKey] === 'object' && obj[objKey] !== null) {
          const modelFieldType = getFieldType(modelName, objKey);
          // Special case for StitchRelations.  Check to see if our target type is just a string, and if so only return the ID
          if (
            modelFieldType === 'SCALAR' &&
            '__typename' in obj[objKey] &&
            obj[objKey]['__typename'] === 'StitchRelation'
          ) {
            result[objKey] = obj[objKey]['id'];
          } else if (modelFieldType) {
            // Otherwise recursively copy the object the same way we are for arrays
            (result[objKey] as object) = copyFields(obj[objKey] as object, modelFieldType);
          } else {
            // If we don't know the type, just copy it over
            result[objKey] = obj[objKey];
          }
        } else {
          // For all other types, just copy them over.  Then check and convert numbers
          result[objKey] = obj[objKey];
          if (isNumberType(modelName, fieldName)) {
            (result[objKey] as number) = Number(result[objKey]);
          }
        }
      }
    }
    return result;
  }
  return copyFields(source, targetModelName);
}
