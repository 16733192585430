import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { decodeJwt } from 'jose';
import {
  Action,
  AUTH_REDIRECT_KEY,
  AUTH_STATE_KEY,
  AUTH_TOKEN_KEY,
  Permission,
  Permissions,
  LIMIT_QUOTES_ROLE_IDS,
} from 'utils/constants';

type AuthContextState = {
  error: string | null;
  returnTo: string | null;
  tokens: {
    accessToken: string | null;
    refreshToken: string | null;
    idToken: string | null;
  };
  permissions: Permissions | null;
  isLoggedIn: boolean;
  initiateLogIn: (returnTo?: string | null) => void;
  validateCode: (code: string, state: string) => Promise<boolean>;
  refreshTokens: () => Promise<string>;
  reportError: (error: string | null) => void;
  logOut: () => void;
  loading: boolean;
  can: (permission: Permission, action: Action) => boolean;
  employeeId: string;
};

const errors: Record<string, string> = {
  invalid_grant: 'The authorization code used was invalid or expired. Please try again.',
  invalid_client: 'There is a configuration error in Netsuite. Please contact your administrator.',
  invalid_request: 'The sales portal sent an invalid request to Netsuite. Please contact your administrator.',
  server_error: 'An error occurred in NetSuite. Please contact your administrator.',
  unauthorized_client: 'There is a configuration error in Netsuite. Please contact your administrator.',
};

type TokensResultType = {
  error?: string | null;
  access_token: string | null;
  id_token: string | null;
  refresh_token: string | null;
  permissions: Permissions;
};

let tokens;
try {
  tokens = JSON.parse(window.localStorage.getItem(AUTH_TOKEN_KEY) ?? 'null');
} catch (ex) {
  //nothing to do, but Catch is required
} finally {
  if (!tokens) {
    tokens = {
      accessToken: '',
      refreshToken: '',
      idToken: '',
      permissions: {},
    };
  }
}

const returnTo = window.localStorage.getItem(AUTH_REDIRECT_KEY);

const state = window.localStorage.getItem(AUTH_STATE_KEY);

const initialState: Partial<AuthContextState> = {
  returnTo,
  tokens,
  permissions: tokens.permissions || {},
};

const AuthContext = createContext<AuthContextState>(initialState as AuthContextState);

const AuthContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | null>(initialState.tokens?.accessToken || null);
  const [refreshToken, setRefreshToken] = useState<string | null>(initialState.tokens?.refreshToken || null);
  const [idToken, setIdToken] = useState<string | null>(initialState.tokens?.idToken || null);
  const [permissions, setPermissions] = useState<Permissions | null>(initialState.permissions || null);
  const [employeeId, setEmployeeId] = useState<string>('');
  const [roleId, setRoleId] = useState<string>('');

  const initiateLogIn = useCallback(async (returnTo?: string | null) => {
    setLoading(true);
    setError(null);
    const cryptoArray = new Uint32Array(10);
    self.crypto.getRandomValues(cryptoArray);
    const state = btoa(cryptoArray.join(''));

    if (returnTo) {
      window.localStorage.setItem(AUTH_REDIRECT_KEY, returnTo);
    }
    window.localStorage.setItem(AUTH_STATE_KEY, state);

    const result = await fetch(`${process.env.REACT_APP_API}/auth/login?state=${state}`);
    if (200 == result.status) {
      const newUrl = await result.text();
      if (newUrl) {
        window.location.href = newUrl;
      } else {
        setError('Failed to redirect to the SSO provider: no URL returned');
        setLoading(false);
      }
    } else {
      const message = await result.json();
      setError(`Failed to redirect to the SSO provider: ${message?.message}`);
      setLoading(false);
    }
  }, []);

  const handleUpdatedTokens = useCallback(
    (tokens: TokensResultType) => {
      if (tokens.error) {
        setError(errors[tokens.error] ?? tokens.error);
        setLoading(false);
        return false;
      }

      if (!tokens.access_token) {
        setError('No access token was returned and no error message was set');
        setLoading(false);
        return false;
      }

      window.localStorage.setItem(
        AUTH_TOKEN_KEY,
        JSON.stringify({
          accessToken: tokens.access_token,
          refreshToken: tokens.refresh_token ?? refreshToken,
          idToken: tokens.id_token ?? idToken,
          permissions: tokens.permissions ?? permissions,
        })
      );
      setAccessToken(tokens.access_token);
      setRefreshToken(tokens.refresh_token ?? refreshToken);
      setIdToken(tokens.id_token ?? idToken);
      setPermissions(tokens.permissions ?? permissions);
      setError(null);
      setLoading(false);
      return true;
    },
    [idToken, permissions, refreshToken]
  );

  const validateCode = useCallback(
    async (code: string, authState: string) => {
      if (state !== authState) {
        setError('Provided state does not match. Please try again.');
        return false;
      }
      setLoading(true);
      const result = await fetch(`${process.env.REACT_APP_API}/auth/token?code=${code}`);
      try {
        const tokens = await result.json();

        return handleUpdatedTokens(tokens);
      } catch (ex) {
        setError(`Failed to get access token: ${(ex as Error).message ?? ''}`);
        setLoading(false);
        return false;
      }
    },
    [handleUpdatedTokens]
  );

  const reportError = useCallback((error: string | null) => {
    if (error) {
      setError(errors[error] ?? error);
    } else {
      setError(null);
    }
  }, []);

  const logOut = useCallback(() => {
    window.localStorage.removeItem(AUTH_TOKEN_KEY);
    setAccessToken(null);
    setRefreshToken(null);
    setIdToken(null);
    setError(null);
  }, []);

  const refreshTokens = useCallback(async () => {
    if (!refreshToken) {
      return '';
    }

    try {
      const decoded = decodeJwt(refreshToken);
      const valid = (decoded?.exp && decoded.exp > Date.now() / 1000) || false;
      if (!valid) {
        return '';
      }
    } catch (ex) {
      return '';
    }

    let result;
    try {
      result = await fetch(`${process.env.REACT_APP_API}/auth/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `refresh_token=${refreshToken}`,
      });
    } catch (ex) {
      setError(`An error occurred while refreshing your session: ${(ex as Error).message}`);
      return '';
    }

    try {
      const tokens = await result.json();
      handleUpdatedTokens(tokens);
      return tokens.access_token;
    } catch (ex) {
      setError('Your session could not be refreshed, and you have been logged out.');
      return '';
    }
  }, [handleUpdatedTokens, refreshToken]);

  const isLoggedIn = useMemo(() => {
    if (!accessToken) {
      return false;
    }

    try {
      const decoded = decodeJwt(accessToken);
      const valid = (decoded?.exp && decoded.exp > Date.now() / 1000) || false;
      if (!valid) {
        refreshTokens().then((token) => {
          if ('' === token) {
            setError('Your session has expired. Please log in again');
          }
        });
      }
      return valid;
    } catch (ex) {
      setError(`failed to parse token: ${ex}`);
      return false;
    }
  }, [accessToken, refreshTokens]);

  const can = useCallback(
    (permission: Permission, action: Action) => {
      if (!permissions) {
        return false;
      }

      if (permission === 'Quotation' && action === Action.ViewAll) {
        return !!(process.env.REACT_APP_SHOW_ALL_QUOTES || !LIMIT_QUOTES_ROLE_IDS.includes(roleId));
      }

      const order = [Action.None, Action.View, Action.Edit, Action.Full];

      return order.findIndex((x) => x == permissions[permission]) >= order.findIndex((x) => x === action);
    },
    [permissions, roleId]
  );

  // sets a timer to refresh the accessTok before it expires
  useEffect(() => {
    if (!accessToken) {
      return;
    }
    const details = decodeJwt(accessToken);
    const employeeId = details.sub?.split(';')[1] || '';
    const roleId = details.sub?.split(';')[0] || '';
    setEmployeeId(employeeId);
    setRoleId(roleId);
    if (details?.exp) {
      //set the refresh to happen 90% of the way through the lifetime of the accessToken.
      //There's no sense in pushing it all the way to when it expires
      const timeoutDuration = 0.9 * (details.exp * 1000 - Date.now());
      const timer = setTimeout(() => {
        refreshTokens().then((newToken) => {
          if (!newToken) {
            setAccessToken(''); //this will log the user out
            setRefreshToken('');
          }
        });
      }, timeoutDuration);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [refreshTokens, accessToken, handleUpdatedTokens, error]);

  return (
    <AuthContext.Provider
      value={{
        error: error,
        reportError: reportError,
        loading: loading,
        returnTo: returnTo,
        tokens: {
          accessToken,
          refreshToken,
          idToken,
        },
        permissions,
        isLoggedIn,
        initiateLogIn,
        validateCode,
        refreshTokens,
        logOut,
        can,
        employeeId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContext was used outside of its Provider');
  }

  return context;
};

export default AuthContextProvider;
