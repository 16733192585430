import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetCommentsByQuoteIdDocument,
  GetCommentsByQuoteIdQuery,
  GetCommentsByQuoteIdQueryVariables,
} from '__generated__/graphql';

export const useQueryGetComments = (
  quoteId: string,
  options?: QueryHookOptions<GetCommentsByQuoteIdQuery, GetCommentsByQuoteIdQueryVariables>
): QueryResult<GetCommentsByQuoteIdQuery, GetCommentsByQuoteIdQueryVariables> => {
  return useQuery(GetCommentsByQuoteIdDocument, {
    ...options,
    variables: { quoteId },
  });
};
