import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { ItemTableItem } from 'contexts/QuoteContext/useItems';
import { useState } from 'react';
import { css, styled } from 'styled-components';

const Input = styled('input')(
  () => css`
    all: unset;
    background: transparent;
    width: 100%;
  `
);

type Props = {
  item: ItemTableItem;
  index: number;
  onBlurCallback?: () => void;
};

export const ItemSalePriceInput = ({ item, index, onBlurCallback }: Props) => {
  const [value, setValue] = useState(item.adjCost ?? 0);

  const { updateItemField } = useQuoteContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value));
  };

  const handleBlur = () => {
    updateItemField(index, 'adjCost', value);
    onBlurCallback && onBlurCallback();
  };

  return <Input onChange={handleChange} onBlur={handleBlur} value={value} type='number' />;
};
