import React from 'react';
import { css, styled, Interpolation } from '@mui/material/styles';

const containerProps = ['column', 'center', 'padding', 'gap', 'wrap', 'w100', 'h100', 'additionalStyles'];
const Container = styled('div', {
  shouldForwardProp: (prop: string) => !containerProps.includes(prop as string),
})<{
  column?: boolean;
  center?: boolean;
  padding?: number;
  gap?: number;
  wrap?: boolean;
  w100?: boolean;
  h100?: boolean;
  additionalStyles?: Interpolation<string>;
}>(
  ({ column, padding = 0, center, gap = 0, wrap, w100, h100, additionalStyles }) => css`
    display: flex;
    flex-direction: ${column ? 'column' : 'row'};
    padding: ${`${padding}rem`};
    flex-wrap: ${wrap ? 'wrap' : 'nowrap'};
    align-items: ${center ? 'center' : 'flex-start'};
    justify-content: ${center ? 'center' : 'flex-start'};
    width: ${w100 ? '100%' : 'auto'};
    height: ${h100 ? '100%' : 'auto'};
    gap: ${`${gap}rem`};
    ${additionalStyles}
  `
);

type Props = {
  children: React.ReactNode;
  center?: boolean;
  column?: boolean;
  w100?: boolean;
  h100?: boolean;
  wrap?: boolean;
  padding?: number;
  gap?: number;
  styles?: Interpolation<string>;
};

const Flex: React.FC<Props> = ({ children, w100, h100, gap, wrap, center, column, padding, styles = {} }) => {
  return (
    <Container
      w100={w100}
      h100={h100}
      gap={gap}
      wrap={wrap}
      column={column}
      center={center}
      padding={padding}
      additionalStyles={styles}
    >
      {children}
    </Container>
  );
};

export default Flex;
