import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { useLocation } from 'react-router-dom';

const Error: React.FC = () => {
  const { state } = useLocation();
  const message = state?.message ?? 'Something went wrong';

  return (
    <Alert color='error'>
      <AlertTitle>{message}</AlertTitle>
    </Alert>
  );
};

export default Error;
