import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { useQueryGetEmployee } from 'hooks/queries/useQueryGetEmployee/useQueryGetEmployee';
import { Employee } from '__generated__/graphql';

type UserContextState = {
  user?: Partial<Employee>;
};

const UserContext = createContext<UserContextState | undefined>(undefined);

const UserContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { employeeId } = useAuthContext();
  const { data: userData } = useQueryGetEmployee({ id: employeeId }, { skip: !employeeId });

  const userContextValue = useMemo(() => {
    return {
      user: userData?.getEmployee,
    };
  }, [userData]);

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext was used outside of its Provider');
  }

  return context;
};

export { useUserContext, UserContextProvider, UserContext };
