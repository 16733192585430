import { styled, css } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

const SearchResultHeader = styled(ButtonBase)(
  ({ theme }) => css`
    width: 100%;
    border-bottom: 0.0625rem solid ${theme.colors.black};
    background-color: ${theme.palette.primary.main};
    font-size: 0.875rem;
    font-weight: 600;
    align-items: center;
    justify-content: start;
    padding: 0 2rem;
    text-align: left;
    height: 4.375rem;

    & span {
      padding: 1.5rem 0.5rem 1.5rem 0.75rem;
    }

    & > div {
      height: 100%;
      padding: 1.5rem 0.5rem 1.5rem 0.75rem;
      flex: 1;
    }

    & > div:last-of-type {
      flex: 2;
    }

    &:first-child {
      border-top: 0.0625rem solid ${theme.colors.bevelTop};
      border-left: 0.0625rem solid ${theme.colors.bevelLeft};
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
    }

    &:nth-child(n + 2) {
      border-left: 0.0625rem solid ${theme.colors.bevelLeft};
    }

    &:last-child {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      flex: 2;
    }
  `
);

export default SearchResultHeader;
