import DataModal from 'components/DataModal/DataModal';
import { useQuoteSubNavContext } from 'contexts/QuoteSubNavContext/QuoteSubNavContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { css, styled } from '@mui/material/styles';
import OwnerAvatar from 'components/OwnerAvatar/OwnerAvatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CommentPopover from './components/CommentPopover';
import AddCommentModal from './components/AddCommentModal';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { useQueryGetEmployee } from 'hooks/queries/useQueryGetEmployee/useQueryGetEmployee';
import { useQueryGetComments } from 'hooks/queries/useQueryGetComments/useQueryGetComments';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { Snackbar } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom';

const CommentContainer = styled('div')(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.darkBoundary};
    padding-bottom: 1em;
    margin-bottom: 1em;
  `
);

const CommentAvatar = styled('div')(
  () => css`
    padding-right: 2em;
  `
);

const CommentHeader = styled('div')(
  () => css`
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
  `
);

const CommentHeaderText = styled('div')(
  () => css`
    flex-grow: 6;
  `
);

const CommentUsername = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.white};
  `
);

const CommentDate = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.whiteOpacity50};
    font-family: ${theme.typography.fontFamily};
    font-size: 0.875rem;
    font-weight: 500;
  `
);

const CommentBody = styled(Typography)(
  ({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    overflow-wrap: break-word;
  `
);

const AddCommentButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.colors.white};
    height: 2.5rem;
    min-width: 0;
    padding: 1rem;
    display: flex;
    border-radius: 1.25rem;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${theme.colors.lighterBlack};
    }
  `
);

const EditedMessage = styled('span')(
  ({ theme }) => css`
    color: ${theme.colors.whiteOpacity25};
    padding-left: 0.5em;
    font-size: small;
  `
);

const getFullUserName = (firstName?: string | null, lastName?: string | null): string => {
  return `${firstName ?? ''} ${lastName ?? ''}`;
};

const getFormattedDate = (date: string): string => {
  return `
    ${new Date(date).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })} |
    ${new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
  `;
};

const CommentModal = () => {
  const { showComments, toggleShowComments } = useQuoteSubNavContext();
  const [showAddComments, setShowAddComments] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const { employeeId } = useAuthContext();
  const { quote, commentsToBeCreated } = useQuoteContext();
  const { quoteNumber } = useParams();
  const { data: userData } = useQueryGetEmployee({ id: employeeId });
  const { data: commentData, refetch } = useQueryGetComments(quote?.id || '', {
    skip: !quote?.id || !quoteNumber,
    pollInterval: 60000,
  });
  const onCloseCallback = useCallback(() => {
    toggleShowComments();
  }, [toggleShowComments]);
  const currentUser = {
    id: employeeId,
    name: getFullUserName(userData?.getEmployee.firstName, userData?.getEmployee.lastName),
  };
  const latestCommentRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => latestCommentRef.current?.scrollIntoView({ behavior: 'smooth' }), 1);
    return;
  }, [commentData?.getCommentsByQuoteId.length, showComments]);

  const comments = quoteNumber ? commentData?.getCommentsByQuoteId || [] : commentsToBeCreated;

  return (
    <>
      <DataModal
        open={showComments || false}
        onCloseCallback={onCloseCallback}
        hideButton={true}
        buttonText='none'
        title='Comments'
        onButtonClick={() => {}}
        trigger={<></>}
        hideCreate={true}
      >
        {comments.map((comment) => {
          const commentOwner = {
            id: comment.userId,
            name: getFullUserName(comment.user.firstName, comment.user.lastName),
          };

          return (
            <CommentContainer key={comment.id}>
              <CommentHeader>
                <CommentAvatar>
                  <OwnerAvatar owner={commentOwner} ownerRole='rep' />
                </CommentAvatar>
                <CommentHeaderText>
                  <CommentUsername variant='h6'>{commentOwner.name}</CommentUsername>
                  <CommentDate>{getFormattedDate(comment.createdAt)}</CommentDate>
                </CommentHeaderText>
                {comment.userId === currentUser.id && (
                  <CommentPopover
                    commentId={comment.id}
                    refetch={refetch}
                    setShowSnackbar={setShowSnackbar}
                    setSnackbarMessage={setSnackbarMessage}
                    currentUser={currentUser}
                    commentBody={comment.commentBody}
                  />
                )}
              </CommentHeader>
              <CommentBody variant='p'>
                {comment.commentBody}
                {comment.updatedAt && comment.updatedAt !== comment.createdAt && (
                  <Tooltip title={getFormattedDate(comment.updatedAt)}>
                    <EditedMessage>(edited)</EditedMessage>
                  </Tooltip>
                )}
              </CommentBody>
            </CommentContainer>
          );
        })}
        <AddCommentButton onClick={() => setShowAddComments(true)}>
          <AddIcon /> Add Comment
        </AddCommentButton>
        <div ref={latestCommentRef} />
      </DataModal>
      <AddCommentModal
        showAddComments={showAddComments}
        setShowAddComments={setShowAddComments}
        owner={currentUser}
        userId={employeeId}
        quoteId={quote?.id ?? ''}
        refetchComments={refetch}
        setShowSnackbar={setShowSnackbar}
        setSnackbarMessage={setSnackbarMessage}
      />
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </>
  );
};

export default CommentModal;
