import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { css, styled, useTheme } from '@mui/material/styles';
import { ApolloError } from '@apollo/client';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

// We should probably make this a component, the default mui button is invisible!
const StyledButton = styled(Button)(
  ({ theme }) => css`
    background-color: ${theme.colors.blue};
    color: white;
    border-radius: 2rem;
    height: 1.625rem;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    border: none;

    &:hover,
    &:focus {
      border: none;
      background-color: ${theme.colors.hoverBlue};
    }
  `
);

const SpacedDialogActions = styled('div')(
  () => css`
    gap: 0.5rem;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: flex-end;
    flex: 0 0 auto;
  `
);

const ErrorTitle = styled(DialogTitle)(
  ({ theme }) => css`
    padding-top: 0;
    color: ${theme.colors.red};
    font-size: 0.875rem;
  `
);

export type ModalProps = {
  modalOpen: boolean;
  setModalOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  title: string;
  children: React.ReactNode;
  formId?: string;
  loading?: boolean;
  error?: ApolloError | undefined;
  controlled?: boolean;
};

const Modal: React.FC<ModalProps> = ({
  modalOpen,
  setModalOpen,
  children,
  title,
  formId,
  loading,
  error,
  controlled,
}) => {
  const theme = useTheme();
  const doClose = () => setModalOpen(false);
  const color = theme.palette.mode === 'dark' ? '#141414' : '#EAEDEF';

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={doClose}
        PaperProps={{
          style: {
            backgroundColor: color,
          },
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        {error && <ErrorTitle>Error: {error.message}</ErrorTitle>}
        <DialogContent>{children}</DialogContent>
        <SpacedDialogActions>
          <LoadingSpinner loading={!!loading} size='1.625rem'>
            <StyledButton type='submit' form={formId} onClick={!controlled ? doClose : () => null}>
              Save
            </StyledButton>
          </LoadingSpinner>
          <StyledButton onClick={doClose}>Cancel</StyledButton>
        </SpacedDialogActions>
      </Dialog>
    </>
  );
};

export default Modal;
