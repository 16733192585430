import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { SAVE_DRAFT } from 'graphql/quotes/saveDraft.gql';
import { SaveDraftMutation, SaveDraftMutationVariables } from '__generated__/graphql';

type Data = SaveDraftMutation;
type Variables = SaveDraftMutationVariables;

function useMutationSaveDraft(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  return useMutation(SAVE_DRAFT, options);
}

export default useMutationSaveDraft;
