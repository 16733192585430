import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetCustomListDocument,
  GetCustomListQuery,
  GetCustomListQueryVariables,
  QueryGetCustomListArgs,
} from '__generated__/graphql';

export const useQueryGetCustomList = (
  input: QueryGetCustomListArgs,
  options?: QueryHookOptions<GetCustomListQuery, GetCustomListQueryVariables>
): QueryResult<GetCustomListQuery, GetCustomListQueryVariables> => {
  return useQuery(GetCustomListDocument, {
    ...options,
    variables: input,
  });
};
