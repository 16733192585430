import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { EditCommentDocument, EditCommentMutation, EditCommentMutationVariables } from '__generated__/graphql';
import { useMemo } from 'react';

type Data = EditCommentMutation;
type Variables = EditCommentMutationVariables;

function useMutationEditComment(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(EditCommentDocument, opts);
}

export default useMutationEditComment;
