import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  ListVendorsDocument,
  ListVendorsQuery,
  ListVendorsQueryVariables,
  QueryListVendorsArgs,
} from '__generated__/graphql';

export const useQueryListVendors = (
  itemInternalId?: QueryListVendorsArgs,
  options?: QueryHookOptions<ListVendorsQuery, ListVendorsQueryVariables>
): QueryResult<ListVendorsQuery, ListVendorsQueryVariables> => {
  return useQuery(ListVendorsDocument, {
    ...options,
    variables: itemInternalId,
  });
};
