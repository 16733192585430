import { Button, Container, Grid, MenuItem, Select, Stack } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  Row as RowModel,
  Cell,
} from '@tanstack/react-table';
import Flex from 'components/Flex/Flex';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import {
  AnchoredTh,
  LeftActionCell,
  RightActionCell,
  Row,
  RowActionsWrapperLeft,
  RowActionsWrapperRight,
  Spacer,
  Table,
  TableCell,
  TableHeaderCell,
} from 'components/TableComponents/TableComponents';
import { useMemo, useState } from 'react';
import { css, styled } from 'styled-components';
import { getPage } from 'utils/functions';

const StyledTableCell = styled(TableCell)(
  ({ theme }) => css`
    border-right-width: 0px;
    border-left-color: ${theme.colors.darkBoundary};
    text-decoration: none;
    padding: 0;
  `
);

type Pagination = {
  page: number;
  perPage: number;
  total: number;
};

export type ActionCellProps<T> = {
  row: RowModel<T>;
  isActive: boolean;
};

type Column<T> = {
  id: string;
  header: string;
  valueFn?: (row: T) => React.ReactNode;
  cellOverride?: (cell: Cell<T, unknown>, children: React.ReactNode) => React.ReactNode;
};

type Props<T> = {
  loading: boolean;
  data: T[] | undefined;
  columns: Column<T>[];
  pagination?: Pagination;
  setPagination?: React.Dispatch<React.SetStateAction<Pagination>>;
  leftActionCell?: React.FC<ActionCellProps<T>>;
  rightActionCell?: React.FC<ActionCellProps<T>>;
};

function DataTable<T>(props: Props<T>) {
  const { data, columns, pagination, setPagination } = props;

  const [rowActionsVisible, setrowActionsVisible] = useState<{ [key: string]: boolean }>({});
  const emptyArray: T[] = [];

  const columnHelper = createColumnHelper<T>();
  const columnDefinitions = useMemo(
    () =>
      columns.map((column) => {
        return columnHelper.accessor((row: T) => row, {
          id: column.id.toString(),
          header: () => <span>{column.header}</span>,
          cell: (info) => {
            if (column.valueFn) {
              return column.valueFn(info.getValue());
            } else {
              return info.getValue()[column.id as keyof T];
            }
          },
        });
      }),
    [columns, columnHelper]
  );

  const columnCount = useMemo(() => {
    return columnDefinitions.length + (props.rightActionCell ? 1 : 0);
  }, [columnDefinitions, props.rightActionCell]);

  const table = useReactTable<T>({
    columns: columnDefinitions,
    data: data ?? emptyArray,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              <AnchoredTh />
              {headerGroup.headers.map((header, i) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <TableHeaderCell $start={i === 0} $end={i === headerGroup.headers.length - 1}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHeaderCell>
                    )}
                  </th>
                );
              })}
              {props.rightActionCell && <RightActionCell />}
            </tr>
          ))}
        </thead>
        {props.loading && (
          <Row>
            <td colSpan={columnCount} align='center'>
              <LoadingSpinner loading={props.loading} />
            </td>
          </Row>
        )}
        {data &&
          table.getRowModel().rows.map((row) => {
            return (
              <Row
                key={row.id}
                onMouseEnter={() => setrowActionsVisible({ ...rowActionsVisible, [row.id]: true })}
                onMouseLeave={() => setrowActionsVisible({ ...rowActionsVisible, [row.id]: false })}
              >
                {props.leftActionCell && (
                  <LeftActionCell>
                    <RowActionsWrapperLeft sx={{ marginTop: '1rem' }}>
                      <Flex column h100 styles={{ justifyContent: 'space-around' }}>
                        {props.leftActionCell({ row, isActive: rowActionsVisible[row.id] })}
                      </Flex>
                    </RowActionsWrapperLeft>
                  </LeftActionCell>
                )}
                {row.getVisibleCells().map((cell, i) => {
                  return (
                    <StyledTableCell $start={i === 0} $end={i === row.getVisibleCells().length - 1} key={cell.id}>
                      {columns[i].cellOverride ? (
                        columns[i].cellOverride!(cell, flexRender(cell.column.columnDef.cell, cell.getContext()))
                      ) : (
                        <Spacer>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Spacer>
                      )}
                    </StyledTableCell>
                  );
                })}
                {props.rightActionCell && (
                  <RightActionCell>
                    <RowActionsWrapperRight>
                      <Flex column h100 styles={{ justifyContent: 'space-around' }}>
                        <props.rightActionCell isActive={rowActionsVisible[row.id]} row={row} />
                      </Flex>
                    </RowActionsWrapperRight>
                  </RightActionCell>
                )}
              </Row>
            );
          })}
      </Table>

      {pagination && setPagination && <PagingControls pagination={pagination} setPagination={setPagination} />}
    </Container>
  );
}

type PagingProps = {
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
};

const PagingControls: React.FC<PagingProps> = ({ pagination, setPagination }: PagingProps) => {
  const pages = useMemo(() => {
    return Math.ceil(pagination.total / pagination.perPage);
  }, [pagination.total, pagination.perPage]);
  const canGetPrevPage = useMemo(() => pagination.page > 0, [pagination.page]);
  const canGetNextPage = useMemo(() => pagination.page < pages - 1, [pages, pagination.page]);

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={0} lg={4} />
      <Grid item xs={12} lg={4} alignItems='center'>
        <Stack direction={'row'} spacing={2} justifyContent='center'>
          <Button
            variant='outlined'
            onClick={() => setPagination((cur) => ({ ...cur, page: 0 }))}
            disabled={!canGetPrevPage}
          >
            {'<<'}
          </Button>
          <Button
            variant='outlined'
            onClick={() => setPagination((cur) => ({ ...cur, page: cur.page - 1 }))}
            disabled={!canGetPrevPage}
          >
            {'< Prev'}
          </Button>
          <Button
            variant='outlined'
            onClick={() => setPagination((cur) => ({ ...cur, page: cur.page + 1 }))}
            disabled={!canGetNextPage}
          >
            {'Next >'}
          </Button>
          <Button
            variant='outlined'
            onClick={() => setPagination((cur) => ({ ...cur, page: pages - 1 }))}
            disabled={!canGetNextPage}
          >
            {'>>'}
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Stack direction='row-reverse' spacing={2}>
          <span>
            <div>Page</div>

            <strong>
              {pagination.page + 1} of {pages}
            </strong>
          </span>
          <span>
            <span style={{ marginRight: '.75rem' }}>Go to page:</span>
            <Select
              value={pagination.page + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                setPagination((cur) => ({ ...cur, page }));
              }}
            >
              {Array.from(Array(pages), (_, index) => index + 1).map((page) => (
                <MenuItem key={page} value={page}>
                  {page}
                </MenuItem>
              ))}
            </Select>
          </span>
          <Select
            value={pagination.perPage}
            onChange={(e) => {
              setPagination((cur) => ({
                page: getPage(cur.perPage, cur.page, Number(e.target.value)),
                perPage: Number(e.target.value),
                total: cur.total,
              }));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize}>
                Show {pageSize}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DataTable;
