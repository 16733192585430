import { ButtonBase } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { opacity } from 'utils/functions';
import Drawer from '@mui/material/Drawer';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Flex from 'components/Flex/Flex';
import Button from 'components/Button/Button';

const ModalHeader = styled('div')(
  ({ theme }) => css`
    padding: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 0.0625rem solid ${theme.colors.bevelTop};
    border-left: 0.0625rem solid ${theme.colors.bevelLeft};

    svg {
      font-size: 1.1rem;
    }
  `
);

const ChildContainer = styled('div')<{ $childPadding?: string; childPaddingTop?: string }>(
  () => css`
    padding: 2rem;
    padding-top: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  `
);

const CloseButton = styled(ButtonBase)(
  ({ theme }) => css`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      color: ${theme.colors.white};
      background-color: ${theme.colors.lightBlack};
    }
  `
);

const Title = styled('h3')(
  () => css`
    margin: 0;
    line-height: 1.4;
    font-weight: 700;
    font-size: 1.5rem;
  `
);

const ModalContainer = styled(Drawer)(
  ({ theme, $width = '32rem' }) => css`
    height: 0;

    .MuiPaper-root {
      transition-duration: 447ms !important;
      top: 5rem;
      right: 1rem;
      border-radius: 1rem;
      border-top: 0.0625rem solid ${theme.colors.bevelTop};
      border-left: 0.0625rem solid ${theme.colors.bevelLeft};
      height: calc(100vh - 6rem);
      width: ${theme.breakpoints.down('tablet') ? $width : '100%'};
      box-shadow: -0.1875rem 0 0.5rem ${opacity(theme.colors.black, 0.2)};
      background: ${theme.palette.primary.main};
    }
  `
);

export type DataDrawerProps = {
  open: boolean;
  onClose: () => void;
  onHeaderButtonClick?: () => void;
  headerButtonText?: string;
  headerButton?: React.ReactNode;
  title: string;
  children?: React.ReactNode;
  width?: string;
  fullHeight?: boolean;
};

const DataDrawer = ({
  open,
  onClose,
  onHeaderButtonClick,
  headerButtonText,
  headerButton,
  title,
  children,
  width,
}: DataDrawerProps) => {
  return (
    <ModalContainer
      disableScrollLock
      $width={width}
      anchor='right'
      open={open}
      onClose={onClose}
      ModalProps={{
        slotProps: {
          backdrop: { invisible: true },
        },
      }}
    >
      <ModalHeader>
        <CloseButton onClick={onClose}>
          <KeyboardDoubleArrowRightIcon />
        </CloseButton>

        <Flex styles={{ justifyContent: 'space-between', padding: '0.75rem 1.75rem' }}>
          <Title>{title}</Title>

          {headerButton
            ? headerButton
            : onHeaderButtonClick && (
                <Button variant='outlined' onClick={onHeaderButtonClick}>
                  {headerButtonText}
                </Button>
              )}
        </Flex>
      </ModalHeader>

      <ChildContainer>{children}</ChildContainer>
    </ModalContainer>
  );
};

export default DataDrawer;
