import styled, { css } from 'styled-components';

export const LoadingContainer = styled('div')(
  () => css`
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);
