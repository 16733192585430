import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { CreateCommentDocument, CreateCommentMutation, CreateCommentMutationVariables } from '__generated__/graphql';
import { useMemo } from 'react';

type Data = CreateCommentMutation;
type Variables = CreateCommentMutationVariables;

function useMutationCreateComment(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(CreateCommentDocument, opts);
}

export default useMutationCreateComment;
