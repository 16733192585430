import InfoIcon from '@mui/icons-material/Info';
import { css, styled } from '@mui/material/styles';
import { LazyQueryExecFunction } from '@apollo/client';
import { ModalFormInput } from 'components/FormInput/FormInput';
import React, { ChangeEvent, FocusEvent, SetStateAction, useMemo } from 'react';
import { AutocompleteOption, FieldInfo, FieldType } from './ItemFormConstants';
import {
  ItemTableItem,
  UpdatableField,
  UpdatableFieldValue,
  validateUpdatableField,
} from 'contexts/QuoteContext/useItems';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { CustomListType, EstimatedUnitCostInput, Exact, GetEstimatedUnitCostsQuery } from '__generated__/graphql';
import { ModalFormLabel } from 'components/FormLabel/FormLabel';
import { useQueryGetCustomList } from 'hooks/queries/useQueryGetCustomList/useQueryGetCustomList';
import { Autocomplete } from 'components/Autocomplete/Autocomplete';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';

export const StyledInfoIcon = styled(InfoIcon)(
  () => css`
    font-size: 0.875rem;
    margin-left: 0.5rem;
  `
);

const Div = styled('div')(
  () => css`
    width: 100%;
  `
);

const InputWrapper = styled('div')(
  () => css`
    width: 100%;
    gap: 0.5rem;
  `
);

type Props = {
  index: number;
  initialVendorSearch: string;
  values: ItemTableItem;
  handleChange: {
    (e: ChangeEvent): void;
    <T = string | ChangeEvent>(field: T): T extends ChangeEvent ? void : (e: string | ChangeEvent) => void;
  };
  vendorOpts: { id: string; label: string }[];
  costEstimateTypeOptions: { [key: string]: string };
  keyList: string[];
  estimatedUnitCostLoading: boolean;
  getEstimatedUnitCost: LazyQueryExecFunction<GetEstimatedUnitCostsQuery, Exact<{ input: EstimatedUnitCostInput }>>;
  getEstimatedUnitCostInput: (
    costestimatetype: string,
    locationId?: string
  ) => {
    client: string;
    items: { item: string; costestimatetype: string; location?: string }[];
  };
  setValues: (values: SetStateAction<ItemTableItem>, shouldValidate?: boolean | undefined) => void;
  hasSelectedWarehouses: boolean;
};

const ItemFormColumn: React.FC<Props> = ({
  index,
  values,
  handleChange,
  vendorOpts,
  costEstimateTypeOptions,
  keyList,
  estimatedUnitCostLoading,
  getEstimatedUnitCost,
  setValues,
  getEstimatedUnitCostInput,
  hasSelectedWarehouses,
}) => {
  const { updateItemField, updateItemFields } = useQuoteContext();

  const { data: deviationCodeListData } = useQueryGetCustomList({ id: CustomListType.DeviationCode });
  const deviationCodeOptions = useMemo(
    () =>
      deviationCodeListData?.getCustomList?.customValueList?.customValue?.map((opt) => ({
        label: opt.value,
        id: `${opt.valueId}`,
      })) ?? [],
    [deviationCodeListData]
  );

  const { data: leadTimeListData } = useQueryGetCustomList({ id: CustomListType.LeadTime });
  const leadTimeOptions = useMemo(
    () =>
      leadTimeListData?.getCustomList?.customValueList?.customValue?.map((opt) => ({
        label: opt.value,
        id: `${opt.valueId}`,
      })) ?? [],
    [leadTimeListData]
  );

  const keyToType: { [key: string]: FieldType } = {
    description: FieldType.TextArea,
    leadTime: FieldType.Select,
    costEstimateType: FieldType.CostEstimateTypeSelect,
    estimatedUnitCost: FieldType.EstimatedUnitCostInput,
    unitCost: FieldType.Number,
    estimatedTotalCost: FieldType.Number,
    margin: FieldType.Percent,
    deviationCode: FieldType.Select,
    adjCost: FieldType.Number,
    isTaxable: FieldType.Select,
    total: FieldType.Number,
    grossProfit: FieldType.Number,
    grossProfitPercent: FieldType.Percent,
    vendor: FieldType.Select,
    vendorQuoteReference: FieldType.String,
    vendorPORate: FieldType.Number,
    quantity: FieldType.QuantityInput,
  };

  const keyToInfo: { [key: string]: FieldInfo } = {
    description: { title: 'Description', disabled: false },
    leadTime: { title: 'Lead Time', disabled: false },
    costEstimateType: { title: 'Cost Estimate Type *', disabled: false, required: true },
    estimatedUnitCost: { title: 'Estimated Unit Cost', disabled: true },
    unitCost: { title: 'Unit Cost', disabled: false },
    estimatedTotalCost: { title: 'Extended Estimated Cost', disabled: values.costEstimateType !== 'CUSTOM' },
    margin: { title: 'Margin', disabled: false },
    deviationCode: {
      title: 'Deviation Code *',
      tooltip: 'Deviation code is required if not using I2P pricing options.',
      required: true,
    },
    adjCost: { title: 'Sale Price', disabled: false },
    isTaxable: { title: 'Tax', disabled: false },
    total: { title: 'Total', disabled: false },
    grossProfit: { title: 'Estimated Gross Profit', disabled: true },
    grossProfitPercent: { title: 'Estimated Gross Profit', disabled: true },
    vendor: { title: 'Vendor', disabled: false },
    vendorQuoteReference: { title: 'Vendor Quote Reference', disabled: false },
    vendorPORate: { title: 'Vendor PO Rate', disabled: false },
    quantity: { title: 'Quantity', disabled: false },
  };

  const selectOptions: { [key: string]: AutocompleteOption[] } = {
    deviationCode: deviationCodeOptions,
    leadTime: leadTimeOptions,
    vendor: vendorOpts,
    isTaxable: [
      { label: 'Yes', id: '1' },
      { label: 'No', id: '0' },
    ],
  };

  const handleEstimatedCostTypeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const costEstimateType = event.target.value ?? '';

    // Set the form's cost estimate type
    setValues((prev) => ({ ...prev, costEstimateType }));

    // If its "CUSTOM" we're done. The item will be update to match the form onBlur
    if (event.target.value === 'CUSTOM') {
      return;
    }

    try {
      // If its not "CUSTOM", we are going to fetch the estimated unit cost, which will disable input and skip the onBlur handler
      const res = await getEstimatedUnitCost({
        variables: {
          input: getEstimatedUnitCostInput(
            event.target.value,
            values?.inventory ? values?.inventory[0]?.id : undefined
          ),
        },
      });

      const estimatedUnitCost = Number(res.data?.getEstimatedUnitCosts?.items?.[0]?.estimatedUnitCost);

      if (isNaN(estimatedUnitCost)) {
        return;
      }

      const quantity = values?.inventory?.reduce((acc, curr) => acc + curr.quantity, 0);

      const updates: Partial<ItemTableItem> = {
        costEstimateType,
        estimatedUnitCost,
        estimatedTotalCost: estimatedUnitCost * Number(quantity ?? 0),
      };

      if (estimatedUnitCost > 0) {
        updates.unitCost = estimatedUnitCost;
      }

      // Update item which will also trigger a form update
      updateItemFields(index, updates as Record<UpdatableField, UpdatableFieldValue>);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const field = validateUpdatableField(e.target.name);

    let value = values[field];

    if (field === 'isTaxable') {
      value = value === '1' ? true : false;
    }

    if (value === undefined || value === null) {
      value = '';
    }

    updateItemField(index, field, value);
  };

  return keyList.map((key) => {
    const fieldType = keyToType[key];
    const value = values[key as keyof typeof values];

    const info = keyToInfo[key];
    const { title, tooltip } = info;

    const isNumber =
      fieldType === FieldType.Percent || fieldType === FieldType.Number || fieldType === FieldType.QuantityInput;

    return (
      <Div key={key}>
        <ModalFormLabel>
          {title}
          {fieldType === FieldType.Percent && '%'}
          {tooltip && (
            <Tooltip title={tooltip}>
              <StyledInfoIcon />
            </Tooltip>
          )}
        </ModalFormLabel>
        <InputWrapper>
          {fieldType !== FieldType.VendorSelect &&
            fieldType !== FieldType.Select &&
            fieldType !== FieldType.CostEstimateTypeSelect &&
            fieldType !== FieldType.EstimatedUnitCostInput && (
              <ModalFormInput
                name={key}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                onScroll={(e: MouseEvent) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onFocus={(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => e.target.select()}
                multiline={fieldType === FieldType.TextArea}
                type={isNumber ? 'number' : 'text'}
                disableUnderline
                disabled={
                  keyToInfo[key].disabled ||
                  (fieldType === FieldType.QuantityInput && hasSelectedWarehouses) ||
                  key === 'total' // This field is only calculated, never set manually
                }
              />
            )}
          {fieldType === FieldType.EstimatedUnitCostInput && (
            <LoadingSpinner loading={estimatedUnitCostLoading}>
              <ModalFormInput name={key} value={value} disableUnderline disabled={true} />
            </LoadingSpinner>
          )}
          {fieldType === FieldType.Select && (
            <Autocomplete
              name={key}
              value={value}
              options={selectOptions[key]}
              disabled={estimatedUnitCostLoading}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
          {fieldType === FieldType.CostEstimateTypeSelect && (
            <Autocomplete
              name={key}
              value={value}
              options={costEstimateTypeOptions}
              disabled={estimatedUnitCostLoading}
              onChange={handleEstimatedCostTypeChange}
              onBlur={handleBlur}
            />
          )}
        </InputWrapper>
      </Div>
    );
  });
};

export default ItemFormColumn;
