import { Box, Typography } from '@mui/material';
import DataModal from 'components/DataModal/DataModal';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { ItemTableItem } from 'contexts/QuoteContext/useItems';
import { CustomListType } from '__generated__/graphql';
import { css, styled } from '@mui/material/styles';
import { PriceList } from './components/PriceList';
import { DeviationCodeInput } from './components/DeviationCodeInput';
import { useEffect, useMemo, useState } from 'react';
import { useQueryGetCustomList } from 'hooks/queries/useQueryGetCustomList/useQueryGetCustomList';

const BorderedBox = styled(Box)(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.secondary.main};
    padding-top: 3rem;
    padding-bottom: 3rem;
    div {
      padding-top: 0;
      padding-bottom: 0;

      span {
        padding-top: 0;
      }
    }
  `
);

export type PricingModalProps = {
  itemsTableItems: ItemTableItem[];
  open: boolean;
  onCloseCallback: () => void;
};

export type DeviationCodesToApply = { [key: number]: string };

const PricingModal = ({ open, onCloseCallback, itemsTableItems }: PricingModalProps) => {
  const { applyI2pPricing, i2pPricing, updateItemField } = useQuoteContext();

  const { data: deviationCodeListData } = useQueryGetCustomList({ id: CustomListType.DeviationCode });

  const deviationCodeOptions = useMemo(
    () =>
      deviationCodeListData?.getCustomList?.customValueList?.customValue?.map((opt) => ({
        label: opt.value,
        id: `${opt.valueId}`,
      })) ?? [],
    [deviationCodeListData]
  );

  // Get the devation codes currently selected in the items table.
  const defaultDeviationCodesToApply = useMemo(() => {
    const codes: DeviationCodesToApply = {};

    const defaultOption = deviationCodeOptions.find((opt) => opt.label === 'Other');

    itemsTableItems.forEach((item, index) => {
      const pricingLine = i2pPricing?.quotelines.find(
        (pricingLine) => pricingLine.line_id === `${item.item.name}__${index}`
      );

      // For items that do not pricing recommendations available, set the deviation code input to “Other”
      if (!pricingLine || pricingLine.error_message !== 'Valid price generated.') {
        codes[index] = item.deviationCode || defaultOption?.id || '';
      } else {
        codes[index] = item.deviationCode || '';
      }
    });

    return codes;
  }, [itemsTableItems, deviationCodeOptions, i2pPricing?.quotelines]);

  // key = index of item in itemsTableItems
  // value = selected deviation code ID
  const [deviationCodesToApply, setDeviationCodesToApply] = useState(defaultDeviationCodesToApply);

  useEffect(() => {
    setDeviationCodesToApply(defaultDeviationCodesToApply);
  }, [defaultDeviationCodesToApply]);

  const handleApplyAll = () => {
    // Apply selected deviation codes
    Object.entries(deviationCodesToApply).forEach(([key, value]) => {
      updateItemField(Number(key), 'deviationCode', value);
    });

    applyI2pPricing();

    onCloseCallback();
  };

  return (
    <DataModal
      open={open}
      onButtonClick={handleApplyAll}
      buttonText={'Apply All'}
      title={'Recommended Pricing'}
      trigger={<></>}
      onCloseCallback={onCloseCallback}
      hideCreate={true}
      width={'48em'}
    >
      {itemsTableItems.map((item, idx) => {
        const pricingLine = i2pPricing?.quotelines.find(
          (pricingLine) => pricingLine.line_id === `${item.item.name}__${idx}`
        );

        return (
          <BorderedBox key={item.itemNumber}>
            <Typography variant='h2'>{item.storeDisplayName ?? item.itemNumber ?? item.item.name}</Typography>
            <Typography>{item.description}</Typography>
            <Typography sx={{ my: '1rem' }}>
              {pricingLine ? 'Recommended Pricing' : 'No recommended pricing available for this item'}
            </Typography>
            {pricingLine && (
              <PriceList
                item={item}
                pricingLine={pricingLine}
                index={idx}
                deviationCodesToApply={deviationCodesToApply}
                setDeviationCodesToApply={setDeviationCodesToApply}
              />
            )}
            {!pricingLine && (
              <DeviationCodeInput
                deviationCodesToApply={deviationCodesToApply}
                setDeviationCodesToApply={setDeviationCodesToApply}
                index={idx}
              />
            )}
          </BorderedBox>
        );
      })}
    </DataModal>
  );
};

export default PricingModal;
