import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';

type Props = TypographyProps & {
  header: string;
};

const PageHeader = styled(({ header, ...props }: Props) => <Typography {...props}>{header}</Typography>)(
  ({ theme }) => css`
    color: ${theme.colors.white};
    padding: 0 0 1rem;
    text-align: left;
    margin-bottom: 1.5rem;
  `
);

export default PageHeader;
