import React, { FocusEvent, useEffect, useMemo, useRef } from 'react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { css, Interpolation, styled, ThemeOptions } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Flex from 'components/Flex/Flex';
import DataModal from 'components/DataModal/DataModal';
import { formatCurrency, formatPercent, stripHTML } from 'utils/functions';
import { QuoteMetrics, useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { QuoteItem } from '__generated__/graphql';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import Button from 'components/Button/Button';
import { ItemTableItem, nonInventoryTypes, nonInventoryTypesWithQuantity } from 'contexts/QuoteContext/useItems';
import { ItemGroupQuantityInput } from '../ItemGroupQuantityInput/ItemGroupQuantityInput';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from 'components/Tooltip/Tooltip';

const Header = styled('div')(
  () => css`
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    margin-top: 0.5rem;
  `
);

const SubtotalDisplay = styled('div')(
  () => css`
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 2.275rem;
  `
);

const Table = styled('table')(
  () => css`
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
  `
);

const TableHeader = styled('thead')(
  ({ theme }) => css`
    background: ${theme.palette.primary.main};
    position: sticky;
    top: 0;
    z-index: 1;
  `
);

const baseCellStyles: Interpolation<{ theme: ThemeOptions }> = ({ theme }: { theme: ThemeOptions }) => css`
  font-size: 0.65rem;
  font-weight: 700;
  color: ${theme.colors.whiteOpacity50};
  text-align: left;
  padding: 0.625rem 0;
  border-bottom: 0.0625rem solid ${theme.colors.black};

  &:not(:first-child) {
    text-align: right;
  }
`;

const HeaderCell = styled('th')(
  () => css`
    ${baseCellStyles};
    padding-left: 0.6rem;
    &:first-of-type {
      padding-left: 0;
    }
  `
);

const Cell = styled('td')(
  ({ theme }) => css`
    ${baseCellStyles};
    color: ${theme.palette.text.primary};
    font-size: 0.875rem;
    font-weight: 600;
    min-width: 4rem;
    padding-left: 0.6rem;

    &:first-of-type {
      padding-left: 0;
    }
  `
);

export const Quantity = styled(TextField)(
  ({ theme }) => css`
    background-color: ${theme.palette.input.main};
    border-radius: 0.5rem;

    input {
      width: 3rem;
      border: none;
      padding: 0.25rem 0.5rem;
      height: 2rem;
      font-size: 0.875rem;
      font-weight: 600;
    }

    fieldset {
      border: none;
    }
  `
);

const StatRow = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: ${theme.palette.text.primary};
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.625rem 0;
    border-bottom: 0.0625rem solid ${theme.colors.black};

    p {
      margin: 0;
      &:first-child {
        color: ${theme.colors.whiteOpacity50};
      }
    }

    &:last-child {
      p {
        &:last-child {
          font-size: 2.25rem;
        }
      }
    }
  `
);

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

const StyledInfoIcon = styled(InfoIcon)(
  () => css`
    font-size: 1rem;
    margin-left: 0.5rem;
  `
);

const displayedStatsMap: { [key: string]: string } = {
  subtotal: 'Subtotal',
  totalMargin: 'Total Margin',
  grossProfit: 'Gross Profit',
  grossProfitPercent: 'Gross Profit Percent',
  shippingCost: 'Estimated Shipping',
  tax: 'Tax',
  total: 'Quote Total',
};

const percentStats = ['totalMargin', 'grossProfitPercent'];

const updateSubtotalItems = (items: QuoteItem[], currItem: QuoteItem, index: number): QuoteItem[] => [
  ...items.slice(0, index),
  currItem,
  ...items.slice(index + 1),
];

const Subtotal = () => {
  const { quote, quoteMetrics, setModifiedFields, updateItemQuantitiesFromQuoteItems, quoteFlowType, itemsTableItems } =
    useQuoteContext();

  const total = quoteMetrics?.total;

  const subtotalItems = useRef(quote?.items || []);

  useEffect(() => {
    subtotalItems.current = quote?.items || [];
  }, [quote?.items]);

  const columnHelper = createColumnHelper<QuoteItem>();

  const updateSelectedQuote = () => {
    if (!quote || !quote.items) return;

    setModifiedFields((prev) => ({ ...prev, areItemsModified: true }));

    updateItemQuantitiesFromQuoteItems(subtotalItems.current);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor((quoteItem) => quoteItem, {
        id: 'itemNumber',
        cell: (info) => {
          const quoteItem = info.getValue();
          if (quoteItem.description) {
            return (
              <Tooltip
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [-2, 0],
                        },
                      },
                    ],
                  },
                }}
                placement='bottom-start'
                title={stripHTML(quoteItem.description, { removeBr: true })}
              >
                <span>{quoteItem.storeDisplayName || quoteItem.item.name}</span>
              </Tooltip>
            );
          }
          return quoteItem.storeDisplayName || quoteItem.item.name;
        },
        header: () => (
          <Flex>
            <span>Item #</span>
            <Tooltip title='Hover over item number to view description'>
              <StyledInfoIcon />
            </Tooltip>
          </Flex>
        ),
      }),
      columnHelper.accessor((quoteItem) => quoteItem.location?.name, {
        id: 'warehouse',
        cell: (info) => info.getValue(),
        header: () => <span>Warehouse</span>,
      }),
      columnHelper.accessor((quoteItem, index) => ({ item: quoteItem, index }), {
        id: 'quantity',
        cell: (info) => {
          const { item, index } = info.getValue();

          if (item.type === 'ItemGroup') {
            return <ItemGroupQuantityInput index={index} item={item} />;
          } else if (
            nonInventoryTypes.includes(item.type ?? '') &&
            !nonInventoryTypesWithQuantity.includes(item.type ?? '')
          ) {
            return null;
          }

          return (
            <Quantity
              defaultValue={item.quantity}
              onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const index = subtotalItems.current.findIndex(
                  (item) => item.item.id === item.item.id && item.location?.id === item.location?.id
                );
                if (-1 === index) {
                  return;
                }

                const currItem = { ...subtotalItems.current[index], quantity: parseInt(e.target.value, 10) };

                subtotalItems.current = updateSubtotalItems(subtotalItems.current, currItem, index);
              }}
              name='numberformat'
              id='formatted-numberformat-input'
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          );
        },
        header: () => <span>Quantity</span>,
      }),
      columnHelper.accessor((quoteItem) => quoteItem, {
        id: 'unitCost',
        cell: (info) => {
          if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(info.getValue().type ?? '')) {
            return null;
          }
          return formatCurrency(info.getValue().unitCost ?? undefined);
        },
        header: () => <span>Unit Cost</span>,
      }),
      columnHelper.accessor((quoteItem) => quoteItem, {
        id: 'price',
        cell: (info) => {
          if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(info.getValue().type ?? '')) {
            return null;
          }
          return formatCurrency(info.getValue().adjCost ?? undefined);
        },
        header: () => <span>Sale Price</span>,
      }),
      columnHelper.accessor((quoteItem) => quoteItem, {
        id: 'margin',
        cell: (info) => {
          if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(info.getValue().type ?? '')) {
            return null;
          }
          return formatPercent(info.getValue().margin ?? undefined);
        },
        header: () => <span>Margin</span>,
      }),
      columnHelper.accessor((quoteItem, index) => ({ item: quoteItem, index }), {
        id: 'lineTotal',
        cell: (info) => {
          const { item, index } = info.getValue();

          if (item.type === 'EndGroup') {
            // Starting at the EndGroup item, loop backwards until you get to an ItemGroup item
            // Add all the items along the way into the "group" array
            let currentIndex = index;
            let currentItem = subtotalItems.current[index];
            const group: ItemTableItem[] = [];

            while (currentItem.type !== 'ItemGroup' && currentIndex > -1) {
              currentItem = subtotalItems.current[currentIndex];
              group.push(currentItem);
              currentIndex--;
            }

            // Get the total cost of the group
            const groupTotal = group.reduce((accumulator, currentValue) => {
              return accumulator + (currentValue?.quantity ?? 0) * (currentValue?.adjCost ?? 0);
            }, 0);

            return formatCurrency(groupTotal);
          } else if (['ItemGroup', 'DescriptionItem'].includes(item.type ?? '')) {
            return null;
          }

          return formatCurrency(item.total ?? undefined);
        },
        header: () => <span>Line Total</span>,
      }),
    ],
    [columnHelper, subtotalItems]
  );

  const subtotalTable = useReactTable({
    columns,
    data: subtotalItems.current || [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Flex column styles={{ marginBottom: '1rem' }}>
      <DataModal
        // onCloseCallback={resetSubtotalItems}
        onButtonClick={updateSelectedQuote}
        buttonText='Update Quantities'
        title='Quote Total'
        width='42rem'
        trigger={
          <Button variant='outlined'>{'new' === quoteFlowType ? 'Review and Save' : 'Review and Save Updates'}</Button>
        }
      >
        <Table>
          <TableHeader>
            {subtotalTable.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <HeaderCell key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </HeaderCell>
                ))}
              </tr>
            ))}
          </TableHeader>
          {subtotalItems.current && (
            <tbody>
              {subtotalTable.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Cell>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {quoteMetrics &&
          Object.keys(quoteMetrics).map((stat) => {
            const statValue = quoteMetrics[stat as keyof QuoteMetrics];
            if (!displayedStatsMap[stat]) return null;
            else {
              return (
                <StatRow key={stat}>
                  <p>{displayedStatsMap[stat]}</p>
                  {/* Stat value can be guaranteed to be a number here*/}
                  <p>
                    {percentStats.includes(stat)
                      ? formatPercent(statValue as number)
                      : formatCurrency(statValue as number)}
                  </p>
                </StatRow>
              );
            }
          })}
      </DataModal>

      <Header>Quote Total</Header>
      <SubtotalDisplay>{formatCurrency(total)}</SubtotalDisplay>
    </Flex>
  );
};

export default Subtotal;
