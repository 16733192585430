import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Flex from 'components/Flex/Flex';
import Search from 'components/Search/Search';
import ClientSearchResults from 'routes/CreateQuote/components/ClientSearchResults/ClientSearchResults';
import { seoFriendly } from 'utils/functions';
import { Customer } from '__generated__/graphql';
import { useQuery } from '@apollo/client';
import { SEARCH_NETSUITE_CLIENTS } from 'graphql/clients/netsuiteClients.gql';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

const ClientSearch: React.FC = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const navigate = useNavigate();
  const [searchTerms, setSearchTerms] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<Customer | null>(null);
  const { data, refetch, loading } = useQuery(SEARCH_NETSUITE_CLIENTS, {
    skip: searchTerms.length < 3,
    variables: { search: searchTerms },
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchTerms.length >= 3) {
        refetch({ search: searchTerms });
      }
    }, 300);

    return () => clearTimeout(timeout);
    //The exhaustive deps requirement here triggers an infinite loop with this useEffect when refetch is included, so we must disable the warning
    //eslint-disable-next-line
  }, [searchTerms]);

  const filteredClients = data ? data.searchNetSuiteClients.clients : [];

  const updateSelectedClient = (client: Customer) => {
    setSelectedClient(client);
  };

  const updateSearchTerms = (term: string) => {
    if (selectedClient) {
      setSelectedClient(null);
    }
    setSearchTerms(term);
  };

  return (
    <>
      <Flex
        w100
        wrap
        gap={1}
        styles={{ justifyContent: 'space-between', marginBottom: '1rem', flexWrap: `${isTablet ? 'wrap' : 'nowrap'}` }}
      >
        <Search
          placeholder={'Search for a client...'}
          value={searchTerms}
          onChange={(e) => updateSearchTerms(e.target.value)}
        />
        <Flex
          gap={1}
          w100={isTablet}
          styles={{ justifyContent: 'space-between', flexWrap: `${isMobile ? 'wrap' : 'nowrap'}` }}
        >
          <Button
            variant='outlined'
            disabled={!selectedClient}
            onClick={() => {
              if (selectedClient) {
                navigate(`/sales-order/${seoFriendly(`${selectedClient.name}`)}-${selectedClient.id}/create`);
              }
            }}
            sx={{ width: `${isTablet ? '100%' : 'auto'}` }}
          >
            Create Sales Order
          </Button>
          <Button
            sx={{ width: `${isMobile ? '100%' : 'auto'}` }}
            disabled={!searchTerms}
            variant='outlined'
            color='error'
            onClick={() => {
              setSearchTerms('');
              setSelectedClient(null);
            }}
          >
            Cancel
          </Button>
        </Flex>
      </Flex>
      <Flex w100 center>
        <LoadingSpinner loading={loading}>
          <ClientSearchResults
            hide={!searchTerms}
            clientList={filteredClients}
            onSelect={updateSelectedClient}
            selectedClient={selectedClient}
          />
        </LoadingSpinner>
      </Flex>
    </>
  );
};

export default ClientSearch;
