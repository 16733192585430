import { Container, Stack } from '@mui/material';
import { CreateClientAddressInput, GetClientV2Query, UpdateClientAddressInput } from '__generated__/graphql';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import Button from 'components/Button/Button';
import DataTable, { ActionCellProps } from 'components/DataTable/DataTable';
import FormHeader from 'components/FormHeader/FormHeader';
import { IconWrapper } from 'components/TableComponents/TableComponents';
import { useClientContext } from 'contexts/ClientContext/ClientContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { stripHTML } from 'utils/functions';
import CreateAddressDrawer, { EditAddressDrawer } from './CreateAddressDrawer';
import { useAppContext } from 'contexts/AppContext/AppContext';
import useMutationCreateAddress from 'hooks/mutations/useMutationCreateAddress/useMutationCreateAddress';
import useMutationUpdateAddress from 'hooks/mutations/useMutationUpdateAddress/useMutationUpdateAddress';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { OpenButton } from 'routes/Quote/components/AddItems/components/ItemsTable/ItemsTable';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Flex from 'components/Flex/Flex';

type NonNullable<T> = T extends null | undefined ? never : T;

type Address = NonNullable<NonNullable<GetClientV2Query['getClientV2']['addressBookList']>['addressBook']>[0];

export const Addresses = () => {
  const { clientV2: client, loading, refetchClientV2: refetchClient } = useClientContext();
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [updateIsOpen, setUpdateIsOpen] = useState(false);
  const { showSnackbar } = useAppContext();

  const [createAddress] = useMutationCreateAddress();
  const [updateAddress] = useMutationUpdateAddress();

  const handleStartEditAddress = useCallback((address: Address) => {
    setSelectedAddress(address);
    setUpdateIsOpen(true);
  }, []);

  const handleCreateSubmit = useCallback(
    (input: CreateClientAddressInput | UpdateClientAddressInput): Promise<void> => {
      return new Promise((resolve) => {
        const createInput = input as CreateClientAddressInput;
        if (createInput) {
          showSnackbar('Creating address...');
          createAddress({ variables: { input: createInput } })
            .then(async () => {
              await refetchClient();
              showSnackbar('Address created successfully');
              setUpdateIsOpen(false);
            })
            .catch(() => {
              showSnackbar('Failed creating address');
            })
            .finally(() => resolve());
        }
      });
    },
    [createAddress, refetchClient, showSnackbar]
  );

  const handleUpdateSubmit = useCallback(
    (input: CreateClientAddressInput | UpdateClientAddressInput): Promise<void> => {
      return new Promise((resolve) => {
        const updateInput = input as UpdateClientAddressInput;
        if (updateInput) {
          showSnackbar('Updating address...');
          updateAddress({ variables: { input: { ...updateInput, id: selectedAddress?.id ?? '' } } })
            .then(async () => {
              await refetchClient();
              showSnackbar('Address updated successfully');
              setUpdateIsOpen(false);
              setSelectedAddress(null);
            })
            .catch(() => {
              showSnackbar('Failed updating address');
            })
            .finally(() => resolve());
        }
      });
    },
    [updateAddress, refetchClient, showSnackbar, selectedAddress]
  );

  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    total: 0,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: client?.addressBookList?.addressBook?.length ?? 0,
    }));
  }, [client]);

  const data = client?.addressBookList?.addressBook?.slice(
    pagination.page * pagination.perPage,
    pagination.page * pagination.perPage + pagination.perPage
  );

  const updateValues = useMemo(() => {
    if (!selectedAddress || !data) {
      return undefined;
    }
    return {
      ...selectedAddress.address,
      isDefaultBilling: selectedAddress.defaultBilling,
      isDefaultShipping: selectedAddress.defaultShipping,
      isResidential: selectedAddress.isResidential,
      label: selectedAddress.label,
      id: selectedAddress.id ?? '',
      client: { id: client?.id ?? '' },
    };
  }, [selectedAddress, data, client?.id]);

  const columns = useMemo(
    () => [
      {
        id: 'address',
        header: 'Address',
        valueFn: (address: Address) => stripHTML(address.address?.addressText ?? '', { removeBr: true }),
      },
      {
        id: 'label',
        header: 'Label',
      },
      {
        id: 'defaultBilling',
        header: 'Default Billing',
        valueFn: (address: Address) => address.defaultBilling && 'Yes',
      },
      {
        id: 'defaultShipping',
        header: 'Default Shipping',
        valueFn: (address: Address) => address.defaultShipping && 'Yes',
      },
      {
        id: 'isResidential',
        header: 'Residential',
        valueFn: (address: Address) => address.defaultShipping && 'Yes',
      },
    ],
    []
  );

  return (
    <Container>
      <Stack direction='row' justifyContent='space-between'>
        <FormHeader variant='h3' header='Addressses' />
        <Button onClick={() => setCreateIsOpen(true)}>Create new Address</Button>
      </Stack>

      <CreateAddressDrawer
        title='Create Client Address'
        onSubmit={handleCreateSubmit}
        open={createIsOpen}
        onClose={() => setCreateIsOpen(false)}
        width='58em'
      />
      {selectedAddress && updateValues && (
        <EditAddressDrawer
          title='Update Client Address'
          values={updateValues}
          onSubmit={handleUpdateSubmit}
          open={updateIsOpen}
          onClose={() => {
            setUpdateIsOpen(false);
            setSelectedAddress(null);
          }}
          width='58em'
        />
      )}

      <DataTable
        data={data}
        columns={columns}
        loading={loading ?? false}
        pagination={pagination}
        setPagination={setPagination}
        leftActionCell={() => null}
        rightActionCell={({ isActive, row }: ActionCellProps<Address>) => (
          <IconWrapper>
            <ActionMenu
              objectName='Address'
              visible={isActive}
              onEdit={() => {
                handleStartEditAddress(row.original);
              }}
            />
          </IconWrapper>
        )}
      />
    </Container>
  );
};
