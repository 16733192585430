import React, { useEffect, useState, useRef } from 'react';
import Lottie, { LottieComponentProps, InteractivityProps, LottieRef } from 'lottie-react';
import { css, styled } from '@mui/material/styles';

interface Props extends LottieComponentProps {
  speed?: number;
  interactivity?: Omit<InteractivityProps, 'lottieObj'>;
  autoPlay?: boolean;
}

const defaultStyle = {
  height: 21,
  width: 21,
};

const LottieContainer = styled('div')(
  ({ theme }) => css`
    & > div:first-child {
      display: flex;

      path {
        fill: ${theme.colors.white};
      }
    }
  `
);

const LottieAnimation: React.FC<Props> = ({
  animationData,
  style = defaultStyle,
  speed = 1.8,
  autoPlay = false,
  ...rest
}) => {
  const [play, setPlay] = useState(false);
  const lottieRef: LottieRef = useRef(null);

  useEffect(() => {
    lottieRef?.current?.setSpeed(speed);
  }, [speed]);

  useEffect(() => {
    if (play || autoPlay) {
      lottieRef.current?.goToAndPlay(0);
    } else {
      lottieRef.current?.stop();
    }
  }, [autoPlay, play]);

  return (
    <LottieContainer role='button' tabIndex={0} onMouseEnter={() => setPlay(true)} onMouseLeave={() => setPlay(false)}>
      <Lottie lottieRef={lottieRef} animationData={animationData} style={style} loop={false} {...rest} />
    </LottieContainer>
  );
};

export default LottieAnimation;
