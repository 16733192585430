import React from 'react';
import PageHeader from 'components/PageHeader/PageHeader';

const NotFound: React.FC = () => {
  return (
    <>
      <PageHeader variant='h1' header='Page not found' />
    </>
  );
};

export default NotFound;
