import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  CreateSalesOrderDocument,
  CreateSalesOrderMutationVariables,
  CreateSalesOrderMutation,
} from '__generated__/graphql';
import { useMemo } from 'react';

type Data = CreateSalesOrderMutation;
type Variables = CreateSalesOrderMutationVariables;

function useMutationCreateSalesOrder(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(CreateSalesOrderDocument, opts);
}

export default useMutationCreateSalesOrder;
