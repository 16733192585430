import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetItemsByIdsDocument,
  GetItemsByIdsQuery,
  GetItemsByIdsQueryVariables,
  QueryGetItemsByIdsArgs,
} from '__generated__/graphql';

export const useQueryGetItemsByIds = (
  input: QueryGetItemsByIdsArgs,
  options?: QueryHookOptions<GetItemsByIdsQuery, GetItemsByIdsQueryVariables>
) => {
  return useQuery(GetItemsByIdsDocument, {
    ...options,
    variables: input,
  });
};
