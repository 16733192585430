import { css, styled } from '@mui/material';
import { SalesOrderItemInput } from '__generated__/graphql';
import { useSalesOrderContext } from 'contexts/SalesOrderContext/SalesOrderContext';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MUIInput from '@mui/material/Input';
import { itemMarginFromCostPrice, itemPriceFromCostMargin } from 'contexts/QuoteContext/useItems';

/**
 * Takes a line item, a field name, a new value and returns an copy of the line item with the appropriate fields updated
 * @param {SalesOrderItemInput} lineItem
 * @param {keyof SalesOrderItemInput} field
 * @param {SalesOrderItemInput[keyof SalesOrderItemInput]} value
 * @returns {SalesOrderItemInput}
 * */
export function updateItemField<T extends keyof SalesOrderItemInput>(
  lineItem: SalesOrderItemInput,
  field: T,
  value: SalesOrderItemInput[T]
): SalesOrderItemInput {
  const item = { ...lineItem };

  const cost = item.unitCost || item.estimatedTotalCost || 0;

  // Number fields
  if (typeof value === 'number') {
    switch (field) {
      case 'adjCost': //FIXME: this should be rate!
        item.adjCost = value;
        item.margin = itemMarginFromCostPrice(cost, value);
        item.total = item.adjCost * (item.quantity ?? 0);
        return item;
      case 'unitCost':
        item.unitCost = value;
        item.adjCost = itemPriceFromCostMargin(value, Number(item.margin));
        item.total = item.adjCost * (item.quantity ?? 0);

        // if (item.costEstimateType === 'CUSTOM') {
        //   item.estimatedUnitCost = value;
        //   item.estimatedTotalCost = item.estimatedUnitCost * (item.quantity ?? 0);
        // }
        return item;
      case 'quantity':
        item.quantity = value;
        item.total = item.quantity * (item.adjCost ?? 0);
        return item;
    }
  }

  // If we made it here, we can just update the field with no side effects
  item[field] = value;

  return item;
}

const Input = styled(MUIInput)(
  () => css`
    all: unset;
    background: transparent;
    width: 100%;
  `
);

type StringProperties<T> = {
  [K in keyof T as T[K] extends string | null | undefined ? K : never]: T[K];
};

type ItemsTableTextInputProps = {
  index: number;
  field: keyof StringProperties<SalesOrderItemInput>;
  multiline?: boolean;
};

export const ItemsTableTextInput = ({ index, field, multiline }: ItemsTableTextInputProps) => {
  const { view } = useParams();

  const { salesOrderInput, setSalesOrderInput } = useSalesOrderContext();

  const [value, setValue] = useState('');

  useEffect(() => {
    if (salesOrderInput.items) {
      setValue(salesOrderInput.items[index][field] ? `${salesOrderInput.items[index][field]}` : '');
    }
  }, [salesOrderInput, index, field]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleBlur = useCallback(() => {
    setSalesOrderInput((prev) => {
      if (!prev?.items) {
        return undefined;
      }

      let item = prev.items[index];

      item = updateItemField(item, field, value);

      const items = [...prev.items.slice(0, index), item, ...prev.items.slice(index + 1)];

      return {
        ...prev,
        items,
      };
    });
  }, [value, setSalesOrderInput, field, index]);

  return (
    <Input
      disableUnderline
      multiline={multiline}
      disabled={view === 'view'}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      type='text'
    />
  );
};

type NumberProperties<T> = {
  [K in keyof T as T[K] extends number | null | undefined ? K : never]: T[K];
};

type ItemsTableNumberInputProps = {
  index: number;
  field: keyof NumberProperties<SalesOrderItemInput>;
};

export const ItemsTableNumberInput = ({ index, field }: ItemsTableNumberInputProps) => {
  const { view } = useParams();

  const { salesOrderInput, setSalesOrderInput } = useSalesOrderContext();

  const [value, setValue] = useState('');

  useEffect(() => {
    if (salesOrderInput.items) {
      setValue(salesOrderInput.items[index][field] ? `${salesOrderInput.items[index][field]}` : '');
    }
  }, [salesOrderInput, index, field]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleBlur = useCallback(() => {
    setSalesOrderInput((prev) => {
      if (!prev?.items) {
        return undefined;
      }

      let item = prev.items[index];

      item = updateItemField(item, field, Number(value));

      const items = [...prev.items.slice(0, index), item, ...prev.items.slice(index + 1)];

      return {
        ...prev,
        items,
      };
    });
  }, [value, setSalesOrderInput, field, index]);

  return (
    <Input
      disableUnderline
      disabled={view === 'view'}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      type='number'
    />
  );
};
