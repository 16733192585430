import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { SearchItemsQuery, ItemSearchInput } from '__generated__/graphql';
import { useSalesOrderContext } from 'contexts/SalesOrderContext/SalesOrderContext';
import { useItemsSearch } from './useItemsSearch';
import { useQueryGetItemsByIds } from 'hooks/queries/useQueryGetItemsByIds/useQueryGetItemsByIds';

export type Item = SearchItemsQuery['searchItems']['items'][0];
export type ItemsSearchFilters = Omit<ItemSearchInput, 'page' | 'perPage'>;

type ItemsContextState = {
  searchData: SearchItemsQuery | undefined;
  searchLoading: boolean;
  searchFilters: ItemsSearchFilters;
  setSearchFilters: React.Dispatch<React.SetStateAction<ItemsSearchFilters>>;
  fetchNextPage: () => void;
  getItems: (ids: string[]) => Promise<ItemReference>;
};

const ItemsContext = React.createContext<ItemsContextState | undefined>(undefined);

type ItemsProviderProps = {
  children: React.ReactNode;
};

type ItemReference = {
  [key: string]: Item;
};

const ItemsContextProvider: React.FC<ItemsProviderProps> = ({ children }) => {
  const [searchFilters, setSearchFilters] = React.useState<ItemsSearchFilters>({});
  const [missingItemIds, setMissingItemIds] = React.useState<string[]>([]);
  const [itemReference, setItemReference] = React.useState<ItemReference>({});
  const { salesOrder } = useSalesOrderContext();
  const { data: searchData, loading: searchLoading, fetchNextPage } = useItemsSearch({ filters: searchFilters });
  const { data: itemsByIds, loading: loadingByIds } = useQueryGetItemsByIds(
    { ids: missingItemIds },
    { skip: missingItemIds.length === 0 }
  );

  const addItems = useCallback((items: Item[]) => {
    items.forEach((item) => {
      setItemReference((prev) => {
        return {
          ...prev,
          [item.id]: item,
        };
      });
    });
  }, []);

  // Sales orders aren't bringing all item fields yet, so when we get a sales order, we need to fetch items
  useEffect(() => {
    if (salesOrder) {
      const ids: string[] = [];
      salesOrder.items?.forEach((item) => {
        if (!itemReference[item.item.id]) {
          ids.push(item.item.id);
        }
      });
      setMissingItemIds(ids);
    }
  }, [salesOrder, setMissingItemIds, itemReference]);

  // When we get the missing items, add them to the item reference
  useEffect(() => {
    if (itemsByIds) {
      addItems(itemsByIds.getItemsByIds);
      setMissingItemIds(() => []);
    }
  }, [itemsByIds, addItems]);

  // Whenever data comes back from the item search query, add the items to the item reference
  useEffect(() => {
    if (searchData) {
      addItems(searchData.searchItems.items);
    }
  }, [searchData, addItems]);

  const getItems = useCallback(
    async (ids: string[]) => {
      // If we're already loading the missing items, wait for that to finish
      while (loadingByIds) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      // Get anything that's missing from the itemReference
      const idsToFetch = ids.filter((id) => !itemReference[id]);
      for (const id of ids) {
        if (!itemReference[id]) {
          idsToFetch.push(id);
        }
      }
      // If there are still missing items, set the missing items and wait for them to be fetched
      if (idsToFetch.length > 0) {
        setMissingItemIds(idsToFetch);
        while (loadingByIds || itemReference[idsToFetch[0]] === undefined) {
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
      }
      return ids.reduce((accumulator, id) => {
        accumulator[id] = itemReference[id];
        return accumulator;
      }, {} as ItemReference);
    },
    [itemReference, loadingByIds]
  );

  const ItemsContextValue = useMemo(
    () => ({
      searchData,
      searchLoading,
      searchFilters,
      setSearchFilters,
      fetchNextPage,
      getItems,
    }),
    [searchData, searchLoading, searchFilters, setSearchFilters, fetchNextPage, getItems]
  );

  return <ItemsContext.Provider value={ItemsContextValue}>{children}</ItemsContext.Provider>;
};

const useItemsContext = () => {
  const context = useContext(ItemsContext);

  if (context === undefined) {
    throw new Error('useItemsContext was used outside of its Provider');
  }

  return context;
};

export { ItemsContext, ItemsContextProvider, useItemsContext };
