import { css, styled } from '@mui/material/styles';

const FormLabel = styled('label')(
  ({ theme, required }) => css`
    text-transform: Uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    display: block;
    cursor: default;
    color: ${theme.colors.whiteOpacity50};

    &:after {
      content: '${required ? '*' : ''}';
      padding-left: 0.25rem;
    }
  `
);

export const ModalFormLabel = styled('label')(
  ({ theme, required }) => css`
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    display: block;
    cursor: default;
    color: ${theme.colors.whiteOpacity50};

    &:after {
      content: '${required ? '*' : ''}';
      padding-left: 0.25rem;
    }
  `
);

export default FormLabel;
