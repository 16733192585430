import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  MarkCommentsAsViewedDocument,
  MarkCommentsAsViewedMutation,
  MarkCommentsAsViewedMutationVariables,
} from '__generated__/graphql';
import { useMemo } from 'react';

type Data = MarkCommentsAsViewedMutation;
type Variables = MarkCommentsAsViewedMutationVariables;

function useMutationMarkCommentsAsViewed(
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(MarkCommentsAsViewedDocument, opts);
}

export default useMutationMarkCommentsAsViewed;
