import React from 'react';
import { styled, css } from '@mui/material/styles';
import MuiTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Flex from 'components/Flex/Flex';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(
  ({ theme }) => css`
    & .${tooltipClasses.arrow} {
      color: ${theme.colors.white};
      transform: translate(0.8rem) !important;
    }

    & .${tooltipClasses.tooltip} {
      white-space: nowrap;
      border-radius: 0.25rem;
      padding: 0.5rem;
      font-size: 0.9rem;
      font-weight: 500;
      color: ${theme.colors.lightBlack};
      background-color: ${theme.colors.white};
    }
  `
);

const AlertIcon = styled(PriorityHighIcon)(
  ({ theme }) => css`
    width: 1.5rem;
    background-color: orange;
    color: ${theme.colors.white};
  `
);

type Props = TooltipProps & {
  tooltipOpen: boolean;
  setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AlertTooltip: React.FC<Props> = ({ title, tooltipOpen, setTooltipOpen, children }) => {
  return (
    <Tooltip
      open={tooltipOpen}
      onClose={() => setTooltipOpen(false)}
      TransitionProps={{ timeout: { enter: 500, exit: 250 } }}
      title={
        <React.Fragment>
          <Flex w100 center gap={1}>
            <AlertIcon />
            <span>{title}</span>
          </Flex>
        </React.Fragment>
      }
      arrow
    >
      {children}
    </Tooltip>
  );
};

export default AlertTooltip;
