import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetClientDocument, GetClientQuery, GetClientQueryVariables, QueryGetClientArgs } from '__generated__/graphql';

export const useQueryGetClient = (
  input: QueryGetClientArgs,
  options?: QueryHookOptions<GetClientQuery, GetClientQueryVariables>
): QueryResult<GetClientQuery, GetClientQueryVariables> => {
  return useQuery(GetClientDocument, {
    ...options,
    variables: input,
  });
};
