import React, { useMemo } from 'react';
import Modal, { ModalProps } from 'components/Modal/Modal';
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import useMutationCreateContact from 'hooks/mutations/useMutationCreateContact/useMutationCreateContact';
import { FormikInput, FormikSelect, FormikValuesFrom, createInitialValues } from 'utils/formik';
import { CreateContactInput } from '__generated__/graphql';
import { useQueryGetContactRoles } from 'hooks/queries/useQueryGetContactRoles/useQueryGetContactRoles';
import { useQueryGetSubsidiaries } from 'hooks/queries/useQueryGetSubsidiaries/useQueryGetSubsidiaries';
import { GenericLookup } from 'routes/Quote/utils/constants';

type CreateClientContactModalProps = Omit<ModalProps, 'children'>;

const CreateClientContactModal: React.FC<CreateClientContactModalProps> = ({ modalOpen, setModalOpen, title }) => {
  const { clientData, clientDataRefetch, setSnackbarMessage, setShowSnackbar } = useQuoteContext();
  const [createContact, { loading, error }] = useMutationCreateContact();
  const { data: contactRolesData } = useQueryGetContactRoles();
  const contactRoleOptions = useMemo(() => {
    const options: { [key: string]: string } = {};
    contactRolesData?.getContactRoles.forEach((role) => {
      options[role.id] = role.name;
    });
    return new GenericLookup(options);
  }, [contactRolesData]);

  const { data: subsidiariesData } = useQueryGetSubsidiaries();
  const subsidiaryOptions = useMemo(() => {
    const options: { [key: string]: string } = {};
    subsidiariesData?.getSubsidiaries.forEach((role) => {
      options[role.id] = role.name;
    });
    return new GenericLookup(options);
  }, [subsidiariesData]);

  const formId = title.toLowerCase().replaceAll(' ', '-');

  const requiredFields = ['fullName', 'subsidary'];
  const formFields = {
    name: 'Name',
    fullName: 'Contact',
    role: 'Role',
    jobTitle: 'Job Title',
    phone: 'Main Phone',
    email: 'Email',
    subsidiary: 'Subsidiary',
  };
  type FormikValues = FormikValuesFrom<typeof formFields, CreateContactInput>;

  const toCreateContactInput = (values: FormikValues): CreateContactInput => {
    const { name, ...rest } = values;
    const [firstName, ...lastName] = name.indexOf(' ') > -1 ? name.split(' ') : [name, ''];
    const subsidiary = subsidiaryOptions.getStitchRelation(values.subsidiary)?.id ?? '';
    return {
      ...rest,
      client: clientData?.id,
      subsidiary,
      firstName,
      lastName: lastName.join(' '),
    };
  };

  const snackWrap = (message: string) => {
    setShowSnackbar(true);
    setSnackbarMessage(message);
  };

  const onSubmit = async (values: FormikValues) => {
    const quoteInput = toCreateContactInput(values);
    snackWrap('Creating contact...');
    createContact({ variables: { input: quoteInput } })
      .then(async () => {
        await clientDataRefetch();
        snackWrap('Contact created successfully');
        setModalOpen(false);
      })
      .catch(() => {
        snackWrap('Failed creating contact');
      });
  };

  return (
    <Modal {...{ title, modalOpen, setModalOpen, formId, loading, error }} controlled>
      <Formik initialValues={createInitialValues<FormikValues>(formFields)} onSubmit={onSubmit}>
        {({ values, handleChange, handleSubmit }) => {
          const props = { handleChange, values, requiredFields, formFields };
          return (
            <form onSubmit={handleSubmit} id={formId}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <FormikInput name='name' {...props} />
                  <FormikInput name='fullName' {...props} />
                  <FormikSelect name='role' {...props} options={contactRoleOptions.values()} />
                  <FormikInput name='jobTitle' {...props} />
                </Grid>

                <Grid item xs={6}>
                  <FormikInput name='phone' {...props} />
                  <FormikInput name='email' {...props} />
                  <FormikSelect name='subsidiary' {...props} options={subsidiaryOptions.values()} />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateClientContactModal;
