import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetClientV2Document,
  GetClientV2Query,
  GetClientV2QueryVariables,
  QueryGetClientV2Args,
} from '__generated__/graphql';

export const useQueryGetClientV2 = (
  input: QueryGetClientV2Args,
  options?: QueryHookOptions<GetClientV2Query, GetClientV2QueryVariables>
): QueryResult<GetClientV2Query, GetClientV2QueryVariables> => {
  return useQuery(GetClientV2Document, {
    ...options,
    variables: input,
  });
};
