import { css, styled } from 'styled-components';

export const SearchResultTable = styled('table')(
  ({ theme }) => css`
    font-size: 0.875rem;
    font-weight: 600;
    border-spacing: 0;
    width: 100%;

    thead {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: black !important;
      border-radius: 0 !important;

      // Hides the overflow in the corners
      > div {
        background: ${theme.palette.background.default};
        width: 100%;
        height: 50px;
        position: absolute;
        top: 0;
        z-index: -1;
      }
    }

    tbody {
      tr {
        cursor: pointer;
      }

      tr:hover {
        background-color: ${theme.colors.hoverGray};
      }
    }

    td,
    th {
      text-align: left;
      padding: 1rem;
      border-bottom: 1px solid ${theme.palette.background.default};
      border-collapse: separate;
    }

    th {
      text-transform: uppercase;
      font-size: 0.65rem;
      color: ${theme.palette.text.secondary};
      border-top: 0.0625rem solid ${theme.colors.bevelTop};
      background-color: ${theme.palette.primary.main};

      &:first-child {
        border-top-left-radius: 0.5rem;
        border-left: 0.0625rem solid ${theme.colors.bevelLeft};
      }

      &:last-child {
        border-top-right-radius: 0.5rem;
        border-right: 0.0625rem solid ${theme.colors.bevelLeft};
      }
    }

    td {
      p {
        white-space: nowrap;
      }
    }

    tr {
      background-color: ${theme.palette.primary.main};
    }
  `
);
