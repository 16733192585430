import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { css, styled, Theme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import ButtonBase from '@mui/material/ButtonBase/ButtonBase';
import Typography from '@mui/material/Typography';
import Search from 'components/Search/Search';
import LottieAnimation from 'components/LottieAnimation/LottieAnimation';
import MUIMenuIcon from '@mui/icons-material/Menu';
import MUIICloseIcon from '@mui/icons-material/Close';
import { NavItem } from 'components/SideNav/SideNav';
import coin from 'assets/lottie/coin.json';
import list from 'assets/lottie/list.json';
import tag from 'assets/lottie/tag.json';
import clients from 'assets/lottie/clients.json';
import { SubNav, subNavItemToComponent } from 'components/TopNav/TopNav';
import { SubNavItem } from 'layout/Layout';

const MenuIcon = styled(MUIMenuIcon)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    opacity: 0.5;
  `
);

const CloseIcon = styled(MUIICloseIcon)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    opacity: 0.5;
  `
);

const MobileNavContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.colors.charcoal};
    display: flex;
    justify-content: space-between;
    height: 4rem;
    position: fixed;
    width: 100%;
    border-bottom: 0.0625rem solid ${theme.colors.black};
    z-index: 10;
  `
);

const LogoSection = styled('div')(
  ({ theme }) => css`
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

const MobileMenuContainer = styled(Drawer)(
  () => css`
    top: 4rem;

    .MuiBackdrop-root {
      top: 4rem;
    }

    .MuiPaper-root {
      transition-duration: 447ms !important;
      top: 4rem;
    }
  `
);

const MobileNavMenu = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.info.main};
    width: 100vw;
    height: calc(100% - 4rem);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 3.5rem;
    overflow: hidden;
  `
);

const MobileNavLink = styled(NavLink)(
  ({ theme }) => css`
    text-decoration: none;
    display: flex;
    gap: 1rem;
    align-items: center;

    svg {
      fill: ${theme.colors.white};
    }

    p {
      color: ${theme.colors.white};
      font-size: 1.5rem;
      font-weight: 600;
    }
  `
);

const navItems: NavItem[] = [
  {
    to: '/',
    imageData: list,
    label: 'Quotes',
  },
  // Sales Orders should not be active on prod
  // {
  //   to: '/sales-orders',
  //   imageData: coin,
  //   label: 'Sales Orders',
  // },
  {
    to: '/items',
    imageData: tag,
    label: 'Items',
  },
  {
    to: '/clients',
    imageData: clients,
    label: 'Clients',
  },
  {
    to: '/logout',
    imageData: null,
    label: 'Log Out',
  },
];

const searchStyles = ({ theme }: { theme: Theme }) => css`
  background-color: ${theme.palette.input.main};
  padding: 2rem 1rem;

  input {
    font-weight: 500;
    font-size: 1rem;

    &::placeholder {
      opacity: 0.5;
    }
  }

  svg {
    font-size: 1.5rem;
    margin-right: 1rem;
  }
`;

type Props = {
  subNavItems?: SubNavItem[];
};

const MobileNav: React.FC<Props> = ({ subNavItems }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [subNavOpen, setSubNavOpen] = useState(false);
  const location = useLocation();
  const isQuotesTable = location.pathname === '/quotes';

  return (
    <MobileNavContainer>
      <LogoSection>
        <ButtonBase disableRipple onClick={() => setDrawerOpen(!drawerOpen)}>
          {drawerOpen ? <CloseIcon /> : <MenuIcon />}
        </ButtonBase>
      </LogoSection>
      <MobileMenuContainer anchor='left' open={drawerOpen}>
        <MobileNavMenu>
          <Search placeholder='Search for anything...' styles={searchStyles} />
          {navItems.map((link) => (
            <MobileNavLink key={link.to} to={link.to} onClick={() => setDrawerOpen(false)}>
              <LottieAnimation animationData={link.imageData} />
              <Typography>{link.label}</Typography>
            </MobileNavLink>
          ))}
        </MobileNavMenu>
      </MobileMenuContainer>

      <SubNav>{subNavItems?.map((item) => subNavItemToComponent(item))}</SubNav>
    </MobileNavContainer>
  );
};

export default MobileNav;
