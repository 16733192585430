export interface ISimpleLookup {
  entries: [string, string][];
  getValue(key: string): string | null;
  values(): { [key: string]: string };
}

abstract class SimpleLookup implements ISimpleLookup {
  get entries(): [string, string][] {
    return Object.entries(this);
  }

  getValue(key: string): string {
    const _key = key as keyof typeof this;
    return this[_key]?.toString() ?? '';
  }

  values(): { [key: string]: string } {
    return this.entries.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }

  getStitchRelation(key: string | undefined): { id: string; name: string } | undefined {
    if (!key) {
      return undefined;
    }
    const name = this[key as keyof typeof this]?.toString();
    return name ? { id: key, name } : undefined;
  }
}

// Create lookup table by passing in an options object with id's and values
export class GenericLookup extends SimpleLookup {
  [key: string]: unknown;

  constructor(options: { [key: string]: string }) {
    super();
    for (const key in options) {
      this[key] = options[key];
    }
  }
}

const FLAT_RATE = 'FLAT_RATE';
const PERCENT_OF_TOTAL = 'PERCENT_OF_TOTAL';
const BY_WEIGHT = 'BY_WEIGHT';
const BY_ITEM = 'BY_ITEM';
const UPS = 'UPS';

type RateInfo = {
  rate: number;
  rate_type: string;
  discount?: number;
  weight_unit?: 'lb' | 'g' | 'oz' | 'kg';
  weight_per?: number;
  whole_increments?: boolean;
  realtime_service_code?: string;
};

// export const projectStatusOptions = ['In Progress', 'In Review', 'On Hold', 'Closed', 'Lost'];
class QuoteStatusOptions extends SimpleLookup {
  A = 'Open';
  C = 'Closed';
  X = 'Expired';
  B = 'Processed';
  V = 'Voided';
}
export const quoteStatusOptions = new QuoteStatusOptions();

class ShipViaOptions extends SimpleLookup {
  /** This option is currently inactive in netsuite, Millennium may reactivate later */
  // '7' = 'Bestway LTL';
  // '489546' = 'UPS Ground - Leasing';
  // '431911' = 'Will Call - Leasing';
  '21373' = 'Flatbed';
  '28888' = 'Millennium Truck';
  '36562' = 'Millennium Truck / Not Taxed';
  '6839' = 'UPS 2nd Day Air';
  '27' = 'UPS Collect';
  '425393' = 'UPS Ground';
  '38604' = 'UPS Ground / NOT TAXED';
  '34594' = 'UPS Next Day Air 10:30am';
  '34593' = 'UPS Next Day Air Early AM 8:30am';
  '34595' = 'UPS Next Day Saver 3:30pm';
  '28883' = 'US Special Delivery';
  '11666' = 'Will Call';
  '21996' = 'Shipping To Be Calculated';
}

export const ShipViaRates: { [key: string]: RateInfo } = {
  '25393': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '5': { rate_type: FLAT_RATE, rate: 0.0 },
  '11224': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '43797': { rate_type: FLAT_RATE, rate: 0.0 },
  '11226': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '43800': { rate_type: FLAT_RATE, rate: 0.0 },
  '43802': { rate_type: FLAT_RATE, rate: 0.0 },
  '34916': { rate_type: BY_ITEM, rate: 0.0 },
  '28880': { rate_type: FLAT_RATE, rate: 0.0 },
  '34581': { rate_type: FLAT_RATE, rate: 0.0 },
  '502875': { rate_type: FLAT_RATE, rate: 0.0 },
  '10': { rate_type: FLAT_RATE, rate: 0.0 },
  '12': { rate_type: PERCENT_OF_TOTAL, rate: 0.2 },
  '36926': { rate_type: BY_ITEM, rate: 0.0 },
  '34601': { rate_type: BY_ITEM, rate: 0.0 },
  '11': { rate_type: FLAT_RATE, rate: 250.0 },
  '391451': { rate_type: FLAT_RATE, rate: 0.0 },
  '34582': { rate_type: BY_ITEM, rate: 0.0 },
  '28887': { rate_type: FLAT_RATE, rate: 0.0 },
  '506386': { rate_type: FLAT_RATE, rate: 1.0 },
  '14': { rate_type: FLAT_RATE, rate: 0.0 },
  '34669': { rate_type: BY_ITEM, rate: 0.0 },
  '42040': { rate_type: FLAT_RATE, rate: 0.0 },
  '15': { rate_type: FLAT_RATE, rate: 250.0 },
  '34600': { rate_type: BY_ITEM, rate: 0.0 },
  '6842': { rate_type: BY_ITEM, rate: 0.0 },
  '34583': { rate_type: BY_ITEM, rate: 0.0 },
  '17': { rate_type: FLAT_RATE, rate: 0.0 }, //*
  '6841': { rate_type: BY_ITEM, rate: 0.0 },
  '34584': { rate_type: BY_ITEM, rate: 0.0 },
  '34585': { rate_type: BY_ITEM, rate: 0.0 },
  '175633': { rate_type: FLAT_RATE, rate: 0.0 },
  '21373': { rate_type: FLAT_RATE, rate: 0.0 },
  '25395': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '28881': { rate_type: FLAT_RATE, rate: 0.0 },
  '11228': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '11227': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '43796': { rate_type: FLAT_RATE, rate: 0.0 },
  '25391': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '31': { rate_type: BY_ITEM, rate: 0.0 },
  '48025': { rate_type: FLAT_RATE, rate: 0.0 },
  '424796': { rate_type: FLAT_RATE, rate: 0.0 },
  '43798': { rate_type: FLAT_RATE, rate: 0.0 },
  '11231': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '40677': { rate_type: FLAT_RATE, rate: 0.0 },
  '36562': { rate_type: FLAT_RATE, rate: 0.0 },
  '28888': { rate_type: FLAT_RATE, rate: 0.0 },
  '43799': { rate_type: FLAT_RATE, rate: 0.0 },
  '28886': { rate_type: FLAT_RATE, rate: 0.0 },
  '34586': { rate_type: BY_ITEM, rate: 0.0 },
  '34587': { rate_type: BY_ITEM, rate: 0.0 },
  '11222': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '34588': { rate_type: BY_ITEM, rate: 0.0 },
  '25390': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '11229': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '28885': { rate_type: FLAT_RATE, rate: 0.0 },
  '43801': { rate_type: FLAT_RATE, rate: 0.0 },
  '25394': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '11223': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '43793': { rate_type: FLAT_RATE, rate: 0.0 },
  '28882': { rate_type: FLAT_RATE, rate: 0.0 },
  '34589': { rate_type: BY_ITEM, rate: 0.0 },
  '25392': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '34590': { rate_type: BY_ITEM, rate: 0.0 },
  '28884': { rate_type: FLAT_RATE, rate: 0.0 },
  '34591': { rate_type: BY_ITEM, rate: 0.0 },
  '25397': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '34592': { rate_type: BY_ITEM, rate: 0.0 },
  '43834': { rate_type: FLAT_RATE, rate: 0.0 },
  '6839': { rate_type: FLAT_RATE, rate: 0.0 },
  '27': { rate_type: FLAT_RATE, rate: 0.0 },
  '425393': { rate_type: UPS, realtime_service_code: 'ups_ground', rate: 0.0, discount: 1.2 },
  '489546': { rate_type: FLAT_RATE, rate: 0.0 },
  '38604': { rate_type: FLAT_RATE, rate: 0.0 },
  '34594': { rate_type: BY_ITEM, rate: 0.0 },
  '34593': { rate_type: BY_ITEM, rate: 0.0 },
  '34595': { rate_type: BY_ITEM, rate: 0.0 },
  '28883': { rate_type: FLAT_RATE, rate: 0.0 },
  '30': { rate_type: FLAT_RATE, rate: 0.0 },
  '29': { rate_type: FLAT_RATE, rate: 0.0 },
  '34596': { rate_type: BY_ITEM, rate: 0.0 },
  '43795': { rate_type: FLAT_RATE, rate: 0.0 },
  '26246': { rate_type: FLAT_RATE, rate: 0.0 },
  '25396': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
  '11666': { rate_type: FLAT_RATE, rate: 0.0 },
  '431911': { rate_type: FLAT_RATE, rate: 0.0 },
  '34598': { rate_type: BY_ITEM, rate: 0.0 },
  '34599': { rate_type: BY_ITEM, rate: 0.0 },
  '11221': { rate_type: PERCENT_OF_TOTAL, rate: 0.12 },
};

export const shipViaOptions = new ShipViaOptions();
