class PricingData {
  quoteType: string = '';
  staggeredDeliveries: string = '';
  cardFees: boolean = false;
  expeditedChecked: boolean = false;
  daySalesOutstanding: string = '';
  cutAndReelFees: string = '';
  warehousingAndStorageFees: string = '';
  processingAndHandlingFees: string = '';
  expeditedFee: string = '';
}

export class QuoteFormData {
  recommendedPricingData: PricingData = new PricingData();
}
