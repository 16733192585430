import React from 'react';
import { styled, css } from '@mui/material/styles';
import MuiTab from '@mui/material/Tab';
import MuiTabs, { TabsProps } from '@mui/material/Tabs';

type TabNavigationProps = TabsProps & {
  children: React.ReactElement<{ header: string }>[];
  disabled?: boolean;
  shouldShowModal?: boolean;
  projectDetailsValidated?: boolean;
  value?: number;
  setValue?: React.Dispatch<React.SetStateAction<number>>;
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

type TabContentProps = {
  children?: JSX.Element | JSX.Element[];
  header: string;
};

export const TabContainer = styled('div')(
  () => css`
    width: 100%;
  `
);

export const Tabs = styled(MuiTabs)(
  ({ theme }) => css`
    & .MuiTabs-indicator {
      display: none;
    }

    border-bottom: 1px solid ${theme.colors.lightGray};
  `
);

export const Tab = styled(MuiTab)(
  ({ theme }) => css`
    color: ${theme.colors.whiteOpacity25};
    font-weight: 600;
    font-size: 1rem;
    text-transform: none;

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      font-size: 0.75rem;
    }

    &.Mui-selected {
      color: ${theme.colors.white};
    }

    &.MuiTab-root {
      min-width: 0;
      align-items: baseline;
      padding-left: 0;
      padding-right: 2rem;
    }
  `
);

export const TabContent: React.FC<TabContentProps> = (props) => {
  return <div>{props.children}</div>;
};

export const TabPanel = styled(function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
})(
  () => css`
    width: 100%;
    padding: 2rem 0;
  `
);

export const TabNavigation: React.FC<TabNavigationProps> = ({
  children,
  disabled,
  onClick,
  shouldShowModal,
  projectDetailsValidated,
  value,
  setValue,
  ...props
}) => {
  const [internalValue, setInternalValue] = React.useState(0);
  const containerRef = React.useRef(null);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    if (setValue) {
      setValue(newValue);
    } else {
      setInternalValue(newValue);
    }
  };

  const selectedTab = React.useMemo(() => {
    return value ?? internalValue;
  }, [internalValue, value]);

  return (
    <TabContainer ref={containerRef} {...props}>
      <Tabs value={selectedTab} onChange={handleChange} onClick={onClick}>
        {children.map((child, index) => {
          return (
            <Tab
              disabled={disabled || (shouldShowModal && !projectDetailsValidated)}
              disableRipple
              key={`tab-${index}`}
              label={child.props.header}
            />
          );
        })}
      </Tabs>
      {children.map((child, index) => {
        return (
          // Original animations were not to spec and were removed.  If need to use them later, they can be found here:
          // https://github.com/caxy/millennium-sales/blob/db6758f7973c40d3e6ccd76f33e04cd167111769/client/src/components/TabNavigation/TabNavigation.tsx
          <TabPanel key={`tab-content-${index}`} value={selectedTab} index={index}>
            {child}
          </TabPanel>
        );
      })}
    </TabContainer>
  );
};
