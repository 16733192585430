import React, { useCallback, useContext, useMemo } from 'react';
import { Snackbar } from '@mui/material';

type AppContextState = {
  showSnackbar: (message: string) => void;
};

const AppContext = React.createContext<AppContextState | undefined>(undefined);

type AppProviderProps = {
  children: React.ReactNode;
};

const AppContextProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setShowSnackbarMessage] = React.useState('');

  const showSnackbar = useCallback((message: string) => {
    setShowSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const AppContextValue = useMemo(
    () => ({
      showSnackbar,
    }),
    [showSnackbar]
  );

  return (
    <AppContext.Provider value={AppContextValue}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useAppContext was used outside of its Provider');
  }

  return context;
};

export { AppContext, AppContextProvider, useAppContext };
