import styled, { css } from 'styled-components';
import InputLabelBase from '@mui/material/InputLabel';

export const InputLabel = styled(InputLabelBase)(
  () => css`
    text-transform: Uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    display: block;
    cursor: default;
    color: rgba(255, 255, 255, 0.5);
  `
);
