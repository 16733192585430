import React from 'react';
import { Alert, Box, Button, Container, Paper, Stack, Typography } from '@mui/material';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
const LoginModal = () => {
  const { initiateLogIn, error, loading, returnTo } = useAuthContext();
  const location = useLocation();

  let message = 'Please log in';
  let btnText = 'Log In';
  let loginPath = location.pathname;

  if (loading) {
    message = 'Please wait while you are logged in';
  } else if (error) {
    message = 'There was an error logging you in';
    btnText = 'Retry Login';
    //Prevent setting /auth/callback as the 'return to'
    loginPath = returnTo ?? '/';
  }

  return (
    <Container maxWidth='sm' sx={{ marginTop: { xs: '1rem', md: '10rem' } }}>
      <Box>
        <Paper sx={{ padding: '2rem' }}>
          <Stack direction='column' spacing={3} justifyContent='center' alignContent='center' alignItems='center'>
            <Typography variant='h2'>{message}</Typography>
            {!error && !loading && <Typography>This application requires that you log in through NetSuite.</Typography>}
            <LoadingSpinner loading={loading} />
            {error && <Alert color='error'>{error}</Alert>}
            <Button onClick={() => initiateLogIn(loginPath)} variant='contained' color='info' disabled={loading}>
              {btnText}
            </Button>
          </Stack>
        </Paper>
      </Box>
    </Container>
  );
};

export default LoginModal;
