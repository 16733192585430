import { gql } from '@apollo/client';

export const SEND_EMAIL = gql`
  mutation SendQuoteEmail($input: QuoteEmailInput!) {
    sendQuoteEmail(input: $input) {
      success
      errorMessage
    }
  }
`;
