import React from 'react';
import { css, styled } from '@mui/material/styles';

type Props = {
  children: React.ReactNode;
  backgroundColor: string;
  activeForegroundColor: string;
  selected?: boolean;
  disabled?: boolean;
  opaqueWhenDisabled?: boolean;
};

const Pill = styled('div')<{
  $backgroundColor: string;
  $activeForegroundColor: string;
  $selected: boolean;
  $disabled?: boolean;
  $opaqueWhenDisabled?: boolean;
}>(
  ({ theme, $backgroundColor, $activeForegroundColor, $selected, $disabled, $opaqueWhenDisabled }) => css`
    width: fit-content;
    height: 1.5rem;
    border: 0.0625rem solid ${$backgroundColor};
    border-radius: 2rem;
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    text-wrap: nowrap;
    color: ${$selected ? $activeForegroundColor : theme.colors.white};
    pointer-events: ${$disabled ? 'none' : 'auto'};
    background-color: ${$selected ? $backgroundColor : '"inherit"'};
    opacity: ${$opaqueWhenDisabled && $disabled ? '0.5' : '1'};

    &:hover {
      cursor: ${$disabled ? 'default' : 'pointer'};
      background-color: ${$backgroundColor};
      color: ${$activeForegroundColor};
    }
  `
);

const ActionPill: React.FC<Props> = ({
  backgroundColor,
  activeForegroundColor,
  selected = false,
  disabled,
  children,
  opaqueWhenDisabled = true,
}) => {
  return (
    <Pill
      $backgroundColor={backgroundColor}
      $activeForegroundColor={activeForegroundColor}
      $selected={selected}
      $disabled={disabled}
      $opaqueWhenDisabled={opaqueWhenDisabled}
    >
      {children}
    </Pill>
  );
};

export default ActionPill;
