import React from 'react';
import { css, styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageHeader from 'components/PageHeader/PageHeader';
import QuoteStatusPopover from 'routes/Quotes/components/QuoteStatusPopover/QuoteStatusPopover';
import {
  QuoteStatusActiveForegroundColorMap,
  QuoteStatusBackgroundColorMap,
} from 'contexts/QuotesContext/QuotesContext';
import { QUOTE_STATUS } from 'utils/types';
import ActionPill from 'components/ActionPill/ActionPill';
import { Stack } from '@mui/material';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { statusMapping, quoteStatusToLookup, seoFriendly } from 'utils/functions';
import Button from 'components/Button/Button';
import { Link } from 'react-router-dom';
import { colors } from 'darkTheme';
import Flex from 'components/Flex/Flex';

const StatusWrapper = styled('div')(
  () => css`
    grid-column-gap: 1rem;
    align-items: center;
    display: flex;
    padding: 0 0 2rem;
    flex-wrap: wrap;

    button {
      width: min-content;
    }
  `
);

const MainHeader = styled(PageHeader)(
  () => css`
    padding: 0;
  `
);

const QuoteNumber = styled(PageHeader)(
  ({ theme }) => css`
    color: ${theme.colors.whiteOpacity50} !important;
    text-wrap: none;
    padding: 0;
    margin: 1rem 0;
  `
);

const QuoteHeaderWrapper = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  `
);

const QuoteHeader: React.FC = () => {
  const { quote, setQuote, quoteFlowType } = useQuoteContext();

  const handleStatusChanged = (status: QUOTE_STATUS) => {
    if (quote) {
      setQuote({ ...quote, status: quoteStatusToLookup(status) });
    }
  };

  return (
    quote && (
      <QuoteHeaderWrapper>
        <Stack direction='row' justifyContent='left' spacing={2} alignItems='center'>
          <MainHeader variant='h1' header={`${quote.client?.name}`} />
        </Stack>

        <StatusWrapper>
          <QuoteNumber variant='h2' header={`${quote.quoteNumber}`} />

          <Flex gap={1}>
            <QuoteStatusPopover
              status={statusMapping(quote.status)}
              trigger={
                <ActionPill
                  backgroundColor={QuoteStatusBackgroundColorMap[statusMapping(quote.status)]}
                  activeForegroundColor={QuoteStatusActiveForegroundColorMap[statusMapping(quote.status)]}
                >
                  <span>{quote.status?.name || 'Open'}</span>
                  <ExpandMoreIcon fontSize={'small'} />
                </ActionPill>
              }
              statusChanged={(status) => handleStatusChanged(statusMapping(status))}
            />

            {quote.messageSent && (
              <ActionPill
                backgroundColor={colors.green}
                activeForegroundColor={colors.white}
                disabled
                opaqueWhenDisabled={false}
              >
                Email sent
              </ActionPill>
            )}

            {quote && quote.client?.name && quote.client.id && quoteFlowType === 'edit' && (
              <Button
                component={Link}
                to={`/sales-order/${seoFriendly(quote.client?.name)}-${quote.client?.id}/create`}
                style={{ borderRadius: '1rem' }}
                state={{ quote }}
              >
                Sales Order
              </Button>
            )}
          </Flex>
        </StatusWrapper>
      </QuoteHeaderWrapper>
    )
  );
};

export default QuoteHeader;
