import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetSalesOrdersDocument,
  GetSalesOrdersQuery,
  GetSalesOrdersQueryVariables,
  QueryGetSalesOrdersArgs,
} from '__generated__/graphql';

export const useQueryGetSalesOrders = (
  input: QueryGetSalesOrdersArgs,
  options?: QueryHookOptions<GetSalesOrdersQuery, GetSalesOrdersQueryVariables>
): QueryResult<GetSalesOrdersQuery, GetSalesOrdersQueryVariables> => {
  return useQuery(GetSalesOrdersDocument, {
    ...options,
    variables: input,
  });
};
