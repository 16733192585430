import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { CreateQuoteDocument, CreateQuoteMutation, CreateQuoteMutationVariables } from '__generated__/graphql';
import { useMemo } from 'react';

type Data = CreateQuoteMutation;
type Variables = CreateQuoteMutationVariables;

function useMutationCreateQuote(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(CreateQuoteDocument, opts);
}

export default useMutationCreateQuote;
