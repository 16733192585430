import React from 'react';
import Container from '@mui/material/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import { QuotesContextProvider } from 'contexts/QuotesContext/QuotesContext';
import { QuoteContextProvider } from 'contexts/QuoteContext/QuoteContext';
import QuotesTable from './components/QuotesTable/QuotesTable';
import { QuotesSubNavContextProvider } from 'contexts/QuotesSubNavContext/QuotesSubNavContext';

const Quotes: React.FC = () => {
  return (
    <QuotesContextProvider>
      <QuoteContextProvider>
        <Container>
          <QuotesSubNavContextProvider>
            <PageHeader variant='h1' header='Quotes' />
            <QuotesTable />
          </QuotesSubNavContextProvider>
        </Container>
      </QuoteContextProvider>
    </QuotesContextProvider>
  );
};

export default Quotes;
