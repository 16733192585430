import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { DeleteDraftDocument, DeleteDraftMutation, DeleteDraftMutationVariables } from '../../../__generated__/graphql';

type Data = DeleteDraftMutation;
type Variables = DeleteDraftMutationVariables;

function useMutationDeleteDraft(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  return useMutation(DeleteDraftDocument, options);
}

export default useMutationDeleteDraft;
