import React, { FocusEvent } from 'react';
import { css, styled, Interpolation } from '@mui/material/styles';
import Input, { InputProps } from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

interface Props extends InputProps {
  placeholder?: string;
  styles?: Interpolation<string>;
}

const SearchWrapper = styled(Input)(
  ({ theme, $styles }) => css`
    background-color: ${theme.colors.charcoal};
    width: 100%;
    padding: 0.5rem 0.9rem;
    height: 3rem;
    border-radius: 2rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: ${theme.colors.white};
    input {
      color: ${theme.colors.white};
      &::placeholder {
        opacity: 1;
      }
    }
    ${$styles}
  `
);

export const CustomSearchIcon = styled(SearchIcon)(
  () => css`
    font-size: 1.3rem;
  `
);

const Search: React.FC<Props> = ({ styles, placeholder, ...props }) => {
  return (
    <SearchWrapper
      $styles={styles}
      startAdornment={
        <InputAdornment position='start'>
          <CustomSearchIcon />
        </InputAdornment>
      }
      disableUnderline
      onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
      placeholder={placeholder ?? 'Search for a quote...'}
      {...props}
    />
  );
};

export default Search;
