import { Container, Stack } from '@mui/material';
import { CreateClientMutation, UpdateClientMutation } from '__generated__/graphql';
import Button from 'components/Button/Button';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import PageHeader from 'components/PageHeader/PageHeader';
import { useAppContext } from 'contexts/AppContext/AppContext';
import { ClientContextProvider, useClientContext } from 'contexts/ClientContext/ClientContext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Client from 'routes/Client/client';
import { css, styled } from 'styled-components';
import { seoFriendly } from 'utils/functions';

const Header = styled(PageHeader)(
  () => css`
    padding: 0;
  `
);

const CreateClient = () => {
  const { clientInput, submitClient } = useClientContext();
  const { showSnackbar } = useAppContext();
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    setSaving(true);
    showSnackbar('Creating a new Client...');
    submitClient()
      .then((clientResult) => {
        const client = (clientResult?.data as CreateClientMutation)?.createClient;
        if (client) {
          showSnackbar('Client created successfully');
          navigate(`/client/${seoFriendly(client.name ?? '')}-${client.id}`);
        }
      })
      .catch((e) => {
        showSnackbar('Failed creating Client');
      })
      .finally(() => setSaving(false));
  };

  return (
    <Container>
      <Stack direction='row' justifyContent='space-between'>
        <Header variant='h1' header='Create Client' />
        <Button onClick={handleSubmit} disabled={saving}>
          <LoadingSpinner size={'1.25rem'} loading={saving}>
            Create Client
          </LoadingSpinner>
        </Button>
      </Stack>

      {clientInput ? <Client /> : <LoadingSpinner loading={true} />}
    </Container>
  );
};

const ClientContextWrapper = () => {
  return (
    <ClientContextProvider>
      <CreateClient />
    </ClientContextProvider>
  );
};

export default ClientContextWrapper;
