import { useMemo } from 'react';
import { useQueryEmployeeReference } from 'hooks/queries/useQueryEmployeeReference/useQueryEmployeeReference';
import { useQueryGetDepartments } from 'hooks/queries/useQueryGetDepartments/useQueryGetDepartments';
import { useQueryGetSupervisors } from 'hooks/queries/useQueryGetSupervisors/useQueryGetSupervisors';
import { Employee, GetSalesOrdersInput } from '__generated__/graphql';
import { salesOrderStatusBackgroundColorMap } from 'utils/constants';

type Props = {
  filters: GetSalesOrdersInput | undefined;
};

export const useSalesOrderFilterDropdowns = ({ filters }: Props) => {
  const sortOptions = [
    { id: 'createdDate|DESC', label: 'Date Created (Newest)' },
    { id: 'createdDate|ASC', label: 'Date Created (Oldest)' },
    { id: 'client_name|ASC', label: 'Client (A-Z)' },
    { id: 'client_name|DESC', label: 'Client (Z-A)' },
  ];

  const statusOptions = Object.keys(salesOrderStatusBackgroundColorMap);
  const { data: employeeData } = useQueryEmployeeReference({ supervisorId: filters?.supervisorId ?? '' });

  const { data: supervisorsData } = useQueryGetSupervisors();
  const supervisorOptions = useMemo(
    () =>
      supervisorsData?.getSupervisors.map((supervisor) => ({
        id: supervisor.internalId,
        label: supervisor.name ?? '',
      })) ?? [],
    [supervisorsData]
  );

  const { data: departmentsData } = useQueryGetDepartments();
  const marketOptions = useMemo(
    () =>
      departmentsData?.getDepartments
        .map((department) => ({ id: department.id, label: department.name ?? '' }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [departmentsData]
  );

  const [salesRepOptions, salesCoordinatorOptions] = useMemo(() => {
    const mapFn = (employee: Employee) => ({ label: employee.fullName, id: employee.id });
    if (employeeData) {
      return [
        employeeData.employeeReference.salesReps.map(mapFn),
        employeeData.employeeReference.salesCoordinators.map(mapFn),
      ];
    }
    return [[], []];
  }, [employeeData]);

  return {
    sortOptions,
    statusOptions,
    marketOptions,
    salesRepOptions,
    salesCoordinatorOptions,
    supervisorOptions,
  };
};
