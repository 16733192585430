import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import { styled, css } from '@mui/material/styles';

const PopoverWrapper = styled('button')(
  () => css`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 100%;
    height: 100%;
  `
);

const PopoverContentContainer = styled('div')<{ $width?: string; $gap?: string }>(
  ({ theme, $width, $gap = '0.5rem' }) => css`
    display: flex;
    background-color: ${theme.palette.input.main};
    gap: ${$gap};
    flex-wrap: wrap;
    ${$width && `width: ${$width}`};
    padding: 1rem;
    justify-content: center;
    align-items: center;
    box-shadow: ${theme.colors.blackOpacity50} 0 0.125rem 0.3125rem 0;
    border-radius: 0.5rem;
  `
);

const StyledPopover = styled(Popover)<{ $offset?: string }>(
  ({ $offset = '-1rem' }) => css`
    margin-top: ${$offset};
  `
);

type Props = {
  width?: string;
  gap?: string;
  offset?: string;
  children: React.ReactNode;
  trigger: React.ReactNode;
};

const QuoteStatusPopover: React.FC<Props> = ({ width, gap, offset, trigger, children }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = anchorEl ? Boolean(anchorEl) : null;

  return (
    <>
      <PopoverWrapper onClick={handleClick}>{trigger}</PopoverWrapper>
      <StyledPopover
        open={!!open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        $offset={offset}
      >
        <PopoverContentContainer $width={width} $gap={gap}>
          {children}
        </PopoverContentContainer>
      </StyledPopover>
    </>
  );
};

export default QuoteStatusPopover;
