import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { Tabs, Tab, TabPanel, TabContainer } from 'components/TabNavigation/TabNavigation';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Flex from 'components/Flex/Flex';
import PageHeader from 'components/PageHeader/PageHeader';
import General from './components/General';
import TransactionHistory from './components/TransactionHistory/TransactionHistory';
import { Contacts } from './components/Contacts';
import { ClientContextProvider, useClientContext } from 'contexts/ClientContext/ClientContext';
import { Addresses } from './components/Addresses';

const ClientDetails: React.FC = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const { clientV2: client } = useClientContext();

  if (!client) {
    return (
      <Flex w100 styles={{ height: 'calc(100vh - 8rem)' }} center>
        <LoadingSpinner loading={true} size={92} />
      </Flex>
    );
  }

  return (
    <Container maxWidth={false}>
      <PageHeader variant='h1' header={client.name} />
      <TabContainer>
        <Tabs value={tab} onChange={handleChange}>
          <Tab disableRipple label='General' />
          <Tab disableRipple label='Contacts' />
          <Tab disableRipple label='Addresses' />
          <Tab disableRipple label='Transaction History' />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <General client={client} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Contacts />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Addresses />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <TransactionHistory clientId={client.id} />
        </TabPanel>
      </TabContainer>
    </Container>
  );
};

const ClientDetailsContextWrapper = () => {
  return (
    <ClientContextProvider>
      <ClientDetails />
    </ClientContextProvider>
  );
};

export default ClientDetailsContextWrapper;
