import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import GoogleAnalytics from 'components/GoogleAnalytics/GoogleAnalytics';
import SideNav from 'components/SideNav/SideNav';
import TopNav from 'components/TopNav/TopNav';
import MobileNav from 'components/MobileNav/MobileNav';
import Flex from 'components/Flex/Flex';
import { useAuthContext } from '../contexts/AuthContext/AuthContext';
import BlurBox from '../components/BlurBox/BlurBox';
import LoginModal from '../LoginModal/LoginModal';

export type SubNavItem = {
  onClick?: () => void;
  showNotification?: boolean;
  disabled?: boolean;
} & (TextButtonProps | IconButtonProps | FancyIconButtonProps);

type TextButtonProps = {
  type: 'text';
  value: string;
};

type IconButtonProps = {
  type: 'icon';
  tooltip: string;
  imageData: unknown;
};

type FancyIconButtonProps = {
  type: 'fancyicon';
  tooltip: string;
  imageData: unknown;
  nudge?: boolean;
};

type ContextType = [SubNavItem[], React.Dispatch<React.SetStateAction<SubNavItem[]>>];

export const Layout = () => {
  if (
    process.env.NODE_ENV !== 'development' &&
    process.env.NODE_ENV !== 'test' &&
    process.env.REACT_APP_GOOGLE_ANALYTICS_CODE
  ) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);
  }

  const theme = useTheme();
  const { isLoggedIn } = useAuthContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const [subNavItems, setSubNavItems] = React.useState<SubNavItem[]>([]);

  const mobileStyle = { paddingTop: '5rem' };
  const tabletStyle = { paddingTop: '7rem' };
  const desktopStyle = { paddingLeft: '8rem', paddingTop: '8rem' };

  const finalStyle = isMobile ? mobileStyle : isTablet ? tabletStyle : desktopStyle;

  return (
    <GoogleAnalytics>
      <Flex h100>
        {isTablet || isMobile ? <MobileNav subNavItems={subNavItems} /> : <SideNav />}

        <Flex column w100 h100>
          <TopNav subNavItems={subNavItems} />

          <Flex w100 h100 column padding={4} styles={finalStyle}>
            <Outlet context={[subNavItems, setSubNavItems]} />
          </Flex>
        </Flex>
      </Flex>

      {!isLoggedIn && (
        <BlurBox>
          <LoginModal />
        </BlurBox>
      )}
    </GoogleAnalytics>
  );
};

export function useSubNav() {
  return useOutletContext<ContextType>();
}

export default Layout;
