import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import AuthContextProvider from 'contexts/AuthContext/AuthContext';
import AuthorizedApolloProvider from 'providers/AuthorizedApolloProvider';
import { UserContextProvider } from 'contexts/UserContext/UserContext';
import { SelectedThemeContextProvider } from './contexts/SelectedThemeContext/SelectedThemeContext';
import SwitchableThemeProvider from './providers/SwitchableThemeProvider';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <SelectedThemeContextProvider>
      <SwitchableThemeProvider>
        <AuthContextProvider>
          <AuthorizedApolloProvider>
            <UserContextProvider>
              <CssBaseline />
              <App />
            </UserContextProvider>
          </AuthorizedApolloProvider>
        </AuthContextProvider>
      </SwitchableThemeProvider>
    </SelectedThemeContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
