import Flex from 'components/Flex/Flex';
import FormLabel from 'components/FormLabel/FormLabel';
import { Box, Container, Grid, Stack } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import {
  FormikCheckbox,
  FormikInput,
  FormikSelect,
  FormikTextArea,
  FormikValuesFrom,
  createInitialValues,
} from 'utils/formik';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { css, styled, useTheme } from '@mui/material/styles';
import { useSalesOrderContext } from 'contexts/SalesOrderContext/SalesOrderContext';
import { seoFriendly } from 'utils/functions';
import { useSalesOrderDropdownOptions } from '../hooks/useSalesOrderDropdownOptions';
import { EditSalesOrderInput } from '__generated__/graphql';
import { NOT_A_REAL_EMPLOYEE, SALES_ROLE } from 'utils/constants';

const RelativeGrid = styled(Grid)(
  () => css`
    position: relative;
  `
);

const formFields = {
  customForm: 'Custom Form',
  additionalContact: 'Additional Contact',
  client: 'Client Contact',
  contact: 'Contact',
  date: 'Date',
  disregardPaystandAutopay: 'Disregard Paystand Autopay',
  firstTimePurchase: 'First Time Purchase',
  followUpDate: 'Follow Up Date',
  freightTerms: 'Freight Terms',
  market: 'Market',
  memo: 'Notes',
  poNumber: 'Client PO Number',
  poNumberRequired: 'PO Number Required',
  project: 'Project',
  projectName: 'Project Name',
  projectProfileApproved: 'Project Profile Approved',
  salesChannel: 'Sales Channel',
  salesCoordinator: 'Sales Coordinator',
  salesOrderNumber: 'Sales Order Number',
  salesTeam: 'Sales Team',
  shipVia: 'Ship Via',
  shippingAddressList: 'Shipping Address',
  shippingCost: 'Shipping Cost',
  terms: 'Terms',
  updates: 'Updates',
  warehouseNotes: 'Warehouse Notes',
  webOrder: 'Web Order',
  classification: 'Class',
  spProjectPricing: 'SP/PRJ Pricing',
  taxIncluded: 'Is Transaction Taxable',
};
const requiredFields: string[] = [
  'customForm',
  'freightTerms',
  'contact',
  'date',
  'market',
  'projectName',
  'spProjectPricing',
];
type FormikValues = FormikValuesFrom<typeof formFields, EditSalesOrderInput>;

type SalesOrderDetailsProps = {
  view?: string;
};
const SalesOrderDetails: React.FC<SalesOrderDetailsProps> = ({ view }: SalesOrderDetailsProps) => {
  const theme = useTheme();
  const { salesOrder, salesOrderInput, setSalesOrderInput } = useSalesOrderContext();

  const {
    contactOptions,
    termOptions,
    salesRepOptiopns,
    salesCoordinatorOptions,
    marketOptions,
    addressOptions,
    projectTypeOptions,
    poRequiredOptions,
    shipViaOptions,
    classificationOptions,
    freightTermsOptions,
    spProjectPricingOptions,
  } = useSalesOrderDropdownOptions();

  const initialValues = useMemo(() => {
    return {
      ...salesOrderInput,
      salesTeam: salesOrderInput.salesTeam?.[0]?.employeeId ?? '',
      shippingCost: salesOrderInput.shippingCost?.toString() ?? '',
      customForm: '122', // Default millenium sales order form
    };
  }, [salesOrderInput]);

  const toEditQuoteInput = (values: FormikValues): EditSalesOrderInput => {
    return {
      id: salesOrderInput.id,
      ...values,
      salesTeam:
        values.salesTeam && values.salesTeam !== NOT_A_REAL_EMPLOYEE
          ? [{ employeeId: values.salesTeam, salesRole: SALES_ROLE, contribution: 100 }]
          : [],
      shippingCost: Number(values.shippingCost),
    };
  };

  return (
    <Flex w100 center>
      <Container>
        <Formik
          initialValues={createInitialValues<FormikValues>(formFields, initialValues)}
          validate={(values) => {
            setSalesOrderInput((prev) => ({ ...prev, ...toEditQuoteInput(values) }));
          }}
          onSubmit={() => {}}
        >
          {({ values, handleChange }) => {
            const props = {
              handleChange,
              values,
              requiredFields,
              formFields,
              disabled: view === 'view',
            };

            return (
              <Grid container spacing={4}>
                {/* Left Side */}
                <Grid item xs={12} tablet={6}>
                  <Stack spacing={4} mb={2}>
                    <Box>
                      <FormikSelect
                        {...props}
                        name='customForm'
                        options={{ '122': 'Millennium Sales Order' }}
                        disabled={true}
                      />
                    </Box>
                    <Box>
                      <FormikSelect {...props} name='contact' options={contactOptions} />
                    </Box>
                  </Stack>
                  <Grid container spacing={4}>
                    <Grid item xs={12} tablet={6}>
                      <FormikInput {...props} name='salesOrderNumber' />
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormikInput {...props} name='projectName' />
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormikInput {...props} name='followUpDate' type='date' />
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormikInput {...props} name='date' type='date' />
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormLabel>Created from</FormLabel>
                      <Link
                        //update route once fields are wired up
                        to={`/quote/${seoFriendly(salesOrder?.client?.name || '')}-${salesOrder?.client
                          ?.id}/${salesOrder?.salesOrderNumber}`}
                        style={{ color: theme.colors.hoverBlue, textDecoration: 'none' }}
                      >
                        {salesOrder?.salesOrderNumber}
                      </Link>
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormikSelect {...props} name='poNumberRequired' options={poRequiredOptions} />
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormikSelect {...props} name='salesTeam' options={salesRepOptiopns} />
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormikSelect {...props} name='salesCoordinator' options={salesCoordinatorOptions} />
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormikSelect {...props} name='project' options={projectTypeOptions} />
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormikSelect {...props} name='salesChannel' options={{}} />
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormikSelect {...props} name='terms' options={termOptions} disabled />
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormikInput {...props} name='poNumber' />
                    </Grid>
                    <Grid item xs={12} tablet={6}>
                      <FormikSelect {...props} name='market' options={marketOptions} />
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <FormikTextArea {...props} name='memo' multiline fullWidth rows={10} />
                  </Box>
                </Grid>
                <RelativeGrid item xs={12} tablet={6}>
                  <Stack spacing={4}>
                    <Box>
                      <FormikSelect {...props} name='classification' options={classificationOptions} />
                    </Box>
                    <Box>
                      <FormikSelect {...props} name='shippingAddressList' options={addressOptions} />
                    </Box>
                    <Box>
                      <FormikSelect {...props} name='freightTerms' options={freightTermsOptions} />
                    </Box>
                    <Box>
                      <FormikSelect {...props} name='shipVia' options={shipViaOptions} />
                    </Box>
                    <FormikCheckbox {...props} name='projectProfileApproved' />
                    <FormikCheckbox {...props} name='webOrder' />
                    <Box>
                      <FormikSelect {...props} name='additionalContact' options={contactOptions} />
                    </Box>
                    <Box>
                      <FormikInput {...props} name='updates' />
                    </Box>
                    <Box>
                      <FormikCheckbox {...props} name='taxIncluded' />
                    </Box>
                    <Box>
                      <FormikTextArea {...props} name='warehouseNotes' multiline fullWidth rows={10} />
                    </Box>
                    <Box>
                      <FormikSelect {...props} name='spProjectPricing' options={spProjectPricingOptions} />
                    </Box>
                    <FormikCheckbox {...props} name='firstTimePurchase' />
                    <FormikCheckbox {...props} name='disregardPaystandAutopay' />
                  </Stack>
                </RelativeGrid>
              </Grid>
            );
          }}
        </Formik>
      </Container>
    </Flex>
  );
};

export default SalesOrderDetails;
