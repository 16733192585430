import React, { PropsWithChildren } from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

interface Props extends CircularProgressProps, PropsWithChildren {
  loading?: boolean;
}

/**
 * Provides a CircularProgress in the event that loading is true, otherwise renders children
 * @param children
 * @param loading
 * @param rest
 * @constructor
 */
const LoadingSpinner: React.FC<Props> = ({ children, loading, ...rest }) => {
  if (loading) {
    return <CircularProgress sx={{ color: '#FF0000' }} {...rest} />;
  } else {
    return children;
  }
};

export default LoadingSpinner;
