import React, { useEffect, useState } from 'react';
import DataModal, { DataModalProps } from 'components/DataModal/DataModal';

interface UpdateModalProps extends DataModalProps {
  modalId: string;
  itemIndex: number;
  setSelectedItemIndex: React.Dispatch<React.SetStateAction<number>>;
  onCloseCallback?: () => void;
}

const UpdateItemModal: React.FC<UpdateModalProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  // Open drawer when an item is selected from the item's table
  useEffect(() => {
    if (props.itemIndex > -1) {
      setIsOpen(true);
    }
  }, [setIsOpen, props.itemIndex]);

  // Close drawer and deselect item
  const handleClose = () => {
    setIsOpen(false);
    props.onCloseCallback && props.onCloseCallback();
    // Keep form visible until drawer close animation is complete
    setTimeout(() => props.setSelectedItemIndex(-1), 200);
  };

  return (
    <DataModal
      {...props}
      hideBackdrop={false}
      width={'58em'}
      fullHeightChildContainer
      onCloseCallback={handleClose}
      openOverride={isOpen}
      hideButton={true}
    >
      {props.itemIndex > -1 && props.children}
    </DataModal>
  );
};

export default UpdateItemModal;
