import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { ProcessTemplateMutation, ProcessTemplateMutationVariables } from '__generated__/graphql';
import { PROCESS_TEMPLATE } from 'graphql/template/template.gql';

type Data = ProcessTemplateMutation;
type Variables = ProcessTemplateMutationVariables;

function useMutationProcessTemplate(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  return useMutation(PROCESS_TEMPLATE, options);
}

export default useMutationProcessTemplate;
