import React, { PropsWithChildren, useMemo } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache, Observable } from '@apollo/client';
import { useAuthContext } from '../contexts/AuthContext/AuthContext';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
});

const AuthorizedApolloProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    refreshTokens,
    tokens: { accessToken },
  } = useAuthContext();

  const authLink = useMemo(() => {
    return setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      };
    });
  }, [accessToken]);

  const refreshLink = onError(({ graphQLErrors, operation, forward }) => {
    // User access token has expired
    // All of this work with Observable is because onError doesn't accept an async function.
    if (graphQLErrors && graphQLErrors[0].message.toLowerCase() === 'unauthorized') {
      return new Observable((observer) => {
        refreshTokens()
          .then((refreshResponse) => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                authorization: refreshResponse ? `Bearer ${refreshResponse}` : null,
              },
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            // No refresh or client token available, we force user to login
            observer.error(error);
          });
      });
    }
  });

  const client = new ApolloClient({
    link: refreshLink.concat(authLink).concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        QuoteStatus: {
          keyFields: false,
        },
      },
    }),
    connectToDevTools: true,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
