import { useEffect, useMemo, useState } from 'react';
import Flex from 'components/Flex/Flex';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import SearchResultHeader from 'components/SearchResultHeader/SearchResultHeader';
import InfiniteScroll from 'react-infinite-scroll-component';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SearchResult from 'components/SearchResult/SearchResult';
import { Container } from '@mui/material';
import { StyledHeader } from 'components/StyledHeader/StyledHeader';
import { QuoteText } from 'components/QuoteText/QuoteText';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer/InfiniteScrollContainer';
import { LoadingContainer } from 'components/LoadingContainer/LoadingContainer';
import { SalesOrderItem, SalesOrderItemInput, SearchItemsQuery } from '__generated__/graphql';
import { getBinsWithoutDuplicateLocations, stripHTML } from 'utils/functions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Action } from 'utils/constants';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import PillWithClose from 'components/PillWithClose/PillWithClose';
import { BoldEllipsis } from 'components/BoldEllipsis/BoldEllipsis';
import { Ellipsis } from 'components/Ellipsis/Ellipsis';
import { toModel } from 'utils/graphqlHelpers';
import { useItemsContext } from 'contexts/ItemsContext/ItemsContext';
import ItemsFilter from './ItemsFilter';
import { useSalesOrderContext } from 'contexts/SalesOrderContext/SalesOrderContext';

const tabletStyleOverrides = {
  height: '3.2rem',
  width: '3rem',
  padding: '0',
  minWidth: '0',
};

type HybridItem = SalesOrderItem & Item;

type Item = SearchItemsQuery['searchItems']['items'][0];

const AddItems = () => {
  const { can } = useAuthContext();
  const { setSalesOrderInput } = useSalesOrderContext();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const { searchData, searchLoading, searchFilters, setSearchFilters, fetchNextPage } = useItemsContext();
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [hideSearch, setHideSearch] = useState(false);

  const items = useMemo(() => {
    return searchData?.searchItems.items ?? [];
  }, [searchData]);

  const canEdit = useMemo(() => {
    return can('Sales_Order', Action.Edit);
  }, [can]);

  const selectItem = (itemId: string) => {
    const selectedItem = items.find((item) => item.itemId === itemId);
    if (selectedItem) {
      setSelectedItems([...selectedItems, selectedItem as HybridItem]);
    }
  };

  const addItems = () => {
    const newItems = selectedItems.map((item) => {
      const newItem = toModel<SalesOrderItemInput>(item, 'SalesOrderItemInput');
      // Temporary defaults, will be removed once we have the update drawerl
      newItem.quantity = 1;
      newItem.estimatedTotalCost = item.unitCost;
      newItem.costEstimateType = 'CUSTOM';
      newItem.margin = 20;
      newItem.adjCost = (item.unitCost ?? 0) * 1.2;
      newItem.total = newItem.adjCost;
      newItem.isTaxable = true;
      const location =
        item.inventoryDetail?.binNumber?.find((bin) => bin.locationQuantityAvailable) ??
        item.inventoryDetail?.binNumber?.[0];
      if (location) {
        newItem.location = location.info?.id;
      }
      newItem.deviationCode = '1';
      // End of temp defaults
      newItem.item = item.id;
      return newItem;
    });
    setSalesOrderInput((prev) => {
      return prev
        ? {
            ...prev,
            items: [...(prev?.items ?? []), ...newItems],
          }
        : prev;
    });
    setHideSearch(true);
    setSelectedItems([]);
  };

  useEffect(() => {
    if (searchFilters) {
      setHideSearch(false);
    }
  }, [searchFilters, setHideSearch]);

  const [selectionContainsInactive, btnTooltip] = useMemo(() => {
    const isSelected = selectedItems.reduce((acc, item) => acc || !!item.isInactive, false);
    const tooltip =
      selectedItems.length == 1
        ? 'This inactive item cannot be added to a quote until it is set to Active status in Netsuite.'
        : 'Inactive items cannot be added to a quote until they are set to Active status in Netsuite.';
    return [isSelected, tooltip];
  }, [selectedItems]);

  const removeItem = (itemId: string) => {
    const newSelectedItems = selectedItems.filter((item) => item.itemId && item.itemId !== itemId);
    setSelectedItems(newSelectedItems);
  };

  return (
    <Container>
      <Flex w100 gap={1} styles={{ justifyContent: 'space-between', marginBottom: '1rem' }}>
        <ItemsFilter filters={searchFilters} setFilters={setSearchFilters} />
        <Tooltip title={selectionContainsInactive ? btnTooltip : ''}>
          <span>
            <Button
              onClick={addItems}
              variant='outlined'
              disabled={selectedItems.length <= 0 || !canEdit || selectionContainsInactive}
              sx={isTablet ? tabletStyleOverrides : {}}
            >
              {isTablet ? <AddIcon /> : 'Add to Order'}
            </Button>
          </span>
        </Tooltip>
      </Flex>
      {selectedItems && (
        <Flex wrap gap={1} styles={{ marginBottom: '1rem' }}>
          {selectedItems.map((item) => (
            <PillWithClose key={item.itemId} handleClose={() => removeItem(item.itemId)}>
              {item.isInactive && (
                <Tooltip title={'Inactive'}>
                  <ErrorOutlineIcon />
                </Tooltip>
              )}
              <BoldEllipsis>{item.storeDisplayName || item.itemId}</BoldEllipsis>
              <Ellipsis>{item.description}</Ellipsis>
            </PillWithClose>
          ))}
        </Flex>
      )}
      {!hideSearch && (
        <Flex center column>
          {items.length === 0 && searchLoading && (
            <LoadingContainer>
              <LoadingSpinner loading={true} />
            </LoadingContainer>
          )}
          {items.length > 0 && (
            <SearchResultHeader>
              <StyledHeader>Item #</StyledHeader>
              <StyledHeader>Warehouse</StyledHeader>
              <StyledHeader>Available</StyledHeader>
              <StyledHeader>Description</StyledHeader>
            </SearchResultHeader>
          )}
          <InfiniteScrollContainer id='scrollable'>
            <InfiniteScroll
              dataLength={items.length}
              next={fetchNextPage}
              hasMore={items.length < (searchData?.searchItems?.total ?? 0)}
              loader={
                <LoadingContainer>
                  <LoadingSpinner loading={true} />
                </LoadingContainer>
              }
              scrollableTarget='scrollable'
            >
              {items.map((item, i) => (
                <SearchResult key={item.itemId + i} onClick={() => selectItem(item.itemId)}>
                  <QuoteText>
                    {item.isInactive && (
                      <Tooltip title='Item is Inactive'>
                        <ErrorOutlineIcon sx={{ verticalAlign: 'middle' }} />
                      </Tooltip>
                    )}
                    {item.storeDisplayName || item.itemId}
                  </QuoteText>
                  <div>
                    {item.inventoryDetail &&
                      getBinsWithoutDuplicateLocations(item.inventoryDetail).map((bin) => (
                        <div key={bin.info?.id}>{bin.info?.name ?? ''}</div>
                      ))}
                  </div>
                  <div>
                    {item.inventoryDetail &&
                      getBinsWithoutDuplicateLocations(item.inventoryDetail).map((bin) => (
                        <div key={bin.info?.id}>{bin.locationQuantityAvailable || 0}</div>
                      ))}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: stripHTML(item.description ?? '') }} />
                </SearchResult>
              ))}
            </InfiniteScroll>
          </InfiniteScrollContainer>
        </Flex>
      )}
    </Container>
  );
};

export default AddItems;
