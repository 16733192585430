import { Button as MUIButton } from '@mui/material';
import { css, styled } from '@mui/material/styles';

const Button = styled(MUIButton)(
  ({ theme }) => css`
    background-color: ${theme.colors.blue};
    border-radius: 1rem;
    color: white;
    height: 1.625rem;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    border: none;

    &:hover,
    &:focus {
      border: none;
      background-color: ${theme.colors.hoverBlue};
    }
  `
);

export default Button;
