import React from 'react';
import { Dialog } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import Button from '@mui/material/Button';
import { css, styled } from '@mui/material/styles';

const DialogContentContainer = styled('div')`
  width: 21rem;
  padding: 2rem 2.5rem;
  border-radius: 1rem;
`;

const DialogTextWrapper = styled('div')`
  display: grid;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 2rem 1fr;
  grid-auto-columns: 1fr;
  font-weight: 500;
  padding-bottom: 1rem;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 1rem;
  }
`;

const StyledReportIcon = styled(ReportIcon)`
  font-size: 3rem;
`;

const StyledButton = styled(Button)(
  ({ theme }) => css`
    width: 16rem;
    background-color: ${theme.colors.blue};
    color: white;
    border-radius: 2rem;
    font-size: 0.75rem;
    padding: 0.25rem 1rem;
    font-weight: 700;

    &:hover {
      background-color: ${theme.colors.lightBlue};
    }
  `
);

const ButtonWrapper = styled('div')`
  width: auto;
  padding: 0.65rem 0 0.5rem 0;
`;

const WarningModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContentContainer>
        <DialogTextWrapper>
          <StyledReportIcon fontSize='large' />
          Please fill out required fields before continuing
        </DialogTextWrapper>
        <ButtonWrapper>
          <StyledButton onClick={onClose}>GOT IT</StyledButton>
        </ButtonWrapper>
      </DialogContentContainer>
    </StyledDialog>
  );
};

export default WarningModal;
