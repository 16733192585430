import React, { useState } from 'react';
import { css, styled } from '@mui/material/styles';
import { NavLink, Link } from 'react-router-dom';
import LottieNavButton from 'components/LottieNavButton/LottieNavButton';
import logo from 'assets/mitp-logo.png';
import coin from 'assets/lottie/coin.json';
import dashboard from 'assets/lottie/dashboard.json';
import list from 'assets/lottie/list.json';
import tag from 'assets/lottie/tag.json';
import settings from 'assets/lottie/settings.json';
import clients from 'assets/lottie/clients.json';
import { Logout } from '@mui/icons-material';
import NavButton from '../NavButton/NavButton';
import DarkLightModeToggle from './components/DarkLightModeToggle/DarkLightModeToggle';
import { Tooltip } from 'components/Tooltip/Tooltip';

export type NavItem = {
  to: string;
  imageData: unknown;
  label?: string;
};

const LogoSection = styled('div')(
  ({ theme }) => css`
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.0625rem solid ${theme.colors.black};
    border-right: 0.0625rem solid ${theme.colors.black};
  `
);

const SubNav = styled('div')(
  () => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: calc(100vh - 4rem);
    justify-content: space-between;
    overflow: hidden;
    padding: 1rem 0;
    width: 4rem;
  `
);

const UpperSubNav = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  `
);

const LowerSubNav = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  `
);

const Img = styled('img')(
  () => css`
    width: 2.5rem;
  `
);

const ImgLink = styled(Link)(
  () => css`
    height: 1.625rem;
  `
);

const navItems: NavItem[] = [
  //TODO: Add back when page is created
  // {
  //   to: '/dashboard',
  //   imageData: dashboard,
  //   label: 'Dashboard',
  // },
  {
    to: '/quotes',
    imageData: list,
    label: 'Quotes',
  },
  // Sales Orders should not be active on prod
  // {
  //   to: '/sales-orders',
  //   imageData: coin,
  //   label: 'Sales Orders',
  // },
  {
    to: '/items',
    imageData: tag,
    label: 'Items',
  },
  {
    to: '/clients',
    imageData: clients,
    label: 'Clients',
  },
];

const SideNavContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.colors.charcoal};
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    width: 4rem;
  `
);

const SideNav: React.FC = () => {
  const [showToggle, setShowToggle] = useState(false);
  return (
    <>
      <SideNavContainer>
        <LogoSection>
          <ImgLink to='/'>
            <Img src={logo} alt='Millennium Accelerator Logo' />
          </ImgLink>
        </LogoSection>
        <SubNav>
          <UpperSubNav>
            {navItems.map((link) => (
              <Tooltip placement='right' title={link.label} key={link.to}>
                <NavLink to={link.to}>
                  {({ isActive }) => <LottieNavButton animationData={link.imageData} active={isActive} />}
                </NavLink>
              </Tooltip>
            ))}
          </UpperSubNav>
          <LowerSubNav>
            <Tooltip placement='right' title='Settings'>
              <LottieNavButton animationData={settings} onClick={() => setShowToggle(!showToggle)} />
            </Tooltip>
            <Tooltip placement='right' title='Logout'>
              <NavLink to='/logout'>
                <NavButton>
                  <Logout fontSize='small' />
                </NavButton>
              </NavLink>
            </Tooltip>
          </LowerSubNav>
        </SubNav>
      </SideNavContainer>
      <DarkLightModeToggle show={showToggle} setShow={setShowToggle} />
    </>
  );
};

export default SideNav;
