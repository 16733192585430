import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetLookupDocument,
  GetLookupQuery as GetLookupQueryBase,
  GetLookupQueryVariables,
  QueryGetLookupArgs,
} from '__generated__/graphql';

// We lose the type safety on the backend when we use the generated result
// Extending the type lets us know that the values will always be a string
interface GetLookupQuery extends GetLookupQueryBase {
  getLookup: { [key: string]: string };
}

export const useQueryGetLookup = (
  input: QueryGetLookupArgs,
  options?: QueryHookOptions<GetLookupQuery, GetLookupQueryVariables>
): QueryResult<GetLookupQuery, GetLookupQueryVariables> => {
  return useQuery(GetLookupDocument, {
    ...options,
    variables: input,
  });
};
