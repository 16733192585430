import { Grid } from '@mui/material';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { css, styled } from '@mui/material/styles';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import Button from 'components/Button/Button';
import { ItemTableItem, PricingSelectionType } from 'contexts/QuoteContext/useItems';
import { CustomListType, LinePricing } from '__generated__/graphql';
import { useQueryGetCustomList } from 'hooks/queries/useQueryGetCustomList/useQueryGetCustomList';
import { SetStateAction, useMemo } from 'react';
import { DeviationCodesToApply } from '../PricingModal';
import { Autocomplete } from 'components/Autocomplete/Autocomplete';

const SmallLabel = styled('small')(
  () => css`
    font-size: 0.7rem;
  `
);
const RadioInput = styled(RadioGroup)(
  ({ theme, disabled, checked }) => css`
    width: 100%;
    height: 100%;
    cursor: ${disabled ? 'default' : 'pointer'};
    border-radius: 0.5rem;
    margin-top: 0;
    margin-left: 0;
    position: relative;
    font-size: 0.875rem;
    padding: 0.5rem;
    border: 1px solid ${theme.palette.secondary.main};
    background-color: ${!disabled ? (checked ? theme.colors.midBlue : theme.palette.secondary.main) : 'inherit'};
    color: ${checked ? 'white' : 'inherit'};
    overflow: hidden;

    &:hover {
      border: ${!disabled ? `1px solid ${theme.colors.midBlue}` : ''};
    }
  `
);

const RadioText = styled('span')(
  () => css`
    text-align: left;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    font-weight: 500;
    font-size: 1.25rem;
    position: relative;
    padding: 0.7rem 0 0 0;
    top: auto;
    bottom: auto;
    left: 0.5rem;
    right: auto;
  `
);

type Props = {
  item: ItemTableItem;
  pricingLine: LinePricing;
  index: number;
  deviationCodesToApply: DeviationCodesToApply;
  setDeviationCodesToApply: React.Dispatch<SetStateAction<DeviationCodesToApply>>;
};

export const PriceList = ({ item, pricingLine, deviationCodesToApply, setDeviationCodesToApply, index }: Props) => {
  const { applyI2pPricing, pricingSelections, setPricingSelection, updateItemField } = useQuoteContext();

  const { data: deviationCodeListData } = useQueryGetCustomList({ id: CustomListType.DeviationCode });

  const deviationCodeOptions = useMemo(
    () =>
      deviationCodeListData?.getCustomList?.customValueList?.customValue?.map((opt) => ({
        label: opt.value,
        id: `${opt.valueId}`,
      })) ?? [],
    [deviationCodeListData]
  );

  const handleDeviationCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDeviationCodesToApply((prev) => ({ ...prev, [index]: e.target.value ?? '' }));

    if (e.target.value) {
      setPricingSelection(pricingLine.line_id, 'deviation');
    } else {
      setPricingSelection(pricingLine.line_id, null);
    }
  };

  const handlePriceSelectionChange = (type: PricingSelectionType) => {
    setPricingSelection(pricingLine.line_id, type);
    setDeviationCodesToApply((prev) => ({ ...prev, [index]: '' }));
  };

  const handleApply = () => {
    updateItemField(index, 'deviationCode', deviationCodesToApply[index]);

    if (!deviationCodesToApply[index]) {
      applyI2pPricing(pricingLine.line_id);
    }
  };

  if (pricingLine.error_message !== 'Valid price generated.') {
    return pricingLine.error_message;
  }

  return (
    <Grid container columns={6} spacing={1} direction='row'>
      <Grid item xs={1}>
        <SmallLabel>Current Price</SmallLabel>
      </Grid>
      <Grid item xs={1}>
        <SmallLabel>Good</SmallLabel>
      </Grid>
      <Grid item xs={1}>
        <SmallLabel>Better</SmallLabel>
      </Grid>
      <Grid item xs={1}>
        <SmallLabel>Best</SmallLabel>
      </Grid>
      <Grid item xs={2}>
        <SmallLabel>Deviation Code</SmallLabel>
      </Grid>

      <Grid item xs={1}>
        <RadioInput disabled>
          <RadioText>{Math.round((item.adjCost ?? 0) * 100) / 100}</RadioText>
        </RadioInput>
      </Grid>
      <Grid item xs={1}>
        <RadioInput
          key={`${item.itemNumber}-good`}
          checked={pricingSelections[pricingLine.line_id] === 'good'}
          onClick={() => handlePriceSelectionChange('good')}
        >
          <RadioText>{Math.round((pricingLine.good_target_price_per_unit ?? 0) * 100) / 100}</RadioText>
        </RadioInput>
      </Grid>
      <Grid item xs={1}>
        <RadioInput
          key={`${item.itemNumber}-better`}
          checked={pricingSelections[pricingLine.line_id] === 'better'}
          onClick={() => handlePriceSelectionChange('better')}
        >
          <RadioText>{Math.round((pricingLine.better_target_price_per_unit ?? 0) * 100) / 100}</RadioText>
        </RadioInput>
      </Grid>
      <Grid item xs={1}>
        <RadioInput
          key={`${item.itemNumber}-best`}
          checked={pricingSelections[pricingLine.line_id] === 'best'}
          onClick={() => handlePriceSelectionChange('best')}
        >
          <RadioText>{Math.round((pricingLine.best_target_price_per_unit ?? 0) * 100) / 100}</RadioText>
        </RadioInput>
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          name='deviationCode'
          value={deviationCodesToApply[index]}
          options={deviationCodeOptions}
          onChange={handleDeviationCodeChange}
        />
      </Grid>

      <Grid item xs={5}></Grid>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color='primary' variant='outlined' onClick={handleApply}>
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};
