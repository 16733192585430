import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css, styled } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { createColumnHelper, useReactTable, getCoreRowModel, flexRender, Row as RowType } from '@tanstack/react-table';
import {
  TableHeaderCell,
  Table as BaseTable,
  DraggableRow,
  LeftActionCell,
  RightActionCell,
  RowActionsWrapperRight,
  IconWrapper,
} from 'components/TableComponents/TableComponents';
import Flex from 'components/Flex/Flex';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { formatCurrency } from 'utils/functions';
import UpdateItemForm from './components/UpdateItemForm/UpdateItemForm';
import UpdateItemModal from './components/UpdateItemModal/UpdateItemModal';
import { SubNavItem } from 'layout/Layout';
import share from 'assets/lottie/share.json';
import checkPricing from 'assets/lottie/check-pricing.json';
import useMutationGetPricing from 'hooks/mutations/useMutationGetPricing/useMutationGetPricing';
import send from 'assets/lottie/send.json';
import SendQuoteDrawer from './components/SendQuoteDrawer/SendQuoteDrawer';
import duplicate from 'assets/lottie/duplicate.json';
import { PricingItemInput } from '__generated__/graphql';
import { useQuoteSubNavContext } from 'contexts/QuoteSubNavContext/QuoteSubNavContext';
import {
  ItemTableItem,
  getDeviationFromPrice,
  nonInventoryTypes,
  nonInventoryTypesWithQuantity,
} from 'contexts/QuoteContext/useItems';
import ItemCheckbox from './components/ItemCheckbox/ItemCheckbox';
import check from 'assets/lottie/check.json';
import trash from 'assets/lottie/trash.json';
import ItemsDeleteDialog from './components/ItemsDeleteDialog/ItemsDeleteDialog';
import ItemDescriptionCell from './components/ItemDescriptionCell/ItemDescriptionCell';
import UnitCostCell from './components/UnitCostCell/unitCostCell';
import PurchaseHistoryTable from 'routes/Quotes/components/PurchaseHistoryTable/PurchaseHistoryTable';
import { ItemGroupQuantityInput } from 'routes/Quote/components/ItemGroupQuantityInput/ItemGroupQuantityInput';
import { Link } from 'react-router-dom';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ItemQuantityInput } from 'routes/Quote/components/ItemQuantityInput/ItemQuantityInput';
import { ItemSalePriceInput } from 'routes/Quote/components/ItemSalePriceInput/ItemSalePriceInput';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import Button from 'components/Button/Button';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import { DndProvider, MouseTransition, TouchTransition } from 'react-dnd-multi-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

const ActionCell: React.FC<{ row: RowType<ItemTableItem>; visible: boolean }> = ({ row, visible }) => {
  const { removeItem, duplicateItem } = useQuoteContext();

  const deleteQuoteItem = useCallback(() => {
    removeItem(row.index);
  }, [removeItem, row]);

  const duplicateQuoteItem = useCallback(() => {
    duplicateItem(row.index);
  }, [duplicateItem, row]);

  // Dont let the user remove end of group items
  if (row.original.type === 'EndGroup') {
    return;
  }

  return (
    <RightActionCell>
      <RowActionsWrapperRight>
        <Flex column h100 styles={{ justifyContent: 'space-around' }}>
          <IconWrapper>
            <ActionMenu
              visible={visible}
              objectName='Item'
              onDelete={deleteQuoteItem}
              onDuplicate={duplicateQuoteItem}
            />
          </IconWrapper>
        </Flex>
      </RowActionsWrapperRight>
    </RightActionCell>
  );
};

export const ItemModalButton = styled('button')<{ $visible: boolean }>(
  ({ theme, $visible }) => css`
    border-radius: 0.5rem;
    color: white;
    padding: 0.25rem 0.5rem;
    font-size: 0.65rem;
    font-weight: 600;
    background-color: ${theme.colors.black};
    box-shadow: none;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    transition: all 313ms ease-in-out;
    opacity: 0;
    border: none;
    background-color: ${theme.colors.blue};
    cursor: pointer;

    &:hover {
      filter: brightness(110%);
    }

    svg {
      font-size: 1rem;
    }
  `
);

export const ItemNumberCellContent = styled('div')<{ $visible: boolean }>(
  () => css`
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: start;
  `
);

export const OpenButton = styled(Button)(
  () => css`
    border-radius: 1rem;

    svg {
      font-size: 1rem;
    }
  `
);

export const QuoteNumber = styled(Link)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    margin-right: 1rem;
    white-space: nowrap;
  `
);

const Table = styled(BaseTable)(
  () => css`
    width: calc(100% + 4rem);
    margin-left: -4rem;
    margin-right: -1rem;
  `
);

const ItemsTable: React.FC = () => {
  const { setAdditionalSubnavItems, bulkSelect, setBulkSelect } = useQuoteSubNavContext();
  const {
    quote,
    updateItemIndex,
    formData,
    setShowSnackbar,
    setSnackbarMessage,
    duplicateQuote,
    itemsTableItems,
    bulkRemoveItems,
    purchaseHistoryItem,
    updateItemField,
    clientData,
  } = useQuoteContext();
  const [getPricing] = useMutationGetPricing();
  const [untruncateDescription, setUntruncateDescription] = useState<{ [key: string]: boolean }>({});
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isShareTooltipClicked, setIsShareTooltipClicked] = useState<boolean>(false);
  const columnHelper = createColumnHelper<ItemTableItem>();
  const { setI2pPricing } = useQuoteContext();
  const [checked, setChecked] = useState<{ [key: string]: boolean | undefined }>({});
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [deleteClicked, setDeleteClicked] = useState<boolean>(false);

  // We keep track of the "selected" item, by storing its index here
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  useEffect(() => {
    setChecked({});
    setSelectAll(false);
  }, [bulkSelect]);

  const deleteCount = React.useMemo(() => {
    return Object.values(checked).filter((item) => item).length;
  }, [checked]);

  const handleCopyToClipboard = () => {
    const pageURL = window.location.href;

    navigator.clipboard
      .writeText(pageURL)
      .then(() => {
        setIsShareTooltipClicked(true);
      })
      .catch((error) => {
        error('Failed to copy to clipboard:', error);
      });
  };

  const tooltipText = isShareTooltipClicked ? 'Link Copied!' : 'SHARE FOR REVIEW';

  const handleCheckPricing = useCallback(async () => {
    setSnackbarMessage('Fetching pricing information...');
    setShowSnackbar(true);
    const { data: pricing } = await getPricing({
      variables: {
        input: {
          customer_id: quote?.client?.id ?? '',
          dso: formData.recommendedPricingData.daySalesOutstanding || '0',
          credit_card_flag: formData.recommendedPricingData.cardFees ? 'Y' : 'N',
          region: quote?.market?.name ?? '',
          quote_type: quote?.quoteType?.name ?? 'bid',
          customer_segment: clientData?.segment ?? null,
          customer_level: clientData?.level ?? null,
          shipping: 100, //TODO MITP-143 this will likely move to the individual items once we know per-item shipping info
          quotelines: itemsTableItems
            .map((item, idx): PricingItemInput => {
              const itemNumber = item.parent?.itemId || item.itemNumber || item.item.name;
              const lineId = item.itemNumber || item.item.name;
              return {
                line_id: `${lineId}__${idx}`, //Presuming an item can be listed more than once, eg if multiple warehouses
                item: itemNumber ?? '',
                quantity: item.quantity ?? 0,
                unit_cost: item.unitCost || item.estimatedTotalCost || 0,
                warehouse: item.warehouse || 'Dropship - ML',
                item_type: item.type ?? '',
                cost_type: item.costEstimateType ?? 'custom',
                customer_segment: clientData?.segment ?? null,
                customer_level: clientData?.level ?? null,
              };
            })
            .filter((item) => item.item_type === 'InventoryItem') // Filter out non-inventory items
            .filter((item) => item.unit_cost && item.unit_cost > 0)
            .filter((item) => item.item !== 'TBD'), //TBD items cause 'Attribute Error: Object is not scriptable',
        },
      },
    });

    if (itemsTableItems) {
      itemsTableItems.forEach((item, index) => {
        updateItemField(index, 'i2pCalculationId', pricing?.getPricing?.metadata?.calculationId ?? '');
      });
    }

    if ('undefined' === typeof pricing?.getPricing || pricing.getPricing?.errorMessage !== null) {
      setSnackbarMessage(`Error fetching pricing: ${pricing?.getPricing?.errorMessage ?? 'Result was empty'}`);
      setShowSnackbar(true);
      setI2pPricing(null);
    } else {
      setShowSnackbar(false);
      setI2pPricing(pricing?.getPricing ?? null);

      itemsTableItems.forEach((item, index) => {
        const deviationCode = getDeviationFromPrice(pricing.getPricing, index, item.item.name ?? '', item.adjCost ?? 0);

        if (deviationCode) {
          updateItemField(index, 'deviationCode', deviationCode);
        }
      });
    }
  }, [
    formData.recommendedPricingData.cardFees,
    formData.recommendedPricingData.daySalesOutstanding,
    getPricing,
    itemsTableItems,
    quote?.client?.id,
    quote?.market?.name,
    quote?.quoteType?.name,
    setI2pPricing,
    setShowSnackbar,
    setSnackbarMessage,
    updateItemField,
    clientData,
  ]);

  const toggleBulkSelect = useCallback(() => {
    if (setBulkSelect) {
      setBulkSelect(!bulkSelect);
    }
  }, [bulkSelect, setBulkSelect]);

  const baseSubnavItems: SubNavItem[] = useMemo(() => {
    const bulkSelectItems = !bulkSelect
      ? [{ type: 'icon', tooltip: 'Bulk Select', imageData: check, onClick: toggleBulkSelect }]
      : [
          { type: 'text', value: 'Cancel', onClick: toggleBulkSelect },
          { type: 'icon', tooltip: 'Delete Items', imageData: trash, onClick: () => setDeleteClicked(true) },
        ];

    const items: SubNavItem[] = [
      {
        type: 'fancyicon',
        tooltip: 'CHECK PRICING',
        nudge: false,
        imageData: checkPricing,
        onClick: handleCheckPricing,
      },
      ...(bulkSelectItems as SubNavItem[]),
    ];
    if (quote?.id) {
      items.push(
        {
          type: 'icon',
          tooltip: 'SEND TO CLIENT',
          imageData: send,
          onClick: () => {
            setIsDrawerOpen(true);
          },
        },
        { type: 'icon', tooltip: 'DUPLICATE QUOTE', imageData: duplicate, onClick: duplicateQuote },
        { type: 'icon', tooltip: tooltipText, imageData: share, onClick: handleCopyToClipboard }
      );
    }
    return items;
  }, [bulkSelect, toggleBulkSelect, handleCheckPricing, duplicateQuote, tooltipText, quote?.id]);

  const toggleUntruncateDescription = (id: string) => {
    setUntruncateDescription({ ...untruncateDescription, [id]: !untruncateDescription[id] });
  };

  useEffect(() => {
    setAdditionalSubnavItems && setAdditionalSubnavItems(baseSubnavItems);

    return () => setAdditionalSubnavItems && setAdditionalSubnavItems([]);
  }, [setAdditionalSubnavItems, baseSubnavItems]);

  const triggerPricingAnimation = useCallback(() => {
    if (setAdditionalSubnavItems) {
      setAdditionalSubnavItems((prev) =>
        prev.map((item) => (item.type === 'fancyicon' ? { ...item, nudge: true } : item))
      );

      return setTimeout(() => {
        setAdditionalSubnavItems((prev) =>
          prev.map((item) => (item.type === 'fancyicon' ? { ...item, nudge: false } : item))
        );
      }, 10);
    }
  }, [setAdditionalSubnavItems]);

  // Trigger nudge then reset when items are added/removed
  useEffect(() => {
    const nudgeTimeout = triggerPricingAnimation();

    return () => {
      clearTimeout(nudgeTimeout);
    };
  }, [itemsTableItems.length, triggerPricingAnimation]);

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (quoteItem) => ({
          line: quoteItem.line,
          isNonI2pPrice: quoteItem.isNonI2pPrice,
          deviationCode: quoteItem.deviationCode,
        }),
        {
          id: 'line',
          cell: (info) => (
            <Flex w100 gap={0.5} styles={{ alignItems: 'center' }}>
              {info.getValue().isNonI2pPrice && !info.getValue().deviationCode && (
                <Tooltip title='Please select an I2P recommended price or a deviation code for the items indicated on the table.'>
                  <ErrorOutlineIcon sx={{ verticalAlign: 'middle' }} />
                </Tooltip>
              )}
              {info.getValue().line}
            </Flex>
          ),
          header: () => <span>Line</span>,
        }
      ),
      columnHelper.accessor((quoteItem, index) => ({ item: quoteItem, index }), {
        id: 'itemNumber',
        cell: (info) => {
          const { item, index } = info.getValue();

          if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(item.type ?? '')) {
            return <ItemNumberCellContent>{item.storeDisplayName || item.item.name!}</ItemNumberCellContent>;
          }
          return (
            <ItemNumberCellContent onClick={() => null}>
              <Tooltip title='View Item Details Page'>
                <QuoteNumber to={`/item/${item.item.id}`} target='_blank'>
                  {item.storeDisplayName || item.item.name}
                </QuoteNumber>
              </Tooltip>

              <Tooltip title='Edit Item Details'>
                <OpenButton onClick={() => setSelectedItemIndex(index)}>
                  <KeyboardDoubleArrowLeftIcon />
                  Open
                </OpenButton>
              </Tooltip>
            </ItemNumberCellContent>
          );
        },
        header: () => <span>Item #</span>,
      }),
      columnHelper.accessor((quoteItem) => quoteItem, {
        id: 'totalOnHand',
        cell: (info) => {
          if (nonInventoryTypes.includes(info.getValue().type ?? '')) {
            return null;
          }
          const quoteItem = info.getValue();
          const binOnHand = quoteItem.inventoryDetail?.binNumber?.reduce(
            (acc, warehouse) => (warehouse.onHandAvail ? acc + Number(warehouse.onHandAvail) : acc),
            0
          );
          return binOnHand || quoteItem.reelQuantityAvailable || quoteItem.parent?.reelQuantityAvailable || 0;
        },
        header: () => <span>Available</span>,
      }),
      columnHelper.accessor((quoteItem, index) => ({ item: quoteItem, index }), {
        id: 'quantity',
        cell: (info) => {
          const { item, index } = info.getValue();

          if (item.type === 'ItemGroup') {
            return <ItemGroupQuantityInput index={index} item={item} />;
          } else if (
            nonInventoryTypes.includes(item.type ?? '') &&
            !nonInventoryTypesWithQuantity.includes(item.type ?? '')
          ) {
            return null;
          }

          return (
            <ItemQuantityInput
              item={item}
              index={index}
              isNonInventoryWithQuantity={nonInventoryTypesWithQuantity.includes(item.type ?? '')}
            />
          );
        },
        header: () => <span>Quantity</span>,
      }),
      columnHelper.accessor((quoteItem) => quoteItem.inventory ?? [], {
        id: 'warehouse',
        cell: (info) => {
          const inventory = info.getValue();

          return (
            <table style={{ width: '100%' }}>
              <tbody>
                {inventory.map((inv) => (
                  <tr key={`${inv.id}-${inv.location}`}>
                    <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{inv.location || 'unspecified'}:</td>
                    <td style={{ textAlign: 'right', minWidth: '2rem' }}>{inv.quantity.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        },
        header: () => <span>Warehouse</span>,
      }),
      columnHelper.accessor(
        (quoteItem) => ({
          id: quoteItem.item.id,
          leadTime: quoteItem.leadTime,
          description: quoteItem.description,
          type: quoteItem.type,
        }),
        {
          id: 'description',
          cell: (info) => {
            const { type } = info.getValue();
            if (type === 'EndGroup') {
              return null;
            }
            return <ItemDescriptionCell info={info} untruncateDescription={untruncateDescription} />;
          },
          header: () => <span>Description</span>,
        }
      ),
      columnHelper.accessor(
        (quoteItem) => ({ id: quoteItem.item.id, unitCost: quoteItem?.unitCost, type: quoteItem.type }),
        {
          id: 'unitCost',
          cell: (info) => {
            if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(info.getValue().type ?? '')) {
              return null;
            }
            return <UnitCostCell onBlurCallback={() => triggerPricingAnimation()} info={info} />;
          },
          header: () => <span>Unit Cost</span>,
        }
      ),
      columnHelper.accessor((quoteItem) => quoteItem.margin, {
        id: 'margin',
        cell: (info) => info.getValue(),
        header: () => <span>Margin %</span>,
      }),
      columnHelper.accessor((quoteItem, index) => ({ item: quoteItem, index }), {
        id: 'adjCost',
        cell: (info) => {
          const { item, index } = info.getValue();

          if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(item.type ?? '')) {
            return null;
          }

          return <ItemSalePriceInput onBlurCallback={() => triggerPricingAnimation()} index={index} item={item} />;
        },
        header: () => <span>Sale Price</span>,
      }),
      columnHelper.accessor((quoteItem, index) => ({ item: quoteItem, index }), {
        id: 'total',
        cell: (info) => {
          const { item, index } = info.getValue();

          if (item.type === 'EndGroup') {
            // Starting at the EndGroup item, loop backwards until you get to an ItemGroup item
            // Add all the items along the way into the "group" array
            let currentIndex = index;
            let currentItem = itemsTableItems[index];
            const group: ItemTableItem[] = [];

            while (currentItem.type !== 'ItemGroup' && currentIndex > -1) {
              currentItem = itemsTableItems[currentIndex];
              group.push(currentItem);
              currentIndex--;
            }

            // Get the total cost of the group
            const groupTotal = group.reduce((accumulator, currentValue) => {
              return accumulator + (currentValue?.quantity ?? 0) * (currentValue?.adjCost ?? 0);
            }, 0);

            return formatCurrency(groupTotal);
          } else {
            if (['ItemGroup', 'DescriptionItem'].includes(item.type ?? '')) {
              return null;
            }
          }

          const calculateTotal = (quantity: number, adjCost: number) => {
            return Math.round(quantity * adjCost * 100) / 100;
          };
          return formatCurrency(calculateTotal(item.quantity || 0, item.adjCost || 0));
        },
        header: () => <span>Total</span>,
      }),
    ],
    [columnHelper, untruncateDescription, itemsTableItems, triggerPricingAnimation]
  );

  const { isSmall, isMedium, isLarge, isExtraLarge } = useBreakpoints();

  const itemsTable = useReactTable({
    columns,
    data: itemsTableItems,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        adjCost: isExtraLarge,
        margin: isLarge,
        description: isSmall,
        totalOnHand: isLarge,
        warehouse: isMedium,
        unitCost: isExtraLarge,
      },
    },
  });

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue: { [key: string]: boolean } = {};
      itemsTable.getRowModel().rows.forEach((row) => {
        newValue[row.id] = event.target.checked;
      });
      setChecked(newValue);
      setSelectAll(event.target.checked);
    },
    [itemsTable]
  );

  const handleChecked = useCallback(
    (id: string) => {
      setChecked({ ...checked, [id]: !checked[id] });
    },
    [checked]
  );

  const handleBulkDelete = useCallback(() => {
    bulkRemoveItems(
      Object.entries(checked)
        .filter(([_, value]) => value)
        .map(([key, _]) => itemsTable.getRow(key).index)
    );
    setBulkSelect!(false);
    setChecked({});
    setDeleteClicked(false);
  }, [bulkRemoveItems, checked, itemsTable, setBulkSelect]);

  const HTML5toTouch = {
    backends: [
      {
        id: 'html5',
        backend: HTML5Backend,
        transition: MouseTransition,
      },
      {
        id: 'touch',
        backend: TouchBackend,
        options: {
          enableMouseEvents: true,
          delayTouchStart: 100,
        },
        preview: true,
        transition: TouchTransition,
      },
    ],
  };

  return (
    <>
      <ItemsDeleteDialog
        deleteCount={deleteCount}
        open={deleteClicked}
        onCancel={() => setDeleteClicked(false)}
        onConfirm={handleBulkDelete}
      />
      <DndProvider options={HTML5toTouch}>
        <SendQuoteDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
        <Table>
          <thead>
            {itemsTable.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <LeftActionCell>
                  <ItemCheckbox header checked={selectAll} onChange={handleSelectAll} />
                </LeftActionCell>
                {headerGroup.headers.map((header, i) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <TableHeaderCell $start={i === 0} $end={i === headerGroup.headers.length - 1} $noMarginBottom>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHeaderCell>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {itemsTable.getRowModel().rows.map((row) => {
              return (
                <DraggableRow
                  key={row.id}
                  row={row as RowType<ItemTableItem>}
                  reorderRow={updateItemIndex}
                  rowActionsVisible
                  untruncateDescription={!!untruncateDescription[row.id]}
                  toggleUntruncateDescription={toggleUntruncateDescription}
                  actionCell={ActionCell}
                  alternateLeftActionCell={
                    bulkSelect ? (
                      <ItemCheckbox checked={checked[row.id] === true} onChange={() => handleChecked(row.id)} />
                    ) : null
                  }
                />
              );
            })}
          </tbody>
        </Table>

        <PurchaseHistoryTable item={purchaseHistoryItem} />

        <UpdateItemModal
          onCloseCallback={() => triggerPricingAnimation()}
          childPadding={'1rem 2rem'}
          headerPadding={'0 1.75rem'}
          onButtonClick={() => null}
          buttonText='Update'
          title={
            itemsTableItems[selectedItemIndex]?.storeDisplayName ?? itemsTableItems[selectedItemIndex]?.item?.name ?? ''
          }
          hideCreate={true}
          modalId={itemsTableItems[selectedItemIndex]?.item?.id ?? 'no-item-selected'}
          itemIndex={selectedItemIndex}
          setSelectedItemIndex={setSelectedItemIndex}
        >
          <UpdateItemForm index={selectedItemIndex} />
        </UpdateItemModal>
      </DndProvider>
    </>
  );
};

export default ItemsTable;
