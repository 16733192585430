import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import PageHeader from 'components/PageHeader/PageHeader';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileNav from 'components/MobileNav/MobileNav';
import SideNav from 'components/SideNav/SideNav';
import TopNav from 'components/TopNav/TopNav';
import Flex from 'components/Flex/Flex';
import { SubNavItem } from '../layout/Layout';

const ErrorBoundary: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const error = useRouteError();
  const [subNavItems] = React.useState<SubNavItem[]>([]);

  const mobileStyle = { paddingTop: '5rem' };
  const tabletStyle = { paddingTop: '7rem' };
  const desktopStyle = { paddingLeft: '8rem', paddingTop: '8rem' };

  const finalStyle = isMobile ? mobileStyle : isTablet ? tabletStyle : desktopStyle;

  if (isRouteErrorResponse(error)) {
    return (
      <Flex h100>
        {isTablet || isMobile ? <MobileNav /> : <SideNav />}
        <Flex column w100 h100>
          {!isTablet && <TopNav subNavItems={subNavItems} />}
          <Flex w100 h100 column padding={4} styles={finalStyle}>
            <PageHeader variant='h1' header={error.statusText} />
          </Flex>
        </Flex>
      </Flex>
    );
  }
};

export default ErrorBoundary;
