import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  QuerySearchItemsArgs,
  SearchItemsDocument,
  SearchItemsQuery,
  SearchItemsQueryVariables,
} from '__generated__/graphql';

export type SearchItemQueryInput = {
  query: string;
};

export const useQuerySearchItems = (
  input: QuerySearchItemsArgs,
  options?: QueryHookOptions<SearchItemsQuery, SearchItemsQueryVariables>
) => {
  return useQuery(SearchItemsDocument, {
    fetchPolicy: 'network-only',
    ...options,
    variables: input,
  });
};
