import React, { useMemo } from 'react';
import { createColumnHelper, getCoreRowModel, useReactTable, flexRender } from '@tanstack/react-table';
import {
  TableCell,
  Row,
  Spacer,
  Table,
  TableHeaderCell,
  RightActionCell,
} from 'components/TableComponents/TableComponents';
import { GetItemQuery, Locations } from '__generated__/graphql';
import { formatCurrency } from 'utils/functions';

type Item = GetItemQuery['getItem'];

type Props = {
  item: Item;
};

const emptyLocationsArray: Locations[] = [];

const InventoryStatusTable: React.FC<Props> = ({ item }) => {
  const columnHelper = createColumnHelper<Locations>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((location) => location.info?.name, {
        id: 'name',
        cell: (info) => info.getValue(),
        header: () => <span>Warehouse</span>,
      }),
      columnHelper.accessor((location) => location.onHandAvail, {
        id: 'available',
        cell: (info) => info.getValue() ?? 0,
        header: () => <span>Available</span>,
      }),
      columnHelper.accessor((location) => location.averageCost, {
        id: 'avgCost',
        cell: (info) => formatCurrency(info.getValue() || item?.averageCost || 0),
        header: () => <span>Average Cost</span>,
      }),
      columnHelper.accessor((location) => location.onHand, {
        id: 'onHand',
        cell: (info) => info.getValue() ?? 0,
        header: () => <span>On Hand</span>,
      }),
      // columnHelper.accessor((location) => location.onOrder, {
      //   id: 'onOrder',
      //   cell: (info) => info.getValue(),
      //   header: () => <span>On Order</span>,
      // }),
      columnHelper.accessor((location) => location.committedQtyPerLocation, {
        id: 'committed',
        cell: (info) => info.getValue() ?? 0,
        header: () => <span>Committed Per Location</span>,
      }),
      // columnHelper.accessor((location) => location.allocated, {
      //   id: 'allocated',
      //   cell: (info) => info.getValue(),
      //   header: () => <span>Allocated</span>,
      // }),
      columnHelper.accessor((location) => location.binNumber?.name, {
        id: 'binNumber',
        cell: (info) => info.getValue(),
        header: () => <span>Bin Number</span>,
      }),
      // columnHelper.accessor((location) => location.status, {
      //   id: 'status',
      //   cell: (info) => info.getValue()?.name,
      //   header: () => <span>Status</span>,
      // }),
    ],
    [columnHelper, item?.averageCost]
  );

  const table = useReactTable({
    columns,
    data: item?.inventoryDetail?.binNumber ?? emptyLocationsArray,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!item?.inventoryDetail?.binNumber) return null;

  return (
    <>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <TableHeaderCell $start={i === 0} $end={i === headerGroup.headers.length - 1}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHeaderCell>
                    )}
                  </th>
                );
              })}
              <RightActionCell />
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Row key={row.id}>
                {row.getVisibleCells().map((cell, i) => {
                  return (
                    <TableCell
                      $start={i === 0}
                      $end={i === row.getVisibleCells().length - 1}
                      $padding={cell.column.id === 'quote' && '0'}
                      key={cell.id}
                    >
                      <Spacer>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Spacer>
                    </TableCell>
                  );
                })}
              </Row>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default InventoryStatusTable;
