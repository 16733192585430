import { Container, Stack } from '@mui/material';
import { GetClientContactsQuery } from '__generated__/graphql';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import Button from 'components/Button/Button';
import ClientContactModal from 'components/ClientContactModal/ClientContactModal';
import DataTable, { ActionCellProps } from 'components/DataTable/DataTable';
import FormHeader from 'components/FormHeader/FormHeader';
import { IconWrapper } from 'components/TableComponents/TableComponents';
import { useClientContext } from 'contexts/ClientContext/ClientContext';
import { useQueryGetClientContacts } from 'hooks/queries/useQueryGetClientContacts/useQueryGetClientContacts';
import { useEffect, useMemo, useState } from 'react';
import { Client } from 'contexts/ClientContext/ClientContext';

type Contact = GetClientContactsQuery['getClientContacts']['contacts'][0];

export const Contacts = () => {
  const { clientV2: client } = useClientContext();

  const [isOpen, setIsOpen] = useState(false);

  const [selectedContact, setSelectedContact] = useState<Contact>();

  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    total: 0,
  });

  const { data, loading, refetch } = useQueryGetClientContacts(
    {
      input: {
        page: pagination.page,
        perPage: pagination.perPage,
        clientId: client?.id ?? '',
      },
    },
    {
      skip: !client,
    }
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: data?.getClientContacts.total ?? 0,
    }));
  }, [data]);

  const columns = useMemo(
    () => [
      {
        id: 'fullName',
        header: 'Name',
      },
      {
        id: 'client',
        header: 'Company Name',
        valueFn: () => client?.name,
      },
      {
        id: 'jobTitle',
        header: 'Job Title',
      },
      {
        id: 'phone',
        header: 'Phone',
      },
      {
        id: 'email',
        header: 'Email',
      },
      {
        id: 'preferredCommunicationMethod',
        header: 'Preferred Communication Method',
      },
    ],
    [client?.name]
  );

  return (
    <Container>
      <Stack direction='row' justifyContent='space-between'>
        <FormHeader variant='h3' header='Contacts' />
        <Button onClick={() => setIsOpen(true)}>Create new contact</Button>
      </Stack>

      <ClientContactModal
        onSubmitCallback={async () => {
          await refetch();
        }}
        open={isOpen}
        onClose={() => {
          setSelectedContact(undefined);
          setIsOpen(false);
        }}
        width='58em'
        contact={selectedContact}
      />

      <DataTable
        data={data?.getClientContacts.contacts}
        columns={columns}
        loading={loading}
        pagination={pagination}
        setPagination={setPagination}
        leftActionCell={() => null}
        rightActionCell={({ isActive, row }: ActionCellProps<Contact>) => (
          <IconWrapper>
            <ActionMenu
              objectName='Contact'
              visible={isActive}
              onEdit={() => {
                setIsOpen(true);
                setSelectedContact(row.original);
              }}
            />
          </IconWrapper>
        )}
      />
    </Container>
  );
};
