import { Button, Grid, MenuItem, Select, Stack } from '@mui/material';
import React, { useMemo } from 'react';

export type PaginationProps = {
  currentPage: number;
  pages: number;
  onPageChange: (page: number) => void;
  perPage?: number;
  onPerPageChange?: (perPage: number) => void;
  maxPageSelectorSize?: number;
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  pages,
  onPageChange,
  perPage,
  onPerPageChange,
  maxPageSelectorSize,
}) => {
  const canGetNextPage = useMemo(() => currentPage < pages - 1, [pages, currentPage]);
  const canGetPrevPage = useMemo(() => currentPage > 0, [currentPage]);

  const pageList = useMemo(() => {
    return Array.from(Array(pages), (_, index) => index).map((page) => (
      <MenuItem key={page} value={page}>
        {page + 1}
      </MenuItem>
    ));
  }, [pages]);

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={0} lg={4} />
      <Grid item xs={12} lg={4} alignItems='center'>
        <Stack direction={'row'} spacing={2} justifyContent='center'>
          <Button variant='outlined' onClick={() => onPageChange(0)} disabled={!canGetPrevPage}>
            {'<<'}
          </Button>
          <Button variant='outlined' onClick={() => onPageChange(currentPage - 1)} disabled={!canGetPrevPage}>
            {'< Prev'}
          </Button>
          <Button variant='outlined' onClick={() => onPageChange(currentPage + 1)} disabled={!canGetNextPage}>
            {'Next >'}
          </Button>
          <Button variant='outlined' onClick={() => onPageChange(pages - 1)} disabled={!canGetNextPage}>
            {'>>'}
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Stack direction='row-reverse' spacing={2}>
          <span>
            <div>Page</div>
            <strong>
              {currentPage + 1} of {pages}
            </strong>
          </span>
          {pages < (maxPageSelectorSize ?? 2000) && (
            <span>
              <span style={{ marginRight: '.75rem' }}>Go to page:</span>
              <Select
                value={currentPage}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 0;
                  onPageChange(page);
                }}
              >
                {pageList}
              </Select>
            </span>
          )}
          {perPage && onPerPageChange && (
            <Select
              value={perPage}
              onChange={(e) => {
                onPerPageChange(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <MenuItem key={pageSize} value={pageSize}>
                  Show {pageSize}
                </MenuItem>
              ))}
            </Select>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Pagination;
