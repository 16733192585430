import { useMemo } from 'react';
import { CustomValue as CustomValueBase } from '__generated__/graphql';

type DropdownOption = {
  id: string;
  label: string;
};

type StitchRelation = {
  id: string;
  name: string;
};

type CustomValue = Pick<CustomValueBase, 'valueId' | 'value'>;

export const useDropdownOptions = (data: DropdownOption[] | CustomValue[] | StitchRelation[] | null | undefined) => {
  return useMemo(() => {
    return (
      data?.map((item: DropdownOption | CustomValue | StitchRelation) => {
        if ('valueId' in item && 'value' in item) {
          return {
            id: item.valueId.toString(),
            label: item.value,
          };
        } else if ('id' in item && 'name' in item) {
          return {
            id: item.id,
            label: item.name,
          };
        }
        return item;
      }) ?? []
    );
  }, [data]);
};
