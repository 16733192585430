import { Quote, EditQuoteInput, CreateQuoteInput } from '__generated__/graphql';
import { QuoteMetrics } from 'contexts/QuoteContext/QuoteContext';
import { itemMarginFromCostPrice } from 'contexts/QuoteContext/useItems';
import { NOT_A_REAL_EMPLOYEE, SALES_ROLE } from '../constants';

export const computeDerivedMetrics = (quote: Quote) => {
  const metrics: QuoteMetrics = {
    subtotal: 0,
    totalMargin: 0,
    grossProfit: 0,
    grossProfitPercent: 0,
    shippingCost: 0,
    tax: 0,
    total: 0,
  };
  let totalCost = 0;
  let totalItemCount = 0;

  if (quote.items && quote.items.length > 0) {
    quote.items.forEach((item) => {
      if (item) {
        totalItemCount += item.quantity || 0;
        metrics.subtotal += (item.quantity || 0) * (item.adjCost || item.unitCost || 0);
        totalCost += (item.unitCost || 0) * (item.quantity || 0);
      }
    });
    metrics.totalMargin = totalCost ? itemMarginFromCostPrice(totalCost, metrics.subtotal) : 0;
    metrics.total = metrics.subtotal; // + (quote.freight || 0) + (quote.tax || 0);
    metrics.grossProfit = metrics.subtotal - totalCost;
    metrics.grossProfitPercent = (metrics.grossProfit * 100) / metrics.subtotal;
  }

  if (quote.shippingCost) {
    metrics.shippingCost = quote.shippingCost;
    metrics.total += metrics.shippingCost;
  }

  if (quote.taxIncluded && quote.taxTotal) {
    metrics.tax = quote.taxTotal;
    metrics.total += metrics.tax;
  }

  return metrics;
};

export const formatExistingQuoteInput = (quote: Quote): EditQuoteInput => {
  const items = quote.items?.map((value) => {
    return {
      item: { id: value.item.id, name: value.item.name },
      location: value.location?.id || '',
      quantity: value.quantity,
      adjCost: value.adjCost,
      total: value.total,
      unitCost: value.unitCost,
      margin: value.margin,
      inventory: value.inventory,
      totalOnHand: value.totalOnHand,
      description: value.description,
      deviationCode: value.deviationCode,
      itemNumber: value.itemNumber,
      leadTime: value.leadTime,
      line: value.line,
      vendorPORate: value.vendorPORate,
      vendorQuoteReference: value.vendorQuoteReference,
      vendor: value.vendor,
      i2pCalculationId: value.i2pCalculationId,
      isTaxable: value.isTaxable,
      costEstimateType: value.costEstimateType,
      estimatedTotalCost: value.estimatedTotalCost,
      type: value.type,
    };
  });

  return {
    client: quote.client ? { id: quote.client.id, name: quote.client.name } : null,
    contact: quote.contact ? { id: quote.contact.id, name: quote.contact.name } : null,
    startDate: quote.startDate,
    dueDate: quote.dueDate,
    expectedClose: quote.expectedClose,
    freightTerms: quote.freightTerms ? { id: `${quote.freightTerms.id}`, name: quote.freightTerms.name } : null,
    shipVia: quote.shipVia ? { id: `${quote.shipVia.id}`, name: quote.shipVia.name } : null,
    id: quote.id,
    items,
    market: quote.market ? { id: quote.market.id, name: quote.market.name } : null,
    memo: quote.memo,
    visibleToCustomer: quote.visibleToCustomer === true,
    projectName: quote.projectName,
    quoteNumber: quote.quoteNumber,
    quoteType: quote.quoteType ? { id: quote.quoteType.id, name: quote.quoteType.name } : null,
    salesCoordinator:
      quote.salesCoordinator && quote.salesCoordinator.id !== NOT_A_REAL_EMPLOYEE
        ? { id: quote.salesCoordinator.id, name: quote.salesCoordinator.name }
        : null,
    salesTeam:
      quote.salesRep && quote.salesRep.id !== NOT_A_REAL_EMPLOYEE
        ? [{ employeeId: quote.salesRep.id, salesRole: SALES_ROLE, contribution: 100 }]
        : [],
    status: quote.status ? { id: quote.status.id, name: quote.status.name } : null,
    taxIncluded: quote.taxIncluded,
    terms: quote.terms ? { id: quote.terms.id, name: quote.terms.name } : null,
    shippingCost: quote.shippingCost,
    shippingAddressId: quote.shippingAddressList?.id,
    followUpDate: quote.followUpDate,
  } as EditQuoteInput;
};

export const formatNewQuoteInput = (quote: Quote): CreateQuoteInput => {
  const formattedClient = {
    id: quote.client?.id || '',
    name: quote?.client?.name || '',
  };

  const formattedContact = {
    id: quote.contact?.id || '',
    name: quote.contact?.name || '',
  };

  const formattedFreightTerms = {
    id: `${quote.freightTerms?.id}` || '',
    name: quote.freightTerms?.name || '',
  };

  const formattedShipVia = quote.shipVia
    ? {
        id: `${quote.shipVia?.id}` || '',
        name: quote.shipVia?.name || '',
      }
    : null;

  return {
    dueDate: quote.dueDate,
    expectedClose: quote.expectedClose,
    startDate: quote.startDate,
    quoteNumber: quote.quoteNumber,
    projectName: quote.projectName,
    client: formattedClient || { id: '', name: '' },
    contact: formattedContact || { id: '', name: '' },
    salesTeam:
      quote.salesRep && quote.salesRep.id !== NOT_A_REAL_EMPLOYEE
        ? [{ employeeId: quote.salesRep.id, salesRole: SALES_ROLE, contribution: 100 }]
        : [],
    salesCoordinator:
      quote.salesCoordinator && quote.salesCoordinator.id !== NOT_A_REAL_EMPLOYEE
        ? { id: quote.salesCoordinator?.id ?? '', name: quote.salesCoordinator?.name ?? '' }
        : { id: '', name: '' },
    freightTerms: formattedFreightTerms || { id: '', name: '' },
    shipVia: formattedShipVia || null,
    market: { id: quote.market?.id ?? '', name: quote.market?.name ?? '' },
    quoteType: { id: quote.quoteType?.id ?? '', name: quote.quoteType?.name ?? '' },
    status: { id: quote.status?.id ?? '', name: quote.status?.name ?? '' },
    terms: { id: quote.terms?.id ?? '', name: quote.terms?.name ?? '' },
    taxIncluded: quote.taxIncluded,
    memo: quote.memo,
    visibleToCustomer: quote.visibleToCustomer === true,
    shippingCost: quote.shippingCost,
    shippingAddressId: quote.shippingAddressList?.id,
    followUpDate: quote.followUpDate,
    items: quote.items?.length
      ? quote.items.map((item) => ({
          item: { id: item.item.id, name: item.item.name },
          location: item.location?.id || '',
          description: item.description,
          deviationCode: item.deviationCode,
          quantity: item.quantity,
          adjCost: item.adjCost,
          total: item.total,
          unitCost: item.unitCost,
          margin: item.margin,
          inventory: item.inventory?.map((inv) => ({ id: inv.id, quantity: inv.quantity, location: inv.location })),
          totalOnHand: item.totalOnHand,
          vendorPORate: item.vendorPORate,
          vendorQuoteReference: item.vendorQuoteReference,
          vendor: item.vendor,
          i2pCalculationId: item.i2pCalculationId,
          isTaxable: item.isTaxable,
          costEstimateType: item.costEstimateType,
          estimatedTotalCost: item.estimatedTotalCost,
          leadTime: item.leadTime,
          type: item.type,
        }))
      : [],
  };
};
