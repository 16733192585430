// noinspection TypeScriptUnresolvedVariable
import React, { createContext, useContext, useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryGetClient } from 'hooks/queries/useQueryGetClient/useQueryGetClient';
import { computeDerivedMetrics, formatExistingQuoteInput, formatNewQuoteInput } from 'utils/Quote/QuoteUtils';
import { CreateQuoteInput, EditContactInput, EditQuoteInput, GetClientQuery, Quote } from '__generated__/graphql';
import { QuoteFormData } from './QuoteFormData';
import * as yup from 'yup';
import useMutationUpdateQuote from 'hooks/mutations/useMutationUpdateQuote/useMutationUpdateQuote';
import { useQueryGetQuoteByNumber } from 'hooks/queries/useQueryGetQuoteByNumber/useQueryGetQuoteByNumber';
import useMutationCreateQuote from 'hooks/mutations/useMutationCreateQuote/useMutationCreateQuote';
import useMutationDuplicateQuote from 'hooks/mutations/useMutationDuplicateQuote/useMutationDuplicateQuote';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import useItems, { ItemTableItem, UseItems } from './useItems';
import { validateRequiredFields } from 'utils/projectDetailsValidation';
import { GetCommentsByQuoteIdQuery } from '__generated__/graphql';
import useMutationCreateComments from 'hooks/mutations/useMutationCreateComments/useMutationCreateComments';
import { createNewQuoteNumber } from 'utils/functions';
import useMutationEditContact from 'hooks/mutations/useMutationEditContact/useMutationEditContact';
import useMutationSaveDraft from 'hooks/mutations/useMutationSaveDraft/useMutationSaveDraft';
import { LAST_DRAFT_ID_KEY } from 'utils/constants';
import { useLazyQueryGetDraft } from 'hooks/queries/useLazyQueryGetDraft/useLazyQueryGetDraft';
import useMutationDeleteDraft from 'hooks/mutations/useMutationDeleteDraft/useMutationDeleteDraft';

export type QuoteMetrics = {
  subtotal: number;
  totalMargin: number;
  grossProfit: number;
  grossProfitPercent: number;
  total: number;
  tax: number;
  shippingCost: number;
};

type ModifiedFields = {
  areProjectDetailsModified: boolean;
  areItemsModified: boolean;
  areCommentsCreated: boolean;
};

export type QuoteStatus = 'loading' | 'error' | 'ok';

type CommentToBeCreated = Omit<
  GetCommentsByQuoteIdQuery['getCommentsByQuoteId'][0],
  '__typename' | 'transactionId' | 'user'
> & {
  user: Omit<GetCommentsByQuoteIdQuery['getCommentsByQuoteId'][0]['user'], '__typename'>;
};

type QuoteContextState = UseItems & {
  status: QuoteStatus;
  errorMessage: string | null;
  quote: Quote | undefined;
  setQuote: React.Dispatch<React.SetStateAction<Quote | undefined>>;
  quoteInput: Partial<CreateQuoteInput | EditQuoteInput>;
  setQuoteInput: React.Dispatch<React.SetStateAction<Partial<CreateQuoteInput | EditQuoteInput>>>;
  quoteMetrics: QuoteMetrics;
  projectDetailsValidated: boolean;
  fieldsValidated: boolean;
  setFieldsValidated: React.Dispatch<React.SetStateAction<boolean>>;
  formData: QuoteFormData;
  setFormData: React.Dispatch<React.SetStateAction<QuoteFormData>>;
  clientData?: GetClientQuery['getClient'];
  clientDataLoading?: boolean;
  clientDataRefetch: () => Promise<ApolloQueryResult<GetClientQuery>>;
  showSnackbar: boolean;
  setShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  snackbarMessage: string | null;
  setSnackbarMessage: (value: string) => void;
  submitQuote: (quoteOverride?: Quote) => void;
  submitQuoteLoading: boolean;
  duplicateQuote: () => void;
  modifiedFields: ModifiedFields;
  setModifiedFields: React.Dispatch<React.SetStateAction<ModifiedFields>>;
  purchaseHistoryItem: ItemTableItem | null;
  setPurchaseHistoryItem: React.Dispatch<React.SetStateAction<ItemTableItem | null>>;
  commentsToBeCreated: CommentToBeCreated[];
  setCommentsToBeCreated: React.Dispatch<React.SetStateAction<CommentToBeCreated[]>>;
  isQuoteCreated: boolean;
  setIsQuoteCreated: React.Dispatch<React.SetStateAction<boolean>>;
  editContactInput?: EditContactInput;
  setEditContactInput: React.Dispatch<React.SetStateAction<EditContactInput | undefined>>;
  validationError?: Error;
  itemsValidationError?: Error;
  quoteFlowType: 'new' | 'edit';
  hasItemGroupNotification: boolean;
  setHasItemGroupNotification: React.Dispatch<React.SetStateAction<boolean>>;
};

const QuoteContext = createContext<QuoteContextState | undefined>(undefined);

type QuoteProviderProps = {
  children: React.ReactNode;
};

const QuoteContextProvider: React.FC<QuoteProviderProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const { quoteNumber, draftId: draftIdParam } = useParams();
  const [quote, setQuote] = useState<Quote>();
  const [quoteInput, setQuoteInput] = useState<Partial<CreateQuoteInput | EditQuoteInput>>({});
  const [editContactInput, setEditContactInput] = useState<EditContactInput>();

  const [quoteMetrics, setQuoteMetrics] = useState<QuoteMetrics>({
    total: 0,
    totalMargin: 0,
    grossProfit: 0,
    grossProfitPercent: 0,
    subtotal: 0,
    tax: 0,
    shippingCost: 0,
  });

  const quoteSchema = yup.object({
    contact: yup.object({ id: yup.string().required('Missing required field: Contact') }),
    client: yup.object({ id: yup.string().required('Missing required field: Client') }),
    quoteNumber: yup.string().required('Missing required field: Quote Number'),
    status: yup.object({ id: yup.string().required('Missing required field: Status') }),
    quoteType: yup.object({ id: yup.string().required('Missing required field: Quote Type') }),
    salesCoordinator: yup.object({ id: yup.string().required('Missing required field: Sales Coordinator') }),
    salesTeam: yup.array(
      yup.object({
        employeeId: yup.string().required('Missing required field: Sales Representative'),
        salesRole: yup.number().required('Missing required field: Sales Representative'),
        contribution: yup.number().required('Missing required field: Sales Representative'),
      })
    ),
    startDate: yup.date().required('Missing required field: Start Date'),
    expectedClose: yup.date().required('Missing required field: Expected Close'),
    dueDate: yup.date().required('Missing required field: Due Date'),
    terms: yup.object({ id: yup.string().required('Missing required field: Quote Terms') }),
    market: yup.object({ id: yup.string().required('Missing required field: Market') }),
    // For some reason, the default Freight Terms is the string value 'undefined'
    freightTerms: yup.object({
      id: yup.string().matches(/^(?!undefined)/, 'Missing required field: Freight Terms'),
    }),
    shippingAddressId: yup.string().required('Missing required field: Shipping Address'),
    items: yup.array().min(1, 'At least one line item is required to create a quote'),
  });

  const quoteItemSchema = yup.array(
    yup.object({
      isNonI2pPrice: yup.boolean().nullable(),
      deviationCode: yup
        .string()
        .nullable()
        .when('isNonI2pPrice', {
          is: (isNonI2pPrice: boolean | undefined) => {
            return isNonI2pPrice;
          },
          then: (schema) => schema.required('Items without an I2P pricing selection require a deviation code.'),
        }),
    })
  );

  const [fieldsValidated, setFieldsValidated] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<Error | undefined>(undefined);
  const [itemsValidationError, setItemsValidationError] = useState<Error | undefined>(undefined);
  const [formData, setFormData] = useState<QuoteFormData>(new QuoteFormData());
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [commentsToBeCreated, setCommentsToBeCreated] = useState<CommentToBeCreated[]>([]);
  const [purchaseHistoryItem, setPurchaseHistoryItem] = useState<ItemTableItem | null>(null);
  const [isQuoteCreated, setIsQuoteCreated] = useState(false);
  const [newQuoteNumber, setNewQuoteNumber] = useState<string | null>(null);
  const [draftId, setDraftId] = useState<string | null>(null);
  const [modifiedFields, setModifiedFields] = useState({
    areProjectDetailsModified: false,
    areItemsModified: false,
    areCommentsCreated: false,
  });
  const [hasItemGroupNotification, setHasItemGroupNotification] = useState(false);

  const autoResumeDraftId = useMemo(() => {
    const lastDraft = window.localStorage.getItem(LAST_DRAFT_ID_KEY);
    if (draftIdParam && !quoteNumber) {
      return draftIdParam;
    }
    if (lastDraft && !draftId && !quoteNumber) {
      return lastDraft;
    }
    return null;
  }, [draftId, draftIdParam, quoteNumber]);

  useEffect(() => {
    setModifiedFields((prev) => ({ ...prev, areCommentsCreated: commentsToBeCreated.length > 0 }));
  }, [commentsToBeCreated]);

  useEffect(() => {
    const validateFields = async () => {
      try {
        if (quote) {
          if (quote.items) {
            try {
              await quoteItemSchema.validate(quote.items);
              setItemsValidationError(undefined);
            } catch (e) {
              setItemsValidationError(e as Error);
            }
          }

          if (quoteNumber) {
            await quoteSchema.validate(formatExistingQuoteInput(quote));
            setValidationError(undefined);
          } else {
            await quoteSchema.validate(formatNewQuoteInput(quote));
            setValidationError(undefined);
          }
        }
      } catch (e) {
        setValidationError(e as Error);
      }
    };
    validateFields();
    //The exhaustive deps requirement here triggers an infinite loop with this useEffect when quoteSchema is included, so we must disable the warning
    //eslint-disable-next-line
  }, [quote]);

  const { clientNameAndId } = useParams();
  const clientId = clientNameAndId?.split('-').pop() ?? '';

  const {
    data: clientData,
    loading: clientDataLoading,
    refetch: clientDataRefetch,
  } = useQueryGetClient({ id: clientId }, { skip: !clientId });

  const itemsContext = useItems(setQuote, quote, clientData);

  const {
    data: quoteData,
    loading: quoteDataLoading,
    error: quoteDataError,
    refetch: quoteDataRefetch,
  } = useQueryGetQuoteByNumber(
    { number: quoteNumber ?? '' },
    { skip: !quoteNumber || clientDataLoading || !!autoResumeDraftId }
  );

  const { status, errorMessage } = useMemo((): { status: QuoteStatus; errorMessage: string | null } => {
    if (quoteData) {
      return { status: 'ok', errorMessage: null };
    } else if (quoteDataLoading || clientDataLoading) {
      return { status: 'loading', errorMessage: null };
    } else if (!quoteNumber) {
      return { status: 'ok', errorMessage: null };
    } else {
      return { status: 'error', errorMessage: quoteDataError?.message ?? null };
    }
  }, [clientDataLoading, quoteData, quoteDataError?.message, quoteDataLoading, quoteNumber]);

  // Redirect user to correct URL after quote creation or update
  useEffect(() => {
    if (
      isQuoteCreated &&
      newQuoteNumber &&
      !modifiedFields.areItemsModified &&
      !modifiedFields.areProjectDetailsModified
    ) {
      navigate(`/quote/${clientNameAndId}/${newQuoteNumber}`, { replace: !!quoteNumber });
      setNewQuoteNumber(null);
      setIsQuoteCreated(false);
      apolloClient.resetStore();
    }
  }, [
    isQuoteCreated,
    newQuoteNumber,
    modifiedFields,
    quoteNumber,
    navigate,
    setNewQuoteNumber,
    setIsQuoteCreated,
    clientNameAndId,
    apolloClient,
  ]);

  useEffect(() => {
    if (!clientData && !clientDataLoading && clientId) {
      setSnackbarMessage(`Failed to fetch the client with id ${clientId}. Some fields will not be available.`);
      setShowSnackbar(true);
    }
  }, [clientId, clientData, clientDataLoading]);

  useEffect(() => {
    if (quote) return;
    let newQuote: Quote | undefined;

    if (quoteNumber) {
      newQuote = quoteData?.getQuoteByNumber as Quote;
    } else if (clientData) {
      const defaultShipping = clientData?.getClient?.addressbooklist?.addressbook.find((x) => x.defaultShipping);

      const shippingAddressList = {
        name: defaultShipping?.label ?? '',
        id: defaultShipping?.internalId ?? '',
      };

      newQuote = {
        id: '',
        quoteNumber: `${
          clientData.getClient.clientPrefix ? `${clientData.getClient.clientPrefix}-` : ''
        }${createNewQuoteNumber()}`,
        client: {
          id: clientData.getClient.id ?? '',
          name: clientData.getClient.name,
        },
        status: {
          id: 'A',
          name: 'Open',
        },
        shippingAddressList,
      };
    }

    if (newQuote !== undefined) {
      setQuote(newQuote);
    }
  }, [quote, location, quoteNumber, quoteData, clientData]);

  const [editQuote, { loading: editLoading }] = useMutationUpdateQuote();
  const [createQuote, { loading: createLoading }] = useMutationCreateQuote();
  const [duplicateQuoteMutation] = useMutationDuplicateQuote();
  const [createComments] = useMutationCreateComments();
  const [editContact] = useMutationEditContact();
  const [saveDraft] = useMutationSaveDraft();
  const [getDraft] = useLazyQueryGetDraft();
  const [deleteDraft] = useMutationDeleteDraft();

  const duplicateQuote = useCallback(async () => {
    if (!quote) return;

    try {
      setShowSnackbar(true);

      setSnackbarMessage(`Quote ${quote?.quoteNumber} is being duplicated.`);

      const duplicationResponse = await duplicateQuoteMutation({ variables: { id: quote.id } });

      const duplicateQuoteNumber = duplicationResponse.data?.duplicateQuote.quoteNumber;

      if (duplicateQuoteNumber) {
        // We must fetch the quote from getQuoteByNumber query because it maps necessary data to the quote
        const refetchResponse = await quoteDataRefetch({ number: duplicateQuoteNumber });

        navigate(window.location.pathname.replace(quote.quoteNumber, duplicateQuoteNumber));

        setQuote(refetchResponse.data.getQuoteByNumber as Quote);

        setNewQuoteNumber(duplicateQuoteNumber);

        setModifiedFields({
          areProjectDetailsModified: false,
          areItemsModified: false,
          areCommentsCreated: false,
        });

        setPurchaseHistoryItem(null);

        setSnackbarMessage(`Quote ${quote?.quoteNumber} has duplicated successfully.`);

        setShowSnackbar(true);

        apolloClient.resetStore();
      } else {
        setSnackbarMessage(`Quote ${quote?.quoteNumber} failed to be duplicated.`);
      }
    } catch (ex) {
      console.warn('Duplicate quote failed:', ex);
    }
  }, [navigate, quote, duplicateQuoteMutation, quoteDataRefetch, apolloClient]);

  const submitQuote = useCallback(
    async (quoteOverride?: Quote) => {
      const quoteToSubmit = quoteOverride || quote;

      if (!quoteToSubmit) {
        setShowSnackbar(true);
        setSnackbarMessage('Quote is missing required fields.');
        return;
      }

      const updateQuoteContacts = async () => {
        if (editContactInput && clientData?.getClient?.contacts) {
          const existingContact = clientData?.getClient?.contacts.find((contact) => contact.id === editContactInput.id);
          if (
            existingContact &&
            (existingContact.email !== editContactInput.email || existingContact.phone !== editContactInput.phone)
          ) {
            await editContact({ variables: { input: editContactInput } });
          }
        }
      };

      if (quoteNumber || quoteOverride) {
        try {
          const editQuoteInput = formatExistingQuoteInput(quoteToSubmit);
          Object.assign(editQuoteInput, quoteInput);

          await quoteSchema.validate(editQuoteInput);

          setShowSnackbar(true);
          setSnackbarMessage(`Quote ${quoteToSubmit?.quoteNumber} is updating.`);

          // await editQuote({ variables: (quote: {id: EditQuoteInput.id, quoteNumber: EditQuoteInput.quoteNumber, status: })})

          await updateQuoteContacts();
          await editQuote({ variables: { quote: editQuoteInput } }).then((response) => {
            const responseData = response?.data?.updateQuote;

            if (responseData?.success) {
              setModifiedFields({
                areProjectDetailsModified: false,
                areItemsModified: false,
                areCommentsCreated: false,
              });
              setShowSnackbar(true);
              setSnackbarMessage(`Quote ${quote?.quoteNumber} has updated successfully.`);
              setNewQuoteNumber(editQuoteInput.quoteNumber);
              quoteDataRefetch();
              setPurchaseHistoryItem(null);
              setIsQuoteCreated(true);
              if (draftId) {
                deleteDraft({ variables: { draftId } });
                setDraftId(null);
              }
              window.localStorage.setItem(LAST_DRAFT_ID_KEY, '');
              setTimeout(() => {
                navigate('/quotes');
              }, 1000);
            } else {
              setSnackbarMessage(`Failed to update quote: ${responseData?.error}`);
              setShowSnackbar(true);
            }
          });
        } catch (ex) {
          setSnackbarMessage(`Failed to update quote: ${ex}`);
          setShowSnackbar(true);
        }
      } else {
        try {
          const createQuoteInput = formatNewQuoteInput(quoteToSubmit);
          Object.assign(createQuoteInput, quoteInput);

          await quoteSchema.validate(createQuoteInput);

          await updateQuoteContacts();
          const newQuote = await createQuote({
            variables: { input: createQuoteInput },
          });
          if (newQuote.data) {
            if (commentsToBeCreated.length) {
              createComments({
                variables: {
                  createCommentsInput: {
                    transactionId: newQuote.data.createQuote.id,
                    commentBodies: commentsToBeCreated.map((comment) => comment.commentBody),
                  },
                },
              }).catch((err) => console.warn(err));
            }
            // We must fetch the quote from getQuoteByNumber query because it maps necessary data to the quote
            const { data } = await quoteDataRefetch({ number: newQuote.data.createQuote.quoteNumber });
            setIsQuoteCreated(true);
            setNewQuoteNumber(data.getQuoteByNumber.quoteNumber);
            setQuote(data.getQuoteByNumber as Quote);
            setModifiedFields({ areProjectDetailsModified: false, areItemsModified: false, areCommentsCreated: false });
            setPurchaseHistoryItem(null);
            setSnackbarMessage('Successfully created quote');
            setShowSnackbar(true);
            if (draftId) {
              deleteDraft({ variables: { draftId } });
              setDraftId(null);
            }
            window.localStorage.setItem(LAST_DRAFT_ID_KEY, '');
            setTimeout(() => {
              navigate('/quotes');
            }, 1000);
          }
        } catch (ex) {
          console.warn('creating quote failed:', ex);
          setSnackbarMessage(`Failed to create quote: ${(ex as string).toString().replace('ApolloError:', '')}`);
          setShowSnackbar(true);
        }
      }
    },
    [
      quote,
      quoteNumber,
      editContactInput,
      clientData?.getClient?.contacts,
      editContact,
      quoteInput,
      quoteSchema,
      editQuote,
      quoteDataRefetch,
      draftId,
      deleteDraft,
      navigate,
      createQuote,
      commentsToBeCreated,
      createComments,
    ]
  );

  useEffect(() => {
    if (quote) {
      setQuoteMetrics(computeDerivedMetrics(quote));
    }
  }, [quote, itemsContext.itemsTableItems]);

  const quoteFlowType = useMemo(() => {
    return quoteNumber ? 'edit' : 'new';
  }, [quoteNumber]);

  const saveDraftQuote = useCallback(async () => {
    if (quote && draftId) {
      const result = await saveDraft({
        variables: {
          input: {
            draftId: draftId,
            userId: 0,
            quote: formatNewQuoteInput({
              ...quote,
              items: itemsContext.getAsQuoteItems(true),
            }),
          },
        },
      });
      if (result.data?.saveDraft?.success) {
        setSnackbarMessage('Draft saved successfully');
        setShowSnackbar(true);
        window.localStorage.setItem(LAST_DRAFT_ID_KEY, draftId);
      }
    }
  }, [quote, draftId, saveDraft, itemsContext]);

  const resumeDraft = useCallback(
    async (draftId: string) => {
      const { data, error } = await getDraft({ variables: { draftId } });
      if (data?.getDraft?.quote) {
        setQuote({ ...(data.getDraft.quote as Quote), id: data.getDraft.quote.id || draftId });
        setDraftId(draftId);
        setNewQuoteNumber(null);
        setIsQuoteCreated(false);
        setSnackbarMessage('Draft resumed successfully');
        setShowSnackbar(true);
      } else {
        console.warn('failed to resume draft', error);
      }
    },
    [getDraft]
  );

  useEffect(() => {
    if (!clientDataLoading && clientData) {
      if (autoResumeDraftId && !quote) {
        resumeDraft(autoResumeDraftId);
        setDraftId(autoResumeDraftId);
      } else if ('new' === quoteFlowType && !draftId) {
        setDraftId(`${Math.random() * 10000}`);
      }
    }
  }, [draftId, quoteFlowType, autoResumeDraftId, resumeDraft, clientDataLoading, clientData, quote]);

  const saveDraftQuoteRef = useRef(saveDraftQuote);

  const hasUnsavedChanges = useRef(false);

  const saveDraftQuoteRefCaller = useRef(() => {
    // Only save draft if user has made changes
    if (hasUnsavedChanges.current) {
      saveDraftQuoteRef.current();
      hasUnsavedChanges.current = false;
    }
  });

  useEffect(() => {
    hasUnsavedChanges.current = true;
  }, [quote]);

  useEffect(() => {
    saveDraftQuoteRef.current = saveDraftQuote;
  }, [saveDraftQuote]);

  useEffect(() => {
    const interval = setInterval(saveDraftQuoteRefCaller.current, 30000);

    return () => clearInterval(interval);
  }, []);

  const quotesContextValue: QuoteContextState = useMemo(() => {
    return {
      status,
      errorMessage,
      quote,
      setQuote,
      quoteInput,
      setQuoteInput,
      quoteMetrics,
      projectDetailsValidated: validateRequiredFields(quote),
      fieldsValidated,
      setFieldsValidated,
      formData,
      setFormData,
      clientData: clientData?.getClient,
      clientDataLoading,
      clientDataRefetch,
      showSnackbar,
      setShowSnackbar,
      snackbarMessage,
      setSnackbarMessage,
      submitQuote,
      submitQuoteLoading: createLoading || editLoading,
      duplicateQuote,
      modifiedFields,
      setModifiedFields,
      purchaseHistoryItem,
      setPurchaseHistoryItem,
      commentsToBeCreated,
      setCommentsToBeCreated,
      isQuoteCreated,
      setIsQuoteCreated,
      editContactInput,
      setEditContactInput,
      ...itemsContext,
      validationError,
      itemsValidationError,
      quoteFlowType,
      hasItemGroupNotification,
      setHasItemGroupNotification,
    };
  }, [
    status,
    errorMessage,
    quote,
    quoteInput,
    setQuoteInput,
    quoteMetrics,
    fieldsValidated,
    formData,
    clientData?.getClient,
    clientDataLoading,
    clientDataRefetch,
    showSnackbar,
    snackbarMessage,
    submitQuote,
    createLoading,
    editLoading,
    duplicateQuote,
    modifiedFields,
    setModifiedFields,
    purchaseHistoryItem,
    setPurchaseHistoryItem,
    commentsToBeCreated,
    setCommentsToBeCreated,
    isQuoteCreated,
    setIsQuoteCreated,
    editContactInput,
    setEditContactInput,
    itemsContext,
    validationError,
    itemsValidationError,
    quoteFlowType,
    hasItemGroupNotification,
    setHasItemGroupNotification,
  ]);

  return <QuoteContext.Provider value={quotesContextValue}>{children}</QuoteContext.Provider>;
};

const useQuoteContext = () => {
  const context = useContext(QuoteContext);

  if (context === undefined) {
    throw new Error('useQuoteContext was used outside of its Provider');
  }

  return context;
};

export { QuoteContext, QuoteContextProvider, useQuoteContext };
