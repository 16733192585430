import { Formik, FormikProps, FormikValues } from 'formik';
import { FormikInitialValues, createInitialValues } from 'utils/formik';
import Drawer, { DrawerHeader, DrawerButtons, DrawerConfirmButton, DrawerCloseButton } from 'components/Drawer/Drawer';
import PageHeader from 'components/PageHeader/PageHeader';
import { useEffect, useMemo, useState } from 'react';
import FilterButton from 'components/FilterButton/FilterButton';

type ExtendedFormikProps<T extends FormikValues> = FormikProps<T> & {
  requiredFields: string[];
};

type FormikDrawerProps<T extends FormikValues> = {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues?: FormikInitialValues<T>;
  defaultValues?: FormikInitialValues<T>;
  formFields: Record<string, string>;
  requiredFields: string[];
  children: React.FC<ExtendedFormikProps<T>>;
  onSubmit: (values: T) => void;
  validate?: (values: T) => void;
};

function FilterDrawer<T extends FormikValues>({
  drawerOpen,
  setDrawerOpen,
  initialValues,
  defaultValues,
  formFields,
  requiredFields,
  children,
  onSubmit,
  validate,
}: FormikDrawerProps<T>) {
  const [filters, setFilters] = useState<T>(createInitialValues<T>(formFields, initialValues));

  const defaultFilters = useMemo(
    () => ({ ...(defaultValues ?? createInitialValues<T>(formFields, initialValues)) }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filtersApplied = useMemo(() => {
    return filters
      ? Object.entries(defaultFilters).some(([key, value]) => filters[key as keyof typeof filters] !== value)
      : false;
  }, [defaultFilters, filters]);

  return (
    <>
      <FilterButton onClick={() => setDrawerOpen(true)} isFilterApplied={filtersApplied} />
      <Drawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}>
        <DrawerHeader>
          <PageHeader variant='h2' header='Filter & Sort' />
        </DrawerHeader>
        <Formik
          initialValues={createInitialValues<T>(formFields, initialValues)}
          onSubmit={() => {}}
          validate={validate ? validate : () => {}}
        >
          {(props: FormikProps<T>) => (
            <>
              {children({ ...props, requiredFields })}
              <DrawerButtons>
                <DrawerCloseButton onClick={() => props.setValues(createInitialValues<T>(formFields, defaultValues))}>
                  Clear Filters
                </DrawerCloseButton>
                <DrawerCloseButton onClick={() => setDrawerOpen(false)}>Close</DrawerCloseButton>
                <DrawerConfirmButton
                  onClick={() => {
                    setFilters(props.values);
                    onSubmit(props.values);
                  }}
                >
                  Apply Filters
                </DrawerConfirmButton>
              </DrawerButtons>
            </>
          )}
        </Formik>
      </Drawer>
    </>
  );
}

export default FilterDrawer;
