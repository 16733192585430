import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetSalesOrderByNumberDocument,
  GetSalesOrderByNumberQuery,
  GetSalesOrderByNumberQueryVariables,
  QueryGetSalesOrderByNumberArgs,
} from '__generated__/graphql';

export const useQueryGetSalesOrderByNumber = (
  input: QueryGetSalesOrderByNumberArgs,
  options: QueryHookOptions<GetSalesOrderByNumberQuery, GetSalesOrderByNumberQueryVariables>
): QueryResult<GetSalesOrderByNumberQuery, GetSalesOrderByNumberQueryVariables> => {
  return useQuery(GetSalesOrderByNumberDocument, {
    ...options,
    variables: input,
  });
};
