import React from 'react';
import Container from '@mui/material/Container';
import { css, styled } from '@mui/material/styles';
import PageHeader from 'components/PageHeader/PageHeader';
import Flex from 'components/Flex/Flex';
import { TabContent, TabNavigation } from 'components/TabNavigation/TabNavigation';
import ActionPill from 'components/ActionPill/ActionPill';
import { Stack } from '@mui/material';
import { formatCurrency } from 'utils/functions';
import AddItems from './components/AddItems';
import SalesOrderDetails from './components/SalesOrderDetails';
import { SalesOrderContextProvider, useSalesOrderContext } from 'contexts/SalesOrderContext/SalesOrderContext';
import { SalesOrdersSubNavContextProvider } from 'contexts/SalesOrderSubNavContext/SalesOrderSubNavContext';
import { ClientContextProvider, useClientContext } from 'contexts/ClientContext/ClientContext';
import Subtotal from './Subtotal/Subtotal';
import { salesOrderStatusBackgroundColorMap } from 'utils/constants';
import { useParams, useNavigate } from 'react-router-dom';
import { ItemsTable } from './components/ItemsTable';
import { ItemsContextProvider } from 'contexts/ItemsContext/ItemsContext';
import Button from 'components/Button/Button';

const StatusWrapper = styled('div')(
  () => css`
    grid-column-gap: 1rem;
    align-items: center;
    display: flex;
    margin-top: 1rem;
    padding: 0 0 2rem;

    button {
      width: min-content;
    }
  `
);

const CompanyHeader = styled(PageHeader)(
  () => css`
    padding: 0;
  `
);

const SalesOrderTotal = styled('div')(
  () => css`
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.275rem;
  `
);

const Header = styled('div')(
  () => css`
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    margin-top: 0.5rem;
  `
);

const SaleOrderNumber = styled(PageHeader)(
  ({ theme }) => css`
    color: ${theme.colors.whiteOpacity50} !important;
    text-wrap: none;
    padding: 0;
    margin: 0;
  `
);

const SalesOrder: React.FC = () => {
  const { salesOrderInput, salesOrderMetrics } = useSalesOrderContext();
  const { client } = useClientContext();
  const { view } = useParams();
  const navigate = useNavigate();

  const handleSalesOrderEdit = () => {
    if (view === 'view') {
      const basePath = location.pathname.split('/').slice(0, -1).join('/');
      navigate(`${basePath}/edit`);
    }
  };

  return (
    <Container>
      <SalesOrdersSubNavContextProvider>
        <Stack direction='row' justifyContent='space-between'>
          <Stack spacing={2}>
            <CompanyHeader variant='h1' header={client?.name} />
            <StatusWrapper>
              <SaleOrderNumber variant='h2' header={salesOrderInput.salesOrderNumber} />
              <ActionPill
                disabled
                backgroundColor={
                  salesOrderStatusBackgroundColorMap[
                    salesOrderInput.status as keyof typeof salesOrderStatusBackgroundColorMap
                  ]
                }
                activeForegroundColor=''
              >
                {salesOrderInput.status ?? ''}
              </ActionPill>
              {view === 'view' && (
                <Button variant='outlined' onClick={handleSalesOrderEdit}>
                  Edit Sales Order
                </Button>
              )}
            </StatusWrapper>
          </Stack>
          <Stack spacing={2}>
            <Subtotal />
            <Stack alignItems={'end'} spacing={1}>
              <Header>Sales Order Total</Header>
              <SalesOrderTotal>{formatCurrency(salesOrderMetrics?.total)}</SalesOrderTotal>
            </Stack>
          </Stack>
        </Stack>
        <Flex w100>
          <TabNavigation>
            <TabContent header='Sales Order Details'>
              <SalesOrderDetails view={view} />
            </TabContent>
            <TabContent header='Add Items'>
              <AddItems />
              <ItemsTable />
            </TabContent>
          </TabNavigation>
        </Flex>
      </SalesOrdersSubNavContextProvider>
    </Container>
  );
};

const SalesOrderContextWrapper: React.FC = () => {
  return (
    <ClientContextProvider>
      <SalesOrderContextProvider>
        <ItemsContextProvider>
          <SalesOrder />
        </ItemsContextProvider>
      </SalesOrderContextProvider>
    </ClientContextProvider>
  );
};

export default SalesOrderContextWrapper;
