import { QuoteItemInventory } from '__generated__/graphql';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { ItemTableItem } from 'contexts/QuoteContext/useItems';
import { useQueryGetItemGroup } from 'hooks/queries/useQueryGetItemGroup/useQueryGetItemGroup';
import { useState } from 'react';
import { Quantity } from '../Subtotal/Subtotal';
import Button from 'components/Button/Button';

type ItemGroupQuantityInputProps = {
  item: ItemTableItem;
  index: number;
};

export function ItemGroupQuantityInput({ item, index: startOfGroupIndex }: ItemGroupQuantityInputProps) {
  const { itemsTableItems, updateItemField } = useQuoteContext();
  const { refetch: fetchItemGroup } = useQueryGetItemGroup({ groupItemId: '' }, { skip: true });
  const [value, setValue] = useState(item.quantity ?? 0);

  async function handleUpdate() {
    const { data } = await fetchItemGroup({ groupItemId: item.item.id });

    const group = data.getItemGroup;

    const groupItem = group[0];

    const memberArray = groupItem.memberList?.itemMember ?? [];

    group.forEach((item) => {
      let endOfGroupIndex = -1;

      for (let i = startOfGroupIndex; i < itemsTableItems.length; i++) {
        if (itemsTableItems[i].type === 'EndGroup') {
          endOfGroupIndex = i;
        }
      }

      // Get the group currently in the items table (items may have been added or removed from the group)
      // There may also be more than one of the same group in the table
      const groupInTable = itemsTableItems.slice(startOfGroupIndex, endOfGroupIndex + 1);

      // Index of item in group
      const groupItemIndex = groupInTable.findIndex((groupItem) => groupItem.item.id === item.id);

      // If the item is no longer in the group in the table, continue to next item in group
      if (groupItemIndex === -1) {
        return;
      }

      // Get index of the item in itemsTableItems array
      const index = startOfGroupIndex + groupItemIndex;

      if (index === -1) {
        return;
      }

      // If the current item is the ItemGroup item, only update the quantity. No inventory infomation.
      if (item.type === 'ItemGroup') {
        updateItemField(index, 'quantity', value);
        return;
      }

      // Get the quantity for the current item, from them ItemGroup item's member array
      let quantity = memberArray.find((member) => member.item?.id === item.id)?.quantity ?? 0;

      quantity = quantity * value;
      const locations = item.inventoryDetail?.binNumber;
      const inventory: QuoteItemInventory[] = [];

      // Get the first location with enough on hand,
      // if none have enough just return the first location
      // if that doesnt exist, give it no inventory information (an empty array)
      if (locations) {
        const locationWithEnoughOnHand = locations.find((location) => Number(location.onHandAvail) > quantity);

        if (locationWithEnoughOnHand?.info?.id && locationWithEnoughOnHand.info.name) {
          inventory.push({
            id: locationWithEnoughOnHand.info.id,
            location: locationWithEnoughOnHand.info.name,
            quantity,
          });
        } else if (locations[0]?.info?.id && locations[0]?.info?.name) {
          inventory.push({
            id: locations[0]?.info?.id ?? '',
            location: locations[0]?.info?.name ?? '',
            quantity,
          });
        }
      }

      updateItemField(index, 'inventory', inventory);
    });
  }

  function handleCancel() {
    setValue(item.quantity ?? 0);
  }

  return (
    <>
      <Quantity
        value={value}
        type='number'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(Number(e.target.value));
        }}
      />
      {value !== item.quantity && value !== 0 && (
        <>
          <div style={{ height: '1rem' }} />
          <Button onClick={handleUpdate}>Update</Button>
          <div style={{ height: '1rem' }} />
          <Button onClick={handleCancel}>Cancel</Button>
        </>
      )}
    </>
  );
}
