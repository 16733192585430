import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  PaginationState,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { ItemPurchaseRecord } from '__generated__/graphql';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';
import { useQueryItemPurchaseHistory } from 'hooks/queries/useQueryItemPurchaseHistory/useQueryItemPurchaseHistory';
import Flex from 'components/Flex/Flex';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { formatCurrency } from 'utils/functions';
import { ItemTableItem } from 'contexts/QuoteContext/useItems';
import Pagination from 'components/Pagination/Pagination';

const Container = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.colors.lightBlack};
    width: 100%;
    border: 0.0625rem solid ${theme.colors.darkGray};
    border-radius: 0.625rem;

    > div {
      padding: 1rem;
      border-bottom: 0.0625rem solid ${theme.colors.darkGray};

      > h2,
      > h3 {
        margin: 0 1rem 0 0;
      }
    }
  `
);

const Table = styled('table')(
  ({ theme }) => css`
    width: 100%;

    th,
    td {
      padding: 1rem;
      font-size: 0.875rem;
      border-bottom: 0.0625rem solid ${theme.colors.darkGray};
    }

    th {
      text-align: start;
    }

    td:first-of-type {
      white-space: nowrap;
    }

    td {
      font-weight: 500;
    }

    tr:last-of-type {
      td {
        border-bottom: none;
      }
    }
  `
);

type Props = {
  item: ItemTableItem | null;
};

function PurchaseHistoryTable({ item }: Props) {
  const columnHelper = createColumnHelper<ItemPurchaseRecord>();
  const { clientData } = useQuoteContext();
  const [pagination, setPagination] = useState<PaginationState>({ pageSize: 10, pageIndex: 0 });
  const [pages, setPages] = useState<number>(0);

  const handlePageChange = useCallback(
    (newPage: number) => setPagination((cur) => ({ ...cur, pageIndex: newPage })),
    []
  );

  const { data, loading } = useQueryItemPurchaseHistory(
    {
      getItemPurchaseHistoryInput: {
        itemId: item?.item.id ?? '',
        clientId: clientData?.id ?? '',
        page: 0,
        sortBy: 'date',
        sortDirection: 'DESC',
      },
    },
    {
      skip: item === null,
    }
  );

  const purchaseRecords: ItemPurchaseRecord[] = data?.itemPurchaseHistory?.purchaseRecords as ItemPurchaseRecord[];

  useEffect(() => {
    const dataPages = purchaseRecords ? Math.ceil(purchaseRecords.length / pagination.pageSize) : 0;
    if (!loading && dataPages !== pages) {
      setPages(dataPages);
    }
  }, [loading, purchaseRecords, pagination.pageSize, pages]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.date, {
        id: 'Date',
        cell: (info) => dayjs(info.getValue()).format('YYYY-MM-DD'),
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.transactionType, {
        id: 'Type',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.transactionNumber, {
        id: 'Document Number',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.clientName, {
        id: 'Name',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.item.quantity, {
        id: 'Quantity',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.item.adjCost, {
        id: 'Sale Price',
        cell: (info) => formatCurrency(info.getValue() ?? 0),
      }),
      columnHelper.accessor((itemPurchaseRecord) => itemPurchaseRecord.item.total, {
        id: 'Amount',
        cell: (info) => formatCurrency(info.getValue() ?? 0),
      }),
    ],
    [columnHelper]
  );

  const table = useReactTable({
    columns,
    data: purchaseRecords || [],
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: { pagination },
  });

  if (!item || !data) {
    return (
      <Flex center>
        <LoadingSpinner loading={loading} />
      </Flex>
    );
  }

  return (
    <Container>
      <div>
        <h2>Item History</h2>
        <h3>{item?.item.name}</h3>
      </div>

      {purchaseRecords?.length > 0 ? (
        <>
          <Table cellSpacing={0}>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            currentPage={pagination.pageIndex}
            pages={pages}
            onPageChange={handlePageChange}
            perPage={pagination.pageSize}
            onPerPageChange={(pageSize) => {
              setPagination({ pageIndex: 0, pageSize });
            }}
          />
        </>
      ) : (
        <Flex center>{clientData?.name} has not purchased this item before</Flex>
      )}
    </Container>
  );
}

export default PurchaseHistoryTable;
