import React from 'react';
import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { opacity } from 'utils/functions';

const BlurBox = styled(Box)(
  ({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: ${opacity(theme.palette.primary.main, 0.5, 'hex')};
    -webkit-backdrop-filter: blur(0.25rem);
    backdrop-filter: blur(0.25rem);
    z-index: 1000000;

    > div {
      box-shadow: 0.0625rem 0.0625rem 0.3125rem 0 rgba(20, 20, 20, 0.5);
    }
  `
);

export default BlurBox;
