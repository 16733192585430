import React from 'react';
import styled, { css } from 'styled-components';
import { Checkbox } from '@mui/material';

const StyledCheckbox = styled(Checkbox)(
  ({ theme }) => css`
    ${theme.unstable_sx({
      marginLeft: '0.6rem',
      width: 24,
      height: 24,
      '&.MuiSvgIcon-root': { fontSize: 12 },
      '&:hover': { borderColor: theme.colors.white },
      '&.Mui-checked': {
        color: theme.colors.lightBlue,
      },
    })}
  `
);

export default StyledCheckbox;
