import { useQuerySearchItems } from 'hooks/queries/useQuerySearchItems/useQuerySearchItems';
import { useCallback, useState } from 'react';
import { ItemsSearchFilters } from './ItemsContext';

type PaginationState = {
  page: number;
  perPage: number;
};

type ItemSearchProps = {
  filters: ItemsSearchFilters;
};

export const useItemsSearch = ({ filters }: ItemSearchProps) => {
  const [pagination, setPagination] = useState<PaginationState>({ page: 0, perPage: 20 });

  const { data, loading, fetchMore } = useQuerySearchItems({
    input: {
      ...filters,
      page: 0,
      perPage: 20,
    },
  });

  const fetchNextPage = useCallback(() => {
    const nextPaginationOptions = {
      page: pagination.page + 1,
      perPage: pagination.perPage,
    };

    setPagination(nextPaginationOptions);

    fetchMore({
      variables: {
        input: {
          ...filters,
          ...nextPaginationOptions,
        },
      },
      // Update the query cache with additional items
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          searchItems: {
            ...fetchMoreResult.searchItems,
            __typename: 'ItemSearchOutput',
            items: [...prev.searchItems.items, ...fetchMoreResult.searchItems.items],
          },
        };
      },
    }).catch((err) => {
      console.warn(err.message);
    });
  }, [filters, pagination, fetchMore]);

  return {
    data,
    loading,
    fetchNextPage,
  };
};
