import React from 'react';
import { css, styled } from '@mui/material/styles';
import SearchResult from 'components/SearchResult/SearchResult';
import { Client } from 'utils/types';
import { Customer } from '__generated__/graphql';

const Div = styled('div')(
  () => css`
    width: 100%;
  `
);

const NoResults = styled('div')(
  () => css`
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

type Props = {
  hide?: boolean;
  clientList: Client[];
  onSelect: (client: Client) => void;
  selectedClient: Customer | null;
};

const CreateQuote: React.FC<Props> = ({ hide, clientList, onSelect, selectedClient }) => {
  if (hide) return null;

  return (
    <Div>
      {clientList.map((client, i) => (
        //Index keys should be ok here since content is not being reordered post render
        <SearchResult
          $selected={client.name === selectedClient?.name}
          onClick={() => onSelect(client)}
          key={`${client}-${i}`}
        >
          <div>{client.name}</div>
        </SearchResult>
      ))}
      {clientList.length === 0 && <NoResults>No Results</NoResults>}
    </Div>
  );
};

export default CreateQuote;
