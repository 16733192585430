import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  ItemPurchaseHistoryDocument,
  ItemPurchaseHistoryQuery,
  ItemPurchaseHistoryQueryVariables,
  QueryItemPurchaseHistoryArgs,
} from '__generated__/graphql';

export const useQueryItemPurchaseHistory = (
  input: QueryItemPurchaseHistoryArgs,
  options?: QueryHookOptions<ItemPurchaseHistoryQuery, ItemPurchaseHistoryQueryVariables>
): QueryResult<ItemPurchaseHistoryQuery, ItemPurchaseHistoryQueryVariables> => {
  return useQuery(ItemPurchaseHistoryDocument, {
    ...options,
    variables: input,
  });
};
