import { css, styled } from '@mui/material/styles';
import Input from '@mui/material/Input';
import { TextField } from '@mui/material';
import { InputVariant } from 'utils/formik';

export const FormInput = styled(Input)(
  ({ theme, $variant }) => css`
    width: 100%;
    color: ${theme.palette.text.primary};
    min-height: 3rem;

    background-color: ${$variant === InputVariant.Drawer ? theme.palette.input.main : theme.palette.primary.main};
    border: 0.0625rem solid ${$variant === InputVariant.Drawer ? theme.palette.input.main : theme.palette.primary.main};

    border-radius: 0.5rem;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;
    resize: none;

    &:focus {
      outline: none;
    }

    &:focus-within {
      border: 0.0625rem solid ${theme.palette.text.primary};
    }

    input {
      color: ${theme.palette.text.primary};
      &::placeholder {
        opacity: 1;
      }
      font-size: 0.875rem;
      line-height: 1.42857;
      display: block;
    }
  `
);

export const ModalFormInput = styled(Input)(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.input.main};
    border: 0.0625rem solid ${theme.palette.input.main};

    border-radius: 0.5rem;
    margin-bottom: 0;
    font-weight: 600;
    padding: 0.5rem 0.75rem;
    display: block;

    &:focus-within {
      border: 0.0625rem solid ${theme.palette.text.primary};
    }

    input {
      color: ${theme.palette.text.primary};
      &::placeholder {
        opacity: 1;
      }
    }

    textarea {
      padding: 0.25rem 0;
      font-size: 0.875rem;
      line-height: 1.3rem;
    }
  `
);

export const ModalFormTextField = styled(TextField)(
  () => css`
    input {
      font-size: 1.25rem;
      font-weight: 600;
      padding: 0.125rem !important;
    }
  `
);

export default FormInput;
