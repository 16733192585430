import React, { useMemo, useRef, useState } from 'react';
import { css, styled, Interpolation, ThemeOptions, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { flexRender } from '@tanstack/react-table';
import { Row as RowType } from '@tanstack/table-core/build/lib/types';
import { useDrop, useDrag } from 'react-dnd';
import LaunchIcon from '@mui/icons-material/Launch';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ButtonBase from '@mui/material/ButtonBase';
import { TableCell as MUITableCell } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { ItemTableItem } from '../../contexts/QuoteContext/useItems';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { Container } from '@mui/material';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useBreakpoints } from 'hooks/util/useBreakpoints';

type Identifier = string | symbol;

export const baseQuotesTableCellStyles: Interpolation<{ theme: ThemeOptions; $start: boolean; $end: boolean }> = ({
  theme,
  $start,
  $end,
}: {
  theme: ThemeOptions;
  $start: boolean;
  $end: boolean;
}) => css`
  vertical-align: top;
  border: 0.0625rem solid ${theme.colors.black};
  border-top-color: ${theme.colors.bevelTop};
  border-left-color: ${theme.colors.bevelLeft};
  background-color: ${theme.colors.charcoal};

  ${$start &&
  css`
    border-bottom-left-radius: 0.5rem;
  `}
  ${$start &&
  css`
    border-top-left-radius: 0.5rem;
  `}

  ${$end &&
  css`
    border-bottom-right-radius: 0.5rem;
  `}
  ${$end &&
  css`
    border-top-right-radius: 0.5rem;
  `}
`;

export const TableCell = styled(MUITableCell)<{ $start: boolean; $end: boolean; $padding?: string }>(
  ({ theme, $padding }) => css`
    ${baseQuotesTableCellStyles};
    padding: ${$padding || '0'};
    font-size: 0.8rem;
    font-weight: 600;
    color: ${theme.colors.white};
    height: 5.23rem;

    &:hover {
      cursor: pointer;
      background-color: ${theme.colors.hoverGray};
      border-radius: 0.25rem;
      box-shadow: 0 0.5rem 0.8125rem ${theme.colors.blackOpacity55};
    }
  `
);

export const TableHeaderCell = styled('div')<{ $start: boolean; $end: boolean; $noMarginBottom?: boolean }>(
  ({ theme, $noMarginBottom }) => css`
    ${baseQuotesTableCellStyles};
    padding: 0.7rem 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.65rem;
    font-weight: 700;
    color: ${theme.colors.whiteOpacity65};
    text-align: left;
    text-transform: uppercase;
    border-right: 0;
    margin-bottom: ${$noMarginBottom ? 0 : '1rem'};
    margin-right: -0.0625rem;
  `
);

const rowActionsWrapperBase: Interpolation<object> = () => css`
  position: absolute;
`;

export const RowActionsWrapperLeft = styled('div')(
  () => css`
    ${rowActionsWrapperBase};
    top: 1rem;
    right: 0.75rem;
  `
);

export const RowActionsWrapperRight = styled('div')(
  () => css`
    ${rowActionsWrapperBase};
    top: 2rem;
    left: 0.75rem;
  `
);

export const Row = styled('tr')(
  () => css`
    height: 1rem;
  `
);

export const Spacer = styled(Container)<{ $borderLeftColor?: string }>(
  ({ theme, $borderLeftColor }) => css`
    ${$borderLeftColor ? `border-left: .25rem solid ${$borderLeftColor};` : ''}
    min-height: 3.13rem;
    height: 100%;
    padding: 1rem;
    text-decoration: none;
    color: ${theme.palette.text.primary};
    min-width: fit-content;
  `
);

export const Table = styled('table')(
  () => css`
    width: 100%;
    border-spacing: 0 1rem;
    border-collapse: separate;

    tr,
    th {
      position: relative;
    }
  `
);

export const iconStyles: Interpolation<{ theme: ThemeOptions; $visible?: boolean }> = ({
  theme,
  $visible,
}: {
  theme: ThemeOptions;
  $visible?: boolean;
}) => css`
  transition: all 313ms ease-in-out;
  opacity: ${$visible ? 0.65 : 0};
  color: ${theme.palette?.text?.primary};
  font-size: 0.9rem;
  cursor: pointer;
`;

export const IconWrapper = styled(Container)(
  ({ theme }) => css`
    transition: all 313ms ease-in-out;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${theme.palette.primary.main};

    &:hover {
      background-color: ${theme.colors.gray};
      svg {
        opacity: 1;
      }
    }
  `
);

export const Wrapper = styled(IconWrapper)(
  () => css`
    position: relative;
    width: 0;
  `
);

export const StyledLaunchIcon = styled(LaunchIcon)<{ $visible: boolean }>(
  () => css`
    ${iconStyles};
    font-size: 1rem;
  `
);

export const LeftActionCell = styled('td')(
  () => css`
    position: relative;
  `
);

export const RightActionCell = styled('td')(
  () => css`
    position: relative;
  `
);

export const StyledDragIcon = styled(DragIndicatorIcon)<{ $visible: boolean }>(
  () => css`
    ${iconStyles};
  `
);

export const StyledClearIcon = styled(ClearIcon)<{ $visible: boolean }>(
  ({ theme }) => css`
    ${iconStyles};
    display: flex;
    justify-content: flex-end;
    color: ${theme.palette.text.primary};
    font-size: 1.5rem;
    margin-left: 2rem;
    margin-top: 3rem;
    position: absolute;
    height: 100%;
    right: -2rem;
  `
);

export const StyledAppsIcon = styled(AppsIcon)<{ $visible: boolean }>(
  () => css`
    ${iconStyles};
    width: 1rem;
    height: 1rem;
  `
);

export const StyledListIcon = styled(ListIcon)<{ $visible: boolean }>(
  () => css`
    ${iconStyles};
    width: 1rem;
    height: 1rem;
  `
);

export const StyledVisibilityOn = styled(VisibilityIcon)<{ $visible: boolean }>(
  () => css`
    ${iconStyles};
  `
);

export const StyledVisibilityOff = styled(VisibilityOffIcon)<{ $visible: boolean }>(
  () => css`
    ${iconStyles};
  `
);

export const AnchoredTh = styled('th')(
  () => css`
    position: absolute;
  `
);

export const IconContainerLeft = styled('span')(
  () => css`
    position: absolute;
    top: 0rem;
    right: 0;
    height: 100%;
    width: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `
);

export const IconContainerRight = styled('td')(
  () => css`
    position: absolute;
    height: 100%;
    right: -2rem;
  `
);

export const TallButton = styled(ButtonBase)(
  () => css`
    height: 100%;
    height: fit-content;
    padding: 0.3125rem 0;
  `
);

export const CellButton = styled(ButtonBase)(
  () => css`
    height: 100%;
    width: 100%;
    padding: 0;
  `
);

type DragRowProps = {
  row: RowType<ItemTableItem>;
  reorderRow: (index: number, targetRowIndex: number) => void;
  rowActionsVisible?: boolean;
  untruncateDescription: boolean;
  toggleUntruncateDescription: (id: string) => void;
  actionCell?: React.FC<{ row: RowType<ItemTableItem>; visible: boolean }>;
  alternateLeftActionCell?: React.ReactNode;
};

export const DraggableRow: React.FC<DragRowProps> = ({
  row,
  reorderRow,
  rowActionsVisible,
  actionCell: ActionCell,
  alternateLeftActionCell,
}) => {
  const { setPurchaseHistoryItem } = useQuoteContext();

  const [isActive, setIsActive] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<RowType<ItemTableItem>, void, { handlerId: Identifier | null }>({
    accept: 'row',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(droppedRow: RowType<ItemTableItem>) {
      if (!ref.current) {
        return;
      }

      reorderRow(droppedRow.index, row.index);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'row',
    item: () => row,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;

  drag(drop(ref));

  const { isMedium } = useBreakpoints();

  return (
    <Row
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <LeftActionCell>
        <IconContainerLeft>
          {alternateLeftActionCell ? (
            alternateLeftActionCell
          ) : (
            <>
              <Tooltip placement='right' title='View Purchase History'>
                <TallButton disableRipple onClick={() => setPurchaseHistoryItem(row.original ?? null)}>
                  <StyledListIcon $visible={rowActionsVisible} />
                </TallButton>
              </Tooltip>
              <TallButton disableRipple>
                <StyledAppsIcon $visible={rowActionsVisible} />
              </TallButton>
            </>
          )}
        </IconContainerLeft>
      </LeftActionCell>
      {row.getVisibleCells().map((cell, i) => {
        return (
          <TableCell $start={i === 0} $end={i === row.getVisibleCells().length - 1} key={cell.id}>
            <Spacer>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Spacer>
          </TableCell>
        );
      })}
      {ActionCell && <ActionCell row={row} visible={isActive || !isMedium} />}
    </Row>
  );
};
