import { useCallback, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import useMutationCreateContact from 'hooks/mutations/useMutationCreateContact/useMutationCreateContact';
import {
  FormikCheckbox,
  FormikInput,
  FormikSelect,
  FormikValuesFrom,
  InputVariant,
  createInitialValues,
} from 'utils/formik';
import { CreateContactInput, CustomListType, EditContactInput, GetClientContactsQuery } from '__generated__/graphql';
import { useQueryGetContactRoles } from 'hooks/queries/useQueryGetContactRoles/useQueryGetContactRoles';
import { useQueryGetSubsidiaries } from 'hooks/queries/useQueryGetSubsidiaries/useQueryGetSubsidiaries';
import { useClientContext } from 'contexts/ClientContext/ClientContext';
import { useDropdownOptions } from 'hooks/util/useDropdownOptions';
import { useAppContext } from 'contexts/AppContext/AppContext';
import DataDrawer, { DataDrawerProps } from 'components/DataDrawer/DataDrawer';
import { useQueryGetCustomList } from 'hooks/queries/useQueryGetCustomList/useQueryGetCustomList';
import { toModel } from 'utils/graphqlHelpers';
import useMutationEditContact from 'hooks/mutations/useMutationEditContact/useMutationEditContact';
import FormLabel from 'components/FormLabel/FormLabel';
import dayjs from 'dayjs';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Button from 'components/Button/Button';

type Contact = GetClientContactsQuery['getClientContacts']['contacts'][0];

type Props = Omit<DataDrawerProps, 'title'> & {
  onSubmitCallback?: () => void | Promise<void>;
  contact?: Contact;
};

const formFields = {
  name: 'Name',
  fullName: 'Contact',
  role: 'Role',
  jobTitle: 'Job Title',
  phone: 'Main Phone',
  email: 'Email',
  subsidiary: 'Subsidiary',
  preferredCommunicationMethod: 'Preferred Communication Method',
  materialsNeeded: 'Materials Needed',
  miscellaneousInfo: 'Miscellaneous Info',
  syncWithHubSpot: 'Sync with Hubspot',
  isEmployee: 'Is Employee',
  hireDate: 'Hire Date',
};

const requiredFields = ['fullName', 'subsidiary'];

type FormikValues = FormikValuesFrom<typeof formFields, CreateContactInput | EditContactInput>;

const ClientContactModal = ({ open, onClose, onSubmitCallback, contact }: Props) => {
  const { showSnackbar } = useAppContext();
  const [saving, setSaving] = useState(false);

  const { client, refetch: refetchClient } = useClientContext();

  const [createContact] = useMutationCreateContact();

  const [editContact] = useMutationEditContact();

  const { data: contactRolesData } = useQueryGetContactRoles();

  const contactRoleOptions = useDropdownOptions(contactRolesData?.getContactRoles);

  const { data: subsidiariesData } = useQueryGetSubsidiaries();

  const subsidiaryOptions = useDropdownOptions(subsidiariesData?.getSubsidiaries);

  const { data: prefComMethodData } = useQueryGetCustomList({
    id: CustomListType.PreferredCommunicationMethod,
  });

  const prefComMethodOptions = useMemo(() => {
    return (
      prefComMethodData?.getCustomList?.customValueList?.customValue?.map((option) => ({
        id: `${option.valueId}`,
        label: option.value,
      })) ?? []
    );
  }, [prefComMethodData]);

  const toCreateContactInput = useCallback(
    (values: FormikValues): CreateContactInput => {
      const { name, ...rest } = values;
      const [firstName, ...lastName] = name.indexOf(' ') > -1 ? name.split(' ') : [name, ''];

      return {
        ...rest,
        client: client?.id,
        firstName,
        lastName: lastName.join(' '),
      };
    },
    [client?.id]
  );

  const toEditContactInput = useCallback(
    (values: FormikValues): EditContactInput => {
      return {
        ...toCreateContactInput(values),
        id: contact?.id ?? '',
      };
    },
    [contact, toCreateContactInput]
  );

  const contactSubsidiaryId = useMemo(() => {
    const name = contact?.subsidiary?.name?.split(' - ')?.pop()?.split(' : ').pop();

    const opt = subsidiaryOptions.find((opt) => opt.label === name);

    return opt?.id;
  }, [contact, subsidiaryOptions]);

  const prefComMethodId = useMemo(() => {
    const opt = prefComMethodOptions.find((opt) => opt.label === contact?.preferredCommunicationMethod);

    return opt?.id;
  }, [contact, prefComMethodOptions]);

  const initialValues = useMemo(() => {
    if (contact) {
      const model = toModel<EditContactInput>(contact, 'EditContactInput');

      return createInitialValues<FormikValues>(formFields, {
        ...model,
        subsidiary: contactSubsidiaryId,
        preferredCommunicationMethod: prefComMethodId,
        name: `${contact.firstName ?? ''}${contact.lastName ? ` ${contact.lastName}` : ''}`,
      });
    }

    return createInitialValues<FormikValues>(formFields);
  }, [contact, contactSubsidiaryId, prefComMethodId]);

  const handleCreate = useCallback(
    async (values: FormikValues) => {
      const input = toCreateContactInput(values);

      showSnackbar('Creating contact...');

      await createContact({ variables: { input } });

      showSnackbar('Successfully created contact');
    },
    [createContact, showSnackbar, toCreateContactInput]
  );

  const handleEditContact = useCallback(
    async (values: FormikValues) => {
      const input = toEditContactInput(values);

      showSnackbar('Updating contact...');

      await editContact({ variables: { input } });

      showSnackbar('Successfully updated contact');
    },
    [showSnackbar, toEditContactInput, editContact]
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values: FormikValues) => {
      try {
        setSaving(true);
        if (contact) {
          await handleEditContact(values);
        } else {
          await handleCreate(values);
        }

        await refetchClient();

        onClose();

        formik.resetForm();

        if (onSubmitCallback) {
          await onSubmitCallback();
        }
      } catch (error) {
        showSnackbar(client ? 'Failed to update contact' : 'Failed to create contact');
      }
      setSaving(false);
    },
  });

  const inputProps = useMemo(() => {
    return {
      handleChange: formik.handleChange,
      values: formik.values,
      requiredFields,
      formFields,
      variant: InputVariant.Drawer,
    };
  }, [formik.values, formik.handleChange]);

  return (
    <DataDrawer
      open={open}
      onClose={onClose}
      title='Create Client Contact'
      width='42rem'
      headerButton={
        <LoadingSpinner loading={saving}>
          <Button variant='outlined' onClick={() => formik.submitForm()}>
            {contact ? 'Save Contact' : 'Create Contact'}
          </Button>
        </LoadingSpinner>
      }
    >
      <form onSubmit={formik.handleSubmit} id='createClientContact'>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormikInput name='name' {...inputProps} />
            <FormikInput name='fullName' {...inputProps} />
            <FormikSelect name='role' {...inputProps} options={contactRoleOptions} />
            <FormikInput name='jobTitle' {...inputProps} />
            <FormikInput name='phone' {...inputProps} />
            <FormikInput name='email' {...inputProps} />
            <FormikSelect name='subsidiary' {...inputProps} options={subsidiaryOptions} />
          </Grid>

          <Grid item xs={6}>
            <FormikSelect name='preferredCommunicationMethod' {...inputProps} options={prefComMethodOptions} />
            <FormikInput name='materialsNeeded' {...inputProps} />
            <FormikInput name='miscellaneousInfo' {...inputProps} />
            <br />
            <FormikCheckbox name='syncWithHubSpot' {...inputProps} />
            <FormikCheckbox name='isEmployee' {...inputProps} />
            <FormikInput name='hireDate' type='date' {...inputProps} />
            {contact?.lastSalesActivity && (
              <>
                <br />
                <FormLabel>Last Sales Activity</FormLabel>
                <p>{dayjs(new Date(contact.lastSalesActivity)).format('M/DD/YYYY')}</p>
              </>
            )}
          </Grid>
        </Grid>
      </form>
    </DataDrawer>
  );
};

export default ClientContactModal;
