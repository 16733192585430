import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Button, useMediaQuery } from '@mui/material';
import FilterMenu from 'components/FilterMenu/FilterMenu';
import Flex from 'components/Flex/Flex';
import Search from 'components/Search/Search';
import { useQueryGetItemSkus } from 'hooks/queries/useQueryGetItemSkus/useQueryGetItemSkus';
import { useQueryGetItemSubSkus } from 'hooks/queries/useQueryGetItemSubSkus/useQueryGetItemSubSkus';
import { useQuerySearchItems } from 'hooks/queries/useQuerySearchItems/useQuerySearchItems';
import { useCallback, useMemo, useState } from 'react';
import FilterButton from 'components/FilterButton/FilterButton';
import { ItemSearchFilters, defaultItemFilters } from 'contexts/QuoteItemsContext/QuoteItemsContext';
import { useTheme } from 'styled-components';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import SearchResultHeader from 'components/SearchResultHeader/SearchResultHeader';
import { StyledHeader } from 'components/StyledHeader/StyledHeader';
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer/InfiniteScrollContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingContainer } from 'components/LoadingContainer/LoadingContainer';
import SearchResult from 'components/SearchResult/SearchResult';
import { getBinsWithoutDuplicateLocations, stripHTML } from 'utils/functions';
import { Item } from 'utils/types';
import { Updater } from '@tanstack/react-table';
import { useQueryGetDepartments } from 'hooks/queries/useQueryGetDepartments/useQueryGetDepartments';

type PaginationState = {
  page: number;
  perPage: number;
};

const tabletStyleOverrides = {
  height: '3.2rem',
  width: '3rem',
  padding: '0',
  minWidth: '0',
};

export function ItemSearch() {
  const [isFilterDrawOpen, setIsFilterDrawerOpen] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({ page: 0, perPage: 20 });
  const [filters, setFilters] = useState<ItemSearchFilters>(defaultItemFilters);
  const [appliedFilters, setAppliedFilters] = useState<ItemSearchFilters>(defaultItemFilters);
  const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);
  const subSkusAborterRef = useRef(new AbortController());
  const itemsAborterRef = useRef(new AbortController());
  const {
    data: itemsData,
    loading,
    fetchMore,
  } = useQuerySearchItems(
    { input: appliedFilters },
    {
      context: {
        fetchOptions: {
          signal: itemsAborterRef.current.signal,
        },
      },
    }
  );
  const { data: itemSkusData } = useQueryGetItemSkus();
  const { data: itemSubSkusData } = useQueryGetItemSubSkus(filters.itemSku, {
    context: {
      fetchOptions: {
        signal: subSkusAborterRef.current.signal,
      },
    },
  });
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  const filtersApplied = useMemo(() => {
    for (const _key in filters) {
      const key = _key as keyof typeof filters;
      if (filters[key] !== defaultItemFilters[key]) {
        return true;
      }
    }
    return false;
  }, [filters]);

  // We need to abort the previous search query when the filters change
  //  so the old query results do not overwrite the new results if the old query was slower
  const setAppliedFiltersAndAbort = useCallback((updater: Updater<ItemSearchFilters>) => {
    itemsAborterRef.current.abort();
    setTimeout(() => {
      setAppliedFilters(updater);
      itemsAborterRef.current = new AbortController();
    }, 50);
  }, []);

  const restorePreviouslySelectedFilters = () => {
    subSkusAborterRef.current.abort();
    setTimeout(() => {
      setFilters({ ...appliedFilters });
      subSkusAborterRef.current = new AbortController();
    }, 50);
  };

  const fetchNextPage = useCallback(() => {
    const nextPaginationOptions = {
      page: pagination.page + 1,
      perPage: pagination.perPage,
    };

    setPagination(nextPaginationOptions);

    fetchMore({
      variables: {
        input: {
          ...filters,
          ...nextPaginationOptions,
        },
      },
      // Updates the query cache with additional items
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          searchItems: {
            ...fetchMoreResult.searchItems,
            __typename: 'ItemSearchOutput',
            items: [...prev.searchItems.items, ...fetchMoreResult.searchItems.items],
          },
        };
      },
    }).catch((err) => {
      console.warn(err.message);
    });
  }, [pagination, fetchMore, filters]);

  const handleFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPagination((prev) => ({ ...prev, page: 0 }));
      setFilters((prev) => ({
        ...prev,
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      }));

      if (e.target.type === 'text') {
        setAppliedFiltersAndAbort((prev) => ({
          ...prev,
          searchQuery: e.target.value,
        }));
      }
    },
    [setAppliedFiltersAndAbort]
  );

  const handleAppliedFiltersChange = () => {
    setPagination({ page: 0, perPage: 20 });
    setAppliedFiltersAndAbort(filters);
  };

  const { data: departmentsData } = useQueryGetDepartments();

  const markets = useMemo(
    () =>
      departmentsData?.getDepartments
        .map((department) => {
          return {
            name: department.parent?.name ? `${department.parent.name} : ${department.name}` : `${department.name}`,
            internalId: department.id,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    [departmentsData]
  );

  const clearFilters = useCallback(() => {
    setFilters({ ...defaultItemFilters, inStock: false });
  }, [setFilters]);

  return (
    <>
      <Flex w100 gap={1} styles={{ justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Search
          placeholder='Search for an item by Millennium part number, Vendor part number, or item description…'
          value={filters.searchQuery}
          name='searchQuery'
          onChange={handleFilterChange}
        />
        <FilterButton onClick={() => setIsFilterDrawerOpen((prev) => !prev)} isFilterApplied={filtersApplied} />
        <Button
          component={Link}
          to={`/item/${selectedItem?.id}`}
          disabled={!selectedItem}
          variant='outlined'
          sx={isTablet ? tabletStyleOverrides : {}}
        >
          {isTablet ? <AddIcon /> : 'View Item Details'}
        </Button>
        <FilterMenu
          hideInactive={filters.hideInactive}
          handleHideInactiveChange={handleFilterChange}
          openDrawer={isFilterDrawOpen}
          setOpenDrawer={setIsFilterDrawerOpen}
          clearFilters={clearFilters}
          inStock={filters.inStock}
          handleInStockFilterChange={handleFilterChange}
          itemSku={filters.itemSku}
          handleItemSkuFilterChange={handleFilterChange}
          itemSubSku={filters.itemSubSku}
          handleItemSubSkuFilterChange={handleFilterChange}
          itemSkus={itemSkusData?.getItemSkus?.itemSkus}
          itemSubSkus={itemSubSkusData?.getItemSubSkus.itemSubSkus}
          ignoreClientWarehouses={filters.ignoreClientWarehouses}
          handleIgnoreClientWarehousesChange={handleFilterChange}
          handleVendorChange={handleFilterChange}
          vendor={filters.vendor}
          handleTypeChange={handleFilterChange}
          type={filters.type}
          hideChildItems={filters.hideChildItems}
          handleHideChildItemsChange={handleFilterChange}
          handleAppliedFiltersChange={handleAppliedFiltersChange}
          handleMarketFilterChange={handleFilterChange}
          marketFilter={filters.market}
          markets={markets}
          handleFiberVendorChange={handleFilterChange}
          fiberVendor={filters.fiberVendor}
          restorePreviouslySelectedFilters={restorePreviouslySelectedFilters}
        />
      </Flex>
      <Flex center column>
        <LoadingSpinner loading={loading}>
          {!!itemsData?.searchItems?.items.length && (
            <>
              <SearchResultHeader>
                <StyledHeader>Item #</StyledHeader>
                <StyledHeader>Warehouse</StyledHeader>
                <StyledHeader>Available</StyledHeader>
                <StyledHeader>Unit Cost</StyledHeader>
                <StyledHeader>Description</StyledHeader>
              </SearchResultHeader>
              <InfiniteScrollContainer id='scrollable'>
                <InfiniteScroll
                  dataLength={itemsData?.searchItems?.items.length ?? 0}
                  next={fetchNextPage}
                  hasMore={!!itemsData?.searchItems?.items.length}
                  loader={
                    <LoadingContainer>
                      <LoadingSpinner loading={true} />
                    </LoadingContainer>
                  }
                  scrollableTarget='scrollable'
                >
                  {itemsData?.searchItems?.items.map((result, i) => (
                    <SearchResult
                      key={result.itemId + i}
                      onClick={() => setSelectedItem(result)}
                      $selected={result.itemId === selectedItem?.itemId}
                    >
                      <div>{result.itemId}</div>
                      <div>
                        {result.inventoryDetail &&
                          getBinsWithoutDuplicateLocations(result.inventoryDetail).map((bin) => (
                            <div key={bin.info?.id}>{bin.info?.name ?? ''}</div>
                          ))}
                      </div>
                      <div>
                        {result.inventoryDetail &&
                          getBinsWithoutDuplicateLocations(result.inventoryDetail).map((bin) => (
                            <div key={bin.info?.id}>{bin.locationQuantityAvailable || 0}</div>
                          ))}
                      </div>
                      <div>
                        {result.inventoryDetail &&
                          getBinsWithoutDuplicateLocations(result.inventoryDetail).map((bin) => (
                            <div key={`${bin.info?.id}-${result.itemId}`} style={{ width: '27%', textAlign: 'right' }}>
                              {Number(bin.averageCost)?.toFixed(2)}
                            </div>
                          ))}
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: stripHTML(result.description ?? '') }} />
                    </SearchResult>
                  ))}
                </InfiniteScroll>
              </InfiniteScrollContainer>
            </>
          )}
        </LoadingSpinner>
      </Flex>
    </>
  );
}
