import { gql } from '@apollo/client';

export const GET_PRICING = gql`
  mutation getPricing($input: PricingInput!) {
    getPricing(input: $input) {
      shipping
      errorMessage
      metadata {
        calculationId
        modelExecutionTime
      }
      quotelines {
        line_id
        error_message
        good_target_price
        good_target_price_per_unit
        better_target_price
        better_target_price_per_unit
        best_target_price
        best_target_price_per_unit
      }
    }
  }
`;
