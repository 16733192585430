import { Quote, StitchRelation } from '__generated__/graphql';

const requiredFields = [
  'contact',
  'dueDate',
  'expectedClose',
  'freightTerms',
  'market',
  'quoteNumber',
  'quoteType',
  'salesCoordinator',
  'salesRep',
  'startDate',
  'status',
  'terms',
];

const requiredClientFields = ['email', 'phone'];

export const validateRequiredFields = (quote?: Quote) => {
  if (!quote || !quote.client) return false;

  for (let i = 0; i < requiredFields.length; i++) {
    if (!quote[requiredFields[i] as keyof Quote]) {
      return false;
    }
  }

  for (let i = 0; i < requiredClientFields.length; i++) {
    if (!quote.client[requiredClientFields[i] as keyof StitchRelation]) {
      return false;
    }
  }

  return true;
};
