import { QueryResult, useQuery } from '@apollo/client';
import {
  GetTransactionTypesDocument,
  GetTransactionTypesQuery,
  GetTransactionTypesQueryVariables,
} from '__generated__/graphql';

export const useQueryGetTransactionTypes = (): QueryResult<
  GetTransactionTypesQuery,
  GetTransactionTypesQueryVariables
> => {
  return useQuery(GetTransactionTypesDocument);
};
