import React from 'react';
import { css, styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Container, IconButton, Select } from '@mui/material';
import Button from 'components/Button/Button';
import { FormInput } from 'components/FormInput/FormInput';

const StyledDrawer = styled(MuiDrawer)(
  ({ theme }) => css`
    & .MuiDrawer-paper {
      position: fixed;
      inset: 1rem 1rem 1rem auto;
      height: calc(100vh - 6rem);
      width: 32rem;
      max-width: 32rem;
      margin-top: 4rem;
      border-radius: 1rem;

      ${theme.breakpoints.down('md')} {
        width: 30rem;
      }

      ${theme.breakpoints.down('sm')} {
        width: 30rem;
      }
    }
  `
);

const DrawerContent = styled('div')(
  ({ theme }) => css`
    position: relative;
    height: 100%;
    display: flex;
    justify-items: left;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    row-gap: 1rem;
    bordertop: 1px solid ${theme.colors.bevelTop};
    borderbottom: 1px solid ${theme.colors.black};
    borderleftcolor: theme.colors.bevelLeft;
    borderradius: 1rem;
    backgroundcolor: theme.colors.charcoal;
  `
);

const CloseIcon = styled(IconButton)(
  () => css`
    z-index: 30;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0.5rem;
    bottom: auto;
    left: 0.5rem;
    right: auto;
  `
);

export const ScrollContainer = styled('div')(
  () => css`
    height: 100vh;
    flex-direction: column;
    padding-bottom: 2.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
  `
);

export const DrawerHeader = styled('div')(
  ({ theme }) => css`
    z-index: 20;
    background-color: ${theme.colors.charcoal};
    border-bottom: 1px solid ${theme.colors.lightBlack};
    align-items: center;
    padding: 3rem 0 0;
    position: sticky;
    font-weight: 600;
    top: 0;
  `
);

export const DrawerInput = styled(FormInput)(
  ({ theme }) => css`
    background-color: ${theme.palette.input.main};
  `
);

export const DrawerSelectInput = styled(Select)(
  ({ theme }) => css`
    width: 100%;
    min-height: 3rem;
    height: 3rem;
    background-color: ${theme.palette.input.main};
    border: none;
    border-radius: 0.5rem;
    margin-bottom: 0;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;

    input {
      font-size: 0.875rem;
      border: none;
    }

    fieldset {
      border: none;
    }
  `
);

export const DrawerButtons = styled(Container)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding-top: 2rem;
    border-top: 1px solid ${theme.colors.lightBlack};
  `
);

export const DrawerConfirmButton = styled(Button)(
  () => css`
    border-radius: 2rem;
    height: 2.25rem;
  `
);

export const DrawerCloseButton = styled('button')(
  ({ theme }) => css`
    color: ${theme.colors.whiteOpacity50};
    border: 0.0625rem solid transparent;
    padding: 0.625rem 0.938rem;
    cursor: pointer;
    border-radius: 2rem;
    background-color: transparent;
    font-weight: 600;
    text-transform: uppercase;

    &:hover {
      border: 0.0625rem solid black;
      color: ${theme.colors.white};
    }
  `
);

type DrawerProps = {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
};

const Drawer: React.FC<DrawerProps> = ({ drawerOpen, setDrawerOpen, children }) => {
  return (
    <StyledDrawer
      ModalProps={{
        slotProps: { backdrop: { invisible: true } },
      }}
      disableScrollLock={true}
      anchor='right'
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <DrawerContent>
        <CloseIcon onClick={() => setDrawerOpen(false)}>
          <KeyboardDoubleArrowRightIcon sx={{ fontSize: 16 }} />
        </CloseIcon>
        <ScrollContainer>{children}</ScrollContainer>
      </DrawerContent>
    </StyledDrawer>
  );
};

export default Drawer;
