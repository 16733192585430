import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { EditContactDocument, EditContactMutation, EditContactMutationVariables } from '__generated__/graphql';
import { useMemo } from 'react';

type Data = EditContactMutation;
type Variables = EditContactMutationVariables;

function useMutationEditContact(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(EditContactDocument, opts);
}

export default useMutationEditContact;
