import { GetClientQuery, UpdateClientMutation } from '__generated__/graphql';
import { css, styled } from 'styled-components';
import FormHeader from 'components/FormHeader/FormHeader';
import Button from 'components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import Client from 'routes/Client/client';
import { Container } from '@mui/material';
import { seoFriendly } from 'utils/functions';
import { useClientContext, Client as ClientType } from 'contexts/ClientContext/ClientContext';
import { useState } from 'react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useAppContext } from 'contexts/AppContext/AppContext';

const HeaderContainer = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  `
);

type Props = {
  client: ClientType;
};

const General = ({ client }: Props) => {
  const { submitClient } = useClientContext();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const { showSnackbar } = useAppContext();

  const isEditing = window.location.pathname.includes('/client/edit');

  function toggleIsEditing() {
    if (isEditing) {
      navigate(`/client/${seoFriendly(client.name ?? '')}-${client.id}`);
    } else {
      navigate(`/client/edit/${seoFriendly(client.name ?? '')}-${client.id}`);
    }
  }

  const handleSubmit = () => {
    setSaving(true);
    showSnackbar('Updating Client...');
    submitClient()
      .then((clientResult) => {
        const client = (clientResult?.data as UpdateClientMutation)?.updateClient;
        if (client) {
          showSnackbar('Client saved successfully');
          navigate(`/client/${seoFriendly(client.name ?? '')}-${client.id}`);
        }
      })
      .catch(() => {
        showSnackbar('Failed saving Client');
      })
      .finally(() => setSaving(false));
  };

  return (
    <Container>
      <HeaderContainer>
        <FormHeader variant='h3' header='Company' />
        <div>
          <Button disabled={saving} onClick={toggleIsEditing}>
            {isEditing ? 'Cancel Editing' : `Edit ${client?.name ?? 'Client'}`}
          </Button>

          {isEditing && (
            <Button disabled={saving} type='submit' onClick={handleSubmit}>
              <LoadingSpinner loading={saving} size={'1.25rem'}>
                Save Updates
              </LoadingSpinner>
            </Button>
          )}
        </div>
      </HeaderContainer>

      <Client disabled={!isEditing} />
    </Container>
  );
};

export default General;
