import { Link } from 'react-router-dom';
import { Tooltip, colors } from '@mui/material';
import { Cell } from '@tanstack/react-table';
import { GetSalesOrdersQuery } from '__generated__/graphql';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import DataTable, { ActionCellProps } from 'components/DataTable/DataTable';
import OwnerAvatars from 'components/OwnerAvatars/OwnerAvatars';
import { IconWrapper, Spacer, StyledLaunchIcon } from 'components/TableComponents/TableComponents';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { formatCurrency, seoFriendly } from 'utils/functions';
import { salesOrderStatusBackgroundColorMap } from 'utils/constants';

type SalesOrder = GetSalesOrdersQuery['getSalesOrders']['salesOrders'][0];

type SalesOrdersTableProps = {
  data: GetSalesOrdersQuery | undefined;
  loading: boolean;
  pagination: {
    page: number;
    perPage: number;
    total: number;
  };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      page: number;
      perPage: number;
      total: number;
    }>
  >;
};
const SalesOrdersTable: React.FC<SalesOrdersTableProps> = ({ data, loading, pagination, setPagination }) => {
  const salesOrderLink = (row: SalesOrder) => {
    return `/sales-order/${seoFriendly(row.client?.name ?? '')}-${row.client?.id ?? ''}/${row.salesOrderNumber}/view`;
  };

  const salesOrderTotal = (salesOrder: SalesOrder) => {
    return (salesOrder.items || []).reduce((total, item) => {
      const calculateTotal = (quantity: number, adjCost: number) => {
        return Math.round(quantity * adjCost * 100) / 100;
      };
      return total + calculateTotal(item.quantity || 0, item.adjCost || 0);
    }, 0);
  };

  const columns = useMemo(
    () => [
      { id: 'client', header: 'Client', valueFn: (salesOrder: SalesOrder) => salesOrder.client?.name },
      {
        id: 'salesOrderNumber',
        header: 'Sales Order',
        cellOverride: (cell: Cell<SalesOrder, unknown>, children: React.ReactNode) => {
          const statusColor =
            salesOrderStatusBackgroundColorMap[
              cell.row.original.status?.name as keyof typeof salesOrderStatusBackgroundColorMap
            ];

          return (
            <Tooltip enterDelay={1000} title={`Status: ${cell.row.original.status?.name ?? ''}`}>
              <Spacer $borderLeftColor={statusColor} component={Link} to={salesOrderLink(cell.row.original)}>
                {children}
              </Spacer>
            </Tooltip>
          );
        },
      },
      {
        id: 'owner',
        header: 'Owner',
        valueFn: (salesOrder: SalesOrder) => (
          <OwnerAvatars salesRep={salesOrder.salesRep} salesCoordintor={salesOrder.salesCoordinator} />
        ),
      },
      { id: 'projectName', header: 'Project' },
      { id: 'clientContact', header: 'Client Contact', valueFn: (salesOrder: SalesOrder) => salesOrder.contact?.name },
      {
        id: 'salesOrderTotal',
        header: 'Sales Order Total',
        valueFn: (salesOrder: SalesOrder) => {
          return <span>{formatCurrency(salesOrderTotal(salesOrder))}</span>;
        },
      },
      { id: 'memo', header: 'Notes' },
      {
        id: 'followUpDate',
        header: 'Follow Up Date',
        valueFn: (salesOrder: SalesOrder) =>
          salesOrder.followUpDate ? dayjs(salesOrder.followUpDate).format('YYYY-MM-DD') : null,
      },
    ],
    []
  );

  const tableProps = {
    data: data?.getSalesOrders.salesOrders,
    loading,
    columns,
    pagination,
    setPagination,
    leftActionCell: ({ row, isActive }: ActionCellProps<SalesOrder>) => (
      <Tooltip title='View Sales Order Details'>
        <IconWrapper
          component={Link}
          to={`/sales-order/${seoFriendly(row.original.client?.name ?? '')}-${row.original.client?.id ?? ''}/${
            row.original.salesOrderNumber
          }/view`}
        >
          <StyledLaunchIcon $visible={isActive} />
        </IconWrapper>
      </Tooltip>
    ),
    rightActionCell: ({ isActive }: ActionCellProps<SalesOrder>) => (
      <IconWrapper>
        <ActionMenu objectName='Sales Order' visible={isActive} onDuplicate={() => {}} />
      </IconWrapper>
    ),
  };

  return <DataTable {...tableProps} />;
};

export default SalesOrdersTable;
