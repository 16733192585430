import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Grid } from '@mui/material';
import { ClientInputAddress, LookupType } from '__generated__/graphql';
import {
  createInitialValues,
  FormikInput,
  FormikCheckbox,
  FormikSelect,
  FormikValuesFrom,
  InputVariant,
} from 'utils/formik';
import { useQueryGetLookup } from 'hooks/queries/useQueryGetLookup/useQueryGetLookup';
import Modal, { ModalProps } from 'components/Modal/Modal';

export type SetAddressModalProps = Omit<ModalProps, 'children'> & {
  onSubmit: (clientInputAddress: ClientInputAddress) => void;
  values?: ClientInputAddress | null | undefined;
};

const SetAddressModal: React.FC<SetAddressModalProps> = ({ modalOpen, onSubmit, setModalOpen, title, values }) => {
  const [stateLookupType, setStateLookupType] = useState<LookupType>(LookupType.StateCode);
  const { data: countryData } = useQueryGetLookup({ input: { type: LookupType.Country } });
  const { data: stateData, loading: stateDataLoading } = useQueryGetLookup({ input: { type: stateLookupType } });

  // Formik setup and configuration
  const formId = title.toLowerCase().replaceAll(' ', '-');
  const requiredFields = ['address1', 'city', 'state', 'zip', 'country'];
  const formFields = {
    country: 'Country',
    attention: 'Attention',
    addressee: 'Addressee',
    phone: 'Phone',
    address1: 'Address 1',
    address2: 'Address 2',
    city: 'City',
    state: 'State',
    zip: 'Zip',
    gstin: 'GSTIN',
    isDefaultBilling: 'Default Billing',
    isDefaultShipping: 'Default Shipping',
    isResidential: 'Residential Address',
    label: 'Label',
  };
  type FormikValues = FormikValuesFrom<typeof formFields, ClientInputAddress>;

  const toClientInputAddress = (values: FormikValues): ClientInputAddress => {
    return {
      ...values,
      isDefaultBilling: values.isDefaultBilling === true,
      isDefaultShipping: values.isDefaultShipping === true,
    };
  };

  const stateLookupTypes = useMemo((): { [key: string]: LookupType } => {
    return {
      US: LookupType.StateCode,
      CA: LookupType.CanadianProvince,
    };
  }, []);

  const countries = useMemo(() => {
    return countryData ? countryData.getLookup : {};
  }, [countryData]);

  const stateCodes = useMemo(() => {
    return stateData ? stateData.getLookup : {};
  }, [stateData]);

  const handleSubmit = async (values: FormikValues) => {
    onSubmit(toClientInputAddress(values));
  };

  const getInitialValues = (): Partial<ClientInputAddress> => {
    const initialValues: Partial<ClientInputAddress> = values ?? {};
    if (!initialValues.country) {
      initialValues.country = 'US';
    }
    return initialValues;
  };

  return (
    <Modal {...{ title, modalOpen, setModalOpen, formId }} controlled>
      <Formik initialValues={createInitialValues<FormikValues>(formFields, getInitialValues())} onSubmit={handleSubmit}>
        {({ values, setFieldValue, handleChange, handleSubmit }) => {
          const props = { handleChange, values, requiredFields, formFields, variant: InputVariant.Drawer };
          const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (stateLookupTypes[event.target.value]) {
              setStateLookupType(stateLookupTypes[event.target.value]);
            }
            setFieldValue('state', '');
            handleChange(event);
          };

          return (
            <form onSubmit={handleSubmit} id={formId}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <FormikSelect name='country' {...props} options={countries} handleChange={handleCountryChange} />
                  <FormikInput name='attention' {...props} />
                  <FormikInput name='addressee' {...props} />
                  <FormikInput name='phone' {...props} />
                  <FormikInput name='address1' {...props} />
                  <FormikInput name='address2' {...props} />
                  <FormikInput name='city' {...props} />
                </Grid>
                <Grid item xs={6}>
                  {stateLookupTypes[values.country] ? (
                    <FormikSelect disabled={stateDataLoading} name='state' {...props} options={stateCodes} />
                  ) : (
                    <FormikInput name='state' {...props} />
                  )}
                  <FormikInput name='zip' {...props} />
                  <FormikInput name='gstin' {...props} />
                  <FormikInput name='label' {...props} />
                  <FormikCheckbox name='isDefaultBilling' {...props} />
                  <FormikCheckbox name='isDefaultShipping' {...props} />
                  <FormikCheckbox name='isResidential' {...props} />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default SetAddressModal;
