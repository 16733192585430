import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { useMemo } from 'react';
import { GET_PRICING } from 'graphql/pricing/pricing.gql';
import { GetPricingMutation, GetPricingMutationVariables } from '__generated__/graphql';

type Data = GetPricingMutation;
type Variables = GetPricingMutationVariables;

function useMutationGetPricing(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(() => ({ ...options }), [options]);
  return useMutation(GET_PRICING, opts);
}

export default useMutationGetPricing;
