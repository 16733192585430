/**
 * This file represents stand-in types until we have Graphql-derived types available
 */
import { SearchItemsQuery } from '__generated__/graphql';

export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type NullablePartial<
  T,
  NK extends keyof T = { [K in keyof T]: null extends T[K] ? K : never }[keyof T],
  NP = Partial<Pick<T, NK>> & Pick<T, Exclude<keyof T, NK>>,
> = { [K in keyof NP]: NP[K] };

/*
 * Quote statuses
 */
export enum QUOTE_STATUS {
  APPROVED = 'Approved',
  OPEN = 'Open',
  // REVIEW = 'In Review',
  CLOSED = 'Closed',
  // LOST = 'Lost',
  PROCESSED = 'Processed',
  EXPIRED = 'Expired',
  VOIDED = 'Voided',
}

export type Owner = {
  id: number;
  name: string;
  image: string;
};

export type Client = {
  id: string;
  internalId?: string;
  name: string;
  logos: string[];
  tags: string[];
};

export type Item = SearchItemsQuery['searchItems']['items'][0];
