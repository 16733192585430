import { SearchItemsQuery, StitchRelation } from '__generated__/graphql';
import { QUOTE_STATUS } from './types';
import { statusLookup } from './lookups';
import dayjs from 'dayjs';

export const opacity = (color: string, opacity: number, inputFormat: 'hex' | 'rgb' = 'hex') => {
  if (inputFormat === 'rgb') {
    const rgb = color.replace(/(rgba?)|[()]/gi, '');
    return `rgba(${rgb}, ${opacity})`;
  } else {
    const bigint = parseInt(color.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
};

export const formatCurrency = (value: string | number | undefined) => {
  if (!value) return '0.00';
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(value));
};

export const formatPercent = (value: string | number | undefined) => {
  if (!value) return '0.00%';
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  }).format(Number(value) / 100);
};

export const seoFriendly = (value: string) => {
  return value
    .replace(/\s/g, '-')
    .replace(/-+/g, '-')
    .replace(/[^a-å0-9-]/gi, '')
    .toLowerCase();
};

export const createNewQuoteNumber = () => {
  const date = new Date();
  const year = date.getFullYear().toString().slice(-2);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}${month < 10 ? `0${month}` : month}${day < 10 ? `0${day}` : day}`;
};

export const statusMapping = (status?: StitchRelation | null): QUOTE_STATUS => {
  if (!status) return QUOTE_STATUS.OPEN;
  switch (status.name) {
    case 'Approved':
      return QUOTE_STATUS.APPROVED;
    // case 'In Review' || 'Pending Approval':
    //   return QUOTE_STATUS.REVIEW;
    case 'Closed':
      return QUOTE_STATUS.CLOSED;
    // case 'Lost':
    //   return QUOTE_STATUS.LOST;
    case 'Processed':
      return QUOTE_STATUS.PROCESSED;
    case 'Expired':
      return QUOTE_STATUS.EXPIRED;
    case 'Voided':
      return QUOTE_STATUS.VOIDED;
    default:
      return QUOTE_STATUS.OPEN;
  }
};

export const quoteStatusToLookup = (status: QUOTE_STATUS) => {
  switch (status) {
    case QUOTE_STATUS.EXPIRED:
      return { id: statusLookup.Expired, name: 'Expired' };
    case QUOTE_STATUS.CLOSED:
      return { id: statusLookup.Closed, name: 'Closed' };
    case QUOTE_STATUS.PROCESSED:
      return { id: statusLookup.Processed, name: 'Processed' };
    case QUOTE_STATUS.VOIDED:
      return { id: statusLookup.Voided, name: 'Voided' };
    default:
      return { id: statusLookup.Open, name: 'Open' };
  }
};

export const truncateStringWithEllipsis = (str: string, length: number): string => {
  if (str.length > length) return `${str.substring(0, length)}...`;
  return str;
};

export function withoutTypename<T extends { __typename?: string }>(
  object?: T | null
): Omit<T, '__typename'> | undefined {
  if (!object) {
    return undefined;
  }

  const newObject = { ...object };
  delete newObject.__typename;

  return newObject;
}

export const uniqueNonNullPush = (array: unknown[], value: unknown) => {
  if (value && !array.includes(value)) {
    array.push(value);
  }
};

export const stripHTML = (str: string, options: { removeBr: boolean } = { removeBr: false }) => {
  if (options.removeBr) {
    const regex = /<br\s*\/?>/gi;
    return str.replace(regex, ' ');
  } else {
    // Remove all HTML tags except for <br>
    const regex = /<(?!br\s*\/?)[^>]+>/gi;
    return str.replace(regex, '');
  }
};

// Recursively remove the __typename attribute objects and arrays
export function removeTypename(obj: unknown) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const recurse = (obj: any) => {
    for (const [_key, value] of Object.entries(obj)) {
      const key = _key as keyof typeof obj;
      if (Array.isArray(value)) {
        obj[key] = value.map((item) => recurse(item));
      } else if (typeof value === 'object' && value !== null) {
        obj[key] = recurse(value);
      } else if (key === '__typename') {
        delete obj[key];
      }
    }
    return obj;
  };
  return recurse(obj);
}

// Calculate which page of results to display when changing the number of results to show per page
export const getPage = (curPageSize: number, curPageIndex: number, newPageSize: number): number => {
  return curPageSize > newPageSize
    ? Math.ceil((curPageSize * curPageIndex) / newPageSize)
    : Math.floor((curPageSize * curPageIndex) / newPageSize);
};

// This function will parse a date string that was saved with a GMT offset
// 1. The call for dayjs.utc will parse without trying to convert to local time.
// 2. Dates already saved with a US Timezone will be after midnight. The time will be dropped so no further steps needed
// 3. If a date was saved with a positive GMT offset, we'd see an hour value above 12.  We need to add a day to the date since would've gone into the previous day
export const tryParseGMTDate = (date: string) => {
  let parsedDate = dayjs.utc(date);

  if (parsedDate.isValid()) {
    if (parsedDate.hour() >= 12) {
      parsedDate = parsedDate.add(1, 'day');
    }

    return parsedDate.format('YYYY-MM-DD');
  }

  return '';
};

type InventoryDetail = Exclude<
  Exclude<SearchItemsQuery['searchItems']['items'][0]['inventoryDetail'], null>,
  undefined
>;

type Bin = Exclude<Exclude<InventoryDetail['binNumber'], null>, undefined>[0];

/**
 * Returns array of bins without duplicate locations. If there are multiple bins at one location, this
 * will only return one bin for that location.
 */
export const getBinsWithoutDuplicateLocations = (inventoryDetail: InventoryDetail) => {
  const binMap: { [key: string]: Bin } = {};

  inventoryDetail?.binNumber?.forEach((bin) => {
    if (bin.info?.id) {
      binMap[bin.info.id] ??= bin;
    }
  });

  return Object.values(binMap);
};
