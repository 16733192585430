import { Box, Container, Grid, Stack } from '@mui/material';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { GetItemQuery } from '__generated__/graphql';
import Flex from 'components/Flex/Flex';
import { Row, Spacer, Table, TableCell, TableHeaderCell } from 'components/TableComponents/TableComponents';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { FormikInput, FormikTextArea, FormikValuesFrom, createInitialValues } from 'utils/formik';

type Item = GetItemQuery['getItem'];

type ItemVendorList = Exclude<Exclude<Item['itemVendorList'], null>, undefined>;

type ItemVendor = ItemVendorList['itemVendor'][0];

type Props = {
  item: Item;
};

const formFields = {
  itemId: 'Item Number',
  sku: 'Sku',
  subSku: 'SKU Subcategory',
  sellingUOM: 'Selling UOM',
  purchaseDescription: 'Purchase Description',
  description: 'Sales Description',
};

type FormikValues = FormikValuesFrom<typeof formFields, Item>;

export const Overview = ({ item }: Props) => {
  const initialValues = useMemo(
    () => ({
      itemId: item.itemId,
      sku: item.sku,
      subSku: item.subSku,
      sellingUOM: item.sellingUOM,
      purchaseDescription: item.purchaseDescription,
      description: item.description,
    }),
    [item]
  );

  const vendors: ItemVendor[] = useMemo(() => item.itemVendorList?.itemVendor ?? [], [item]);

  const columnHelper = createColumnHelper<ItemVendor>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((vendor) => vendor.vendor.name, {
        id: 'name',
        header: () => 'Name',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((vendor) => vendor.vendorCode, {
        id: 'vendorPartNumber',
        header: () => 'Vendor Part Number',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((vendor) => vendor.subsidiary?.name, {
        id: 'subsidary',
        header: () => 'Subsidary',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((vendor) => vendor.preferredVendor, {
        id: 'preferred',
        header: () => 'Preferred',
        cell: (info) => info.getValue() && 'Yes',
      }),
      columnHelper.accessor((vendor) => vendor.purchasePrice, {
        id: 'purchasePrice',
        header: () => 'Purchase Price',
        cell: (info) => info.getValue(),
      }),
    ],
    [columnHelper]
  );

  const vendorTable = useReactTable({
    columns,
    data: vendors,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Flex w100 center>
      <Container>
        <Formik
          initialValues={createInitialValues<FormikValues>(formFields, initialValues)}
          validate={() => {}}
          onSubmit={() => {}}
        >
          {({ values, handleChange }) => {
            const props = {
              handleChange,
              values,
              requiredFields: [],
              formFields,
              disabled: true,
            };

            return (
              <Grid container spacing={4}>
                <Grid item xs={12} tablet={6}>
                  <Stack spacing={4}>
                    <Box>
                      <FormikInput {...props} name='sku' />
                    </Box>
                    <Box>
                      <FormikInput {...props} name='subSku' />
                    </Box>
                    <Box>
                      <FormikInput {...props} name='sellingUOM' />
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <Stack spacing={4}>
                    <Box>
                      <FormikTextArea {...props} multiline fullWidth rows={5} name='description' />
                    </Box>
                    <Box>
                      <FormikTextArea {...props} multiline fullWidth rows={4} name='purchaseDescription' />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            );
          }}
        </Formik>

        <br />

        <p>
          <strong>Vendors</strong>
        </p>

        <Table>
          {vendorTable.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <TableHeaderCell $start={i === 0} $end={header.id === 'purchasePrice'} $noMarginBottom>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHeaderCell>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
          {vendorTable.getRowModel().rows.map((row) => {
            return (
              <Row key={row.id}>
                {row.getVisibleCells().map((cell, i) => {
                  return (
                    <TableCell $start={i === 0} $end={cell.column.id === 'amount'} key={cell.id}>
                      <Spacer>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Spacer>
                    </TableCell>
                  );
                })}
              </Row>
            );
          })}
        </Table>
      </Container>
    </Flex>
  );
};
