import React from 'react';
import Container from '@mui/material/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import { QuotesContextProvider } from 'contexts/QuotesContext/QuotesContext';
import { TabContent, TabNavigation } from 'components/TabNavigation/TabNavigation';
import ClientSearch from './components/ClientSearch/ClientSearch';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { Action } from 'utils/constants';
import { useError } from 'hooks/util/useError';

const CreateQuote: React.FC = () => {
  const { can } = useAuthContext();
  const raiseError = useError();

  if (!can('Sales_Order', Action.Full)) {
    raiseError('You do not have permission to view this page');
  }

  return (
    <Container>
      <PageHeader variant='h1' header='Create New Sales Order' />
      <TabNavigation disabled>
        <TabContent header='Project Details'>
          <QuotesContextProvider>
            <ClientSearch />
          </QuotesContextProvider>
        </TabContent>
        <TabContent header='Add Items' />
      </TabNavigation>
    </Container>
  );
};

export default CreateQuote;
