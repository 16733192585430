import React, { useEffect } from 'react';
import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import TopNav from 'components/TopNav/TopNav';
import BlurBox from 'components/BlurBox/BlurBox';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileNav from 'components/MobileNav/MobileNav';
import SideNav from 'components/SideNav/SideNav';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const { isLoggedIn, logOut } = useAuthContext();
  const theme = useTheme();
  const nav = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  useEffect(() => {
    if (isLoggedIn) {
      logOut();
    }
  }, [isLoggedIn, logOut]);

  // We need to implement the basic layout here. If we use <Layout>, it will trigger
  // the login flow.
  return (
    <>
      {isTablet || isMobile ? <MobileNav /> : <SideNav />}
      <TopNav />
      <BlurBox>
        <Container maxWidth='sm' sx={{ marginTop: { xs: '1rem', md: '10rem' } }}>
          <Box>
            <Paper sx={{ padding: '2rem' }}>
              <Stack direction='column' spacing={3} justifyContent='center' alignContent='center' alignItems='center'>
                <LoadingSpinner loading={isLoggedIn}>
                  <Typography variant='h2'>You have successfully logged out</Typography>
                </LoadingSpinner>
                <Button variant='contained' color='info' onClick={() => nav('/')}>
                  Return to the Dashboard
                </Button>
              </Stack>
            </Paper>
          </Box>
        </Container>
      </BlurBox>
    </>
  );
};

export default Logout;
