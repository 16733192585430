import { colors } from 'darkTheme';

export const AUTH_TOKEN_KEY = 'mitp_user_auth_token';

export const AUTH_STATE_KEY = 'mitp_user_auth_state';

export const AUTH_REDIRECT_KEY = 'mitp_user_auth_redirect';

export const CLIENT_CATEGORY_FILTERS_KEY = 'mitp_user_filters_client_category';

export const GLOBAL_FILTERS_KEY = 'mitp_user_filters_global';

export const STATUS_FILTERS_KEY = 'mitp_user_filters_status';

export const SORT_FILTERS_KEY = 'mitp_user_filters_sort';

export const SALES_REP_FILTERS_KEY = 'mitp_user_filters_sales_representative';

export const SALES_COORD_FILTERS_KEY = 'mitp_user_filters_sales_coordinator';

export const MARKET_FILTERS_KEY = 'mitp_user_filters_market';

export const SUPERVISOR_FILTERS_KEY = 'mitp_user_filters_supervisor';

export const CLIENT_TIER_FILTERS_KEY = 'mitp_user_filters_client_tier';

export const LAST_DRAFT_ID_KEY = 'max_last_draft_id';

export const LIMIT_QUOTES_ROLE_IDS = ['1042', '1036'];

export const NOT_A_REAL_EMPLOYEE = 'this_is_not_a_real_employee';

export const SALES_ORDERS_FILTERS_KEY = 'mitp_user_filters_sales_orders';

//don't know where this number actually comes from
export const SALES_ROLE = -2;

export enum Action {
  View = 'View',
  ViewAll = 'View All',
  Full = 'Full',
  Edit = 'Edit',
  None = 'None',
}

export interface Permissions {
  Bin_Putaway_Worksheet: Action;
  Bin_Transfer: Action;
  Client_Deposit: Action;
  Client_Payment: Action;
  Credit_Memo: Action;
  Edit_Forecast: Action;
  Edit_Manager_Forecast: Action;
  Establish_Quotas: Action;
  Expense_Report: Action;
  Find_Transaction: Action;
  Fulfill_Orders: Action;
  Invoice: Action;
  Item_Fulfillment: Action;
  Item_Receipt: Action;
  Opportunity: Action;
  Order_Reservation: Action;
  Override_Estimated_Cost_on_Transactions: Action;
  Pay_Bills: Action;
  Purchase_Contract: Action;
  Purchase_Order: Action;
  Quotation: Action;
  Receive_Order: Action;
  Receive_Returns: Action;
  Rental_Item_Fulfillment: Action;
  Rental_Return_Authorization: Action;
  Return_Authorization: Action;
  Sales_Order: Action;
  Timer: Action;
  Track_Time: Action;
  Transfer_Inventory: Action;
  Transfer_Order: Action;
  Vendor_Return_Authorization: Action;
  Vendor_Returns: Action;
}

export type Permission = keyof Permissions;

export const salesOrderStatusBackgroundColorMap = {
  Billed: colors.purple,
  Closed: colors.red,
  Cancelled: colors.red,
  'Partially Fulfilled': colors.yellow,
  'Pending Billing Partially Fulfilled': colors.yellow,
  'Pending Billing': colors.yellow,
  'Pending Fulfillment': colors.yellow,
  'Pending Approval': colors.yellow,
};
