import React, { Dispatch, SetStateAction, useState } from 'react';
import OwnerAvatar from 'components/OwnerAvatar/OwnerAvatar';
import { css, styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextareaAutosize } from '@mui/material';
import useMutationCreateComment from 'hooks/mutations/useMutationCreateComment/useMutationCreateComment';
import { useParams } from 'react-router-dom';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { useUserContext } from 'contexts/UserContext/UserContext';

const AddCommentDrawer = styled(Drawer)(
  ({ theme }) => css`
    height: 0;
    z-index: 1500;

    .MuiBackdrop-root {
      height: 0;
    }

    .MuiPaper-root {
      top: 5rem;
      right: 1rem;
      border-radius: 1rem;
      border-top: 0.0625rem solid ${theme.colors.bevelTop};
      border-left: 0.0625rem solid ${theme.colors.bevelLeft};
      height: calc(100vh - 6rem);
      width: ${theme.breakpoints.down('tablet') ? '32rem' : '100%'};
      background: ${theme.colors.black95};
      backdrop-filter: blur(5px);
    }
  `
);

const AddCommentFormContainer = styled('div')(
  () => css`
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 27rem;
  `
);

const ConfirmButton = styled(Button)(
  ({ theme }) => css`
    background-color: ${theme.colors.blue};
    color: white;
    border-radius: 2rem;
    font-size: 0.75rem;
    padding: 0.25rem 1rem;
    font-weight: 700;
    margin-right: 1rem;

    &:hover {
      background-color: ${theme.colors.lightBlue};
    }
  `
);

const CancelButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.colors.white};
    border-radius: 2rem;
    font-size: 0.75rem;
    padding: 0.25rem 1rem;
    font-weight: 700;
    border: 1px solid ${theme.colors.black95};

    &:hover {
      border: 1px solid ${theme.colors.hoverGray};
    }
  `
);

const AddCommentText = styled(TextareaAutosize)(
  ({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.lighterBlack};
    border: none;
    padding: 0.5em 1em;
    width: 100%;
    border-radius: 0.5rem;
    font-family: ${theme.typography.fontFamily};
    margin-top: 1em;

    &:focus-visible {
      border: none;
      outline: none;
    }
  `
);

const AddCommentHeader = styled('div')(
  () => css`
    display: flex;
    align-items: center;
  `
);

const TextAreaContainer = styled('div')(
  () => css`
    position: relative;
  `
);

const AddCommentButtons = styled('div')(
  () => css`
    margin-top: 1em;
  `
);

const CommentAvatar = styled('div')(
  () => css`
    padding-right: 2em;
  `
);

const CommentUsername = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.white};
  `
);

type Props = {
  showAddComments: boolean;
  setShowAddComments: (value: ((prevState: boolean) => boolean) | boolean) => void;
  owner: { name: string; id: string };
  userId: string;
  quoteId: string;
  refetchComments: () => void;
  setShowSnackbar: Dispatch<SetStateAction<boolean>>;
  setSnackbarMessage: Dispatch<SetStateAction<string>>;
};

const AddCommentModal: React.FC<Props> = ({
  showAddComments,
  setShowAddComments,
  owner,
  userId,
  quoteId,
  refetchComments,
  setShowSnackbar,
  setSnackbarMessage,
}) => {
  const { setCommentsToBeCreated } = useQuoteContext();
  const [commentBody, setCommentBody] = useState<string>('');
  const [createComment, { error }] = useMutationCreateComment();
  const { user } = useUserContext();
  const { quoteNumber } = useParams();

  const handleCommentBodyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommentBody(e.target.value);
  };

  const handleCreateComment = () => {
    if (!quoteNumber) {
      const createdAt = new Date();
      setCommentsToBeCreated((prev) => [
        ...prev,
        {
          id: prev.length,
          createdAt,
          updatedAt: createdAt,
          commentBody,
          userId,
          user: {
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
          },
        },
      ]);
      setShowAddComments(false);
      setCommentBody('');
      return;
    }

    createComment({
      variables: {
        newComment: {
          commentBody,
          transactionId: quoteId,
        },
      },
    })
      .then((result) => {
        if (result.data?.createComment.success) {
          refetchComments();
          setShowAddComments(false);
          setCommentBody('');
        } else {
          console.warn(result.data?.createComment.error);
          setSnackbarMessage('Failed to save comment');
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        console.warn(error.message);
        setSnackbarMessage('Failed to save comment');
        setShowSnackbar(true);
      });
  };

  const handleCancelClicked = () => {
    setShowAddComments(false);
    setCommentBody('');
  };

  return (
    <AddCommentDrawer
      open={showAddComments}
      hideBackdrop
      disableScrollLock
      anchor='right'
      transitionDuration={{ enter: 0 }}
    >
      <AddCommentFormContainer>
        <AddCommentHeader>
          <CommentAvatar>
            <OwnerAvatar owner={owner} ownerRole='rep' />
          </CommentAvatar>
          <CommentUsername variant='h6'>{owner.name}</CommentUsername>
        </AddCommentHeader>
        <TextAreaContainer>
          <AddCommentText
            placeholder={'Comment or add others with @'}
            minRows={4}
            value={commentBody}
            onChange={handleCommentBodyChange}
          />
        </TextAreaContainer>
        <AddCommentButtons>
          <ConfirmButton onClick={handleCreateComment} disabled={!commentBody}>
            Comment
          </ConfirmButton>
          <CancelButton onClick={handleCancelClicked}>Cancel</CancelButton>
        </AddCommentButtons>
      </AddCommentFormContainer>
    </AddCommentDrawer>
  );
};

export default AddCommentModal;
