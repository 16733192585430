import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { ItemTableItem } from 'contexts/QuoteContext/useItems';
import { useState } from 'react';
import { css, styled } from 'styled-components';

const Input = styled('input')(
  () => css`
    all: unset;
    background: transparent;
    width: 100%;
  `
);

type Props = {
  item: ItemTableItem;
  index: number;
  isNonInventoryWithQuantity: boolean;
};

export const ItemQuantityInput = ({ item, index, isNonInventoryWithQuantity }: Props) => {
  const [value, setValue] = useState(item.quantity ?? 0);

  const { updateItemField } = useQuoteContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value));
  };

  const handleBlur = () => {
    const inventoryObj = item?.inventory && item.inventory[0];

    if (inventoryObj) {
      updateItemField(index, 'inventory', [{ ...inventoryObj, quantity: value }]);
    } else if (isNonInventoryWithQuantity) {
      updateItemField(index, 'quantity', value);
    }
  };

  return <Input onChange={handleChange} onBlur={handleBlur} value={value} type='number' />;
};
