import { css, styled } from 'styled-components';
import { GetSalesOrdersInput } from '__generated__/graphql';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import Flex from 'components/Flex/Flex';
import Search from 'components/Search/Search';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormikCheckboxGroup, FormikSelect, FormikValuesFrom, InputVariant, createInitialValues } from 'utils/formik';
import { useSalesOrderFilterDropdowns } from './useSalesOrdersFilterDropdowns';
import { Container } from '@mui/material';
import { SALES_ORDERS_FILTERS_KEY } from 'utils/constants';

type SalesOrdersFilterProps = {
  filters: GetSalesOrdersInput;
  setFilters: React.Dispatch<React.SetStateAction<GetSalesOrdersInput>>;
};

const DrawerContainer = styled(Container)(
  ({ theme }) => css`
    > :not(:last-child) {
      padding-bottom: 1rem;
      border-bottom: 1px solid ${theme.colors.lightBlack} !important;
    }
  `
);

const SalesOrdersFilter: React.FC<SalesOrdersFilterProps> = ({ filters, setFilters }: SalesOrdersFilterProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { sortOptions, statusOptions, marketOptions, salesRepOptions, salesCoordinatorOptions, supervisorOptions } =
    useSalesOrderFilterDropdowns({ filters });

  const getSavedFilters = useCallback(
    () => JSON.parse(window.localStorage.getItem(SALES_ORDERS_FILTERS_KEY) ?? '{}'),
    []
  );

  useEffect(() => {
    const savedFilters = getSavedFilters();
    setFilters((prev) => ({ ...prev, ...savedFilters }));
    setSearchText((prev) => savedFilters.textSearch ?? prev);
  }, [setFilters, setSearchText, getSavedFilters]);

  // TODO - Permissions aren't working as expected, this will need to be included when they are
  // const { can } = useAuthContext();
  // const { user } = useUserContext();
  // const restrictSalesOrders = !can('Sales_Order', Action.ViewAll);
  // const userName = user?.fullName ?? '';

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilters((prev) => {
        const newFilters = { ...prev, textSearch: searchText, page: 0 };
        window.localStorage.setItem(SALES_ORDERS_FILTERS_KEY, JSON.stringify(newFilters));
        return newFilters;
      });
    }, 500);
    return () => clearTimeout(timeout);
  }, [setFilters, searchText]);

  const formFields = useMemo(
    () => ({
      sort: 'Sort',
      status: 'Status',
      salesRepId: 'Sales Representative',
      salesCoordinator: 'Sales Coordinator',
      supervisorId: 'Supervisor',
      marketId: 'Market',
    }),
    []
  );
  type FormikValues = FormikValuesFrom<typeof formFields, GetSalesOrdersInput> & { status: string[] };

  const defaultValues = useMemo(
    () => createInitialValues<FormikValues>(formFields, { sort: sortOptions[0].id }),
    [formFields, sortOptions]
  );
  const initialValues = () => {
    const filterValues = { ...filters, ...getSavedFilters() };
    return {
      ...defaultValues,
      ...filterValues,
    };
  };

  const toGetSalesOrderInput = (values: FormikValues) => {
    const newValues = { ...filters, ...values };
    // TODO - Permissions aren't working as expected, this will need to be included when they are
    // if (restrictSalesOrders) {
    //   newValues.salesCoordinator = userName;
    //   newValues.salesRepId = userName;
    //   newValues.marketId = '';
    //   newValues.supervisorId = '';
    // }
    return newValues;
  };

  const onSubmit = (values: FormikValues) => {
    const newFilters = toGetSalesOrderInput(values);
    setFilters(newFilters);
    window.localStorage.setItem(SALES_ORDERS_FILTERS_KEY, JSON.stringify(newFilters));
    setDrawerOpen(false);
  };

  return (
    <Flex styles={{ alignItems: 'center', justifyContent: 'space-between' }} gap={1}>
      <Search
        placeholder={'Search for a sales order...'}
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
      />
      <FilterDrawer<FormikValues>
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        requiredFields={[]}
        formFields={formFields}
        defaultValues={defaultValues}
        initialValues={initialValues()}
        onSubmit={onSubmit}
      >
        {({ values, handleChange }) => {
          const props = { handleChange, values, requiredFields: [], formFields, variant: InputVariant.Drawer };
          return (
            <DrawerContainer>
              <FormikSelect {...props} name='sort' options={sortOptions} />
              <FormikCheckboxGroup {...props} name='status' options={statusOptions} />

              {/* {restrictSalesOrders && (  -- TODO - Permissions aren't working as expected, this will need to be included when they are
                <> */}
              <FormikSelect {...props} name='salesRepId' options={salesRepOptions} />
              <FormikSelect {...props} name='salesCoordinator' options={salesCoordinatorOptions} />
              <FormikSelect {...props} name='supervisorId' options={supervisorOptions} />
              <FormikSelect {...props} name='marketId' options={marketOptions} />
              {/* </>
              )} */}
            </DrawerContainer>
          );
        }}
      </FilterDrawer>
    </Flex>
  );
};

export default SalesOrdersFilter;
