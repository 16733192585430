import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { css, styled } from '@mui/material/styles';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { AutocompleteProps as MuiAutocompleteProps, AutocompleteRenderGroupParams } from '@mui/material';
import { ModalFormTextField } from 'components/FormInput/FormInput';

const SelectInputGroup = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
    background-color: ${theme.palette.input.main};
    font-size: 1rem;
    padding: 0.5rem 1rem;
  `
);

export type AutocompleteOption = {
  id: string;
  label: string;
  group?: string;
};

export type Options = { [key: string]: string } | AutocompleteOption[];

type AutocompleteProps = Omit<
  MuiAutocompleteProps<AutocompleteOption, boolean, boolean, boolean, React.ElementType>,
  'value' | 'options' | 'onChange' | 'renderInput'
> & {
  name: string;
  value: unknown;
  options: Options;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  renderInput?: (params: object) => ReactElement;
};

export const AutocompleteBase: React.FC<AutocompleteProps> = ({ name, value, options, onChange, ...props }) => {
  // Mapping options based on our GetLookup hook's output or an array of { id, label } objects
  const internalOptions = useMemo(() => {
    if (Array.isArray(options)) {
      return options;
    }
    return [{ id: '', label: '' }, ...Object.entries(options).map(([id, label]) => ({ id, label }))];
  }, [options]);
  // Recalculate internal value when options change (loading from server) or if the external value changes
  const getInternalValue = useCallback(() => {
    return internalOptions.find((opt) => opt.id === value) ?? { id: '', label: '' };
  }, [internalOptions, value]);
  const [internalValue, setInternalValue] = useState<AutocompleteOption>(getInternalValue());
  useEffect(() => {
    setInternalValue(getInternalValue());
  }, [setInternalValue, getInternalValue]);

  return (
    <MuiAutocomplete<AutocompleteOption, boolean, boolean, boolean, React.ElementType>
      value={internalValue}
      options={internalOptions}
      getOptionLabel={(option: AutocompleteOption | string) => (typeof option === 'string' ? option : option.label)}
      renderInput={(params: object) => <ModalFormTextField {...params} name={name} />}
      isOptionEqualToValue={(option: AutocompleteOption, value: AutocompleteOption) => option.label === value.label}
      getOptionDisabled={(option: AutocompleteOption) => option.id === ''}
      onChange={(
        _: React.SyntheticEvent<Element, Event>,
        newValue: NonNullable<AutocompleteOption | string> | (AutocompleteOption | string)[] | null
      ) => {
        // Simulating an event similar to text box or select
        // We expect this to update the `value` being passed in which will be kept in sync with the effects above
        const returnValue = typeof newValue === 'string' || Array.isArray(newValue) ? newValue : newValue?.id;
        onChange({
          target: { name, value: returnValue },
        } as React.ChangeEvent<HTMLInputElement>);
      }}
      groupBy={(option: AutocompleteOption) => option.group ?? ''}
      renderGroup={(params: AutocompleteRenderGroupParams) => (
        <li key={params.key}>
          {params.group && <SelectInputGroup>{params.group}</SelectInputGroup>}
          <p>{params.children}</p>
        </li>
      )}
      {...props}
    />
  );
};

export const Autocomplete = styled(AutocompleteBase)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.input.main};
    border: 0.0625rem solid ${theme.palette.input.main};
    border-radius: 0.5rem;
    font-size: 0.875rem;

    &:focus-within {
      border: 0.0625rem solid ${theme.palette.text.primary};
    }

    input {
      font-size: 1rem;
    }

    fieldset {
      border: none;
    }
  `
);
