import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import {
  GetEstimatedUnitCostsDocument,
  GetEstimatedUnitCostsQuery,
  GetEstimatedUnitCostsQueryVariables,
} from '__generated__/graphql';

export const useLazyQueryGetEstimatedUnitCost = (
  options?: QueryHookOptions<GetEstimatedUnitCostsQuery, GetEstimatedUnitCostsQueryVariables>
) => {
  return useLazyQuery(GetEstimatedUnitCostsDocument, options);
};
