import { css, styled } from '@mui/material/styles';

const Pill = styled('div')(
  ({ theme }) => css`
    border-top: 0.0625rem solid ${theme.colors.bevelTop};
    border-left: 0.0625rem solid ${theme.colors.bevelLeft};
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.text.primary};
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
    padding: 0.25rem 0.25rem 0.25rem 1rem;
    border-radius: 2rem;
    width: 17rem;
  `
);

export default Pill;
