import { Avatar, AvatarProps } from '@mui/material';
import React from 'react';
import { colors } from 'darkTheme';
import { QuoteSalesRep, QuoteSalesCoordinator, StitchRelation } from '__generated__/graphql';

interface OwnerAvatarProps extends AvatarProps {
  owner: QuoteSalesRep | QuoteSalesCoordinator | StitchRelation;
  ownerRole: 'coordinator' | 'rep';
}

const avatarColors: string[] = [colors.vividBlue, colors.weeblyOrange];

const OwnerAvatar: React.FC<OwnerAvatarProps> = ({ owner, ownerRole, ...rest }) => {
  if (!owner) {
    return <></>;
  }
  let initials = (owner.name ?? '')
    .split(' ')
    .map((part) => part[0])
    .join('');
  if (owner.name === 'National House Account') {
    initials = 'HA';
  }
  //Use their initials to index into the avatarColor list
  const color = ownerRole === 'rep' ? avatarColors[0] : avatarColors[1];
  return (
    <Avatar variant='circular' sx={{ bgcolor: color }} title={`${owner.name}`} {...rest}>
      {initials}
    </Avatar>
  );
};

export default React.memo(OwnerAvatar);
