import React, { useEffect } from 'react';
import PageHeader from 'components/PageHeader/PageHeader';
import { useAuthContext } from '../../contexts/AuthContext/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';

const AuthCallback: React.FC = () => {
  const { validateCode, isLoggedIn, returnTo, reportError } = useAuthContext();
  const [searchParams] = useSearchParams();
  const nav = useNavigate();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const remoteError = searchParams.get('error');

  useEffect(() => {
    if (remoteError) {
      reportError(remoteError);
    }

    return () => reportError(null);
  }, [remoteError, reportError]);

  useEffect(() => {
    if (isLoggedIn && returnTo) {
      nav(returnTo);
    } else if (isLoggedIn) {
      nav('/');
    } else if (code && state) {
      validateCode(code, state);
    }
  }, [code, isLoggedIn, nav, returnTo, state, validateCode]);

  return (
    <>
      <PageHeader variant='h1' header='Logging in' />
    </>
  );
};

export default AuthCallback;
