import Layout from 'layout/Layout';
import Quotes from 'routes/Quotes/Quotes';
import Dashboard from 'routes/Dashboard/Dashboard';
import CreateQuote from 'routes/CreateQuote/CreateQuote';
import CreateSalesOrder from 'routes/CreateSalesOrder/CreateSalesOrder';
import CreateItem from 'routes/CreateItem/CreateItem';
import Quote from 'routes/Quote/Quote';
import Search from 'routes/Search/Search';
import QASpinner from 'routes/QASpinner/QASpinner';
import AuthCallback from 'routes/AuthCallback/AuthCallback';
import Logout from 'routes/Logout/Logout';
import Clients from 'routes/Clients/Clients';
import ClientDetails from 'routes/ClientDetails/ClientDetails';
import { Navigate } from 'react-router-dom';
import ItemsSearch from 'routes/ItemsSearch/ItemsSearch';
import ItemDetails from 'routes/ItemDetails/ItemDetails';
import NotFound from './routes/NotFound';
import ErrorBoundary from './routes/ErrorBoundary';
import SalesOrder from 'routes/SalesOrder/SalesOrder';
import SalesOrders from 'routes/SalesOrders/SalesOrders';
import Error from 'routes/Error/Error';
import CreateClient from 'routes/CreateClient/CreateClient';

export default [
  {
    path: '/',
    element: <Layout />,
    ErrorBoundary: ErrorBoundary,
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/',
        element: <Navigate to='/quotes' replace={true} />,
      },
      {
        path: '/quotes',
        element: <Quotes />,
      },
      {
        path: '/items',
        element: <ItemsSearch />,
      },
      {
        path: '/item/:itemId',
        element: <ItemDetails />,
      },
      {
        path: '/quote/:clientNameAndId',
        element: <Quote />,
      },
      {
        path: '/quote/:clientNameAndId/:quoteNumber',
        element: <Quote />,
      },
      {
        path: '/quote/:clientNameAndId/draft/:draftId',
        element: <Quote />,
      },
      {
        path: '/create-quote',
        element: <CreateQuote />,
      },
      {
        path: '/sales-orders',
        element: <SalesOrders />,
      },
      {
        path: '/create-sales-order',
        element: <CreateSalesOrder />,
      },
      {
        path: '/sales-order/:clientNameAndId/:view',
        element: <SalesOrder />,
      },
      {
        path: '/sales-order/:clientNameAndId/:transactionNumber/:view',
        element: <SalesOrder />,
      },
      {
        path: '/create-item',
        element: <CreateItem />,
      },
      {
        path: '/auth/callback',
        element: <AuthCallback />,
      },
      {
        path: '/clients',
        element: <Clients />,
      },
      {
        path: '/client',
        element: <CreateClient />,
      },
      {
        path: '/client/:clientNameAndId',
        element: <ClientDetails />,
      },
      {
        path: '/client/edit/:clientNameAndId',
        element: <ClientDetails />,
      },
      {
        path: '/error',
        element: <Error />,
      },
      {
        // Temporary to test the loading spinner
        // https://caxy.slack.com/archives/C056WUSUV8C/p1692221328976059
        path: '/qa/loading-spinner',
        element: <QASpinner />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '/search',
    element: <Search />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
];
