import { CellContext } from '@tanstack/react-table';
import Flex from 'components/Flex/Flex';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { ItemTableItem } from 'contexts/QuoteContext/useItems';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Maybe } from 'yup';
import Input from '@mui/material/Input';

const EditableCellInput = styled(Input)(
  () => css`
    all: unset;
    background: transparent;
    width: 100%;
    resize: none;
    display: block;
    margin-bottom: 10px;
    border-bottom: 0px;

    &:before {
      border-bottom: 0px;
    }

    &:hover:not(.Mui-disabled, .Mui-error):before {
      border-bottom: 0px;
    }
  `
);

type UnitCostCellProps = {
  info: CellContext<
    ItemTableItem,
    {
      id: string;
      unitCost: Maybe<number> | undefined;
    }
  >;
  onBlurCallback?: () => void;
};

function UnitCostCell({ info, onBlurCallback }: UnitCostCellProps) {
  const { updateItemField } = useQuoteContext();
  const { unitCost: initialUnitCost } = info.getValue();
  const [unitCost, setUnitCost] = useState<number | undefined | null>(Number(initialUnitCost) ?? 0.0);

  // If the description is changed externally, sync this cell state with that
  useEffect(() => {
    setUnitCost(Number(initialUnitCost));
  }, [initialUnitCost]);

  const ref = useRef<HTMLTextAreaElement | null>(null);

  return (
    <Flex column gap={0.5}>
      <EditableCellInput
        ref={ref}
        type='number'
        value={unitCost}
        onFocus={(e) => e.target.select()}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setUnitCost(Number(e.target.value))}
        onBlur={() => {
          updateItemField(info.row.index, 'unitCost', unitCost?.toFixed(2) ?? 0.0);
          onBlurCallback && onBlurCallback();
        }}
      />
    </Flex>
  );
}

export default UnitCostCell;
