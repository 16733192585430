import { Bold } from 'components/Bold/Bold';
import styled, { css } from 'styled-components';

export const BoldEllipsis = styled(Bold)(
  () => css`
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 50%;
  `
);
