import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import FormLabel from 'components/FormLabel/FormLabel';
import FormInputBase from 'components/FormInput/FormInput';
import {
  Autocomplete,
  Checkbox,
  Container,
  FormControlLabel,
  InputProps,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { css, styled } from '@mui/material/styles';
import { FormikProps } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import dayjs from 'dayjs';
import { InputLabel } from 'components/InputLabel/InputLabel';
import Flex from 'components/Flex/Flex';
import { useTheme } from '@mui/material/styles';

const FormSelectBase = styled(Select)(
  ({ theme }) => css`
    width: 100%;
    min-height: 3rem;
    height: 3rem;
    background-color: ${theme.colors.charcoal};
    border: none;
    border-radius: 0.5rem;
    margin-bottom: 0;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;

    input {
      font-size: 0.875rem;
    }
  `
);

const SelectItem = styled(MenuItem)(
  () => css`
    font-size: 0.875rem;
  `
);

const PaddedLabel = styled(FormLabel)(
  () => css`
    padding-top: 1rem;
  `
);

const PaddedFCLabel = styled(FormControlLabel)(
  () => css`
    padding-top: 1rem;
    margin-top: 0rem !important;
  `
);

const DropdownOptions = styled('ul')(
  ({ theme }) => css`
    background-color: ${theme.palette.secondary.main};
  `
);

const StyledAutocomplete = styled(Autocomplete)(
  ({ theme }) => css`
    background-color: ${theme.palette.input.main};
    border-radius: 2rem;
    font-weight: 500;
    padding: 0.25rem;
    display: block;
    font-size: 0.875rem;

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  `
);

const StyledCheckboxLabel = styled(FormControlLabel)(
  () => css`
    grid-column-gap: 0.5rem;
    text-transform: uppercase;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-left: 0;
    display: flex;

    span {
      font-size: 0.75rem;
      font-weight: 600;
    }
  `
);

const StyledCheckbox = styled(Checkbox)(({ theme }) =>
  theme.unstable_sx({
    marginLeft: '0.6rem',
    width: 24,
    height: 24,
    '&.MuiSvgIcon-root': { fontSize: 12 },
    '&:hover': { borderColor: theme.colors.white },
    '&.Mui-checked': {
      color: theme.colors.lightBlue,
    },
  })
);

type IsAny<T> = 0 extends 1 & T ? true : false; // Utility type to check for 'any'
/**
 * Represents the type of values for a Formik form, derived from two types T and U.
 * The resulting type includes only the keys that exist in both T and U, with values of type InputValue.
 * @param T - The type of the form fields.  Expect a type derived from a const to map form fields to their keys.
 * @param U - The input/output type of the form. Typically a GraphQL Input type.
 */
export type FormikValuesFrom<T, U> = {
  [K in keyof T]: K extends keyof U
    ? IsAny<U[K]> extends true // Check if U[K] is `any`
      ? string // Force to string if U[K] is any
      : U[K] extends boolean | null | undefined
      ? boolean // Keep boolean type from U
      : U[K] extends string[]
      ? string[] // Keep string array type from U
      : string // Default to string otherwise
    : string; // Default to string if key K is not in U
};

export type Fields = { [key: string]: string };
export type FormikInitialValues<T> = { [key in keyof Partial<T>]: InputValue | undefined | null };

/**
 * Creates initial values for a form using the provided fields and defaults.
 * @param fields - An object representing the form fields and their corresponding keys.
 * @param defaults - An object containing default values for some or all of the form fields.
 * @returns The initial values object for the form.
 */
export function createInitialValues<T>(
  fields: { [key in keyof T]?: string },
  defaults?: { [key in keyof Partial<T>]: InputValue | undefined | null }
): T {
  const initialValues: { [key: string]: InputValue | undefined | null } = {};
  // See if we have a default value provided, otherwise use an empty string
  for (const key in fields) {
    initialValues[key] = defaults?.[key] !== undefined && defaults?.[key] !== null ? defaults[key] : '';
  }
  return initialValues as T; // Type assertion to return the correct type
}

/**
 *   The possible value types for an input
 */
// Most will be strings, but checkboxes are boolean and multi-selects are string[]
type InputValue = string | string[] | boolean;
type FormValues = { [key: string]: InputValue };

export enum InputVariant {
  Default = 'default',
  Drawer = 'drawer',
}

/**
 * Props for the FormInput component.
 * @param name - The name of the input, which must match the key of the values object.
 * @param requiredFields - An array of keys that are required.
 * @param formFields - An object that matches keys to form labels.
 * @param type - The type of input, such as 'text' or 'number'.
 * @param as - The type of input, such as 'textarea'.
 */
type FormInputProps = InputProps &
  Pick<FormikProps<FormValues>, 'values' | 'handleChange'> & {
    InputComponent?: React.ElementType;
    name: string;
    requiredFields: string[];
    formFields: { [key: string]: string };
    type?: string;
    as?: string;
    variant?: InputVariant;
  };

/**
 * Props for the FormSelect component.
 * @typedef {Object} FormSelectProps
 * @property {FormInputProps} - Props for the FormInput component.
 * @property {Object<string, string> | { id: string; label: string }[]} options - Options for the dropdown.
 */
type FormSelectProps = FormInputProps & {
  options: { [key: string]: string } | { id: string; label: string }[];
};

type FormRadioGroupProps = FormInputProps & {
  options: { id: string | boolean; label: string }[];
};

type FormMultiSelectAutocompleteProps = Omit<FormSelectProps, 'options'> & {
  options: string[];
  limitTags?: number;
  placeholder?: string;
  freeSolo: boolean;
};

export const FormikInput: React.FC<FormInputProps> = ({
  name,
  requiredFields,
  handleChange,
  values,
  formFields,
  InputComponent = FormInputBase,
  type,
  required,
  disabled,
  variant = InputVariant.Default,
  placeholder,
}) => {
  const isRequired = useMemo(() => {
    return required !== undefined ? required : requiredFields.includes(name);
  }, [name, required, requiredFields]);

  useEffect(() => {
    const key = name as keyof typeof values;
    if (type === 'date' && values[key]) {
      const newDate = dayjs(values[key].toString()).format('YYYY-MM-DD');
      handleChange({ target: { name, value: newDate } });
    } else if (values[key] === undefined) {
      handleChange({ target: { name, value: '' } });
    }
  }, [values, handleChange, name, type]);

  return (
    <Container>
      <PaddedLabel required={isRequired}>{formFields[name]}</PaddedLabel>
      <InputComponent
        name={name}
        required={isRequired}
        disableUnderline
        value={values[name as keyof typeof values].toString()}
        onChange={handleChange}
        type={type}
        disabled={disabled}
        $variant={variant}
        placeholder={placeholder}
      />
    </Container>
  );
};

export const FormikTextArea: React.FC<FormInputProps> = ({
  name,
  requiredFields,
  handleChange,
  values,
  formFields,
  InputComponent = FormInputBase,
  multiline,
  rows,
  disabled,
}) => {
  const isRequired = useMemo(() => requiredFields.includes(name), [name, requiredFields]);
  useEffect(() => {
    if (values[name as keyof typeof values] === undefined) {
      handleChange({ target: { name, value: '' } });
    }
  });
  return (
    <Container>
      <PaddedLabel required={isRequired}>{formFields[name]}</PaddedLabel>
      <InputComponent
        name={name}
        required={isRequired}
        disableUnderline
        value={values[name as keyof typeof values].toString()}
        onChange={handleChange}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
      />
    </Container>
  );
};

export const FormikSelect: React.FC<FormSelectProps> = ({
  name,
  requiredFields,
  handleChange,
  values,
  formFields,
  options,
  InputComponent = FormSelectBase,
  disabled,
  variant,
}) => {
  const isRequired = useMemo(() => requiredFields.includes(name), [name, requiredFields]);
  useEffect(() => {
    if (values[name as keyof typeof values] === undefined) {
      handleChange({ target: { name, value: '' } });
    }
  });

  const dropdownOptions = useMemo(() => {
    return Array.isArray(options)
      ? options
      : Object.entries(options).map(([key, value]) => ({ id: key, label: value }));
  }, [options]);

  return (
    <Container>
      <PaddedLabel required={isRequired}>{formFields[name]}</PaddedLabel>
      <InputComponent
        name={name}
        required={isRequired}
        disableUnderline
        value={values[name as keyof typeof values]?.toString()}
        onChange={handleChange}
        IconComponent={ExpandMoreIcon}
        displayEmpty
        disabled={disabled}
        sx={variant === InputVariant.Drawer ? { backgroundColor: 'input.main' } : {}}
      >
        <SelectItem value='' disabled>
          Select {formFields[name]}...
        </SelectItem>
        {dropdownOptions.map(({ id, label }) => (
          <SelectItem key={id} value={id}>
            {label}
          </SelectItem>
        ))}
      </InputComponent>
    </Container>
  );
};

export const FormikMultiSelectAutocomplete: React.FC<FormMultiSelectAutocompleteProps> = ({
  name,
  values,
  handleChange,
  options,
  limitTags,
  placeholder,
  freeSolo,
  InputComponent = StyledAutocomplete,
}) => {
  useEffect(() => {
    if (values[name as keyof typeof values] === undefined) {
      handleChange({ target: { name, value: [] } });
    }
  });

  const _handleChange = (_: SyntheticEvent<Element, Event>, values: string[]) => {
    handleChange({ target: { name, value: values } });
  };

  // Affirms that this is an array to keep typescript happy
  const selectedValues = useMemo(() => {
    const value = values[name as keyof typeof values];
    return Array.isArray(value) ? value : [];
  }, [name, values]);

  return (
    <Container>
      <PaddedLabel>{name}</PaddedLabel>
      <InputComponent
        options={options ?? []}
        freeSolo={freeSolo}
        renderInput={(params: Object) => <TextField {...params} placeholder={placeholder} />}
        multiple={true}
        filterSelectedOptions={true}
        size='small'
        value={selectedValues}
        onChange={_handleChange}
        limitTags={limitTags}
        ListboxComponent={DropdownOptions}
      />
    </Container>
  );
};

export const FormikCheckbox: React.FC<Omit<FormInputProps, 'InputComponent'>> = ({
  name,
  requiredFields,
  handleChange,
  values,
  formFields,
  variant,
}) => {
  const theme = useTheme();

  const _handleChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleChange({ target: { name, value: checked } });
  };
  useEffect(() => {
    if (values[name as keyof typeof values] === undefined || values[name as keyof typeof values] === '') {
      handleChange({ target: { name, value: false } });
    }
  });

  const LabelComponent = variant === 'drawer' ? StyledCheckboxLabel : PaddedFCLabel;

  return (
    <LabelComponent
      required={requiredFields.includes(name)}
      onChange={_handleChange}
      checked={values[name as keyof typeof values] === true}
      control={
        variant === 'drawer' ? (
          <StyledCheckbox name={name} />
        ) : (
          <Checkbox
            name={name}
            sx={{
              '&.Mui-checked': {
                color: theme.colors.blue,
              },
            }}
          />
        )
      }
      label={formFields[name]}
    />
  );
};

export const FormikCheckboxGroup: React.FC<Omit<FormInputProps, 'InputComponent'> & { options: string[] }> = ({
  name,
  handleChange,
  values,
  options,
  formFields,
}) => {
  useEffect(() => {
    if (values[name as keyof typeof values] === undefined) {
      handleChange({ target: { name, value: [] } });
    }
  });

  const _handleChange = (event: React.ChangeEvent<HTMLInputElement>, option: string) => {
    const newValues = event.target.checked
      ? [...selectedValues, option]
      : [...selectedValues.filter((value) => value !== option)];
    handleChange({ target: { name, value: newValues } });
  };

  // Affirms that this is an array to keep typescript happy
  const selectedValues = useMemo(() => {
    const value = values[name as keyof typeof values];
    return Array.isArray(value) ? value : [];
  }, [name, values]);

  return (
    <Container sx={{ paddingTop: 2 }}>
      <InputLabel>{formFields[name]}</InputLabel>
      <Flex column>
        {options.map((option) => (
          <StyledCheckboxLabel
            key={option}
            control={
              <StyledCheckbox
                checked={selectedValues.includes(option)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => _handleChange(event, option)}
                name={option}
              />
            }
            label={option}
          />
        ))}
      </Flex>
    </Container>
  );
};

export const FormikRadioGroup = ({
  name,
  requiredFields,
  handleChange,
  values,
  formFields,
  options,
  disabled,
}: FormRadioGroupProps) => {
  const theme = useTheme();

  return (
    <Container>
      <PaddedLabel required={requiredFields.includes(name)}>{formFields[name]}</PaddedLabel>

      <RadioGroup name={name} value={values[name]} onChange={handleChange}>
        {options.map((opt) => (
          <StyledCheckboxLabel
            key={opt.id}
            value={opt.id}
            label={opt.label}
            control={
              <Radio
                disabled={disabled}
                sx={{
                  '&.Mui-checked': {
                    color: theme.colors.blue,
                  },
                }}
              />
            }
          />
        ))}
      </RadioGroup>
    </Container>
  );
};

type RichTextInputProps = Omit<FormInputProps, 'InputComponent' | 'rows' | 'multiline'>;

const CKEditorContainer = styled('div')(({ theme }) => ({
  //Sets the base toolbar colors
  '& .ck.ck-editor__top > .ck-toolbar, .ck.ck-toolbar': {
    border: 'none',
    borderBottom: `1px solid ${theme.palette.background.default}`,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.input.main,
  },

  //Sets the toolbar button colors
  '& .ck.ck-toolbar .ck-button': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.input.main,
  },

  //Sets the main editor box colors:
  '& .ck.ck-editor__main > .ck-editor__editable': {
    border: 'none',
    borderBottomLeftRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.input.main,
  },

  '& .ck.ck-editor__main > .ck-editor__editable a': {
    textDecoration: 'underline',
  },
}));

export const FormikRichEditor: React.FC<RichTextInputProps> = ({
  name,
  requiredFields,
  handleChange,
  values,
  formFields,
  disabled,
}) => {
  const isRequired = useMemo(() => requiredFields.includes(name), [name, requiredFields]);
  const editorRef = useRef<ClassicEditor>();

  const handleEditorChange = useCallback(() => {
    handleChange({ target: { name, value: editorRef.current?.data.get() ?? '' } });
  }, [handleChange, name]);

  return (
    <Container>
      <PaddedLabel required={isRequired}>{formFields[name]}</PaddedLabel>
      <CKEditorContainer>
        <CKEditor
          editor={ClassicEditor}
          data={values[name].toString()}
          onChange={handleEditorChange}
          onReady={(editor: ClassicEditor) => (editorRef.current = editor)}
          disabled={disabled}
          config={{
            toolbar: {
              items: [
                'undo',
                'redo',
                '|',
                'heading',
                'fontSize',
                'fontColor',
                '|',
                'bold',
                'italic',
                'numberedList',
                'bulletedList',
              ],
            },
          }}
        />
      </CKEditorContainer>
    </Container>
  );
};
