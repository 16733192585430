import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import Flex from 'components/Flex/Flex';
import { ItemSearch } from './components/ItemSearch/ItemSearch';
import { useSubNav } from 'layout/Layout';
import add from 'assets/lottie/add.json';

const ItemsSearch: React.FC = () => {
  const navigate = useNavigate();
  const [, setSubNavItems] = useSubNav();

  useEffect(() => {
    setSubNavItems([
      {
        type: 'icon',
        tooltip: 'New item',
        imageData: add,
        onClick: () => navigate('/create-item'),
      },
    ]);

    return () => setSubNavItems([]);
  }, [setSubNavItems, navigate]);

  return (
    <Container maxWidth={false}>
      <Flex w100 styles={{ justifyContent: 'space-between' }}>
        <PageHeader variant='h1' header='Items' />
      </Flex>
      <ItemSearch />
    </Container>
  );
};

export default ItemsSearch;
