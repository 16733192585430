import React from 'react';
import styled, { css } from 'styled-components';
import { FormControlLabel } from '@mui/material';

const StyledLabel = styled(FormControlLabel)(
  () => css`
    grid-column-gap: 0.5rem;
    text-transform: uppercase;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    padding-left: 0;
    display: flex;

    span {
      font-size: 0.75rem;
      font-weight: 600;
    }
  `
);

export default StyledLabel;
