import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetQuoteByNumberDocument,
  GetQuoteByNumberQuery,
  GetQuoteByNumberQueryVariables,
  QueryGetQuoteByNumberArgs,
} from '__generated__/graphql';

export const useQueryGetQuoteByNumber = (
  input: QueryGetQuoteByNumberArgs,
  options: QueryHookOptions<GetQuoteByNumberQuery, GetQuoteByNumberQueryVariables>
): QueryResult<GetQuoteByNumberQuery, GetQuoteByNumberQueryVariables> => {
  return useQuery(GetQuoteByNumberDocument, {
    ...options,
    variables: input,
  });
};
