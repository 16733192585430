import React, { useEffect } from 'react';
import { css, styled } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { Button } from '@mui/material';
import { generateFakeResults } from './utils/sample-search-results';
import PageHeader from 'components/PageHeader/PageHeader';
import AlertTooltip from 'components/AlertTooltip/AlertTooltip';

const SearchWrapper = styled('div')(
  () => css`
    max-width: 940px;
    margin: 0 auto;

    & .MuiTypography-h1 {
      font-size: 4rem;
      margin-bottom: 0;
      padding-bottom: 0.5rem;
    }
  `
);

const SearchBar = styled(FormControl)(
  ({ theme }) => css`
    width: 100%;
    & .MuiInputLabel-root {
      color: ${theme.colors.white};
      font-size: 1rem;
      font-weight: 600;
      transform: none;
    }

    & .MuiInputBase-input {
      margin-top: ${theme.spacing(3.5)};
      position: relative;
      color: ${theme.colors.lightBlack};
      background-color: ${theme.colors.white};
      font-size: 0.9rem;
      width: 100%;
      padding: 0.5rem 0.75rem;
      transition: border-color, background-color, box-shadow;
    }
  `
);

const SearchButton = styled(Button)(
  ({ theme }) => css`
    background-color: ${theme.colors.lightBlue};
    color: ${theme.colors.white};
    text-transform: none;
    font-weight: unset;
    margin: 0.6125rem 0;
    padding: 0.5rem 1rem;
    border-radius: 0.125rem;

    &:hover {
      background-color: ${theme.colors.lightBlue};
    }
  `
);

const SearchResult = styled('div')(
  () => css`
    font-weight: 500;
    line-height: 1.5;
  `
);

const Search: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = React.useState(searchParams.get('query'));
  const [searchResults, setSearchResults] = React.useState<{ title: string; description: string }[]>([]);
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);

  useEffect(() => {
    setSearchResults(generateFakeResults(searchParams.get('query') ?? ''));
  }, [searchParams]);

  const runSearch = () => {
    if (search === '') {
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 4000);
    } else {
      setSearchParams([['query', search ?? '']]);
    }
  };

  return (
    <SearchWrapper>
      <PageHeader variant='h1' header='Search results' />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          runSearch();
        }}
      >
        <AlertTooltip title={'Please fill out this field'} tooltipOpen={tooltipOpen} setTooltipOpen={setTooltipOpen}>
          <SearchBar variant='standard' onSubmit={runSearch}>
            <InputLabel shrink htmlFor='bootstrap-input'>
              Search
            </InputLabel>
            <InputBase
              fullWidth
              placeholder='Search...'
              value={search ?? ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(event.target.value);
              }}
            />
          </SearchBar>
        </AlertTooltip>
        <SearchButton type='submit' variant='contained' size='large' onClick={runSearch}>
          Search
        </SearchButton>
      </form>
      <div>
        {searchResults.map((result, index) => (
          <SearchResult key={index}>
            <a href={'/sample-result'}>{result.title}</a>
            <div>{`${location.host}/sample-result`}</div>
            <div>{`${result.description}...`}</div>
          </SearchResult>
        ))}
      </div>
    </SearchWrapper>
  );
};

export default Search;
