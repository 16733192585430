import React, { CSSProperties } from 'react';
import LottieAnimation from 'components/LottieAnimation/LottieAnimation';
import NavButton from 'components/NavButton/NavButton';

type Props = {
  animationData: unknown;
  active?: boolean;
  style?: CSSProperties;
  speed?: number;
  onClick?: () => void;
  disabled?: boolean;
};

const LottieNavButton = React.forwardRef<HTMLDivElement, Props>(function LottieNavButton(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const { disabled, animationData, active, style, speed, onClick, ...remaining } = props;
  return (
    <NavButton disabled={disabled} onClick={onClick} $active={active} ref={ref} {...remaining}>
      <LottieAnimation animationData={animationData} style={style} speed={speed} />
    </NavButton>
  );
});

export default LottieNavButton;
