import React, { useCallback, useEffect, useMemo, useState, FocusEvent } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { css, styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormHeader from 'components/FormHeader/FormHeader';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GenericLookup, ISimpleLookup, quoteStatusOptions, shipViaOptions } from 'routes/Quote/utils/constants';
import FormInput from 'components/FormInput/FormInput';
import FormLabel from 'components/FormLabel/FormLabel';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Flex from 'components/Flex/Flex';
import { Box, Container, Grid, IconButton, Stack, TextField, TextFieldProps } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { Action, NOT_A_REAL_EMPLOYEE } from 'utils/constants';
import { ContactFormatted, CreateClientAddressInput, CustomListType, Employee, Quote } from '__generated__/graphql';
import { useQueryEmployeeReference } from 'hooks/queries/useQueryEmployeeReference/useQueryEmployeeReference';
import { useParams } from 'react-router-dom';
import { withoutTypename } from 'utils/functions';
import CreateClientContactModal from './components/CreateClientContactModal';
import NewAddressModal, { ShippingAddressMode } from './components/NewAddressModal';
import StyledLabel from 'components/StyledLabel/StyledLabel';
import StyledCheckbox from 'components/StyledCheckbox/StyledCheckbox';
import InfoIcon from '@mui/icons-material/Info';
import useMutationCreateAddress from 'hooks/mutations/useMutationCreateAddress/useMutationCreateAddress';
import { useQueryGetCustomList } from 'hooks/queries/useQueryGetCustomList/useQueryGetCustomList';
import { useQueryGetDepartments } from 'hooks/queries/useQueryGetDepartments/useQueryGetDepartments';
import { useQueryGetTerms } from 'hooks/queries/useQueryGetTerms/useQueryGetTerms';
import { Tooltip } from 'components/Tooltip/Tooltip';
import Button from 'components/Button/Button';

dayjs.extend(utc);

const StyledInfoIcon = styled(InfoIcon)(
  () => css`
    font-size: 1rem;
    margin-left: 0.5rem;
  `
);

export const FormSelect = styled(Select)(
  ({ theme }) => css`
    width: 100%;
    min-height: 3rem;
    height: 3rem;
    background-color: ${theme.colors.charcoal};
    border: none;
    border-radius: 0.5rem;
    margin-bottom: 0;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;

    input {
      font-size: 0.875rem;
    }

    .addressLabelContainer {
      padding: 1rem 0;
    }
  `
);

export const SelectItem = styled(MenuItem)(
  () => css`
    font-size: 0.875rem;
  `
);

const AddressLabelContainer = styled('div')(
  () => css`
    width: 100%;
    padding: 0.375rem 1rem;
  `
);

AddressLabelContainer.defaultProps = {
  className: 'addressLabelContainer',
};

const RelativeGrid = styled(Grid)(
  () => css`
    position: relative;
  `
);

export const Memo: React.FC<TextFieldProps> = styled(TextField)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.primary.main};
    border: none;
    border-radius: 0.5rem;
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;

    &:focus {
      outline: none;
    }
  `
);

function getDateXDaysFromNow(days: number) {
  const today = new Date();
  return new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
}

function getExpectedCloseDate(quoteType: 'Immediate Buy' | 'Bid' | 'Budgetary') {
  switch (quoteType) {
    case 'Immediate Buy':
      return dayjs.utc().add(14, 'day').format('YYYY-MM-DD');
    case 'Bid':
      return dayjs.utc().add(45, 'day').format('YYYY-MM-DD');
    case 'Budgetary':
      return dayjs.utc().add(60, 'day').format('YYYY-MM-DD');
  }
}

function getFollowUpDate(quoteType: 'Immediate Buy' | 'Bid' | 'Budgetary') {
  switch (quoteType) {
    case 'Immediate Buy':
      return dayjs.utc().add(5, 'day').format('YYYY-MM-DD');
    case 'Bid':
      return dayjs.utc().add(30, 'day').format('YYYY-MM-DD');
    case 'Budgetary':
      return dayjs.utc().add(45, 'day').format('YYYY-MM-DD');
  }
}

type FormikValues = Omit<Quote, 'client'> & {
  clientContact?: { id?: string; email?: string; phone?: string };
  client?: ContactFormatted;
  estimatedShippingCost?: number;
};

type Props = {
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
};

const ProjectDetails: React.FC<Props> = ({ setTabValue }) => {
  const [showCreateClient, setShowCreateClient] = useState<boolean>(false);
  const [showShippingAddressModal, setShowShippingAddressModal] = useState<boolean>(false);
  const [shippingAddressMode, setShippingAddressMode] = useState<ShippingAddressMode>(ShippingAddressMode.Custom);

  const {
    quote,
    setQuote,
    quoteInput,
    setQuoteInput,
    clientData,
    clientDataRefetch,
    clientDataLoading,
    setModifiedFields,
    setEditContactInput,
    editContactInput,
    setSnackbarMessage,
    setShowSnackbar,
  } = useQuoteContext();

  const { can } = useAuthContext();
  const { data: employeeReferenceData } = useQueryEmployeeReference();
  const [createAddress, { loading: addressLoading, error: addressError }] = useMutationCreateAddress();

  // Create freight terms lookup
  const { data: freightTermsListData } = useQueryGetCustomList({ id: CustomListType.FreightTerms });
  const freightTermsOptions = useMemo(() => {
    const options: { [key: string]: string } = {};
    freightTermsListData?.getCustomList?.customValueList?.customValue?.forEach((opt) => {
      options[opt.valueId] = opt.value;
    });
    return new GenericLookup(options);
  }, [freightTermsListData]);

  // Create quote type lookup
  const { data: quoteTypeListData } = useQueryGetCustomList({ id: CustomListType.QuoteType });
  const quoteTypeOptions = useMemo(() => {
    const options: { [key: string]: string } = {};
    quoteTypeListData?.getCustomList?.customValueList?.customValue?.forEach((opt) => {
      options[opt.valueId] = opt.value;
    });
    return new GenericLookup(options);
  }, [quoteTypeListData]);

  // Create terms lookup
  const { data: termsData } = useQueryGetTerms();
  const termOptions = useMemo(() => {
    const options: { [key: string]: string } = {};
    termsData?.getTerms.forEach((term) => {
      options[term.id] = term.name;
    });
    return new GenericLookup(options);
  }, [termsData]);

  const defaultTerms = termsData?.getTerms.find((term) => term.isPreferred) ?? null;

  // Create market lookup
  const { data: departmentsData } = useQueryGetDepartments();
  const marketOptions = useMemo(() => {
    const options: { [key: string]: string } = {};
    departmentsData?.getDepartments.forEach((department) => {
      options[department.id] = department.parent?.name
        ? `${department.parent?.name} : ${department.name}`
        : `${department.name}`;
    });
    return new GenericLookup(options);
  }, [departmentsData]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuote({ ...(quote as Quote), visibleToCustomer: event.target.checked });
  };

  const handleTaxableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuote({ ...(quote as Quote), taxIncluded: event.target.checked });
  };

  const [salesReps, salesCoordinators] = useMemo(() => {
    if (employeeReferenceData) {
      const reducer = (acc: { [key: string]: Employee }, employee: Employee) => {
        acc[employee.id] = employee;
        return acc;
      };
      return [
        employeeReferenceData.employeeReference.salesReps.reduce(reducer, {}),
        employeeReferenceData.employeeReference.salesCoordinators.reduce(reducer, {}),
      ];
    }
    return [{}, {}];
  }, [employeeReferenceData]);

  //Since there's no separate view page we'll do view here
  //and gate teh actual save functionality on edit permission
  const canEdit = useMemo(() => {
    return can('Quotation', Action.Edit);
  }, [can]);

  // Get addresses from clientData
  const clientAddresses = useMemo(() => {
    if (!clientData) return [];

    const addresses = clientData?.addressbooklist?.addressbook;

    // Join addr + city + state + zip
    return addresses?.flatMap((address) => {
      const addr1 = address?.addressbookAddress?.addr1 || '';
      const addr2 = address?.addressbookAddress?.addr2 || '';
      const addr3 = address?.addressbookAddress?.addr3 || '';
      const city = address?.addressbookAddress?.city;
      const state = address?.addressbookAddress?.state;
      const zip = address?.addressbookAddress?.zip;

      if (addr1 || addr2 || addr3) {
        const formattedAddress = `${addr1} ${addr2} ${addr3} ${city}, ${state} ${zip}`;

        return { ...address, formattedAddress };
      } else {
        return [];
      }
    });
  }, [clientData]);

  const contacts = clientData?.contacts || [];
  const firstContact = contacts[0];

  const { quoteNumber } = useParams();

  // This function will parse a date string that was saved with a GMT offset
  // 1. The call for dayjs.utc will parse without trying to convert to local time.
  // 2. Dates already saved with a US Timezone will be after midnight. The time will be dropped so no further steps needed
  // 3. If a date was saved with a positive GMT offset, we'd see an hour value above 12.  We need to add a day to the date since would've gone into the previous day
  const tryParseGMTDate = (date: string) => {
    let parsedDate = dayjs.utc(date);
    if (parsedDate.isValid()) {
      if (parsedDate.hour() >= 12) {
        parsedDate = parsedDate.add(1, 'day');
      }
      return parsedDate.format('YYYY-MM-DD');
    }
    return '';
  };

  const initialFormValues = useMemo((): Partial<FormikValues> => {
    const salesRep = quote?.id && !quote?.salesRep ? { id: NOT_A_REAL_EMPLOYEE } : quote?.salesRep;

    const defaultShippingAddressId = clientData?.addressbooklist?.addressbook?.find(
      (address) => address.defaultShipping
    )?.internalId;

    let shippingAddressId =
      quote?.shippingAddressList?.id ||
      defaultShippingAddressId ||
      clientData?.addressbooklist?.addressbook?.[0]?.internalId;

    if (!quote?.shippingAddressList?.id && quote?.shippingAddress?.id) {
      const shippingAddress = clientData?.addressbooklist?.addressbook?.find(
        (address) => address?.addressbookAddress?.addrText === quote?.shippingAddress?.addressText
      );
      if (shippingAddress?.internalId) {
        shippingAddressId = shippingAddress.internalId;
      }
    }

    if (quote && !quote?.shippingAddressList?.id && defaultShippingAddressId && !quote?.shippingAddress?.id) {
      setQuote({
        ...quote,
        shippingAddressList: {
          ...quote.shippingAddressList,
          id: shippingAddressId ?? '',
        },
      });
    }

    const contact = clientData?.contacts?.find((contact) => contact.id === quote?.contact?.id) ?? firstContact;
    if (!editContactInput && contact) {
      setEditContactInput({
        id: contact.id,
        client: clientData?.id,
        email: contact?.email,
        phone: contact?.phone,
      });
    }
    return {
      ...quote,
      startDate: tryParseGMTDate(quote?.startDate),
      dueDate: tryParseGMTDate(quote?.dueDate),
      expectedClose: tryParseGMTDate(quote?.expectedClose),
      followUpDate: tryParseGMTDate(quote?.followUpDate),
      salesRep,
      shippingAddressList: {
        ...quote?.shippingAddressList,
        id: shippingAddressId ?? '',
      },
      client: withoutTypename(quote?.client),
      clientContact: { id: contact?.id ?? '', email: contact?.email ?? '', phone: contact?.phone ?? '' },
    };
  }, [quote, firstContact, setQuote, editContactInput, setEditContactInput, clientData]);

  const formik = useFormik({
    initialValues: initialFormValues as Required<FormikValues>,
    onSubmit: (values) => {
      if (canEdit) {
        setQuote({ ...values, client: { id: values.client.id, name: values.client.name } });
      }
      formik.setSubmitting(false);
    },
  });

  const handleProjectDetailsChange = useCallback(
    (field: keyof FormikValues | keyof Quote, newValue: unknown) => {
      setModifiedFields((prev) => ({ ...prev, areProjectDetailsModified: true }));
      // @ts-ignore
      setQuote((prev) => ({ ...prev, [field]: newValue }));
      return formik.setFieldValue(field, newValue);
    },
    [formik, setQuote, setModifiedFields]
  );

  useEffect(() => {
    const contact = clientData?.contacts?.find((x) => x.name === (quote?.contact || firstContact)?.name);
    // API not returning contact's ID right now on quote, but we have the name and clientData has the contact with ID.
    if (!formik.values.contact?.id && (quote?.contact || firstContact) && contact) {
      setQuote((prev) => (prev ? { ...prev, contact: withoutTypename(contact) } : undefined));
      formik.setFieldValue('contact', contact);
    }

    if (
      clientData &&
      ((clientData.phone && !formik.values.client.phone) || (clientData.email && !formik.values.client.email))
    ) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        client: {
          ...withoutTypename(clientData),
          ...prevValues.client,
          id: prevValues.client?.id || clientData?.id || '',
        },
      }));

      setQuote((prevValues) => ({
        ...prevValues,
        id: prevValues?.id || '',
        quoteNumber: prevValues?.quoteNumber || '',
        client: {
          ...withoutTypename(clientData),
          ...prevValues?.client,
          id: prevValues?.client?.id || clientData?.id || '',
        },
      }));
    }

    // If new quote, set startDate, dueDate, terms, salesRep, and market
    if (
      !quoteNumber &&
      clientData &&
      (!formik.values.startDate ||
        !formik.values.dueDate ||
        !formik.values.terms ||
        !formik.values.salesRep ||
        !formik.values.market)
    ) {
      const startDate = dayjs(new Date()).format('YYYY-MM-DD');
      const dueDate = dayjs(getDateXDaysFromNow(30)).format('YYYY-MM-DD');
      const terms = clientData.terms ? { id: clientData.terms.id, name: clientData.terms.name } : { id: '', name: '' };
      const salesRep =
        clientData.salesRep?.id && clientData.salesRep?.fullName
          ? { id: clientData.salesRep.id, name: clientData.salesRep.fullName }
          : { id: '', name: '' };
      const salesCoordinator =
        clientData.salesCoordinator?.id && clientData.salesCoordinator?.fullName
          ? { id: clientData?.salesCoordinator.id, name: clientData?.salesCoordinator?.fullName }
          : { id: '', name: '' };
      const market =
        clientData.salesRep?.department?.id && clientData.salesRep?.department?.name
          ? { id: clientData?.salesRep?.department?.id, name: clientData?.salesRep?.department?.name }
          : { id: '', name: '' };

      formik.setValues((prevValues) => ({
        ...prevValues,
        quoteNumber: prevValues?.quoteNumber || '',
        startDate,
        terms,
        salesRep,
        salesCoordinator,
        market,
        dueDate,
        taxIncluded: clientData.customerTaxable === true,
      }));

      setQuote((prevValues) => ({
        ...prevValues,
        id: prevValues?.id || '',
        quoteNumber: prevValues?.quoteNumber || '',
        startDate,
        terms,
        salesRep,
        salesCoordinator,
        market,
        dueDate,
        taxIncluded: clientData.customerTaxable === true,
      }));
    }
  }, [
    quoteNumber,
    clientData,
    firstContact,
    formik,
    handleProjectDetailsChange,
    quote?.contact,
    quote?.freightTerms,
    setQuote,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedFields((prev) => ({ ...prev, areProjectDetailsModified: true }));
    formik.setFieldValue(event.target.name, event.target.value);
    setQuote((prev) => (prev ? { ...prev, [event.target.name]: event.target.value } : undefined));
  };

  const handleNumericChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedFields((prev) => ({ ...prev, areProjectDetailsModified: true }));
    formik.setFieldValue(event.target.name, Number(event.target.value));
    setQuote((prev) => (prev ? { ...prev, [event.target.name]: Number(event.target.value) } : undefined));
  };

  const createNewContact = 'create-new-contact';

  const changeContact = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === createNewContact) {
      setShowCreateClient(true);
      return;
    }

    const contact = contacts?.find((contact) => contact?.id === event.target.value);
    handleProjectDetailsChange('contact', { id: contact?.id, name: contact?.name });
    if (contact?.email && contact?.phone) {
      handleProjectDetailsChange('client', { ...formik.values.client, email: contact.email, phone: contact.phone });
    } else if (contact?.phone) {
      handleProjectDetailsChange('client', { ...formik.values.client, phone: contact.phone });
    } else if (contact?.email) {
      handleProjectDetailsChange('client', { ...formik.values.client, email: contact.email });
    }
    if (contact) {
      setEditContactInput({
        id: contact.id,
        client: clientData?.id,
        email: contact.email,
        phone: contact.phone,
      });
      formik.setFieldValue('clientContact', { id: contact.id, phone: contact.phone, email: contact.email });
    }
  };

  const addNewAddress = 'add-new-address';
  const setCustomAddress = '-2';

  const changeShippingAddress = (event: SelectChangeEvent) => {
    if (event.target.value === addNewAddress || event.target.value === setCustomAddress) {
      const mode = event.target.value === addNewAddress ? ShippingAddressMode.New : ShippingAddressMode.Custom;
      setShippingAddressMode(mode);
      setShowShippingAddressModal(true);
      if (event.target.value === setCustomAddress) {
        handleProjectDetailsChange('shippingAddressList', { id: setCustomAddress, name: '' });
      }
      return;
    } else if (quoteInput.shippingAddress) {
      setQuoteInput((prev) => {
        delete prev.shippingAddress;
        return prev;
      });
    }

    const val = clientAddresses?.find((address) => address.internalId === event.target.value);

    handleProjectDetailsChange('shippingAddressList', { id: val?.internalId, name: val?.label });
  };

  const snackWrap = (message: string) => {
    setShowSnackbar(true);
    setSnackbarMessage(message);
  };

  const submitShippingAddress = (addressInput: CreateClientAddressInput) => {
    if (shippingAddressMode === ShippingAddressMode.New) {
      snackWrap('Creating address...');
      createAddress({ variables: { input: addressInput } })
        .then(async () => {
          const updatedClient = await clientDataRefetch();
          const existingAddressIds = clientData?.addressbooklist?.addressbook?.map((address) => address.internalId);
          const newAddress = updatedClient.data?.getClient?.addressbooklist?.addressbook?.find((address) => {
            return !existingAddressIds?.includes(address.internalId);
          });
          if (newAddress) {
            handleProjectDetailsChange('shippingAddressList', { id: newAddress.internalId, name: newAddress.label });
          }
          snackWrap('Address created successfully');
          setShowShippingAddressModal(false);
        })
        .catch(() => {
          snackWrap('Failed creating address');
        });
    } else if (shippingAddressMode === ShippingAddressMode.Custom) {
      setQuoteInput((prev) => ({ ...prev, shippingAddress: addressInput }));
      setShowShippingAddressModal(false);
    }
  };

  const handleLookupChange = (event: React.ChangeEvent<HTMLInputElement>, lookup: ISimpleLookup) => {
    setModifiedFields((prev) => ({ ...prev, areProjectDetailsModified: true }));
    formik.setFieldValue(event.target.name, {
      id: event.target.value,
      name: lookup.getValue(event.target.value),
    });
    // @ts-ignore
    setQuote((prev) => ({
      ...prev,
      [event.target.name]: {
        id: event.target.value,
        name: lookup.getValue(event.target.value),
      },
    }));
  };

  const handleQuoteTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedFields((prev) => ({ ...prev, areProjectDetailsModified: true }));

    const quoteType = quoteTypeOptions.getValue(event.target.value);

    formik.setFieldValue(event.target.name, {
      id: event.target.value,
      name: quoteType,
    });

    if (quoteType === 'Immediate Buy' || quoteType === 'Bid' || quoteType === 'Budgetary') {
      const expectedClose = getExpectedCloseDate(quoteType);
      formik.setFieldValue('expectedClose', expectedClose);
      setQuote((prev) =>
        prev
          ? {
              ...prev,
              [event.target.name]: {
                id: event.target.value,
                name: quoteType,
              },
              expectedClose,
            }
          : undefined
      );
    }

    if (quoteType === 'Immediate Buy' || quoteType === 'Bid' || quoteType === 'Budgetary') {
      const followUpDate = getFollowUpDate(quoteType);
      formik.setFieldValue('followUpDate', followUpDate);
      setQuote((prev) =>
        prev
          ? {
              ...prev,
              [event.target.name]: {
                id: event.target.value,
                name: quoteType,
              },
              followUpDate,
            }
          : undefined
      );
    }
  };

  return (
    <Flex w100 center>
      <LoadingSpinner loading={clientDataLoading}>
        {showCreateClient && (
          <CreateClientContactModal
            modalOpen={showCreateClient}
            setModalOpen={setShowCreateClient}
            title='Create Client Contact'
          />
        )}
        {showShippingAddressModal && (
          <NewAddressModal
            modalOpen={showShippingAddressModal}
            setModalOpen={setShowShippingAddressModal}
            title='New Shipping Address'
            loading={addressLoading}
            error={addressError}
            onSubmit={submitShippingAddress}
            mode={shippingAddressMode}
            values={quoteInput?.shippingAddress ?? quote?.shippingAddress}
          />
        )}
        <Container>
          <Grid container spacing={4}>
            {/*Left Side*/}
            <Grid item xs={12} tablet={6}>
              <Stack spacing={4} mb={1.5}>
                <FormHeader variant='h3' header='Client' />
                <Box>
                  <FormLabel required>Client Contact</FormLabel>
                  <FormSelect
                    name='contact'
                    value={formik.values.contact?.id ?? ''}
                    onChange={changeContact}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    disabled={!canEdit || formik.isSubmitting}
                  >
                    <SelectItem value={''} disabled>
                      Select client contact...
                    </SelectItem>
                    <SelectItem value={createNewContact}>+ Create new...</SelectItem>
                    <Divider />
                    {contacts?.map((option) => (
                      <SelectItem key={option?.name} value={option?.id}>
                        {option?.name}
                      </SelectItem>
                    ))}
                  </FormSelect>
                </Box>
                <Box>
                  <FormLabel required>Contact Phone</FormLabel>
                  <FormInput
                    name='phone'
                    maxLength='3'
                    value={editContactInput?.phone ?? formik.values.clientContact?.phone}
                    onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setModifiedFields((prev) => ({ ...prev, areProjectDetailsModified: true }));
                      setEditContactInput((prev) => (prev ? { ...prev, phone: event.target.value } : prev));
                      formik.setFieldValue('clientContact', {
                        ...formik.values.clientContact,
                        phone: event.target.value,
                      });
                    }}
                    onBlur={() => {
                      if (!editContactInput?.phone) {
                        setEditContactInput(undefined);
                      }
                    }}
                    disableUnderline
                    placeholder='Enter contact phone number... '
                    disabled={!canEdit || formik.isSubmitting}
                  ></FormInput>
                </Box>
                <Box>
                  <FormLabel required>Contact Email</FormLabel>
                  <FormInput
                    type='email'
                    name='contactEmail'
                    value={editContactInput?.email ?? formik.values.clientContact?.email}
                    onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setModifiedFields((prev) => ({ ...prev, areProjectDetailsModified: true }));
                      setEditContactInput((prev) => (prev ? { ...prev!, email: event.target.value } : undefined));
                      formik.setFieldValue('clientContact', {
                        ...formik.values.clientContact,
                        email: event.target.value,
                      });
                    }}
                    onBlur={() => {
                      if (!editContactInput?.email) {
                        setEditContactInput(undefined);
                      }
                    }}
                    disableUnderline
                    placeholder='Enter contact Email... '
                    disabled={!canEdit || formik.isSubmitting}
                  ></FormInput>
                </Box>
              </Stack>

              <Grid container spacing={4}>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <FormHeader variant='h3' header='Project' />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel required>Quote Number</FormLabel>
                  <FormInput
                    name='quoteNumber'
                    value={formik.values.quoteNumber}
                    onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
                    onChange={handleChange}
                    disableUnderline
                    placeholder='Enter Quote Number...'
                    disabled={!canEdit || formik.isSubmitting}
                  ></FormInput>
                  <StyledLabel
                    control={
                      <StyledCheckbox checked={quote?.visibleToCustomer === true} onChange={handleCheckboxChange} />
                    }
                    label={'Available in Client Portal'}
                  />
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel>Project Name</FormLabel>
                  <FormInput
                    name='projectName'
                    value={formik.values.projectName}
                    onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
                    onChange={handleChange}
                    disableUnderline
                    placeholder='Enter Project Name...'
                    disabled={!canEdit || formik.isSubmitting}
                  ></FormInput>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel required>Quote type</FormLabel>
                  <FormSelect
                    name='quoteType'
                    value={formik.values.quoteType?.id || ''}
                    onChange={handleQuoteTypeChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    disabled={!canEdit || formik.isSubmitting}
                  >
                    <SelectItem value='' disabled>
                      Select quote type...
                    </SelectItem>
                    {quoteTypeOptions.entries.map(([key, value]) => (
                      <SelectItem key={key} value={key}>
                        {value}
                      </SelectItem>
                    ))}
                  </FormSelect>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel required>Select Quote Status</FormLabel>
                  <FormSelect
                    name='status'
                    value={formik.values.status?.id || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleLookupChange(event, quoteStatusOptions)
                    }
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    disabled={!canEdit || formik.isSubmitting}
                  >
                    <SelectItem value='' disabled>
                      Select Quote Status...
                    </SelectItem>
                    {quoteStatusOptions.entries.map(([key, value]) => (
                      <SelectItem key={key} value={key}>
                        {value}
                      </SelectItem>
                    ))}
                  </FormSelect>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel>Follow Up Date</FormLabel>
                  <FormInput
                    type='date'
                    name='followUpDate'
                    value={formik.values.followUpDate || ''}
                    onChange={handleChange}
                    disableUnderline
                    placeholder='05/22/2023'
                    disabled={!canEdit || formik.isSubmitting}
                  ></FormInput>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel required>Sales Representative</FormLabel>
                  <FormSelect
                    name='salesRep'
                    value={(salesReps && formik.values.salesRep?.id) || ''}
                    onChange={(event: SelectChangeEvent) => {
                      const repName =
                        event.target.value === NOT_A_REAL_EMPLOYEE ? '' : salesReps[event.target.value].fullName;
                      handleProjectDetailsChange('salesRep', {
                        id: event.target.value,
                        names: repName,
                      });
                      setQuote((prev) =>
                        prev
                          ? {
                              ...prev,
                              salesRep: {
                                id: event.target.value,
                                name: repName,
                              },
                            }
                          : undefined
                      );
                    }}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    disabled={!canEdit || formik.isSubmitting}
                  >
                    <SelectItem value='' disabled>
                      Select a Sales Rep...
                    </SelectItem>
                    <SelectItem value={NOT_A_REAL_EMPLOYEE}>[ No Sales Rep ]</SelectItem>
                    {Object.values(salesReps)
                      .sort((a, b) => (a.fullName > b.fullName ? 1 : -1))
                      .map((option) => (
                        <SelectItem key={option.id} value={option.id}>
                          {option.fullName}
                        </SelectItem>
                      ))}
                  </FormSelect>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel required>Sales Coordinator</FormLabel>
                  <FormSelect
                    name='salesCoordinator'
                    value={(salesCoordinators && formik.values.salesCoordinator?.id) || ''}
                    onChange={(event: SelectChangeEvent) => {
                      handleProjectDetailsChange('salesCoordinator', {
                        id: event.target.value,
                        names: salesCoordinators[event.target.value].fullName,
                      });
                      setQuote((prev) =>
                        prev
                          ? {
                              ...prev,
                              salesCoordinator: {
                                id: event.target.value,
                                name: salesCoordinators[event.target.value].fullName,
                              },
                            }
                          : undefined
                      );
                    }}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    disabled={!canEdit || formik.isSubmitting}
                  >
                    <SelectItem value='' disabled>
                      Select a Sales Coordinator...
                    </SelectItem>
                    {Object.values(salesCoordinators)
                      .sort((a, b) => (a.fullName > b.fullName ? 1 : -1))
                      .map((option) => (
                        <SelectItem key={option.id} value={option.id}>
                          {option.fullName}
                        </SelectItem>
                      ))}
                  </FormSelect>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel required>Date</FormLabel>
                  <FormInput
                    type='date'
                    name='startDate'
                    value={formik.values.startDate || ''}
                    onChange={handleChange}
                    disableUnderline
                    placeholder='05/08/2023'
                    disabled={!canEdit || formik.isSubmitting}
                  ></FormInput>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel required>Expected Close</FormLabel>
                  <FormInput
                    type='date'
                    name='expectedClose'
                    value={formik.values.expectedClose || ''}
                    onChange={handleChange}
                    disableUnderline
                    placeholder='05/22/2023'
                    disabled={!canEdit || formik.isSubmitting}
                  ></FormInput>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel required>Expire Date</FormLabel>
                  <FormInput
                    type='date'
                    name='dueDate'
                    value={formik.values.dueDate || ''}
                    onChange={handleChange}
                    disableUnderline
                    placeholder='06/07/2023'
                    disabled={!canEdit || formik.isSubmitting}
                  ></FormInput>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel required>Terms</FormLabel>
                  <FormSelect
                    name='terms'
                    value={formik.values.terms?.id || defaultTerms}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    disabled={true}
                  >
                    <SelectItem value='' disabled>
                      Select Terms...
                    </SelectItem>
                    {termOptions.entries.map(([key, value]) => (
                      <SelectItem key={key} value={key}>
                        {value}
                      </SelectItem>
                    ))}
                  </FormSelect>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel required>Market</FormLabel>
                  <FormSelect
                    name='market'
                    value={formik.values.market?.id || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleLookupChange(event, marketOptions)}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    disabled={!canEdit || formik.isSubmitting}
                  >
                    <SelectItem value='' disabled>
                      Select a market...
                    </SelectItem>
                    {marketOptions.entries
                      .sort((a, b) => {
                        return a[1].localeCompare(b[1]);
                      })
                      .map(([key, value]) => (
                        <SelectItem key={key} value={key}>
                          {value}
                        </SelectItem>
                      ))}
                  </FormSelect>
                </Grid>
                <Grid item xs={12} tablet={6}>
                  <FormLabel>Days Past Due</FormLabel>
                  <FormInput
                    name='daySalesOutstanding'
                    value={formik.values.client.daysOverdue ?? '0'}
                    disableUnderline
                    placeholder='0'
                    disabled={true}
                  ></FormInput>
                </Grid>
              </Grid>
            </Grid>

            {/* Right Side */}
            <RelativeGrid item xs={12} tablet={6}>
              <Stack spacing={4}>
                <FormHeader variant='h3' header='Shipping & Billing' />
                <Box>
                  <FormLabel required>Freight Terms</FormLabel>
                  <FormSelect
                    name='freightTerms'
                    value={formik.values.freightTerms?.id || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleLookupChange(event, freightTermsOptions)
                    }
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    disabled={!canEdit || formik.isSubmitting}
                  >
                    <SelectItem value='' disabled>
                      Select terms...
                    </SelectItem>
                    {freightTermsOptions.entries
                      .sort((a, b) => a[1].localeCompare(b[1]))
                      .map(([key, value]) => (
                        <SelectItem key={key} value={key}>
                          {value}
                        </SelectItem>
                      ))}
                  </FormSelect>
                </Box>
                <Box>
                  <FormLabel>Ship Via</FormLabel>
                  <FormSelect
                    name='shipVia'
                    value={formik.values.shipVia?.id || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (!event.target.value) {
                        formik.setFieldValue('shippingCost', 0);
                        setQuote((prev) => (prev ? { ...prev, shippingCost: 0 } : undefined));
                      }
                      handleLookupChange(event, shipViaOptions);
                    }}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    disabled={!canEdit || formik.isSubmitting}
                  >
                    <SelectItem value=''>Select method...</SelectItem>
                    {shipViaOptions.entries
                      .sort((a, b) => a[1].localeCompare(b[1]))
                      .map(([key, value]) => (
                        <SelectItem key={key} value={key}>
                          {value}
                        </SelectItem>
                      ))}
                  </FormSelect>
                </Box>
                <Box>
                  <FormLabel required>Shipping Address</FormLabel>
                  <Flex>
                    <FormSelect
                      name='shippingAddress'
                      value={formik.values.shippingAddressList?.id ?? ''}
                      onChange={changeShippingAddress}
                      IconComponent={ExpandMoreIcon}
                      displayEmpty
                      disabled={!canEdit || formik.isSubmitting}
                    >
                      <SelectItem value='' disabled>
                        Select a shipping address...
                      </SelectItem>
                      <SelectItem value={addNewAddress}>+ Create new...</SelectItem>
                      <SelectItem value={setCustomAddress}>- Custom -</SelectItem>
                      <Divider />
                      {clientAddresses
                        ?.sort((a, b) => {
                          return (a.label ?? a.formattedAddress).localeCompare(b.label ?? b.formattedAddress);
                        })
                        .map((option, index) => (
                          <SelectItem
                            style={{ padding: 0 }}
                            key={`${index}_${option.formattedAddress}`}
                            value={option.internalId}
                          >
                            <Tooltip title={option.formattedAddress}>
                              <AddressLabelContainer>{option.label}</AddressLabelContainer>
                            </Tooltip>
                          </SelectItem>
                        ))}
                    </FormSelect>{' '}
                    {formik?.values?.shippingAddressList?.id === setCustomAddress && (
                      <Tooltip title='Edit Address'>
                        <IconButton
                          onClick={() => {
                            setShowShippingAddressModal(true);
                          }}
                        >
                          <LaunchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Flex>
                </Box>
                <Box>
                  <FormLabel required>Billing Address</FormLabel>
                  <FormSelect
                    required
                    name='billingAddress'
                    value={clientAddresses?.find((addr) => addr.defaultBilling)?.formattedAddress ?? ''}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    disabled={true}
                  >
                    <SelectItem value='' disabled>
                      Select a billing address...
                    </SelectItem>
                    <Divider />
                    {clientAddresses?.map((option) => (
                      <SelectItem key={option.formattedAddress} value={option.formattedAddress}>
                        {option.formattedAddress}
                      </SelectItem>
                    ))}
                  </FormSelect>
                </Box>

                <Box>
                  <FormLabel>
                    <Flex>
                      Estimated Shipping Cost
                      <Tooltip title='Select Ship Via option to set Shipping Cost'>
                        <StyledInfoIcon />
                      </Tooltip>
                    </Flex>
                  </FormLabel>
                  <FormInput
                    type='number'
                    name='shippingCost'
                    value={formik.values.shippingCost}
                    onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
                    onChange={handleNumericChange}
                    disableUnderline
                    disabled={!canEdit || formik.isSubmitting || !formik.values.shipVia?.id}
                  />
                </Box>
                <StyledLabel
                  disabled={!clientData?.customerTaxable}
                  control={<StyledCheckbox checked={quote?.taxIncluded === true} onChange={handleTaxableChange} />}
                  label={'Transaction Taxable?'}
                />

                <FormHeader variant='h3' header='Notes' />
                <Box>
                  <FormLabel>Memo</FormLabel>
                  <Memo
                    type='text'
                    multiline
                    fullWidth
                    minRows={10}
                    name='memo'
                    value={formik.values.memo}
                    onChange={handleChange}
                    disabled={!canEdit || formik.isSubmitting}
                  ></Memo>
                </Box>
              </Stack>
              <Flex styles={{ justifyContent: 'flex-end', marginTop: '1rem' }}>
                <Button
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setTabValue(1);
                  }}
                >
                  Continue To Add Items
                </Button>
              </Flex>
            </RelativeGrid>
          </Grid>
        </Container>
      </LoadingSpinner>
    </Flex>
  );
};

export default ProjectDetails;
