import { QueryResult, useQuery } from '@apollo/client';
import {
  GetClientsForItemHistoryDocument,
  GetClientsForItemHistoryQuery,
  GetClientsForItemHistoryQueryVariables,
} from '__generated__/graphql';

export const useQueryGetClientsForItemHistory = (
  itemId: string
): QueryResult<GetClientsForItemHistoryQuery, GetClientsForItemHistoryQueryVariables> => {
  return useQuery(GetClientsForItemHistoryDocument, {
    variables: { itemId },
  });
};
