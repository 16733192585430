import { css, styled } from '@mui/material';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { SalesOrderItemInput } from '__generated__/graphql';
import { useSalesOrderContext } from 'contexts/SalesOrderContext/SalesOrderContext';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { nonInventoryTypes } from 'contexts/QuoteContext/useItems';
import { formatCurrency } from 'utils/functions';
import { Row, Spacer, Table, TableCell, TableHeaderCell } from 'components/TableComponents/TableComponents';
import { ItemsTableNumberInput } from './ItemTableInput';
import { useItemsContext, Item } from 'contexts/ItemsContext/ItemsContext';

export const ItemNumberCellContent = styled('div')<{ $visible: boolean }>(
  () => css`
    background: transparent;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: start;
  `
);

export const SalesOrderNumber = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.hoverBlue};
    margin-right: 1rem;
    white-space: nowrap;
    text-decoration: none;
  `
);

const emptyArray: SalesOrderItemInput[] = [];

export const ItemsTable = () => {
  const { salesOrderInput } = useSalesOrderContext();
  const { getItems } = useItemsContext();
  const [itemData, setItemData] = useState<{ [key: string]: Item }>({});

  useEffect(() => {
    getItems(salesOrderInput.items?.map((item) => item.item) ?? []).then((items) => {
      setItemData(items);
    });
  }, [getItems, salesOrderInput.items]);

  const columnHelper = createColumnHelper<SalesOrderItemInput>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((lineItem) => lineItem.line, {
        id: 'line',
        cell: (info) => info.getValue(),
        header: () => <span>Line</span>,
      }),
      columnHelper.accessor((lineItem) => lineItem, {
        id: 'itemNumber',
        cell: (info) => {
          const lineItem = info.getValue();
          const item = itemData[lineItem.item];

          if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(lineItem.type ?? '')) {
            // MITP-715 - Change to itemId
            return <ItemNumberCellContent>{lineItem.item}</ItemNumberCellContent>;
          }

          return item ? (
            <SalesOrderNumber to={`/item/${lineItem.item}`} target='_blank'>
              {item.itemId}
            </SalesOrderNumber>
          ) : null;
        },
        header: () => <span>Item #</span>,
      }),
      columnHelper.accessor((lineItem) => lineItem, {
        id: 'totalAvailable',
        cell: (info) => {
          const lineItem = info.getValue();
          const item = itemData[lineItem.item];

          if (nonInventoryTypes.includes(lineItem.type ?? '')) {
            return null;
          }

          const totalAvailable = item?.inventoryDetail?.binNumber?.reduce(
            (acc, location) =>
              location.locationQuantityAvailable ? acc + Number(location.locationQuantityAvailable) : acc,
            0
          );

          return totalAvailable;
        },
        header: () => <span>Available</span>,
      }),
      columnHelper.accessor((_, index) => index, {
        id: 'quantity',
        cell: (info) => {
          const index = info.getValue();

          // TODO: Create qty input item group types

          return <ItemsTableNumberInput field='quantity' index={index} />;
        },
        header: () => <span>Quantity</span>,
      }),
      columnHelper.accessor((lineItem) => lineItem, {
        id: 'warehouse',
        cell: (info) => {
          const salesOrderItem = info.getValue();
          const item = itemData[salesOrderItem.item];

          const locationName = item?.inventoryDetail?.binNumber?.find(
            (location) => location.info?.id === salesOrderItem.location
          )?.info?.name;

          return locationName;
        },
        header: () => <span>Warehouse</span>,
      }),
      columnHelper.accessor((lineItem) => lineItem, {
        id: 'description',
        cell: (info) => {
          const lineItem = info.getValue();
          const item = itemData[lineItem.item];

          if (lineItem.type === 'EndGroup') {
            return null;
          }

          return item?.description;
        },
        header: () => <span>Description</span>,
      }),
      columnHelper.accessor((lineItem, index) => ({ lineItem, index }), {
        id: 'unitCost',
        cell: (info) => {
          const { lineItem, index } = info.getValue();

          if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(lineItem.type ?? '')) {
            return null;
          }

          return <ItemsTableNumberInput index={index} field='unitCost' />;
        },
        header: () => <span>Unit Cost</span>,
      }),
      columnHelper.accessor((lineItem) => lineItem.margin, {
        id: 'margin',
        cell: (info) => info.getValue(),
        header: () => <span>Margin %</span>,
      }),
      columnHelper.accessor((lineItem, index) => ({ lineItem, index }), {
        id: 'adjCost',
        cell: (info) => {
          const { lineItem, index } = info.getValue();

          if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(lineItem.type ?? '')) {
            return null;
          }

          return <ItemsTableNumberInput index={index} field='adjCost' />;
        },
        header: () => <span>Sale Price</span>,
      }),
      columnHelper.accessor((lineItem) => ({ lineItem }), {
        id: 'total',
        cell: (info) => {
          const { lineItem } = info.getValue();

          const calculateTotal = (quantity: number, adjCost: number) => {
            return Math.round(quantity * adjCost * 100) / 100;
          };

          return formatCurrency(calculateTotal(lineItem.quantity || 0, lineItem.adjCost || 0));
        },
        header: () => <span>Total</span>,
      }),
    ],
    [columnHelper, itemData]
  );

  const itemsTable = useReactTable({
    columns,
    data: salesOrderInput.items && Object.keys(itemData).length > 0 ? salesOrderInput.items : emptyArray,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table>
      <thead>
        {itemsTable.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, i) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <TableHeaderCell $start={i === 0} $end={header.id === 'total'} $noMarginBottom>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHeaderCell>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {itemsTable.getRowModel().rows.map((row) => {
          return (
            <Row key={row.id}>
              {row.getVisibleCells().map((cell, i) => {
                return (
                  <TableCell $start={i === 0} $end={cell.column.id === 'total'} key={cell.id}>
                    <Spacer>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Spacer>
                  </TableCell>
                );
              })}
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};
