import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  ListQuotesDocument,
  ListQuotesQuery,
  ListQuotesQueryVariables,
  QueryListQuotesArgs,
} from '__generated__/graphql';

export const useQueryListQuotes = (
  input: QueryListQuotesArgs,
  options?: QueryHookOptions<ListQuotesQuery, ListQuotesQueryVariables>
): QueryResult<ListQuotesQuery, ListQuotesQueryVariables> => {
  return useQuery(ListQuotesDocument, {
    ...options,
    variables: input,
  });
};
