import React, { useEffect, useMemo } from 'react';
import Container from '@mui/material/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import { QuotesContextProvider } from 'contexts/QuotesContext/QuotesContext';
import { TabContent, TabNavigation } from 'components/TabNavigation/TabNavigation';
import ClientSearch from './components/ClientSearch/ClientSearch';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { Action, LAST_DRAFT_ID_KEY } from 'utils/constants';
import { Alert, AlertTitle } from '@mui/material';

const CreateQuote: React.FC = () => {
  const { can } = useAuthContext();

  const canCreateQuotes = useMemo(() => {
    return can('Quotation', Action.Full);
  }, [can]);

  useEffect(() => {
    window.localStorage.setItem(LAST_DRAFT_ID_KEY, '');
  }, []);

  return (
    <Container>
      <PageHeader variant='h1' header='Create New Quote' />
      {!canCreateQuotes && (
        <Alert color='error'>
          <AlertTitle>You do not have permission to create a quote</AlertTitle>
        </Alert>
      )}
      {canCreateQuotes && (
        <TabNavigation disabled>
          <TabContent header='Quote Details'>
            <QuotesContextProvider>
              <ClientSearch />
            </QuotesContextProvider>
          </TabContent>
          {/* Disabled for MITP-324 */}
          {/*<TabContent header='Recommended Pricing' />*/}
          <TabContent header='Add Items' />
        </TabNavigation>
      )}
    </Container>
  );
};

export default CreateQuote;
