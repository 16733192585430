import React from 'react';
import { css, styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
  deleteCount: number;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const DialogButton = styled(Button)<ButtonProps>(
  ({ theme }) => css`
    color: ${theme.colors.white};
    border-radius: 1rem;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid ${theme.colors.lightBlack};
    }
  `
);

const ItemsDeleteDialog: React.FC<Props> = ({ deleteCount, open, onCancel, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{'Remove Items?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {deleteCount > 1
            ? `Would you like to remove the ${deleteCount} selected items?`
            : 'Would you like to remove the selected item?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={onCancel}>Cancel</DialogButton>
        <DialogButton onClick={onConfirm}>Confirm</DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default ItemsDeleteDialog;
