import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  ListClientsDocument,
  ListClientsQuery,
  ListClientsQueryVariables,
  QueryListClientsArgs,
} from '__generated__/graphql';

export const useQueryListClients = (
  input: QueryListClientsArgs,
  options?: QueryHookOptions<ListClientsQuery, ListClientsQueryVariables>
): QueryResult<ListClientsQuery, ListClientsQueryVariables> => {
  return useQuery(ListClientsDocument, {
    ...options,
    variables: input,
  });
};
