export enum FieldType {
  Number,
  Percent,
  String,
  Boolean,
  TextArea,
  VendorSelect,
  Select,
  CostEstimateTypeSelect,
  EstimatedUnitCostInput,
  QuantityInput,
}

export type AutocompleteOption = {
  label: string;
  id: string;
  group?: string;
};

export type FieldInfo = {
  title: string;
  disabled?: boolean;
  tooltip?: string;
  required?: boolean;
};
