import { Switch, SwitchProps } from '@mui/material';
import { css, styled } from '@mui/material/styles';

export interface LabeledSwitchProps extends SwitchProps {
  checkedLabel?: string;
  uncheckedLabel?: string;
}

const LabeledSwitch = styled(Switch, {
  shouldForwardProp: (prop: string) => prop !== 'checkedLabel' && prop !== 'uncheckedLabel',
})<LabeledSwitchProps>(
  ({ checked, checkedLabel, uncheckedLabel }: LabeledSwitchProps) => css`
    padding-left: 0;

    .MuiSwitch-thumb {
      margin-left: -0.75rem;
    }

    .MuiSwitch-track {
      align-items: center;
      border: 0.0625rem solid transparent;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      padding-top: 0.1rem;
      padding-left: 0.45rem;
      padding-right: 0.45rem;
      text-transform: uppercase;
      transform-style: preserve-3d;

      &:before {
        content: ${checkedLabel || 'yes'};
        opacity: ${checked ? '1' : '0'};
        transition: 300ms ease-in-out;
        font-size: 0.875rem;
      }

      &:after {
        content: ${uncheckedLabel || 'no'};
        text-align: right;
        position: absolute;
        right: 0.5rem;
        opacity: ${checked ? '0' : '1'};
        transition: 300ms ease-in-out;
        font-size: 0.875rem;
      }
    }
  `
);

export default LabeledSwitch;
