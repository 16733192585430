import React from 'react';
import Pill from 'components/Pill/Pill';
import CloseIcon from '@mui/icons-material/Close';
import { css, styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

type Props = {
  children: React.ReactNode;
  handleClose: () => void;
};

const StyledPill = styled(Pill)(
  () => css`
    display: flex;
    justify-content: space-between;
  `
);

const CloseButton = styled(ButtonBase)(
  ({ theme }) => css`
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
    flex-shrink: 0;

    &:hover {
      background-color: ${theme.colors.lightBlack};
    }

    svg {
      font-size: 1rem;
      path {
        stroke: ${theme.colors.white};
      }
    }
  `
);

const PillWithClose: React.FC<Props> = ({ children, handleClose }) => {
  return (
    <StyledPill>
      {children}
      <CloseButton onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
    </StyledPill>
  );
};

export default PillWithClose;
