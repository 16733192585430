import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';

type SelectedThemeContextState = {
  lightMode: boolean;
  setLightMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const SelectedThemeContext = React.createContext<SelectedThemeContextState | undefined>(undefined);

type SelectedThemeProviderProps = {
  children: React.ReactNode;
};

const SelectedThemeContextProvider: React.FC<SelectedThemeProviderProps> = ({ children }) => {
  const [lightModeCookie, setLightModeCookie] = useCookies(['mil-light-mode']);
  const [lightMode, setLightMode] = useState(lightModeCookie?.['mil-light-mode'] || false);

  useEffect(() => {
    setLightModeCookie('mil-light-mode', lightMode, { maxAge: 31536000 });
  }, [lightMode, setLightModeCookie]);

  const selectedThemeContextValue = useMemo(
    () => ({
      lightMode,
      setLightMode,
    }),
    [lightMode]
  );

  return <SelectedThemeContext.Provider value={selectedThemeContextValue}>{children}</SelectedThemeContext.Provider>;
};

const useSelectedThemeContext = () => {
  const context = useContext(SelectedThemeContext);

  if (context === undefined) {
    throw new Error('useSelectedThemeContext was used outside of its Provider');
  }

  return context;
};

export { SelectedThemeContext, SelectedThemeContextProvider, useSelectedThemeContext };
