import React, { useCallback, useEffect, useState } from 'react';
import {
  GetClientQuery,
  Item,
  LinePricing,
  Pricing,
  Quote,
  QuoteItem,
  QuoteItemInventory,
} from '__generated__/graphql';
import { useQueryGetItemGroup } from 'hooks/queries/useQueryGetItemGroup/useQueryGetItemGroup';

export type PricingSelectionType = 'good' | 'better' | 'best' | 'deviation' | null;

export interface ItemTableItem extends QuoteItem {
  isNonI2pPrice?: boolean;
}

const updatableFields = [
  'unitCost',
  'adjCost',
  'margin',
  'description',
  'inventory',
  'vendorPORate',
  'vendorQuoteReference',
  'vendor',
  'leadTime',
  'isTaxable',
  'i2pCalculationId',
  'deviationCode',
  'costEstimateType',
  'estimatedTotalCost',
  'estimatedUnitCost',
  'quantity',
] as const;

export type UpdatableField = (typeof updatableFields)[number];

/**
 * Takes a string and returns it as an UpdatableField type.
 * Throws an error if the string not a valid UpdatableField.
 * @param {string} field
 * */
export const validateUpdatableField = (fieldName: string) => {
  const field = fieldName as UpdatableField;

  if (!updatableFields.includes(field)) {
    throw new Error(`${field} is not an updatable field`);
  }

  return field;
};

export type UpdatableFieldValue = string | number | boolean | Array<QuoteItemInventory>;

export type UseItems = {
  itemsTableItems: ItemTableItem[];

  addItems: (item: Item[]) => void;

  updateItemField: (index: number, field: UpdatableField, value: UpdatableFieldValue) => void;

  updateItemFields: (index: number, updates: Record<UpdatableField, UpdatableFieldValue>) => void;

  updateItemQuantities: (index: number, quantities: Array<QuoteItemInventory>) => void;
  updateItemIndex: (index: number, desiredOrder: number) => void;

  /**
   * Takes an array of QuoteItem, where each line represents an item coming from a particular warehouse
   * and updates the itemsTableItems structure, where each line represents a unique item number
   * and all of its quantity sources
   * @param items
   */
  updateItemQuantitiesFromQuoteItems: (items: QuoteItem[]) => void;
  getAsQuoteItems: (includeNoQuantity: boolean) => QuoteItem[];
  updateQuoteItems: () => void;
  removeItem: (index: number) => void;
  bulkRemoveItems: (index: number[]) => void;
  duplicateItem: (index: number) => void;

  //I2P-related data & functions:
  i2pPricing: Pricing | null;
  setI2pPricing: (pricing: Pricing | null) => void;
  pricingSelections: Record<string, PricingSelectionType>;
  setPricingSelection: (key: string, value: PricingSelectionType) => void;
  applyI2pPricing: (lineItemId?: string | null) => void;
};

/**
 * Returns a deviation code id based on the selected vs suggested pricing
 * @param {Pricing} pricing the pricing data returned from insight to profit
 * @param {number} index index of item in table
 * @param {string} itemNumber item's identifiable number
 * @param {number} adjCost the item's sale price being applied to the item
 */
export const getDeviationFromPrice = (pricing: Pricing, index: number, itemNumber: string, adjCost: number) => {
  // Find the pricing line for this item
  const pricingLine = pricing?.quotelines?.find((line) => {
    return line.line_id === `${itemNumber}__${index}`;
  });

  // If the pricing data has not been fetched or if the user has changed the order of the line items
  if (!pricingLine) {
    return;
  }

  // Get the good and best pricing
  const { good_target_price_per_unit: good, best_target_price_per_unit: best } = pricingLine;

  if (!good || !best) {
    return;
  }

  // Apply deviation code accordingly
  if (adjCost < 0.95 * good) {
    return process.env.REACT_APP_DEVIATION_CODE_BELOW_GOOD_BY_5_PERCENT;
  } else if (adjCost > 1.05 * best) {
    return process.env.REACT_APP_DEVIATION_CODE_MARKET_TRENDS;
  } else {
    return process.env.REACT_APP_DEVIATION_CODE_WITHIN_RECOMMENDED_PRICING;
  }
};

/**
 * Computes item price to achieve the given margin based on unit cost
 * @param cost item unit const in dollars
 * @param margin desired margin in percent (eg, for 20% pass 20)
 */
export const itemPriceFromCostMargin = (cost: number, margin: number): number => {
  const price = cost / (1 - margin / 100);
  return Math.round(price * 100) / 100;
};

export const itemMarginFromCostPrice = (cost: number, price: number): number => {
  const margin = ((price - cost) / price) * 100;
  return Math.round(margin * 10) / 10; // Round to nearest 10th of a percent
};

export const itemProfitFromCostPriceQuantity = (cost: number, price: number, quantity: number): number =>
  (price - cost) * quantity;

// Items with these types will be added to the quote.items even if they don't have inventory/quantity information set
export const nonInventoryTypes = [
  'OtherChargeResaleItem',
  'ServiceSaleItem',
  'NonInventoryResaleItem',
  'OtherChargeSaleItem',
  'NonInventorySaleItem',
  'ServiceResaleItem',
  'OtherChargePurchaseItem',
  'DescriptionItem',
  'ItemGroup',
  'EndGroup',
];

//These item types don't need an inventory location, but can take a quantity, and
//should default to a quantity of 1 when added to a quote
export const nonInventoryTypesWithQuantity = [
  'OtherChargeResaleItem',
  'ServiceSaleItem',
  'NonInventoryResaleItem',
  'OtherChargeSaleItem',
  'NonInventorySaleItem',
  'ServiceResaleItem',
  'OtherChargePurchaseItem',
  'ItemGroup',
];

export default function useItems(
  updateQuote: React.Dispatch<React.SetStateAction<Quote | undefined>>,
  quote?: Quote,
  client?: GetClientQuery | undefined
): UseItems {
  const [pricing, setPricing] = useState<Pricing | null>(null);
  const [pricingSelections, setPricingSelections] = useState<Record<string, PricingSelectionType>>({});
  const [itemsTableItems, setItemsTableItems] = useState<ItemTableItem[]>([]);
  const [doUpdateQuote, setDoUpdateQuote] = useState<boolean>(false);
  const [lastQuoteId, setLastQuoteId] = useState<string>('');

  const { refetch: fetchItemGroup } = useQueryGetItemGroup({ groupItemId: '' }, { skip: true });

  /**
   * Manages pulling items out of a quote and building a list of ItemTableItems,
   * when the quote changes
   */
  useEffect(() => {
    //if the quote changes, we want to recompute the items table items
    if (quote?.id === lastQuoteId) {
      return;
    }

    setLastQuoteId(quote?.id ?? '');

    if (quote?.items) {
      setItemsTableItems(
        quote.items.map((item) => {
          return {
            ...item,
            inventory:
              item.location?.name && item.quantity
                ? [
                    {
                      id: item.location.id,
                      location: item.location.name,
                      quantity: item.quantity,
                    },
                  ]
                : [],
            total: (item.adjCost ?? 0) * (item.quantity ?? 0),
          };
        })
      );
    } else {
      setItemsTableItems([]);
    }
  }, [lastQuoteId, quote]);

  type ItemWithInventory = Item & {
    inventory?: QuoteItemInventory[];
    isInGroup?: boolean;
    quantity?: number;
  };

  // Item model + inventory array
  // Lets us give items initial inventory data
  const addItems = useCallback(
    async (items: Array<Item & { quantity?: number }>, addIndex?: number) => {
      const newItems: ItemTableItem[] = [];

      let allItems: ItemWithInventory[] = [];

      for (let i = 0; i < items.length; i++) {
        if (items[i].type === 'ItemGroup') {
          // Get all items in group
          const { data } = await fetchItemGroup({ groupItemId: items[i].id });

          const group = data.getItemGroup;

          const groupItem = group[0];

          const memberArray = groupItem.memberList?.itemMember ?? [];

          const outerQuantity = items[i].quantity || 1;

          items[i].quantity = outerQuantity;

          const itemsWithInventory = group.map((item) => {
            // Get the quantity for the current item, from them ItemGroup item's member array
            let quantity = outerQuantity * (memberArray.find((member) => member.item?.id === item.id)?.quantity ?? 0);

            if (item.type === 'ItemGroup') {
              quantity = outerQuantity;
            }

            const locations = item.inventoryDetail?.binNumber;
            const inventory: QuoteItemInventory[] = [];

            // Get the first location with enough on hand,
            // if none have enough just return the first location
            // if that doesnt exist, give it no inventory information
            if (locations) {
              const locationWithEnoughOnHand = locations.find((location) => Number(location.onHandAvail) > quantity);

              if (locationWithEnoughOnHand?.info?.id && locationWithEnoughOnHand.info.name) {
                inventory.push({
                  id: locationWithEnoughOnHand.info.id,
                  location: locationWithEnoughOnHand.info.name,
                  quantity,
                });
              } else if (locations[0]?.info?.id && locations[0]?.info?.name) {
                inventory.push({
                  id: locations[0]?.info?.id ?? '',
                  location: locations[0]?.info?.name ?? '',
                  quantity,
                });
              }
            }

            return {
              ...item,
              inventory,
              quantity,
              isInGroup: true,
            };
          });

          allItems = [...allItems, ...(itemsWithInventory as ItemWithInventory[])];
        } else if (nonInventoryTypesWithQuantity.includes(items[i].type ?? '')) {
          allItems.push({ ...items[i], quantity: 1, inventory: [{ quantity: 1, id: '', location: '' }] });
        } else {
          allItems.push(items[i]);
        }
      }

      allItems.forEach((item) => {
        let conditionalLeadTime: string | undefined | null = undefined;

        if (quote && quote.items) {
          quote.items.forEach((quoteItem) => {
            if (item.id === quoteItem.item.id) {
              conditionalLeadTime = item.leadTime;
            }
          });
        }

        newItems.push({
          __typename: 'QuoteItem',
          adjCost: itemPriceFromCostMargin(item.unitCost || item.estimatedUnitCost || 0, 20),
          description: item.description,
          estimatedUnitCost: item.estimatedUnitCost,
          inventory: item.isInGroup ? item.inventory : [],
          item: { id: item.id, name: item.itemId },
          leadTime: conditionalLeadTime,
          line: undefined,
          location: undefined,
          margin: 20, //default margin
          quantity: item.quantity ?? 0, //TODO: should be the minimum quantity, it's not yet part of Item
          inventoryDetail: item.inventoryDetail,
          total: undefined,
          totalOnHand: undefined,
          unitCost: item.unitCost || item.estimatedUnitCost,
          warehouse: undefined,
          weight: item.weight,
          weightUnit: item.weightUnit,
          itemNumber: item.itemId,
          vendor: item.preferredVendor?.id,
          vendorQuoteReference: '',
          isTaxable: client?.getClient?.customerTaxable ?? false,
          storeDisplayName: item.storeDisplayName,
          parent: item.parent,
          reelQuantityAvailable: item.reelQuantityAvailable,
          isNonI2pPrice: true,
          type: item.type,
          deviationCode: item.type && nonInventoryTypes.includes(item.type) ? '1' : undefined,
        });
      });

      if (addIndex !== undefined) {
        setItemsTableItems((items) =>
          items
            .slice(0, addIndex + 1)
            .concat(...items)
            .concat(items.slice(addIndex + 1))
        );
      } else {
        setItemsTableItems([...itemsTableItems, ...newItems]);
      }
      setDoUpdateQuote(true);
    },
    [itemsTableItems, quote, fetchItemGroup, client?.getClient?.customerTaxable]
  );

  /**
   * Translates the internal itemsTableItems structure into a quote.items strcuture
   * @return QuoteItem[] the quote.items structure
   */
  const getAsQuoteItems = useCallback(
    (includeNoInventory = false) => {
      const quoteItems: QuoteItem[] = [];
      let line = 1;
      itemsTableItems.forEach((item) => {
        //push a quoteItem for every inventory record in the item
        //if there is no inventory, we won't add it to the quote
        if (item.type && nonInventoryTypes.includes(item.type)) {
          quoteItems.push({
            ...item,
            line: (line++).toString(),
            inventory: [],
          });
          return;
        }
        if (item.inventory && item.inventory.length) {
          item.inventory?.forEach((inventory) => {
            const newItem: QuoteItem = {
              ...item,
              line: (line++).toString(),
              quantity: inventory.quantity,
              total: inventory.quantity * (item.adjCost ?? 0),
              location: { name: inventory.location, id: inventory.id },
              inventory: [inventory],
              vendorQuoteReference: item.vendorQuoteReference,
            };
            quoteItems.push(newItem);
          });
        } else if (nonInventoryTypesWithQuantity.includes(item.type ?? '')) {
          quoteItems.push({
            ...item,
            line: (line++).toString(),
            quantity: item.quantity,
            location: null,
          });
        } else if (includeNoInventory) {
          //if includeNoInventory is set, we want at least one item returned even if' it has no quantity set
          quoteItems.push({
            ...item,
            line: (line++).toString(),
            quantity: 0,
            location: null,
          });
        }
      });
      return quoteItems;
    },
    [itemsTableItems]
  );

  /**
   * Updates the `quote.items` structure with the current contents of itemsTableItems
   */
  const updateQuoteItems = useCallback(() => {
    if (quote) {
      updateQuote({ ...quote, items: getAsQuoteItems() });
    }
  }, [quote, getAsQuoteItems, updateQuote]);

  const removeItem = useCallback(
    (index: number) => {
      // Dont let the user remove an end group item
      if (itemsTableItems[index].type === 'EndGroup') {
        return;
      }

      let endIndex = index + 1;

      // If the item is a ItemGroup, set the endIndex so that all the items in the group will be removed
      if (itemsTableItems[index].type === 'ItemGroup') {
        for (let i = index; i < itemsTableItems.length; i++) {
          if (itemsTableItems[i].type === 'EndGroup') {
            endIndex = i + 1;
            break;
          }
        }
      }

      setItemsTableItems([...itemsTableItems.slice(0, index), ...itemsTableItems.slice(endIndex)]);
      //let itemsTableItems settle first
      setDoUpdateQuote(true);
    },
    [itemsTableItems, setDoUpdateQuote]
  );

  const bulkRemoveItems = useCallback(
    (excludedIndices: number[]) => {
      setItemsTableItems(
        itemsTableItems.filter((value) => {
          return !excludedIndices.includes(itemsTableItems.indexOf(value));
        })
      );
      setDoUpdateQuote(true);
    },
    [itemsTableItems, setDoUpdateQuote]
  );

  const duplicateItem = useCallback(
    async (index: number) => {
      const srcItem = { ...itemsTableItems[index], line: undefined };
      if ('ItemGroup' === srcItem.type) {
        //if this is an item group, we want to go through the full add process
        //so that the child items are added as well

        //we also need to find the EndGroup item that ends this group
        //because the new group should be added at that index, not the index of the
        //group itself
        let endGroupIndex = index;
        for (let i = index; i < itemsTableItems.length; i++) {
          if (itemsTableItems[i].type === 'EndGroup') {
            endGroupIndex = i;
            break;
          }
        }

        await addItems(
          [
            {
              ...srcItem,
              id: srcItem.item.id,
              itemId: srcItem.item.name ?? '',
              __typename: undefined,
              quantity: srcItem.quantity ?? 1,
            },
          ],
          endGroupIndex
        );
      } else if ('EndGroup' === srcItem.type) {
        //No Op - we don't want to duplicate an end of group item
      } else {
        //add the duplicate item under the duplicated item
        setItemsTableItems((items) =>
          items
            .slice(0, index + 1)
            .concat(srcItem)
            .concat(items.slice(index + 1))
        );
        setDoUpdateQuote(true);
      }
    },
    [addItems, itemsTableItems]
  );

  const updateItemFields = useCallback(
    (index: number, updates: Record<UpdatableField, UpdatableFieldValue>) => {
      setItemsTableItems((prev) => {
        const item = prev[index];

        Object.entries(updates).forEach(([field, value]) => {
          const cost = item.unitCost || item.estimatedUnitCost || 0;
          switch (field) {
            case 'adjCost': //FIXME: this should be rate!
              item.adjCost = Number(value);
              if (pricing) {
                item.deviationCode =
                  getDeviationFromPrice(pricing, index, item.item.name ?? '', item.adjCost) ?? item.deviationCode;
              }
              item.margin = itemMarginFromCostPrice(cost, Number(value));
              item.total = item.adjCost * (item.quantity ?? 0);
              item.isNonI2pPrice = true;
              break;
            case 'margin':
              item.margin = Number(value);
              item.adjCost = itemPriceFromCostMargin(cost, Number(value));
              if (pricing) {
                item.deviationCode =
                  getDeviationFromPrice(pricing, index, item.item.name ?? '', item.adjCost) ?? item.deviationCode;
              }
              item.isNonI2pPrice = true;
              item.total = item.adjCost * (item.quantity ?? 0);
              break;
            case 'unitCost':
              item.unitCost = Number(value);
              if (item.costEstimateType === 'CUSTOM') {
                item.estimatedUnitCost = Number(value);
                item.estimatedTotalCost = item.estimatedUnitCost * (item.quantity ?? 0);
              }
              item.adjCost = itemPriceFromCostMargin(Number(value), Number(item.margin));
              if (pricing) {
                item.deviationCode =
                  getDeviationFromPrice(pricing, index, item.item.name ?? '', item.adjCost) ?? item.deviationCode;
              }
              item.isNonI2pPrice = true;
              item.total = item.adjCost * (item.quantity ?? 0);
              break;
            case 'costEstimateType':
              item.costEstimateType = value.toString();
              break;
            case 'description':
              item.description = value.toString();
              break;
            case 'leadTime':
              item.leadTime = value.toString();
              break;
            case 'inventory':
              item.inventory = value as Array<QuoteItemInventory>;
              item.quantity = Object.entries(value).reduce((total: number, [, { quantity }]) => total + quantity, 0);
              item.total = item.quantity * (item.adjCost ?? 0);
              // If the est unit cost is also being updated, grab the updated value instead of current
              item.estimatedTotalCost =
                item.quantity *
                (updates.estimatedUnitCost ? Number(updates.estimatedUnitCost) : item.estimatedUnitCost ?? 0);
              break;
            case 'vendorPORate':
              item.vendorPORate = Number(value);
              break;
            case 'vendorQuoteReference':
              item.vendorQuoteReference = value.toString();
              break;
            case 'vendor':
              item.vendor = value.toString();
              break;
            case 'isTaxable':
              item.isTaxable = typeof value === 'boolean' && value;
              break;
            case 'i2pCalculationId':
              item.i2pCalculationId = value.toString();
              break;
            case 'deviationCode':
              item.deviationCode = value.toString();
              break;
            case 'estimatedUnitCost':
              item.estimatedUnitCost = Number(value);
              break;
            case 'estimatedTotalCost':
              item.estimatedTotalCost = Number(value);
              if (item.costEstimateType === 'CUSTOM') {
                item.estimatedUnitCost = item.quantity ? item.estimatedTotalCost / item.quantity : 0;
              }
              break;
            case 'quantity':
              // Only update this directly for ItemGroup items, or items that can have quantity without
              // an inventory location
              if (!nonInventoryTypesWithQuantity.includes(item.type ?? '')) {
                break;
              }
              item.quantity = Number(value);
              break;
          }
        });

        return [...prev.slice(0, index), item, ...prev.slice(index + 1)];
      });

      //let itemsTableItems settle first
      setDoUpdateQuote(true);
    },
    [setItemsTableItems, pricing]
  );

  const updateItemField = useCallback(
    (index: number, field: UpdatableField, value: UpdatableFieldValue) => {
      //type assertion is required https://github.com/microsoft/TypeScript/issues/13948
      updateItemFields(index, { [field]: value } as Record<UpdatableField, UpdatableFieldValue>);
    },
    [updateItemFields]
  );

  const updateItemQuantities = useCallback(
    (index: number, quantities: Array<QuoteItemInventory>, skipUpdatingQuote = false) => {
      const item = itemsTableItems[index];

      item.inventory = quantities;

      item.quantity = Object.entries(quantities).reduce((total: number, [, { quantity }]) => total + quantity, 0);
      item.total = item.quantity * (item.adjCost ?? 0);
      setItemsTableItems([...itemsTableItems.slice(0, index), item, ...itemsTableItems.slice(index + 1)]);

      //let itemsTableItems settle first
      if (!skipUpdatingQuote) {
        setDoUpdateQuote(true);
      }
    },
    [itemsTableItems]
  );

  const updateItemQuantitiesFromQuoteItems = useCallback(
    (items: QuoteItem[]) => {
      const invMap: Record<number, Array<QuoteItemInventory>> = {};
      items.forEach((item, index) => {
        if (!invMap[index]) {
          invMap[index] = [];
        }
        if (item.quantity && item.location) {
          invMap[index].push({
            quantity: item.quantity,
            id: item.location.id,
            location: item.location.name ?? '',
          });
        } else {
          //We still need to keep it, or items with no location that have quantity on existing quote vanish if you edit them
          //from the subtotal modal. For now assign them to the Dropship - ML location
          invMap[index].push({
            quantity: item.quantity ?? 0,
            id: '17',
            location: 'Dropship - ML',
          });
        }
      });
      Object.entries(invMap).forEach(([index, quantities]) => {
        updateItemQuantities(Number(index), quantities, true);
      });
      setDoUpdateQuote(true);
    },
    [updateItemQuantities]
  );

  const updateItemIndex = useCallback(
    (existingIndex: number, desiredIndex: number) => {
      const updatedItems = [...itemsTableItems];

      updatedItems.splice(desiredIndex, 0, updatedItems.splice(existingIndex, 1)[0]);

      setItemsTableItems(updatedItems);
      setDoUpdateQuote(true);
    },
    [itemsTableItems]
  );

  useEffect(() => {
    if (doUpdateQuote) {
      setDoUpdateQuote(false);
      updateQuoteItems();
    }
  }, [itemsTableItems, doUpdateQuote, updateQuoteItems]);

  const applyI2pPricing = useCallback(
    (lineItemId?: string | null) => {
      if (!quote) {
        throw new Error('Quote must be set to update its pricing');
      }
      const applyToIndividualItem = (lineItemId: string, items: ItemTableItem[]) => {
        const [itemId, idx] = lineItemId.split('__');
        const index = Number(idx);
        const pricingline = pricing?.quotelines.find((pricing) => pricing.line_id === lineItemId);

        const selection = pricingSelections[lineItemId];

        if (selection === 'deviation') {
          return items;
        }

        const price = Number(pricingline?.[`${selection}_target_price_per_unit` as keyof LinePricing]);

        if (!price) {
          return items;
        }

        const item = items[index];

        if ((item.itemNumber || item.item.name) !== itemId) {
          return items; //something got messed up along the way
        }

        //we want to keep the same index, that's how we're linking pricing to line items
        //in the even the same item is added more than once (eg, multiple warehouses)
        const updatedItems: ItemTableItem[] = [
          ...items.slice(0, index),
          {
            ...item,
            adjCost: price,
            total: Math.round(price * (item.quantity ?? 0) * 100) / 100,
            margin: itemMarginFromCostPrice(item.unitCost ?? item.estimatedUnitCost ?? 0, price),
            i2pCalculationId: pricing?.metadata?.calculationId ?? '',
            isNonI2pPrice: false,
          },
          ...items.slice(index + 1),
        ];
        return updatedItems;
      };

      if (lineItemId) {
        setItemsTableItems((prev) => applyToIndividualItem(lineItemId, prev ?? []));
      } else {
        setItemsTableItems(
          (prev) =>
            pricing?.quotelines?.reduce((items, priceItem): ItemTableItem[] => {
              return applyToIndividualItem(priceItem.line_id, items);
            }, prev ?? []) ?? []
        );
      }
      setDoUpdateQuote(true);
    },
    [setItemsTableItems, pricing?.quotelines, pricing?.metadata, pricingSelections, quote]
  );

  const setPricingSelection = useCallback(
    (key: string, value: PricingSelectionType) => {
      setPricingSelections({ ...pricingSelections, [key]: value });
    },
    [pricingSelections]
  );

  return {
    itemsTableItems,
    addItems,
    removeItem,
    bulkRemoveItems,
    updateItemField,
    updateItemFields,
    updateQuoteItems,
    getAsQuoteItems,
    updateItemQuantities,
    updateItemQuantitiesFromQuoteItems,
    updateItemIndex,
    i2pPricing: pricing,
    setI2pPricing: setPricing,
    applyI2pPricing,
    pricingSelections,
    setPricingSelection,
    duplicateItem,
  };
}
