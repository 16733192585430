import { gql } from '@apollo/client';

export const SEARCH_NETSUITE_CLIENTS = gql`
  query SearchNetSuiteClients($search: String!) {
    searchNetSuiteClients(search: $search) {
      clients {
        fields {
          accountnumber
          balance
          billaddr1
          billcity
          billstate
          billzip
          category
          companyname
          consolbalance
          consoloverduebalance
          creditlimit
          currid
          custentity_link_name_lsa
          custentity_mi_latest_event_date
          custentity_mil_bookedsaleslast360
          datecreated
          daysoverdue
          defaultaddress
          depositbalance
          email
          entityid
          entitytitle
          hasbillingaddress
          id
          isperson
          lastmodifieddate
          monthlyclosing
          nameorig
          overduebalance
          phone
          salesrep
          salesteamtotal
          shipaddr1
          shipaddr2
          shipaddressee
          shipcity
          shipping_country
          shipstate
          shipzip
          stage
          startdate
          sys_id
          taxable
          taxexempt
          taxitem
          territory
          type
          unbilledorders
          unsubscribe
          vatregnumber
        }
        id
        isDynamic
        name
        sublists {
          addressbook {
            addr1_initialvalue
            addr2_initialvalue
            addr3_initialvalue
            addressbookaddress_text
            addressbookaddress_type
            addressee_initialvalue
            addressid
            addrlanguage_initialvalue
            addrtext_initialvalue
            attention_initialvalue
            city_initialvalue
            country_initialvalue
            defaultbilling
            defaultshipping
            displaystate_initialvalue
            dropdownstate_initialvalue
            id
            internalid
            isnewline
            isresidential
            issyncedfromsubsidiary
            label
            override_initialvalue
            phone_initialvalue
            state_initialvalue
            sys_id
            unbilledorders
            zip_initialvalue
          }
          contactroles {
            contact
            contactname
            email
            role
            sendemail
            sys_id
          }
          currency {
            balance
            consolbalance
            consoloverduebalance
            consolunbilledorders
            currency
            displaysymbol
            hastransactionorproject
            overduebalance
            overridecurrencyformat
            symbolplacement
            sys_id
            unbilledorders
          }
          salesteam {
            contribution
            customer
            employee
            employee_display
            id
            iscontributionuserdefined
            isprimary
            issalesrep
            salesrole
            sys_id
          }
          submachine {
            balance
            balancecurrency
            depositbalance
            entity
            hasproject
            hastransaction
            sys_id
            unbilledcurrency
            unbilledorders
          }
        }
        type
      }
      count
      limit
      page
      totalCount
    }
  }
`;

export const GET_NETSUITE_CLIENT = gql`
  query GetNetSuiteClient($id: Float!) {
    getNetSuiteClient(id: $id) {
      customer {
        fields {
          accountnumber
          balance
          billaddr1
          billcity
          billstate
          billzip
          category
          companyname
          consolbalance
          consoloverduebalance
          creditlimit
          currid
          custentity_link_name_lsa
          custentity_mi_latest_event_date
          custentity_mil_bookedsaleslast360
          datecreated
          daysoverdue
          defaultaddress
          depositbalance
          email
          entityid
          entitytitle
          hasbillingaddress
          id
          isperson
          lastmodifieddate
          monthlyclosing
          nameorig
          overduebalance
          phone
          salesrep
          salesteamtotal
          shipaddr1
          shipaddr2
          shipaddressee
          shipcity
          shipping_country
          shipstate
          shipzip
          stage
          startdate
          sys_id
          taxable
          taxexempt
          taxitem
          territory
          type
          unbilledorders
          unsubscribe
          vatregnumber
        }
        id
        isDynamic
        name
        sublists {
          addressbook {
            addr1_initialvalue
            addr2_initialvalue
            addr3_initialvalue
            addressbookaddress_text
            addressbookaddress_type
            addressee_initialvalue
            addressid
            addrlanguage_initialvalue
            addrtext_initialvalue
            attention_initialvalue
            city_initialvalue
            country_initialvalue
            defaultbilling
            defaultshipping
            displaystate_initialvalue
            dropdownstate_initialvalue
            id
            internalid
            isnewline
            isresidential
            issyncedfromsubsidiary
            label
            override_initialvalue
            phone_initialvalue
            state_initialvalue
            sys_id
            unbilledorders
            zip_initialvalue
          }
          contactroles {
            contact
            contactname
            email
            role
            sendemail
            sys_id
          }
          creditcards {
            cardstate
            ccdefault
            ccexpiredate
            ccmemo
            ccname
            ccnumber
            ccpanid
            customercode
            internalid
            ispaymentcardtoken
            paymentmethod
            statefrom
            sys_id
          }
          currency {
            balance
            consolbalance
            consoloverduebalance
            consolunbilledorders
            currency
            displaysymbol
            hastransactionorproject
            overduebalance
            overridecurrencyformat
            symbolplacement
            sys_id
            unbilledorders
          }
          salesteam {
            contribution
            customer
            employee
            employee_display
            id
            iscontributionuserdefined
            isprimary
            issalesrep
            salesrole
            sys_id
          }
          submachine {
            balance
            balancecurrency
            depositbalance
            entity
            hasproject
            hastransaction
            sys_id
            unbilledcurrency
            unbilledorders
          }
        }
        type
      }
      salesCoor {
        firstName
        lastName
      }
      salesRep {
        firstName
        lastName
      }
    }
  }
`;
