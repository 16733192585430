import React, { CSSProperties, useEffect, useState } from 'react';
import { css, styled } from '@mui/material/styles';
import LottieAnimation from 'components/LottieAnimation/LottieAnimation';
import NavButton from 'components/NavButton/NavButton';

type Props = {
  animationData: unknown;
  active?: boolean;
  style?: CSSProperties;
  speed?: number;
  onClick?: () => void;
  label?: string;
  nudge?: boolean;
};

const StyledNavButton = styled(NavButton)(
  ({ theme, $highlight }) => css`
    transition:
      all 0.5s,
      background-color 0.3s;
    font-size: 0.6rem;

    span {
      transition:
        opacity 0.2s,
        width 0.4s;
      display: none;
      width: 0;
      opacity: 0;
      white-space: nowrap;
    }

    ${$highlight
      ? css`
          background-color: ${theme.colors.midBlue};
          color: white;
          opacity: 1;
          width: 8rem;
          border-radius: 1.25rem;
          span {
            padding-left: 0.5rem;
            display: inline;
            opacity: 1;
            width: auto;
          }
          & svg {
            opacity: 1;
          }
        `
      : ''}
  `
);

const FancyLottieNavButton = React.forwardRef<HTMLDivElement, Props>(function FancyLottieNavButton(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const { animationData, active, style, speed, onClick, label, nudge, ...remaining } = props;
  const [highlight, setHighlight] = useState<boolean>(false);
  const [playAnimation, setPlayAnimation] = useState<boolean>(false);

  const triggerAnimation = () => {
    const turnOn = setTimeout(() => setHighlight(true), 200);
    const animate = setTimeout(() => setPlayAnimation(true), 700);
    const turnOff = setTimeout(() => {
      setHighlight(false);
      setPlayAnimation(false);
    }, 1900);

    return { turnOn, animate, turnOff };
  };

  useEffect(() => {
    if (nudge) {
      triggerAnimation();
    }
  }, [nudge]);

  useEffect(() => {
    const { turnOn, animate, turnOff } = triggerAnimation();

    return () => {
      setHighlight(false);
      setPlayAnimation(false);
      clearTimeout(turnOn);
      clearTimeout(animate);
      clearTimeout(turnOff);
    };
  }, []);

  return (
    <StyledNavButton $highlight={highlight} onClick={onClick} $active={active} ref={ref} {...remaining}>
      <LottieAnimation animationData={animationData} style={style} speed={speed} autoPlay={playAnimation} />
      <span>{label}</span>
    </StyledNavButton>
  );
});

export default FancyLottieNavButton;
