import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetEmployeeDocument,
  GetEmployeeQuery,
  GetEmployeeQueryVariables,
  QueryGetEmployeeArgs,
} from '__generated__/graphql';

export const useQueryGetEmployee = (
  input: QueryGetEmployeeArgs,
  options?: QueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>
): QueryResult<GetEmployeeQuery, GetEmployeeQueryVariables> => {
  return useQuery(GetEmployeeDocument, {
    ...options,
    variables: input,
  });
};
