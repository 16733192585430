import MoreVertIcon from '@mui/icons-material/MoreVert';
import { css, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { Popover } from '@mui/material';
import useMutationDeleteComment from 'hooks/mutations/useMutationDeleteComment/useMutationDeleteComment';
import { ApolloQueryResult } from '@apollo/client';
import { Exact, GetCommentsByQuoteIdQuery } from '__generated__/graphql';
import useMutationEditComment from '../../../../../hooks/mutations/useMutationEditQuote/useMutationEditComment';
import EditCommentModal from './EditCommentModal';
import { useParams } from 'react-router-dom';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';

const MoreButton = styled(Button)<{ $blue: boolean }>(
  ({ theme, $blue = false }) => css`
    background-color: ${$blue ? theme.colors.blue : 'rgba(0, 0, 0, 0)'};
    color: ${$blue ? 'white' : theme.colors.whiteOpacity50};
    width: 2.5rem;
    height: 2.5rem;
    min-width: 0;
    padding: 1rem;
    display: flex;
    border-radius: 1.25rem;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${$blue ? theme.colors.blue : theme.colors.lighterBlack};
      color: ${$blue ? 'white' : theme.colors.white};
    }
  `
);

const PopoverButton = styled(Button)(
  ({ theme }) => css`
    background-color: ${theme.colors.blue};
    color: white;
    display: flex;
    width: 100%;
    text-transform: none;
    &:hover {
      background-color: #186ac2;
      color: white;
    }
  `
);

const ButtonContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.colors.blue};
  `
);

const StyledPopover = styled(Popover)(
  () => css`
    margin-top: 0.5em;
  `
);

type CommentPopoverProps = {
  commentId: number;
  refetch: (
    variables?: Partial<Exact<{ quoteId: string }>> | undefined
  ) => Promise<ApolloQueryResult<GetCommentsByQuoteIdQuery>>;
  setShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  currentUser: { name: string; id: string };
  commentBody: string;
};

const CommentPopover = ({
  commentId,
  refetch,
  setShowSnackbar,
  setSnackbarMessage,
  currentUser,
  commentBody,
}: CommentPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setCommentsToBeCreated } = useQuoteContext();
  const { quoteNumber } = useParams();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = anchorEl ? Boolean(anchorEl) : null;

  const [showEditComments, setShowEditComments] = useState<boolean>(false);

  const [deleteComment] = useMutationDeleteComment();

  const handleDeleteComment = async () => {
    if (!quoteNumber) {
      setCommentsToBeCreated((prev) => prev.filter((comment) => comment.id !== commentId));
      return;
    }

    try {
      const response = await deleteComment({ variables: { commentId } });

      if (response.data?.deleteComment.success) {
        refetch();
      } else {
        console.warn(response.data?.deleteComment.error);
        setSnackbarMessage('Failed to delete comment');
        setShowSnackbar(true);
      }
    } catch (error) {
      console.warn(error);
      setSnackbarMessage('Failed to delete comment');
      setShowSnackbar(true);
    }
  };

  const handleEditComment = async () => {
    setShowEditComments(true);
    setAnchorEl(null);
  };

  return (
    <>
      <MoreButton $blue={!!open} onClick={handleClick}>
        <MoreVertIcon />
      </MoreButton>
      <StyledPopover
        open={!!open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ButtonContainer>
          <PopoverButton onClick={handleEditComment}>Edit</PopoverButton>
          <PopoverButton onClick={handleDeleteComment}>Delete</PopoverButton>
        </ButtonContainer>
      </StyledPopover>
      <EditCommentModal
        showEditComments={showEditComments}
        setShowEditComments={setShowEditComments}
        owner={currentUser}
        commentId={commentId}
        refetchComments={refetch}
        setShowSnackbar={setShowSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        initialCommentBody={commentBody}
      />
    </>
  );
};

export default CommentPopover;
