import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetClientContactsDocument,
  GetClientContactsQuery,
  GetClientContactsQueryVariables,
  QueryGetClientContactsArgs,
} from '__generated__/graphql';

export const useQueryGetClientContacts = (
  input: QueryGetClientContactsArgs,
  options?: QueryHookOptions<GetClientContactsQuery, GetClientContactsQueryVariables>
): QueryResult<GetClientContactsQuery, GetClientContactsQueryVariables> => {
  return useQuery(GetClientContactsDocument, {
    ...options,
    variables: input,
  });
};
