import Container from '@mui/material/Container';
import { Grid, IconButton, Stack, css, styled } from '@mui/material';
import {
  FormikCheckbox,
  FormikInput,
  FormikRadioGroup,
  FormikSelect,
  FormikTextArea,
  FormikValuesFrom,
  createInitialValues,
} from 'utils/formik';
import {
  ClientInputAddress,
  CreateClientInput,
  CustomListType,
  LookupType,
  UpdateClientInput,
} from '__generated__/graphql';
import { Formik } from 'formik';
import { useDropdownOptions } from 'hooks/util/useDropdownOptions';
import { useQueryGetCustomList } from 'hooks/queries/useQueryGetCustomList/useQueryGetCustomList';
import { useClientContext } from 'contexts/ClientContext/ClientContext';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Flex from 'components/Flex/Flex';
import { useQueryGetSubsidiaries } from 'hooks/queries/useQueryGetSubsidiaries/useQueryGetSubsidiaries';
import PageHeader from 'components/PageHeader/PageHeader';
import { useMemo, useState } from 'react';
import { useQueryGetLookup } from 'hooks/queries/useQueryGetLookup/useQueryGetLookup';
import FormLabel from 'components/FormLabel/FormLabel';
import FormInput from 'components/FormInput/FormInput';
import SetAddressModal from './components/SetAddressModal';
import { Tooltip } from 'components/Tooltip/Tooltip';
import LaunchIcon from '@mui/icons-material/Launch';
import { useQueryGetClassifications } from 'hooks/queries/useQueryGetClassifications/useQueryGetClassifications';
import { useQueryGetDepartments } from 'hooks/queries/useQueryGetDepartments/useQueryGetDepartments';

const PaddedLabel = styled(FormLabel)(
  () => css`
    padding-top: 1rem;
  `
);

const formFields = {
  customerId: 'Customer ID',
  type: 'Type',
  name: 'Company Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  phone: 'Phone',
  email: 'Email',
  subsidiary: 'Subsidiary',
  fax: 'Fax',
  poNumberRequired: 'PO # REQ',
  website: 'Web Address',
  isInactive: 'Inactive',
  pleaseReadClientNotes: 'Please Read - Client Notes',
  materialsNeeded: 'Materials Needed / Quote Requirements',
  salesPlan: 'Sales Plan',
  projectedAnnualSales: 'Projected Annual Sales',
  estimatedMarketingBudget: 'Estimated Marketing Budget',
  status: 'Status',
  doesNotReceiveFulfillmentNotifications: 'Does not receive fulfillment notifications',
  doNotSendTuesdayPickupEmails: 'Do not send Tuesday pickup emails',
  doNotSendThursdayPickupEmails: 'Do not send Thursday pickup emails',
  clientNotes: 'Client Notes',
  syncWithHubSpot: 'Sync with HubSpot',
  circuit: 'Circuit Number',
  category: 'Category',
  market: 'Market',
  accountNumber: 'Account Number',
  territory: 'Territory',
};

const requiredFields: string[] = [
  'customerId',
  'name',
  'firstName',
  'lastName',
  'status',
  'circuit',
  'category',
  'market',
  'accountNumber',
  'territory',
];

type FormikValues = FormikValuesFrom<typeof formFields, CreateClientInput>;

type Props = {
  disabled?: boolean;
};

const ClientForm = ({ disabled }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { clientInput, setClientInput } = useClientContext();
  const { data: poRequiredData } = useQueryGetCustomList({ id: CustomListType.PoNumberRequired });
  const poRequiredOptions = useDropdownOptions(poRequiredData?.getCustomList.customValueList?.customValue);

  const { data: circuitNumberData } = useQueryGetCustomList({ id: CustomListType.CircuitNumber });
  const circuitNumberOptions = useDropdownOptions(circuitNumberData?.getCustomList.customValueList?.customValue);

  const { data: subsidiariesData } = useQueryGetSubsidiaries();
  const subsidiaryOptions = useDropdownOptions(subsidiariesData?.getSubsidiaries);

  const { data: territoriesData } = useQueryGetLookup({ input: { type: LookupType.Territories } });
  const territoryOptions = useMemo(() => {
    return territoriesData?.getLookup
      ? Object.entries(territoriesData.getLookup)
          .map(([key, value]) => ({
            label: value,
            id: key,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];
  }, [territoriesData]);

  const { data: departmentsData } = useQueryGetDepartments();
  const markets = useMemo(
    () =>
      departmentsData?.getDepartments
        .map((department) => {
          return {
            id: department.id,
            name: department.parent?.name ? `${department.parent.name} : ${department.name}` : `${department.name}`,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    [departmentsData]
  );
  const marketOptions = useDropdownOptions(markets);

  const { data: categoryData } = useQueryGetLookup({ input: { type: LookupType.ClientCategory } });
  const categoryOptions = useMemo(() => {
    return categoryData?.getLookup
      ? Object.entries(categoryData.getLookup)
          .map(([key, value]) => ({
            label: value,
            id: key,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];
  }, [categoryData]);

  const { data: statusData } = useQueryGetLookup({ input: { type: LookupType.ClientStatus } });
  const statusOptions = useMemo(() => {
    return statusData?.getLookup
      ? Object.entries(statusData.getLookup).map(([key, value]) => ({
          label: value,
          id: key,
        }))
      : [];
  }, [statusData]);

  const initialValues = useMemo(() => {
    return {
      ...clientInput,
      projectedAnnualSales: clientInput?.projectedAnnualSales?.toString() ?? '',
      estimatedMarketingBudget: clientInput?.estimatedMarketingBudget?.toString() ?? '',
    };
  }, [clientInput]);

  const toCreateClientInput = (values: FormikValues): UpdateClientInput => {
    return {
      ...clientInput!,
      ...values,
      estimatedMarketingBudget: values.estimatedMarketingBudget ? parseFloat(values.estimatedMarketingBudget) : null,
      projectedAnnualSales: values.projectedAnnualSales ? parseFloat(values.projectedAnnualSales) : null,
      doesNotReceiveFulfillmentNotifications: values.doesNotReceiveFulfillmentNotifications === true,
      doNotSendTuesdayPickupEmails: values.doNotSendTuesdayPickupEmails === true,
      doNotSendThursdayPickupEmails: values.doNotSendThursdayPickupEmails === true,
      syncWithHubSpot: values.syncWithHubSpot === true,
    };
  };

  const formatAddress = (address: ClientInputAddress | null | undefined) => {
    if (!address) return '';
    return [
      address.addressee,
      address.address1,
      address.address2,
      `${address.city}, ${address.state} ${address.zip}`,
      address.country,
    ]
      .filter((value) => value?.length > 0)
      .join('\n');
  };

  const handleValidate = (values: FormikValues) => {
    setClientInput(toCreateClientInput(values));
  };

  return (
    <Container>
      <Formik
        initialValues={createInitialValues<FormikValues>(formFields, initialValues)}
        validate={handleValidate}
        onSubmit={() => {}}
      >
        {({ values, handleChange, setFieldValue }) => {
          const props = {
            handleChange,
            values,
            requiredFields,
            formFields,
            disabled,
          };

          return (
            <>
              <SetAddressModal
                title='Address'
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onSubmit={(newAddress) => {
                  if (clientInput) {
                    setClientInput({ ...clientInput, address: newAddress });
                  }
                  setModalOpen(false);
                }}
                values={clientInput?.address}
              />
              <Grid container spacing={4}>
                {/* Left */}
                <Grid item xs={12} tablet={6}>
                  <Stack spacing={4}>
                    <FormikInput {...props} name='customerId' />
                    <FormikRadioGroup
                      {...props}
                      name='type'
                      options={[
                        { label: 'Company', id: false },
                        { label: 'Individual', id: true },
                      ]}
                      handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('type', event.target.value == 'true');
                      }}
                    />
                    {values.type === true && (
                      <>
                        <FormikInput {...props} name='firstName' />
                        <FormikInput {...props} name='lastName' />
                      </>
                    )}
                    <FormikInput {...props} name='name' required={values.type !== true} />
                    <FormikInput {...props} name='phone' />
                    <FormikInput {...props} name='email' />
                    <FormikSelect {...props} disabled options={subsidiaryOptions} name='subsidiary' />
                    <FormikInput {...props} name='fax' placeholder='(xxx)-xxx-xxxx' />
                    <FormikSelect {...props} options={poRequiredOptions} name='poNumberRequired' />
                    <FormikInput {...props} name='website' />
                    <FormikCheckbox {...props} name='isInactive' />
                    <FormikSelect {...props} options={categoryOptions} name='category' />
                    {clientInput?.id === '' && (
                      <>
                        <Flex center>
                          <Container>
                            <PaddedLabel>Address</PaddedLabel>
                            <FormInput
                              disableUnderline
                              value={formatAddress(clientInput?.address)}
                              multiline
                              disabled
                              rows={5}
                            />
                          </Container>
                          <Tooltip title='Edit Address'>
                            <IconButton
                              onClick={() => {
                                setModalOpen(true);
                              }}
                            >
                              <LaunchIcon />
                            </IconButton>
                          </Tooltip>
                        </Flex>
                      </>
                    )}
                  </Stack>
                </Grid>

                {/* Right */}
                <Grid item xs={12} tablet={6}>
                  <Stack spacing={4}>
                    <FormikTextArea {...props} name='materialsNeeded' multiline fullWidth rows={5} />
                    <FormikTextArea {...props} name='salesPlan' multiline fullWidth rows={5} />
                    <FormikInput {...props} name='projectedAnnualSales' type='number' />
                    <FormikInput {...props} name='estimatedMarketingBudget' type='number' />
                    <FormikSelect {...props} options={statusOptions} name='status' />
                    <FormikCheckbox {...props} name='doesNotReceiveFulfillmentNotifications' />
                    <FormikCheckbox {...props} name='doNotSendTuesdayPickupEmails' />
                    <FormikCheckbox {...props} name='doNotSendThursdayPickupEmails' />
                    <FormikTextArea {...props} name='pleaseReadClientNotes' multiline fullWidth rows={5} />
                    <FormikCheckbox {...props} name='syncWithHubSpot' />
                    <FormikSelect {...props} options={circuitNumberOptions} name='circuit' />
                    <FormikSelect {...props} options={marketOptions} name='market' />
                    <FormikInput {...props} name='accountNumber' />
                    <FormikSelect {...props} options={territoryOptions} name='territory' />
                  </Stack>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

const Client = ({ disabled }: { disabled?: boolean }) => {
  const { clientInput } = useClientContext();

  if (!clientInput) {
    return (
      <Flex w100 styles={{ height: 'calc(100vh - 8rem)' }} center>
        <LoadingSpinner loading={true} size={92} />
      </Flex>
    );
  }

  return <ClientForm disabled={disabled} />;
};

export default Client;
