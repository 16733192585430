import React from 'react';
import { Dialog as BaseDialog } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import Button from '@mui/material/Button';
import { css, styled } from '@mui/material/styles';
import Flex from 'components/Flex/Flex';

const Dialog = styled(BaseDialog)`
  .MuiPaper-root {
    border-radius: 1rem;
  }
`;
const ContentContainer = styled('div')`
  padding: 2rem 2.5rem;
  border-radius: 1rem;
`;

const Message = styled('span')`
  padding-left: 1rem;
`;

const ConfirmButton = styled(Button)(
  ({ theme }) => css`
    width: 16rem;
    background-color: ${theme.colors.blue};
    color: white;
    border-radius: 2rem;
    font-size: 0.75rem;
    padding: 0.25rem 1rem;
    font-weight: 700;

    &:hover {
      background-color: ${theme.colors.lightBlue};
    }
  `
);

const ButtonWrapper = styled('div')`
  width: auto;
  padding: 1.5rem 0 0.5rem 0;
`;

const WarningModal: React.FC<{ open: boolean; onClose: () => void; message: string }> = ({
  open,
  onClose,
  message,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <ContentContainer>
        <Flex column center>
          <Flex>
            <ReportIcon fontSize='large' />
            <Message>{message}</Message>
          </Flex>
          <ButtonWrapper>
            <ConfirmButton onClick={onClose}>OK</ConfirmButton>
          </ButtonWrapper>
        </Flex>
      </ContentContainer>
    </Dialog>
  );
};

export default WarningModal;
