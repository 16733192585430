import { useMediaQuery } from '@mui/material';
import { useTheme } from 'styled-components';

export const useBreakpoints = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('mobile'));
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.up('tablet'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));

  return { isMobile, isSmall, isMedium, isTablet, isLarge, isExtraLarge };
};
