import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetItemGroupDocument,
  GetItemGroupQuery,
  GetItemGroupQueryVariables,
  QueryGetItemGroupArgs,
} from '__generated__/graphql';

export const useQueryGetItemGroup = (
  input: QueryGetItemGroupArgs,
  options?: QueryHookOptions<GetItemGroupQuery, GetItemGroupQueryVariables>
): QueryResult<GetItemGroupQuery, GetItemGroupQueryVariables> => {
  return useQuery(GetItemGroupDocument, {
    ...options,
    variables: input,
  });
};
