import React from 'react';
import { SwitchProps } from '@mui/material';
import { createTheme, ThemeOptions } from '@mui/material/styles';

export const HEADER_HEIGHT = 64;

interface ThemeColors {
  charcoal: string;
  lightBlack: string;
  lighterBlack: string;
  lightGray: string;
  lightInput: string;
  lighterInput: string;
  gray: string;
  darkGray: string;
  hoverGray: string;
  black: string;
  black95: string;
  blackOpacity50: string;
  blackOpacity55: string;
  white: string;
  whiteOpacity25: string;
  whiteOpacity50: string;
  whiteOpacity65: string;
  purple: string;
  green: string;
  red: string;
  yellow: string;
  lightBlue: string;
  midBlue: string;
  blue: string;
  hoverBlue: string;
  vividBlue: string;
  weeblyOrange: string;
  bevelTop: string;
  bevelLeft: string;
  darkBoundary: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    input: Palette['primary'];
  }

  interface PaletteOptions {
    input?: PaletteOptions['primary'];
  }

  // Allow configuration using `createMuiTheme`.
  interface ThemeOptions {
    colors: ThemeColors;
    breakpoints: {
      values: {
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
        mobile: number;
        tablet: number;
      };
    };
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
  }

  interface TypographyVariants {
    menuitem: React.CSSProperties;
    tableText: React.CSSProperties;
    subtext: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    menuitem: React.CSSProperties;
    tableText: React.CSSProperties;
    subtext: React.CSSProperties;
  }

  interface Theme {
    colors: ThemeColors;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    menuitem: true;
    tableText: true;
    subtext: true;
  }
}

const fontFamily = '"Montserrat", sans-serif';

export const colors: ThemeColors = {
  charcoal: '#312f32',
  lightBlack: '#141414',
  lighterBlack: '#1f1f1f',
  lightGray: '#404040',
  lightInput: '#3d3b3f',
  lighterInput: '#dbdbdb',
  gray: '#606060',
  darkGray: '#313032',
  hoverGray: '#525252',
  black: '#000000',
  blackOpacity50: 'rgba(0, 0, 0, 0.5)',
  blackOpacity55: 'rgba(0, 0, 0, 0.55)',
  black95: 'rgba(20, 20, 20, 0.95)',
  white: '#ffffff',
  whiteOpacity25: 'rgba(255, 255, 255, 0.25)',
  whiteOpacity50: 'rgba(255, 255, 255, 0.5)',
  whiteOpacity65: 'rgba(255, 255, 255, 0.65)',
  purple: '#aa3bff',
  green: '#19aa4e',
  red: '#dc2c1a',
  yellow: '#e5c929',
  lightBlue: '#3898ec',
  midBlue: '#1a78dc',
  blue: '#1a78dc',
  hoverBlue: '#228dff',
  vividBlue: '#0065ff',
  weeblyOrange: '#ff9a00',
  bevelTop: '#606060',
  bevelLeft: 'rgba(0, 0, 0, 0)',
  darkBoundary: 'black',
};

export const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 472,
      tablet: 992,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: colors.charcoal,
    },
    secondary: {
      main: colors.lightBlack,
    },
    info: {
      main: colors.black95,
    },
    input: {
      main: colors.lighterBlack,
    },
    text: {
      primary: colors.white,
      secondary: '#999',
      disabled: colors.whiteOpacity25,
    },
    background: {
      default: colors.lightBlack,
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
      },
      styleOverrides: {
        colorInherit: {
          backgroundColor: colors.white,
          color: '#353435',
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
        avatar: {
          borderColor: colors.blackOpacity50,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        circular: {
          fontSize: '1.25rem',
          fontWeight: 500,
          borderColor: colors.blackOpacity50,
          color: colors.white,
          textShadow: `.0625rem .0625rem .25rem ${colors.black95}`,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: `${HEADER_HEIGHT / 16}rem`,
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
        disableGutters: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
        outlined: {
          padding: '0.5rem 2rem',
          borderRadius: '2rem',
          height: '2.875rem',
        },
        outlinedPrimary: {
          border: `0.0625rem solid ${colors.blue}`,
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.blue,
          },
        },
        outlinedSecondary: {
          color: colors.whiteOpacity50,
          '&:hover': {
            color: colors.white,
            borderColor: colors.charcoal,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: colors.charcoal,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 'auto',
          height: 'auto',
          overflow: 'visible',
          '&:hover': {
            '.MuiSwitch-track': {
              border: `0.0625rem solid ${colors.midBlue}`,
            },
          },
          '&.Mui-checked': {
            '.MuiSwitch-track': {
              backgroundColor: colors.midBlue,
            },
          },
        },
        thumb: {
          width: '1.75rem',
          height: '1.75rem',
          position: 'relative',
          top: '0.4375rem',
          left: '0.5rem',
          backgroundColor: `${colors.black} !important`,
          borderTop: `0.0625rem solid ${colors.gray}`,
        },
        track: ({ ownerState }: { ownerState: SwitchProps }) => ({
          height: '2.25rem',
          width: '4.75rem',
          borderRadius: '1.125rem',
          opacity: '1 !important',
          transition: 'background-color 0.5s ease',
          backgroundColor: ownerState.checked ? `${colors.midBlue} !important` : `${colors.charcoal}`,
        }),
        switchBase: {
          transitionDuration: '500ms',
          '&.Mui-checked': {
            transform: 'translateX(2.5rem)',
          },
          '&:hover, &:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
  typography: {
    fontFamily,
    fontSize: 16,
    h1: {
      fontFamily: 'Montserrat',
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: 1,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontSize: '1.75',
      fontWeight: 700,
      lineHeight: 1.08,
    },
    h3: {
      fontFamily: 'Montserrat',
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h4: {
      fontFamily: 'Montserrat',
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.25,
    },
    h5: {
      fontFamily: 'Montserrat',
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: 1.22,
    },
    h6: {
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.29,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.75rem',
    },
    button: {
      fontFamily: 'Montserrat',
      fontSize: '0.8rem',
      fontWeight: '600',
      textTransform: 'uppercase',
      lineHeight: 'inherit',
    },
    menuitem: {
      fontFamily: 'Montserrat',
      fontSize: '.8rem',
      fontWeight: 600,
      color: colors.whiteOpacity50,
      textTransform: 'uppercase',
    },
    subtext: {
      fontFamily: 'Montserrat',
      fontSize: '.75rem',
      fontWeight: 700,
      color: colors.whiteOpacity50,
      textTransform: 'uppercase',
    },
    tableText: {
      fontFamily: 'Montserrat',
      fontSize: '.75rem',
      fontWeight: 700,
      color: colors.white,
    },
  },
  colors,
};

export default createTheme(themeOptions);
