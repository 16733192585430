/*
These are hard-coded lookups for all of the non-people entities.  This shouldn't be a permanent solution, but it can be used to build selects or match a value to an ID.

The queries are what I used to find the lookups.  For queries where we aren't getting a quote ID, we can get that quote from netsuite and record the appropriate entity ID
*/

/*
SELECT DISTINCT status, max(internalid) AS example_internalid
FROM mil_sales.transaction
WHERE _type = 'Estimate'
GROUP BY status;
*/
export const statusLookup = {
  Closed: 'C',
  Expired: 'X',
  Processed: 'B',
  Voided: 'V',
  Open: 'A',
};
