import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { css, styled } from '@mui/material/styles';
import { ModalFormLabel } from 'components/FormLabel/FormLabel';
import Flex from 'components/Flex/Flex';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import QuoteItemInventoryInput from './components/QuoteItemInventoryInput/QuoteItemInventoryInput';
import {
  itemMarginFromCostPrice,
  ItemTableItem,
  nonInventoryTypesWithQuantity,
  UpdatableField,
  UpdatableFieldValue,
} from 'contexts/QuoteContext/useItems';
import { useQueryListVendors } from 'hooks/queries/useQueryListVendors/useQueryListVendors';
import { CustomListType, LookupType, QuoteItemInventory, VendorListItem } from '__generated__/graphql';
import { useQueryGetLocations } from 'hooks/queries/useQueryGetLocations/useQueryGetLocations';
import { Autocomplete, InputAdornment, ListItemBaseProps, TextFieldProps } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useQueryGetLookup } from 'hooks/queries/useQueryGetLookup/useQueryGetLookup';
import ItemFormColumn, { StyledInfoIcon } from './components/ItemFormColumn';
import { AutocompleteOption } from './components/ItemFormConstants';
import { useLazyQueryGetEstimatedUnitCost } from 'hooks/queries/useLazyQueryGetEstimatedUnitCost/useLazyQueryGetEstimatedUnitCost';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { ModalFormTextField } from 'components/FormInput/FormInput';
import { useQueryGetCustomList } from 'hooks/queries/useQueryGetCustomList/useQueryGetCustomList';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useBreakpoints } from 'hooks/util/useBreakpoints';

const LocationsAutocomplete = styled(Autocomplete)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.input.main};
    border-radius: 0.5rem;

    .MuiButtonBase-root {
      display: none;
    }

    svg {
      position: absolute;
      right: 0.5rem;
    }

    input {
      width: 100%;
      font-size: 1rem;

      &:focus {
        + svg {
          transform: rotate(180deg);
        }
      }
    }

    fieldset {
      border: none;
      padding: 0.313rem 0;
    }
  `
);

const StyledListItem = styled('li')(
  ({ theme }) => css`
    background: ${theme.palette.input.main};
  `
);

const Fieldset = styled('fieldset')(
  ({ theme }) => css`
    border: none;
    padding: 0;
    margin: 0;
  `
);

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)<{ $disabled: boolean }>(
  ({ theme, $disabled }) => css`
    background: ${theme.palette.input.main};
    color: ${$disabled ? `${theme.palette.text.disabled}` : 'inherit'};
    cursor: pointer;
  `
);

const DropdownOptionContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 1rem;
    color: ${theme.palette.text.primary};
  `
);

const OnHandAvailWrapper = styled('div')(
  () => css`
    font-size: 0.85rem;
    padding-right: 6rem;
  `
);

const WarehouseNameWrapper = styled('div')(
  () => css`
    font-size: 0.85rem;
  `
);

const Subtitle = styled('p')(
  ({ theme }) => css`
    font-size: 0.75rem;
    font-weight: 600;
    color: ${theme.colors.whiteOpacity50};
    margin: 0;
    padding-bottom: 0.25rem;
  `
);

const ColumnHeaders = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: ${theme.palette.text.primary};
    padding: 0.25rem;

    p {
      width: fit-content;
    }

    p:nth-child(1) {
      margin-right: auto;
    }

    p:nth-child(2) {
      margin-right: 3.438rem;
    }

    p:nth-child(3) {
      margin-right: 2.063rem;
    }
  `
);

const QuantitiesWarehousesContainer = styled('div')(
  () => css`
    width: 100%;
  `
);

const leftKeyList: string[] = [
  'costEstimateType',
  'estimatedUnitCost',
  'unitCost',
  'estimatedTotalCost',
  'margin',
  'adjCost',
];

const rightKeyList: string[] = [
  'description',
  'leadTime',
  'total',
  'vendor',
  'vendorQuoteReference',
  'vendorPORate',
  'deviationCode',
  'grossProfit',
  'grossProfitPercent',
  'isTaxable',
];

type Location = {
  id: string;
  name?: string | null;
  onHandAvail?: string | null;
  inStock?: boolean;
};

type Props = {
  index: number;
};

const assignAvailableStockToLocations = (locations?: Location[], item?: ItemTableItem) => {
  const bins = item?.inventoryDetail?.binNumber;
  if (bins && locations) {
    bins.forEach((bin) => {
      const index = locations.findIndex((location) => location.id === bin.info?.id);
      if (index > -1) {
        if (Number(locations[index].onHandAvail) > 0) {
          locations[index].onHandAvail = String(Number(locations[index].onHandAvail) + Number(bin.onHandAvail));
        } else {
          locations[index].onHandAvail = bin.onHandAvail;
          locations[index].inStock = true;
        }
      }
    });
  }
  return locations;
};

type SyncFormWithItemProps = {
  setValues: (values: SetStateAction<ItemTableItem>, shouldValidate?: boolean | undefined) => void;
  vendorizedItem: ItemTableItem;
};

const SyncFormWithItem = ({ setValues, vendorizedItem }: SyncFormWithItemProps) => {
  useEffect(() => {
    setValues(vendorizedItem);
  }, [vendorizedItem, setValues]);

  return null;
};

const UpdateItemForm: React.FC<Props> = ({ index }) => {
  const { updateItemField, updateItemFields, itemsTableItems } = useQuoteContext();
  const item = itemsTableItems[index];
  const { data: preferredVendorData } = useQueryListVendors({ itemInternalId: item.item.id });
  const { data: vendorData } = useQueryListVendors();

  const { data: deviationCodeListData } = useQueryGetCustomList({ id: CustomListType.DeviationCode });
  const deviationCodeOptions = useMemo(
    () =>
      deviationCodeListData?.getCustomList?.customValueList?.customValue?.map((opt) => ({
        label: opt.value,
        id: `${opt.valueId}`,
      })) ?? [],
    [deviationCodeListData]
  );

  const { data: leadTimeListData } = useQueryGetCustomList({ id: CustomListType.LeadTime });
  const leadTimeOptions = useMemo(
    () =>
      leadTimeListData?.getCustomList?.customValueList?.customValue?.map((opt) => ({
        label: opt.value,
        id: `${opt.valueId}`,
      })) ?? [],
    [leadTimeListData]
  );

  const vendorOpts = useMemo(() => {
    const allVendors = vendorData?.listVendors.vendors ?? [];
    const preferedVendors = preferredVendorData?.listVendors.vendors ?? [];
    const nonPreferredVendors = allVendors.filter((v) => preferedVendors.findIndex((pv) => pv.id === v.id) === -1);

    const preferredOptions =
      preferedVendors.map((vendor: VendorListItem) => ({
        label: vendor.name,
        id: vendor.id,
        group: 'Preferred Vendors',
      })) ?? [];
    const nonPreferredOptions =
      nonPreferredVendors.map((vendor: VendorListItem) => ({
        label: vendor.name,
        id: vendor.id,
        group: 'All Other Vendors',
      })) ?? [];

    return [...preferredOptions, ...nonPreferredOptions];
  }, [preferredVendorData, vendorData]);

  const { data: locationsData, loading: loadingLocations } = useQueryGetLocations();
  const locations = useMemo(() => {
    if (!loadingLocations) {
      return assignAvailableStockToLocations(
        locationsData?.getLocations.map((location: Location) => ({
          ...location,
          onHandAvail: null,
        })),
        item
      );
    }
  }, [locationsData?.getLocations, item, loadingLocations]);
  const { data: costEstimateTypeData } = useQueryGetLookup({ input: { type: LookupType.CostEstimateType } });
  const { clientData } = useQuoteContext();

  //This manages the state of the warehouse selection dropdown. We have to manage it manually
  //to make the end adornment work as a control to open/close the dropdown
  const [warehouseOpen, setWarehouseOpen] = useState<boolean>(false);

  const [getEstimatedUnitCost, { loading: estimatedUnitCostLoading }] = useLazyQueryGetEstimatedUnitCost();
  const costEstimateTypeOptions = useMemo(() => {
    return costEstimateTypeData ? costEstimateTypeData.getLookup : {};
  }, [costEstimateTypeData]);

  const grossProfitDollar =
    item.adjCost && item.unitCost && item.quantity ? ((item.adjCost - item.unitCost) * item.quantity).toFixed(2) : null;
  const grossProfitPercentage =
    item.adjCost && item.unitCost ? itemMarginFromCostPrice(Number(item.unitCost), Number(item.adjCost)) : null;

  const { isMedium } = useBreakpoints();

  const getEstimatedUnitCostInput = (costestimatetype: string, locationId?: string) => {
    return {
      client: clientData!.id,
      items: [
        {
          item: item.item.id,
          costestimatetype,
          location: locationId,
        },
      ],
    };
  };

  const vendorizedItem = useMemo(() => {
    if (
      !vendorData?.listVendors.vendors ||
      !Object.keys(costEstimateTypeOptions).length ||
      !leadTimeOptions.length ||
      !deviationCodeOptions.length
    ) {
      return null;
    }
    //when set by Stitch, item.vendor will be the entityId, but when set by us after updating a quote
    //it will be id. so we accommodate both. when we save, we need to use id for Netsuite.
    // This logic is repeated for costEstimateType, leadTime, deviationCode, and isTaxable
    const selectedVendor = vendorData?.listVendors.vendors.find(
      (v: VendorListItem) => v.id === itemsTableItems[index].vendor || v.entityId === itemsTableItems[index].vendor
    );
    const selectedIsTaxable = itemsTableItems[index].isTaxable === true ? '1' : '0';

    return {
      ...itemsTableItems[index],
      vendor: selectedVendor?.id ?? '',
      isTaxable: selectedIsTaxable,
      grossProfit: grossProfitDollar,
      grossProfitPercent: grossProfitPercentage,
    };
  }, [
    itemsTableItems,
    vendorData?.listVendors.vendors,
    grossProfitDollar,
    grossProfitPercentage,
    costEstimateTypeOptions,
    deviationCodeOptions,
    leadTimeOptions,
    index,
  ]);

  if (vendorizedItem) {
    return (
      <Flex column w100>
        {/* we injected the Autocomplete style vendor data into the vendorizedItem. I couldn't find where*/}
        {/* Formik is getting its type data, or I'd adjust it there to accept the updated object */}
        <Formik initialValues={vendorizedItem as unknown as ItemTableItem} onSubmit={() => {}}>
          {({ values, handleChange, setValues }) => {
            const selectedWarehouses: Location[] = [];

            if (values?.inventory?.length) {
              values?.inventory?.forEach((inv) => {
                const existingLocation = locations?.find((location) => location.id === inv.id);

                if (existingLocation) {
                  selectedWarehouses.push(existingLocation);
                }
              });
            }

            return (
              <Fieldset disabled={estimatedUnitCostLoading} style={{ width: '100%' }}>
                {/* Updates form with calculated item values after change */}
                <SyncFormWithItem setValues={setValues} vendorizedItem={vendorizedItem as unknown as ItemTableItem} />

                <Flex column={!isMedium} w100 gap={isMedium ? 3 : 0.8} styles={{ width: '100%', flexWrap: 'no-wrap' }}>
                  <Flex column gap={0.8} styles={{ width: '100%' }}>
                    <QuantitiesWarehousesContainer>
                      <ModalFormLabel>
                        Quantities and Warehouses
                        <Tooltip title='Please select a warehouse and quantity when quoting inventory items.'>
                          <StyledInfoIcon />
                        </Tooltip>
                      </ModalFormLabel>

                      {selectedWarehouses.length > 0 && (
                        <>
                          <ColumnHeaders>
                            <Subtitle>Warehouse</Subtitle>
                            <Subtitle>Available</Subtitle>
                            <Subtitle>Quantity</Subtitle>
                          </ColumnHeaders>
                          {selectedWarehouses?.map((location) => {
                            const { id, name, onHandAvail } = location;
                            return (
                              <QuoteItemInventoryInput
                                index={index}
                                key={`${id}-${name}`}
                                location={name ?? ''}
                                totalOnHand={Number(onHandAvail ?? 0)}
                                values={values}
                                setValues={setValues}
                              />
                            );
                          })}
                        </>
                      )}
                      {selectedWarehouses.length === 0 && (
                        <>
                          <ModalFormLabel required>Add Warehouse</ModalFormLabel>
                          <LocationsAutocomplete
                            disabled={(values?.inventory && values?.inventory.length > 0) || estimatedUnitCostLoading}
                            open={warehouseOpen}
                            onOpen={() => setWarehouseOpen(true)}
                            onClose={() => setWarehouseOpen(false)}
                            value={selectedWarehouses ?? []}
                            isOptionEqualToValue={(option: AutocompleteOption, value: AutocompleteOption) =>
                              option.id === value.id
                            }
                            onChange={async (event: React.ChangeEvent, newValue: Location[] | null) => {
                              if (!newValue || !newValue.length) {
                                return;
                              }

                              const newInventory: QuoteItemInventory = {
                                id: newValue[0].id,
                                location: newValue[0].name ?? '',
                                quantity: 0,
                              };

                              // Update the item which will also trigger a form update
                              updateItemField(index, 'inventory', [newInventory]);

                              // If there is a cost estimate type set, fetch the estimated unit cost based on the item's location
                              const costEstimateType = values.costEstimateType;

                              if (!costEstimateType || costEstimateType === 'CUSTOM') {
                                return;
                              }

                              try {
                                const res = await getEstimatedUnitCost({
                                  variables: {
                                    input: getEstimatedUnitCostInput(costEstimateType, newInventory.id),
                                  },
                                });

                                const estimatedUnitCost = Number(
                                  res.data?.getEstimatedUnitCosts?.items?.[0]?.estimatedUnitCost
                                );

                                if (isNaN(estimatedUnitCost)) {
                                  return;
                                }

                                const quantity = values?.inventory?.reduce((acc, curr) => acc + curr.quantity, 0);

                                const updates: Partial<ItemTableItem> = {
                                  costEstimateType,
                                  estimatedUnitCost,
                                  estimatedTotalCost: estimatedUnitCost * Number(quantity ?? 0),
                                };

                                if (estimatedUnitCost > 0) {
                                  updates.unitCost = estimatedUnitCost;
                                }

                                // Update the item which will also trigger a form update
                                updateItemFields(index, updates as Record<UpdatableField, UpdatableFieldValue>);
                              } catch (error) {
                                console.warn(error);
                              }
                            }}
                            multiple
                            options={
                              locations ? locations.sort((a, b) => Number(b.onHandAvail) - Number(a.onHandAvail)) : []
                            }
                            filterSelectedOptions={true}
                            groupBy={(option: Location) => (Number(option.onHandAvail) ? 'In Stock' : 'Out of Stock')}
                            getOptionLabel={(option: Location) => option.name}
                            renderInput={(params: TextFieldProps) => (
                              <ModalFormTextField
                                {...params}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                  if (e.key === 'Backspace') {
                                    e.stopPropagation();
                                  }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <InputAdornment
                                      position='end'
                                      onClick={() => {
                                        if (!values?.inventory?.length) {
                                          setWarehouseOpen((cur) => !cur);
                                        }
                                      }}
                                    >
                                      <StyledArrowDropDownIcon
                                        $disabled={values?.inventory && values?.inventory.length > 0}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                            renderOption={(props: ListItemBaseProps, option: Location) => {
                              return (
                                <StyledListItem {...props}>
                                  <DropdownOptionContainer>
                                    <WarehouseNameWrapper key={`${option.id}-name`}>{option.name}</WarehouseNameWrapper>
                                    <OnHandAvailWrapper key={`${option.id}-stock`}>
                                      {option.onHandAvail ?? 0}
                                    </OnHandAvailWrapper>
                                  </DropdownOptionContainer>
                                </StyledListItem>
                              );
                            }}
                          />
                        </>
                      )}
                    </QuantitiesWarehousesContainer>
                    <ItemFormColumn
                      index={index}
                      vendorOpts={vendorOpts}
                      values={values}
                      handleChange={handleChange}
                      initialVendorSearch={vendorizedItem?.vendor ?? ''}
                      costEstimateTypeOptions={costEstimateTypeOptions}
                      keyList={
                        nonInventoryTypesWithQuantity.includes(item.type ?? '')
                          ? ['quantity', ...leftKeyList]
                          : leftKeyList
                      }
                      estimatedUnitCostLoading={estimatedUnitCostLoading}
                      getEstimatedUnitCost={getEstimatedUnitCost}
                      getEstimatedUnitCostInput={getEstimatedUnitCostInput}
                      setValues={setValues}
                      hasSelectedWarehouses={selectedWarehouses.length > 0}
                    />
                  </Flex>
                  <Flex column gap={0.8} styles={{ width: '100%' }}>
                    <ItemFormColumn
                      index={index}
                      vendorOpts={vendorOpts}
                      values={values}
                      handleChange={handleChange}
                      initialVendorSearch={vendorizedItem?.vendor ?? ''}
                      costEstimateTypeOptions={costEstimateTypeOptions}
                      keyList={rightKeyList}
                      estimatedUnitCostLoading={estimatedUnitCostLoading}
                      getEstimatedUnitCost={getEstimatedUnitCost}
                      getEstimatedUnitCostInput={getEstimatedUnitCostInput}
                      setValues={setValues}
                      hasSelectedWarehouses={selectedWarehouses.length > 0}
                    />
                  </Flex>
                </Flex>
              </Fieldset>
            );
          }}
        </Formik>
      </Flex>
    );
  }
  return (
    <Flex w100 h100 center>
      <LoadingSpinner loading size={63} />
    </Flex>
  );
};

export default UpdateItemForm;
