import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryGetClient } from 'hooks/queries/useQueryGetClient/useQueryGetClient';
import {
  CreateClientInput,
  CreateClientMutation,
  GetClientQuery,
  GetClientV2Query,
  UpdateClientInput,
  UpdateClientMutation,
} from '__generated__/graphql';
import { useParams } from 'react-router-dom';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { useQueryGetClientV2 } from 'hooks/queries/useQueryGetClientV2/userQueryGetClientV2';
import { toModel } from 'utils/graphqlHelpers';
import useMutationCreateClient from 'hooks/mutations/useMutationCreateClient/useMutationCreateClient';
import useMutationUpdateClient from 'hooks/mutations/useMutationUpdateClient/useMutationUpdateClient';

export type Client = GetClientV2Query['getClientV2'];

type ClientContextValue = {
  client?: GetClientQuery['getClient'];
  loading?: boolean;
  refetch: () => Promise<ApolloQueryResult<GetClientQuery>>;

  clientV2?: Client;
  clientV2Loading?: boolean;
  refetchClientV2: () => Promise<ApolloQueryResult<GetClientV2Query>>;

  clientInput?: UpdateClientInput;
  setClientInput: React.Dispatch<React.SetStateAction<UpdateClientInput | undefined>>;
  submitClient: () => Promise<FetchResult<UpdateClientMutation | CreateClientMutation> | undefined>;
};

const ClientContext = createContext<ClientContextValue | undefined>(undefined);

type ClientContextProviderProps = {
  children: React.ReactNode;
};

const ClientContextProvider: React.FC<ClientContextProviderProps> = ({ children }) => {
  const { clientNameAndId } = useParams();
  const [clientInput, setClientInput] = useState<UpdateClientInput>();

  const clientId = useMemo(() => {
    return clientNameAndId?.split('-').pop() ?? '';
  }, [clientNameAndId]);

  const { data, loading, refetch } = useQueryGetClient({ id: clientId }, { skip: !clientId });
  const {
    data: clientV2Data,
    loading: clientV2Loading,
    refetch: refetchClientV2,
  } = useQueryGetClientV2({ id: clientId }, { skip: !clientId });

  const [createClient] = useMutationCreateClient();
  const [updateClient] = useMutationUpdateClient();

  const toClientInput = useCallback((client: Client): UpdateClientInput => {
    return toModel<UpdateClientInput>(client, 'UpdateClientInput');
  }, []);

  const submitClient = useCallback(async () => {
    if (clientInput) {
      // When edit is added, this should be put back in and modeled after SalesOrderContext
      if (!clientInput.id) {
        const { id, ...input } = clientInput;
        return createClient({ variables: { input: input as CreateClientInput } });
      } else {
        return updateClient({ variables: { input: clientInput } });
      }
    }
  }, [createClient, updateClient, clientInput]);

  useEffect(() => {
    if (!clientId && !clientInput) {
      setClientInput({
        id: '',
        subsidiary: '1',
        type: false,
        status: '13',
      });
    }
  }, [setClientInput, clientId, clientInput]);

  useEffect(() => {
    if (!clientV2Loading && clientV2Data) {
      setClientInput(toClientInput(clientV2Data.getClientV2));
    }
  }, [clientV2Data, clientV2Loading, toClientInput]);

  const contextValue: ClientContextValue = {
    client: data?.getClient,
    loading,
    refetch,

    clientV2: clientV2Data?.getClientV2,
    clientV2Loading,
    refetchClientV2,

    clientInput,
    setClientInput,
    submitClient,
  };

  return <ClientContext.Provider value={contextValue}>{children}</ClientContext.Provider>;
};

const useClientContext = () => {
  const context = useContext(ClientContext);

  if (context === undefined) {
    throw new Error('useClientContext was used outside of its Provider');
  }

  return context;
};

export { ClientContextProvider, useClientContext };
