import styled, { css } from 'styled-components';

export const StyledHeader = styled('div')(
  ({ theme }) => css`
    font-weight: 700;
    font-size: 0.65rem;
    text-transform: uppercase;
    color: ${theme.colors.whiteOpacity65};
  `
);
