import { Tooltip as MUITooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { css, styled } from 'styled-components';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(
  ({ theme }) => css`
    & .${tooltipClasses.tooltip} {
      text-transform: uppercase;
      border-radius: 0.25rem;
      padding: 0.25rem 0.55rem;
      font-size: 0.65rem;
      font-weight: 500;
      color: ${theme.palette.text.secondary};
      background-color: ${theme.palette.secondary.main};
    }
  `
);
