import React from 'react';
import { css, styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';

interface StyledFilterButtonProps {
  isFilterApplied: boolean;
}

const FilterWrapper = styled(Button)<StyledFilterButtonProps>(
  ({ theme, isFilterApplied }) => css`
    background-color: ${theme.colors.charcoal};
    color: ${isFilterApplied ? theme.colors.purple : theme.colors.white};
    width: 3rem;
    height: 3rem;
    min-width: 0;
    padding: 1rem;
    border-radius: 1.5rem;
    border-top: 0.0625rem solid ${theme.colors.bevelTop};
    border-left: 0.0625rem solid ${theme.colors.bevelLeft};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${theme.colors.hoverGray};
    }
  `
);

interface FilterButtonProps extends ButtonProps {
  isFilterApplied: boolean;
}

const FilterButton: React.FC<FilterButtonProps> = ({ isFilterApplied, ...props }) => {
  return (
    <FilterWrapper isFilterApplied={isFilterApplied} {...props}>
      <FilterListIcon sx={{ fontSize: '1.5rem' }} />
    </FilterWrapper>
  );
};

export default FilterButton;
