import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryGetSalesOrderByNumber } from 'hooks/queries/useQueryGetSalesOrderByNumber/useQueryGetSalesOrderByNumber';
import {
  CreateSalesOrderInput,
  CreateSalesOrderMutation,
  EditSalesOrderInput,
  GetSalesOrderByNumberQuery,
  Quote,
  UpdateSalesOrderMutation,
} from '__generated__/graphql';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Flex from 'components/Flex/Flex';
import useMutationUpdateSalesOrder from 'hooks/mutations/useMutationUpdateSalesOrder/useMutationUpdateSalesOrder';
import { useError } from 'hooks/util/useError';
import { FetchResult } from '@apollo/client';
import { toModel } from 'utils/graphqlHelpers';
import { NOT_A_REAL_EMPLOYEE, SALES_ROLE } from 'utils/constants';
import { useClientContext } from 'contexts/ClientContext/ClientContext';
import dayjs from 'dayjs';
import { createNewQuoteNumber } from 'utils/functions';
import useMutationCreateSalesOrder from 'hooks/mutations/useMutationCreateSalesOrder/useMutationCreateSalesOrder';
import { computeDerivedMetrics } from 'utils/SalesOrder/computeDervivedMetrics';
import { useLocation } from 'react-router-dom';

export type SalesOrderMetrics = {
  subtotal: number;
  totalMargin: number;
  grossProfit: number;
  grossProfitPercent: number;
  total: number;
  tax: number;
  shippingCost: number;
};

export type SalesOrder = GetSalesOrderByNumberQuery['getSalesOrderByNumber'];

type SalesOrderContextState = {
  salesOrder: SalesOrder | undefined;
  setSalesOrder: React.Dispatch<React.SetStateAction<SalesOrder | undefined>>;
  salesOrderInput: EditSalesOrderInput;
  setSalesOrderInput: React.Dispatch<React.SetStateAction<EditSalesOrderInput | undefined>>;
  salesOrderMetrics: SalesOrderMetrics;
  submitSalesOrder: () => Promise<FetchResult<UpdateSalesOrderMutation | CreateSalesOrderMutation> | undefined>;
  validationError?: Error;
  itemsValidationError?: Error;
};

const SalesOrderContext = createContext<SalesOrderContextState | undefined>(undefined);

type SalesOrderProviderProps = {
  children: React.ReactNode;
};

enum SalesOrderView {
  View = 'view',
  Edit = 'edit',
  Create = 'create',
}

const SalesOrderContextProvider: React.FC<SalesOrderProviderProps> = ({ children }) => {
  const { client } = useClientContext();
  const raiseError = useError();
  const [salesOrder, setSalesOrder] = useState<SalesOrder>();
  const [salesOrderInput, setSalesOrderInput] = useState<EditSalesOrderInput>();
  const [salesOrderMetrics, setSalesOrderMetrics] = useState<SalesOrderMetrics>({
    subtotal: 0,
    totalMargin: 0,
    grossProfit: 0,
    grossProfitPercent: 0,
    total: 0,
    tax: 0,
    shippingCost: 0,
  });
  const { state: locationState } = useLocation();

  const [updateSalesOrder] = useMutationUpdateSalesOrder();
  const [createSalesOrder] = useMutationCreateSalesOrder();

  const { transactionNumber, view } = useParams();

  const { data, error } = useQueryGetSalesOrderByNumber(
    { number: transactionNumber ?? '' },
    { skip: !transactionNumber }
  );

  const toSalesOrderInput = useCallback((salesOrder: SalesOrder): EditSalesOrderInput => {
    const result = toModel<EditSalesOrderInput>(salesOrder, 'EditSalesOrderInput');
    if (salesOrder.salesRep) {
      result.salesTeam = [{ employeeId: salesOrder.salesRep.id, salesRole: SALES_ROLE, contribution: 100 }];
    }
    result.status = salesOrder?.status?.name ?? result.status;
    return result;
  }, []);

  const newSalesOrderInput = useCallback(() => {
    const result: EditSalesOrderInput = {
      id: '',
      salesOrderNumber: createNewQuoteNumber(),
    };
    result.client = client?.id;
    result.status = 'Pending Approval';
    result.date = dayjs().format('YYYY-MM-DD');
    result.followUpDate = dayjs().add(14, 'day').format('YYYY-MM-DD');

    if (locationState?.quote) {
      const quote = locationState.quote as Quote;
      const convertedSalesOrder = toModel<EditSalesOrderInput>(quote, 'EditSalesOrderInput');
      Object.assign(result, { ...convertedSalesOrder, ...result });
      result.salesTeam = [
        { employeeId: quote?.salesRep?.id ?? NOT_A_REAL_EMPLOYEE, salesRole: SALES_ROLE, contribution: 100 },
      ];
      result.salesCoordinator = quote?.salesCoordinator?.id;
      if (result.shippingAddressList) {
        delete result.shippingAddress;
      }
      result.firstTimePurchase = false;
      result.projectProfileApproved = false;
      result.webOrder = false;
    } else {
      result.contact = client?.contacts?.[0]?.id;
      result.salesTeam = [
        { employeeId: client?.salesRep?.id ?? NOT_A_REAL_EMPLOYEE, salesRole: SALES_ROLE, contribution: 100 },
      ];
      result.salesCoordinator = client?.salesCoordinator?.id;
      result.market = client?.salesRep?.department?.id;
      result.terms = client?.terms?.id;

      for (const address of client?.addressbooklist?.addressbook ?? []) {
        // Assign to the first address in the list or the default shipping address if available
        if (result.shippingAddressList === undefined || address.defaultShipping) {
          result.shippingAddressList = address.internalId;
        }
      }
    }
    return result;
  }, [client, locationState]);

  useEffect(() => {
    if (view === SalesOrderView.Create && client && !transactionNumber && !salesOrderInput) {
      setSalesOrderInput(() => newSalesOrderInput());
    }
  }, [view, client, transactionNumber, salesOrderInput, setSalesOrderInput, newSalesOrderInput]);

  useEffect(() => {
    if (error) {
      raiseError(error.message);
    }
  }, [raiseError, error]);

  useEffect(() => {
    if (data) {
      setSalesOrder(data.getSalesOrderByNumber);
      setSalesOrderInput(toSalesOrderInput(data.getSalesOrderByNumber));
    }
  }, [setSalesOrder, data, toSalesOrderInput, setSalesOrderInput]);

  useEffect(() => {
    if (salesOrderInput) {
      setSalesOrderMetrics(computeDerivedMetrics(salesOrderInput, salesOrder));
    }
  }, [salesOrderInput, salesOrder]);

  const submitSalesOrder = useCallback(async () => {
    if (salesOrderInput) {
      if (!salesOrderInput.id) {
        const { id, ...input } = salesOrderInput;
        return createSalesOrder({ variables: { input: input as CreateSalesOrderInput } });
      } else {
        return updateSalesOrder({ variables: { input: salesOrderInput } });
      }
    }
  }, [updateSalesOrder, createSalesOrder, salesOrderInput]);

  const salesOrderContextValue: SalesOrderContextState | null = useMemo(() => {
    if (!salesOrderInput) {
      return null;
    }
    return {
      salesOrder,
      setSalesOrder,
      salesOrderInput,
      setSalesOrderInput,
      salesOrderMetrics,
      submitSalesOrder,
    };
  }, [salesOrder, setSalesOrder, salesOrderInput, setSalesOrderInput, salesOrderMetrics, submitSalesOrder]);

  if (!salesOrderContextValue) {
    return (
      <Flex w100 styles={{ height: 'calc(100vh - 8rem)' }} center>
        <LoadingSpinner loading={true} size={92} />
      </Flex>
    );
  }
  return <SalesOrderContext.Provider value={salesOrderContextValue}>{children}</SalesOrderContext.Provider>;
};

const useSalesOrderContext = () => {
  const context = useContext(SalesOrderContext);

  if (context === undefined) {
    throw new Error('useSalesOrderContext was used outside of its Provider');
  }

  return context;
};

export { SalesOrderContext, SalesOrderContextProvider, useSalesOrderContext };
