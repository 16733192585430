import { QueryResult, useQuery } from '@apollo/client';
import {
  GetAllTransactionStatusesDocument,
  GetAllTransactionStatusesQuery,
  GetAllTransactionStatusesQueryVariables,
} from '__generated__/graphql';

export const useQueryGetAllTransactionStatuses = (): QueryResult<
  GetAllTransactionStatusesQuery,
  GetAllTransactionStatusesQueryVariables
> => {
  return useQuery(GetAllTransactionStatusesDocument);
};
