import 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './routes';
import { AppContextProvider } from 'contexts/AppContext/AppContext';

const router = createBrowserRouter(routes);

function App() {
  return (
    <div className='App'>
      <AppContextProvider>
        <RouterProvider router={router} />
      </AppContextProvider>
    </div>
  );
}

export default App;
