import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  CheckForNewCommentsDocument,
  CheckForNewCommentsQuery,
  CheckForNewCommentsQueryVariables,
} from '__generated__/graphql';

export const useQueryCheckForNewComments = (
  quoteId: string,
  options?: QueryHookOptions<CheckForNewCommentsQuery, CheckForNewCommentsQueryVariables>
): QueryResult<CheckForNewCommentsQuery, CheckForNewCommentsQueryVariables> => {
  return useQuery(CheckForNewCommentsDocument, {
    ...options,
    variables: { quoteId },
  });
};
