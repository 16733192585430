import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import PageHeader from 'components/PageHeader/PageHeader';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { useQueryGetSalesOrders } from 'hooks/queries/useQueryGetSalesOrders/useQueryGetSalesOrders';
import { Action } from 'utils/constants';
import { GetSalesOrdersInput } from '__generated__/graphql';
import { useError } from 'hooks/util/useError';
import SalesOrdersFilter from './components/SalesOrdersFilter/SalesOrdersFilter';
import SalesOrdersTable from './components/SalesOrdersTable/SalesOrdersTable';
import { useSubNav } from 'layout/Layout';
import add from 'assets/lottie/add.json';

const SalesOrders = () => {
  const throwError = useError();
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    total: 0,
  });
  const [filters, setFilters] = useState<GetSalesOrdersInput>({});
  const navigate = useNavigate();
  const [, setSubNavItems] = useSubNav();

  useEffect(() => {
    setSubNavItems([
      { type: 'icon', tooltip: 'New Sales Order', imageData: add, onClick: () => navigate('/create-sales-order') },
    ]);

    return () => setSubNavItems([]);
  }, [setSubNavItems, navigate]);

  useEffect(() => {
    if (pagination) {
      setFilters((prev) => ({ ...prev, page: pagination.page, perPage: pagination.perPage }));
    }
  }, [setFilters, pagination]);

  const { can } = useAuthContext();
  if (!can('Sales_Order', Action.View)) {
    throwError('You do not have permission to view this page');
  }

  const { data, loading } = useQueryGetSalesOrders({ input: filters });
  useEffect(() => {
    if (data?.getSalesOrders) {
      setPagination((prev) => ({ ...prev, total: data.getSalesOrders.total }));
    }
  }, [data]);

  return (
    <Container>
      <PageHeader variant='h1' header='Sales Orders' />
      <SalesOrdersFilter filters={filters} setFilters={setFilters} />
      <SalesOrdersTable data={data} loading={loading} pagination={pagination} setPagination={setPagination} />
    </Container>
  );
};

export default SalesOrders;
