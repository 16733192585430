import React from 'react';
import { css, styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { useQuoteSubNavContext } from 'contexts/QuoteSubNavContext/QuoteSubNavContext';

const StyledCheckbox = styled(Checkbox)(({ theme }) =>
  theme.unstable_sx({
    '& .MuiSvgIcon-root': { fontSize: 20 },
    '&:hover': { borderColor: theme.colors.white },
    '&.Mui-checked': {
      color: theme.colors.lightBlue,
    },
  })
);

const CheckboxContainer = styled('div')(
  () => css`
    width: 3rem;
    height: 100%;
  `
);

type Props = CheckboxProps & {
  header?: boolean;
};

const ItemCheckbox: React.FC<Props> = ({ header, ...props }) => {
  const containerRef = React.useRef(null);
  const { bulkSelect } = useQuoteSubNavContext();

  return (
    <CheckboxContainer className={header ? 'header' : ''} ref={containerRef}>
      <Fade in={!!bulkSelect}>
        <div>
          <Slide in={bulkSelect} direction='right' container={containerRef.current}>
            <div>
              <StyledCheckbox {...props} />
            </div>
          </Slide>
        </div>
      </Fade>
    </CheckboxContainer>
  );
};

export default ItemCheckbox;
