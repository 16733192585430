import React from 'react';
import PageHeader from 'components/PageHeader/PageHeader';

const Dashboard: React.FC = () => {
  return (
    <>
      <PageHeader variant='h1' header='Dashboard' />
    </>
  );
};

export default Dashboard;
