import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { ThemeOptions, TextField, Tooltip, ButtonBase } from '@mui/material';
import { Interpolation } from '@mui/styled-engine';
import { NumericFormatProps } from 'react-number-format/types/types';
import { NumericFormat } from 'react-number-format';
import InfoIcon from '@mui/icons-material/Info';
import { CreateSalesOrderMutation, SalesOrderItemInput, UpdateSalesOrderMutation } from '__generated__/graphql';
import { SalesOrderMetrics, useSalesOrderContext } from 'contexts/SalesOrderContext/SalesOrderContext';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { formatCurrency, formatPercent, seoFriendly, stripHTML } from 'utils/functions';
import Flex from 'components/Flex/Flex';
import Button from 'components/Button/Button';
import DataDrawer from 'components/DataDrawer/DataDrawer';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext/AppContext';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { Item, useItemsContext } from 'contexts/ItemsContext/ItemsContext';
import { useClientContext } from 'contexts/ClientContext/ClientContext';

const CreateButtonContainer = styled('div')(
  () => css`
    padding: 1.5rem 0.125rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
  `
);

const Table = styled('table')(
  () => css`
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
  `
);

const TableHeader = styled('thead')(
  ({ theme }) => css`
    background: ${theme.palette.primary.main};
    position: sticky;
    top: 0;
    z-index: 1;
  `
);

const baseCellStyles: Interpolation<{ theme: ThemeOptions }> = ({ theme }: { theme: ThemeOptions }) => css`
  font-size: 0.65rem;
  font-weight: 700;
  color: ${theme.colors.whiteOpacity50};
  text-align: left;
  padding: 0.625rem 0;
  border-bottom: 0.0625rem solid ${theme.colors.black};

  &:not(:first-child) {
    text-align: right;
  }
`;

const HeaderCell = styled('th')(
  () => css`
    ${baseCellStyles};
    padding-left: 0.6rem;
    &:first-of-type {
      padding-left: 0;
    }
  `
);

const Cell = styled('td')(
  ({ theme }) => css`
    ${baseCellStyles};
    color: ${theme.palette.text.primary};
    font-size: 0.875rem;
    font-weight: 600;
    min-width: 4rem;
    padding-left: 0.6rem;

    &:first-of-type {
      padding-left: 0;
    }
  `
);

export const Quantity = styled(TextField)(
  ({ theme }) => css`
    background-color: ${theme.palette.input.main};
    border-radius: 0.5rem;

    input {
      width: 3rem;
      border: none;
      padding: 0.25rem 0.5rem;
      height: 2rem;
      font-size: 0.875rem;
      font-weight: 600;
    }

    fieldset {
      border: none;
    }
  `
);

const StatRow = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: ${theme.palette.text.primary};
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.625rem 0;
    border-bottom: 0.0625rem solid ${theme.colors.black};

    p {
      margin: 0;
      &:first-child {
        color: ${theme.colors.whiteOpacity50};
      }
    }

    &:last-child {
      p {
        &:last-child {
          font-size: 2.25rem;
        }
      }
    }
  `
);

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

const StyledInfoIcon = styled(InfoIcon)(
  () => css`
    font-size: 1rem;
    margin-left: 0.5rem;
  `
);

const displayedStatsMap: { [key: string]: string } = {
  subtotal: 'Subtotal',
  totalMargin: 'Total Margin',
  grossProfit: 'Gross Profit',
  grossProfitPercent: 'Gross Profit Percent',
  shippingCost: 'Estimated Shipping',
  tax: 'Tax',
  total: 'Sales Order Total',
};

const percentStats = ['totalMargin', 'grossProfitPercent'];

const Subtotal = () => {
  const { salesOrderInput, validationError, itemsValidationError, salesOrder, salesOrderMetrics, submitSalesOrder } =
    useSalesOrderContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
  const { view } = useParams();
  const navigate = useNavigate();
  const [saving, setSaving] = React.useState(false);
  const { showSnackbar } = useAppContext();
  const { getItems } = useItemsContext();
  const [itemData, setItemData] = useState<{ [key: string]: Item }>({});
  const { client } = useClientContext();

  useEffect(() => {
    if (salesOrderInput?.items) {
      getItems(salesOrderInput.items.map((item) => item.item)).then((items) => {
        setItemData(items);
      });
    }
  }, [getItems, salesOrderInput?.items]);

  const total = salesOrderMetrics?.total;

  const subtotalItems = salesOrderInput?.items;

  const columnHelper = createColumnHelper<SalesOrderItemInput>();

  const updateSalesOrder = () => {
    if (!salesOrder || !salesOrder.items) return;
  };

  const handleSubmitQuote = () => {
    const basePath = location.pathname.split('/').slice(0, -1).join('/');
    if (view === 'view') {
      navigate(`${basePath}/edit`);
    } else {
      showSnackbar(view === 'edit' ? 'Updating sales order...' : 'Creating sales order...');
      setSaving(true);
      submitSalesOrder()
        .then((salesOrderResult) => {
          const salesOrder =
            (salesOrderResult?.data as UpdateSalesOrderMutation)?.updateSalesOrder ??
            (salesOrderResult?.data as CreateSalesOrderMutation)?.createSalesOrder;
          showSnackbar(`Sales order ${view === 'edit' ? 'updated' : 'created'}`);
          navigate(`/sales-order/${seoFriendly(`${client?.name}`)}-${client?.id}/${salesOrder.salesOrderNumber}/view`);
        })
        .catch(() => {
          showSnackbar(view === 'edit' ? 'Error updating sales order' : 'Error creating sales order');
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const handleSalesOrderTotal = () => {
    setModalOpen(true);
  };

  const emptyArray: SalesOrderItemInput[] = [];

  const columns = useMemo(
    () => [
      columnHelper.accessor((salesOrderItem) => salesOrderItem, {
        id: 'itemNumber',
        cell: (info) => {
          const SalesOrderItemInput = info.getValue();
          const item = itemData[SalesOrderItemInput.item];
          if (!item) {
            return null;
          }

          if (item.description) {
            return (
              <Tooltip
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [-2, 0],
                        },
                      },
                    ],
                  },
                }}
                placement='bottom-start'
                title={stripHTML(item.description, { removeBr: true })}
              >
                <span>{item.storeDisplayName || item.itemId}</span>
              </Tooltip>
            );
          }
          return item.itemId;
        },
        header: () => (
          <Flex>
            <span>Item #</span>
            <Tooltip title='Hover over item number to view description'>
              <StyledInfoIcon />
            </Tooltip>
          </Flex>
        ),
      }),
      columnHelper.accessor((salesOrderItem) => salesOrderItem, {
        id: 'warehouse',
        cell: (info) => {
          const salesOrderItem = info.getValue();
          const item = itemData[salesOrderItem.item];
          const locationName = item?.inventoryDetail?.binNumber?.find(
            (location) => location.info?.id === salesOrderItem.location
          )?.info?.name;

          return locationName;
        },
        header: () => <span>Warehouse</span>,
      }),
      columnHelper.accessor((SalesOrderItemInput, index) => ({ item: SalesOrderItemInput, index }), {
        id: 'quantity',
        cell: (info) => {
          const { item, index } = info.getValue();
          return item.quantity;
        },
        header: () => <span>Quantity</span>,
      }),
      columnHelper.accessor((SalesOrderItemInput) => SalesOrderItemInput, {
        id: 'unitCost',
        cell: (info) => {
          if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(info.getValue().type ?? '')) {
            return null;
          }
          return formatCurrency(info.getValue().unitCost ?? undefined);
        },
        header: () => <span>Unit Cost</span>,
      }),
      columnHelper.accessor((SalesOrderItemInput) => SalesOrderItemInput, {
        id: 'price',
        cell: (info) => {
          if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(info.getValue().type ?? '')) {
            return null;
          }
          return formatCurrency(info.getValue().adjCost ?? undefined);
        },
        header: () => <span>Sale Price</span>,
      }),
      columnHelper.accessor((SalesOrderItemInput) => SalesOrderItemInput, {
        id: 'margin',
        cell: (info) => {
          if (['ItemGroup', 'EndGroup', 'DescriptionItem'].includes(info.getValue().type ?? '')) {
            return null;
          }
          return formatPercent(info.getValue().margin ?? undefined);
        },
        header: () => <span>Margin</span>,
      }),
      columnHelper.accessor((SalesOrderItemInput, index) => ({ item: SalesOrderItemInput, index }), {
        id: 'lineTotal',
        cell: (info) => {
          const { item, index } = info.getValue();

          const calculateTotal = (quantity: number, adjCost: number) => {
            return Math.round(quantity * adjCost * 100) / 100;
          };

          return formatCurrency(calculateTotal(item.quantity || 0, item.adjCost || 0));
        },
        header: () => <span>Line Total</span>,
      }),
    ],
    [itemData, columnHelper]
  );

  const subtotalTable = useReactTable({
    columns,
    data: subtotalItems ?? emptyArray,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Flex column styles={{ alignItems: 'flex-end' }}>
      <Button variant='outlined' onClick={handleSalesOrderTotal}>
        {view !== 'view' ? 'Review and Save' : 'Review'}
      </Button>
      <DataDrawer
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        {...(view !== 'view'
          ? {
              onHeaderButtonClick: updateSalesOrder,
              headerButtonText: 'Update Quantites',
            }
          : {})}
        title={'Sales Order Total'}
        width={'42rem'}
      >
        <Table>
          <TableHeader>
            {subtotalTable.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <HeaderCell key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </HeaderCell>
                ))}
              </tr>
            ))}
          </TableHeader>
          {subtotalItems && (
            <tbody>
              {subtotalTable.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Cell>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {salesOrderMetrics &&
          Object.keys(salesOrderMetrics).map((stat) => {
            const statValue = salesOrderMetrics[stat as keyof SalesOrderMetrics];
            if (!displayedStatsMap[stat]) return null;
            else {
              return (
                <StatRow key={stat}>
                  <p>{displayedStatsMap[stat]}</p>
                  {/* Stat value can be guaranteed to be a number here*/}
                  <p>
                    {percentStats.includes(stat)
                      ? formatPercent(statValue as number)
                      : formatCurrency(statValue as number)}
                  </p>
                </StatRow>
              );
            }
          })}

        {view !== 'view' && (
          <CreateButtonContainer>
            {validationError || itemsValidationError ? (
              <Tooltip
                title={validationError?.message || itemsValidationError?.message}
                open={tooltipOpen}
                arrow
                placement='top-end'
              >
                <ButtonBase
                  disableRipple
                  onMouseEnter={() => setTooltipOpen(true)}
                  onMouseLeave={() => setTooltipOpen(false)}
                >
                  <Button disabled variant='outlined' onClick={handleSubmitQuote}>
                    {salesOrderInput.id ? 'Save Updates' : 'Create Sales Order'}
                  </Button>
                </ButtonBase>
              </Tooltip>
            ) : (
              <Button disabled={saving} variant='outlined' onClick={handleSubmitQuote}>
                {salesOrderInput.id ? 'Save Updates' : 'Create Sales Order'}
              </Button>
            )}
            <LoadingSpinner loading={saving} />
          </CreateButtonContainer>
        )}
      </DataDrawer>
    </Flex>
  );
};

export default Subtotal;
