import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { css, styled } from '@mui/material/styles';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import useMutationSendQuoteEmail from 'hooks/mutations/useMutationSendQuoteEmail/useMutationSendQuoteEmail';
import { Formik } from 'formik';
import { useUserContext } from 'contexts/UserContext/UserContext';
import {
  FormikInput,
  FormikMultiSelectAutocomplete,
  FormikValuesFrom,
  createInitialValues,
  FormikCheckbox,
  FormikSelect,
  FormikRichEditor,
} from 'utils/formik';
import { QuoteEmailInput } from '__generated__/graphql';
import Drawer, {
  DrawerHeader,
  DrawerInput,
  DrawerCloseButton,
  DrawerConfirmButton,
  DrawerSelectInput,
} from 'components/Drawer/Drawer';
import PageHeaderBase from 'components/PageHeader/PageHeader';
import { uniqueNonNullPush } from 'utils/functions';
import useMutationProcessTemplate from 'hooks/mutations/useMutationProcessTemplate/useMutationProcessTemplate';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { MuiFileInput } from 'mui-file-input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import { Container } from '@mui/material';
import { InputLabel } from 'components/InputLabel/InputLabel';

const StyledFileInput = styled(MuiFileInput)(
  ({ theme }) => css`
    margin-right: 2rem;

    .MuiInputBase-root {
      border-radius: 2rem;
    }

    & label {
      cursor: pointer;
      text-transform: uppercase;
      width: 7.5rem;
      height: 2.5rem;

      &:hover {
        color: ${theme.colors.white};
      }
    }

    .MuiFileInput-placeholder {
      color: ${theme.colors.whiteOpacity50} !important;
      font-weight: 600;
      font-size: 0.75rem;
    }
  `
);

const StyledAttachIcon = styled(AttachFileIcon)(
  ({ theme }) => css`
    color: ${theme.colors.whiteOpacity50};
  `
);

const ButtonContainer = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.75rem;
    padding-top: 2rem;
  `
);

const StatusWrapper = styled('div')(
  () => `
    display: flex;
    padding: 0 0 2rem;
    font-weight: 600;
  `
);

const PageHeader = styled(PageHeaderBase)(
  () => css`
    margin-bottom: 0.5rem;
    padding-bottom: 0;
  `
);

const Attachment = styled(Chip)(
  () => css`
    font-weight: 600;
    background-color: #1f1f1f;
    margin: 0.25rem;
  `
);

const SendQuoteDrawer: React.FC<{
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const { quote, clientData, setSnackbarMessage, setShowSnackbar } = useQuoteContext();
  const { user } = useUserContext();
  const [sendEmail] = useMutationSendQuoteEmail();
  const [processTemplate] = useMutationProcessTemplate();
  const [templateLoading, setTemplateLoading] = useState<boolean>(false);
  const [attachments, setAttachments] = React.useState<File[]>([]);

  function getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Attaching the file failed - could not get file contents.'));
        }
      };
      reader.onerror = (err) => reject(err);
    });
  }

  const handleChangeAttachments = (files: File[]) => {
    setAttachments((prev) => prev.concat(files));
  };

  // Get all non-empty name from client contacts
  const contactNames = useMemo(() => {
    const results: string[] = [];
    const emailMap: { [key: string]: string } = {};

    if (quote?.salesRep?.name) {
      uniqueNonNullPush(results, quote.salesRep.name);
      emailMap[quote.salesRep.name] = quote.salesRep.email || '';
    }

    if (quote?.salesCoordinator?.name) {
      uniqueNonNullPush(results, quote.salesCoordinator.name);
      emailMap[quote.salesCoordinator.name] = quote.salesCoordinator.email || '';
    }

    if (clientData?.salesRep?.fullName) {
      uniqueNonNullPush(results, clientData.salesRep.fullName);
      emailMap[clientData.salesRep.fullName] = clientData.salesRep.email || '';
    }

    if (clientData?.salesCoordinator?.fullName) {
      uniqueNonNullPush(results, clientData.salesCoordinator.fullName);
      emailMap[clientData.salesCoordinator.fullName] = clientData.salesCoordinator.email || '';
    }

    clientData?.contacts?.forEach((contact) => {
      if (contact.name) {
        uniqueNonNullPush(results, contact.name);
        emailMap[contact.name] = contact.email || '';
      }
    });

    return { results, emailMap };
  }, [clientData, quote]);

  const emptyArray = useMemo(() => [], []);

  const isOptionDisabled = (option: string) => {
    // Check if the recipient option has an associated email address
    return !contactNames.emailMap[option];
  };

  const filteredRecipients = contactNames.results
    .filter((option) => !isOptionDisabled(option))
    .sort((a, b) => a.localeCompare(b));

  const formId = 'send-quote-form';
  const requiredFields = ['subject', 'body', 'recipients'];
  const formFields = {
    subject: 'Subject',
    body: 'Message',
    recipients: 'Recipients',
    includeQuotePdf: 'Include Quote PDF',
    templateId: 'Template',
  };
  type FormikValues = FormikValuesFrom<typeof formFields, typeof initialValues>;

  const toQuoteEmailInput = async (values: FormikValues): Promise<QuoteEmailInput> => {
    if (!quote || !user) {
      throw new Error('Unable to send email');
    }

    //including templateId in the query input causes a graphql error
    const { templateId, ...rest } = values;

    try {
      return {
        ...rest,
        files: await Promise.all(
          attachments.map(async (file) => ({
            filename: file.name,
            content: await getBase64(file),
          }))
        ),
        recipients: values.recipients.map((name) => contactNames.emailMap[name]),
        includeQuotePdf: !!values.includeQuotePdf,
        quoteId: quote.id,
        sender: user.id ? Number(user.id) : 0,
      };
    } catch (error) {
      snackWrap('Failed to upload email attachments');
      throw new Error('Unable to send email');
    }
  };

  const initialValues = {
    subject: '',
    body: '',
    templateId: '',
    includeQuotePdf: true,
    recipients: [],
  };

  const snackWrap = (message: string) => {
    setShowSnackbar(true);
    setSnackbarMessage(message);
  };

  const onSubmit = async (values: FormikValues) => {
    const quoteEmailInput = await toQuoteEmailInput(values);
    const allRecipients = values.recipients.map((nameOrEmail) =>
      contactNames.emailMap[nameOrEmail] ? contactNames.emailMap[nameOrEmail] : nameOrEmail
    );

    quoteEmailInput.recipients = allRecipients;

    snackWrap('Sending email...');
    sendEmail({ variables: { input: quoteEmailInput } })
      .then(async (result) => {
        const sendQuoteEmailResult = result?.data?.sendQuoteEmail;

        if (sendQuoteEmailResult) {
          if (sendQuoteEmailResult.success) {
            snackWrap('Email sent successfully');
            setIsDrawerOpen(false);
          } else {
            snackWrap('Failed sending email');
          }
        }
      })
      .catch(() => {
        snackWrap('Failed sending email');
      });
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      setAttachments([]);
    }
  }, [isDrawerOpen, setAttachments]);

  return (
    <Drawer drawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen}>
      <DrawerHeader>
        <PageHeader variant='h2' header='Send Quote' />
        <StatusWrapper>{quote?.quoteNumber}</StatusWrapper>
      </DrawerHeader>
      <Formik initialValues={createInitialValues<FormikValues>(formFields, initialValues)} onSubmit={onSubmit}>
        {({ values, handleChange, handleSubmit }) => {
          const props = { handleChange, values, requiredFields, formFields };

          //the parameter to handleChange is incorrectly typed somewhere, because what we're getting in
          //event.target is { name: string, value: string }
          const selectChangeHandler = (event: React.ChangeEvent & { target: { name: string; value: string } }) => {
            if (parseInt(event.target.value, 10) > 0) {
              setTemplateLoading(true);
              processTemplate({
                variables: {
                  input: {
                    templateId: event.target.value ?? '',
                    objectType: 'transaction',
                    objectId: quote?.id ?? 'ERROR',
                  },
                },
              }).then(({ data }) => {
                setTemplateLoading(false);
                handleChange({ target: { name: 'subject', value: data?.processTemplate.subject ?? '' } });
                handleChange({ target: { name: 'body', value: data?.processTemplate.body ?? '' } });
                handleChange(event);
              });
            } else {
              handleChange(event);
            }
          };
          return (
            <form onSubmit={handleSubmit} id={formId}>
              <FormikMultiSelectAutocomplete
                {...props}
                freeSolo
                name='recipients'
                options={filteredRecipients}
                limitTags={2}
              />
              <FormikSelect
                name='templateId'
                InputComponent={DrawerSelectInput}
                {...props}
                handleChange={selectChangeHandler}
                options={{ '0': 'Custom', '460': 'Quote - Sales Coordinator & Sales Rep (no names)' }}
              />
              <div>
                <LoadingSpinner loading={templateLoading}>
                  <FormikInput
                    InputComponent={DrawerInput}
                    name='subject'
                    {...props}
                    disabled={'' === values['templateId'] || templateLoading}
                  />
                  <FormikRichEditor name='body' {...props} disabled={'' === values['templateId'] || templateLoading} />
                </LoadingSpinner>
              </div>
              {attachments?.length > 0 && (
                <Container sx={{ mt: 2 }}>
                  <InputLabel>Attachments</InputLabel>
                  {attachments.map((file, index) => (
                    <Attachment
                      key={index}
                      label={file.name}
                      onDelete={() => setAttachments(attachments.filter((_, i) => i !== index))}
                    />
                  ))}
                </Container>
              )}
              <FormikCheckbox name='includeQuotePdf' {...props} />
            </form>
          );
        }}
      </Formik>
      <ButtonContainer>
        <StyledFileInput
          value={emptyArray} // Handling the list of files elsewhere, this just allows us to pick the files
          onChange={handleChangeAttachments}
          placeholder='Attach files'
          multiple
          hideSizeText
          InputProps={{
            inputProps: {
              accept: 'file/*',
            },
            startAdornment: <StyledAttachIcon fontSize='small' />,
          }}
        />
        <DrawerCloseButton disableRipple onClick={() => setIsDrawerOpen(false)}>
          Cancel
        </DrawerCloseButton>
        <DrawerConfirmButton type='submit' form={formId}>
          Send Email
        </DrawerConfirmButton>
      </ButtonContainer>
    </Drawer>
  );
};

export default SendQuoteDrawer;
