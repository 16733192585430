import React, { createContext, useContext, useState, useMemo } from 'react';
import { colors } from 'darkTheme';
import { Quote } from '__generated__/graphql';
import { Client, QUOTE_STATUS } from 'utils/types';
import useMutationUpdateQuote from 'hooks/mutations/useMutationUpdateQuote/useMutationUpdateQuote';
import useMutationDuplicateQuote from 'hooks/mutations/useMutationDuplicateQuote/useMutationDuplicateQuote';

type QuoteIndex = {
  [key: string]: string;
};

export const QuoteStatusBackgroundColorMap: QuoteIndex = {
  [QUOTE_STATUS.APPROVED]: colors.green,
  [QUOTE_STATUS.OPEN]: colors.yellow,
  // [QUOTE_STATUS.REVIEW]: colors.purple,
  [QUOTE_STATUS.CLOSED]: colors.red,
  // [QUOTE_STATUS.LOST]: colors.gray,
  [QUOTE_STATUS.PROCESSED]: colors.purple,
  [QUOTE_STATUS.EXPIRED]: colors.gray,
  [QUOTE_STATUS.VOIDED]: colors.gray,
};

export const QuoteStatusActiveForegroundColorMap: QuoteIndex = {
  [QUOTE_STATUS.APPROVED]: 'white',
  [QUOTE_STATUS.OPEN]: 'black',
  // [QUOTE_STATUS.REVIEW]: 'white',
  [QUOTE_STATUS.CLOSED]: 'white',
  // [QUOTE_STATUS.LOST]: 'white',
  [QUOTE_STATUS.PROCESSED]: 'white',
  [QUOTE_STATUS.EXPIRED]: 'white',
  [QUOTE_STATUS.VOIDED]: 'white',
};

type QuotesContextState = {
  quotes: Quote[];
  quotesList: Quote[];
  setQuotesList: React.Dispatch<React.SetStateAction<Quote[] | []>>;
  updateQuote: (id: string, number: string, data: Partial<Quote>) => void;
  deleteQuote: (id: string) => void;
  duplicateQuote: (quote: Quote) => void;
  createQuote: (quote: Quote) => void;
  selectedQuote?: Quote;
  selectedClient?: Client;
  showSnackbar: boolean;
  snackbarMessage: string | null;
  setShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedClient?: (value: ((prevState: Client | undefined) => Client | undefined) | Client | undefined) => void;
};

const initialState: QuotesContextState = {
  quotes: [],
  quotesList: [],
  setQuotesList: () => [],
  snackbarMessage: null,
  showSnackbar: false,
  setShowSnackbar: () => false,
  updateQuote: () => null,
  duplicateQuote: () => null,
  deleteQuote: () => null,
  createQuote: () => null,
};

const QuotesContext = createContext<QuotesContextState | undefined>(initialState);

type QuotesProviderProps = {
  children: React.ReactNode;
};

const QuotesContextProvider: React.FC<QuotesProviderProps> = ({ children }) => {
  const [editQuote] = useMutationUpdateQuote();
  const [selectedClient, setSelectedClient] = useState<Client | undefined>(undefined);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [quotesList, setQuotesList] = useState<Quote[]>([]);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [duplicateQuoteMutation] = useMutationDuplicateQuote();

  const quotesContextValue = useMemo(() => {
    const updateQuote = (id: string, number: string, data: Partial<Quote>) => {
      const quoteToUpdate: Quote | undefined = quotesList.find((quote) => quote.id === id);
      if (!quoteToUpdate) return;
      const newQuoteUpdated: Quote = { ...quoteToUpdate, ...data, items: [] };
      const index = quotesList.indexOf(quoteToUpdate);

      const newQuotes: Quote[] = [...quotesList.slice(0, index), newQuoteUpdated, ...quotesList.slice(index + 1)];
      setQuotesList(newQuotes);
    };

    const deleteQuote = (id: string) => {
      const index = quotes.findIndex((quote) => quote.id === id);
      if (-1 !== index) {
        setQuotes([...quotes.slice(0, index), ...quotes.slice(index + 1)]);
      }
    };

    const createQuote = (quote: Quote) => {
      setQuotes([...quotes, quote]);
    };

    const duplicateQuote = (quote: Quote) => {
      const index = quotesList.findIndex((item) => item.id === quote.id);
      setShowSnackbar(true);
      setSnackbarMessage(`Quote ${quote?.quoteNumber} is being duplicated.`);
      duplicateQuoteMutation({ variables: { id: quote.id } })
        .then((response) => {
          setShowSnackbar(true);
          setSnackbarMessage(`Quote ${quote?.quoteNumber} has duplicated successfully.`);
          const newQuote = response.data?.duplicateQuote;
          if (newQuote) {
            setQuotesList([...quotesList.slice(0, index + 1), newQuote, ...quotesList.slice(index + 1)]); // add new quote to liste
          }
        })
        .catch(() => {
          setShowSnackbar(true);
          setSnackbarMessage(`Quote ${quote?.quoteNumber} failed to duplicate.`);
        });
    };

    return {
      quotes,
      quotesList,
      setQuotesList,
      setQuotes,
      showSnackbar,
      setShowSnackbar,
      updateQuote,
      deleteQuote,
      duplicateQuote,
      createQuote,
      selectedClient,
      setSelectedClient,
      snackbarMessage,
    };
  }, [quotes, quotesList, selectedClient, snackbarMessage, showSnackbar, duplicateQuoteMutation]);

  return <QuotesContext.Provider value={quotesContextValue}>{children}</QuotesContext.Provider>;
};

const useQuotesContext = () => {
  const context = useContext(QuotesContext);

  if (context === undefined) {
    throw new Error('useQuotesContext was used outside of its Provider');
  }

  return context;
};

export { QuotesContext, QuotesContextProvider, useQuotesContext };
