import React, { useState } from 'react';
import Container from '@mui/material/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import { TabContainer, Tabs, Tab, TabPanel } from 'components/TabNavigation/TabNavigation';

const CreateItem: React.FC = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Container>
      <PageHeader variant='h1' header='New Item' />
      <TabContainer>
        <Tabs value={tab} onChange={handleChange} initialSelectedIndex={1}>
          <Tab disableRipple label='Overview' />
          <Tab disableRipple label='Facets' />
        </Tabs>
        <TabPanel value={tab} index={0} />
        <TabPanel value={tab} index={1} />
      </TabContainer>
    </Container>
  );
};

export default CreateItem;
