import { css, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const NavButton = styled(IconButton)(
  ({ theme, $active }) => css`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${$active && theme.colors.lightBlack};

    svg {
      opacity: 0.5;
      transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover,
    &:focus {
      color: ${theme.colors.white};
      background-color: ${theme.colors.lightBlack};

      svg {
        opacity: 1;
      }
    }
  `
);

export default NavButton;
