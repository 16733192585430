import { SubNavItem, useSubNav } from 'layout/Layout';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import comments from 'assets/lottie/comments.json';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import useMutationMarkCommentsAsViewed from 'hooks/mutations/useMutationMarkCommentsAsViewed/useMutationMarkCommentsAsViewed';
import { useParams } from 'react-router-dom';
import { useQueryCheckForNewComments } from 'hooks/queries/useQueryCheckForNewComments/useQueryCheckForNewComments';

type QuoteSubNavContextState = {
  showComments?: boolean;
  setShowComments?: React.Dispatch<React.SetStateAction<boolean>>;
  bulkSelect?: boolean;
  setBulkSelect?: React.Dispatch<React.SetStateAction<boolean>>;
  setAdditionalSubnavItems?: React.Dispatch<React.SetStateAction<SubNavItem[]>>;
  toggleShowComments: () => void;
};

const QuoteSubNavContext = React.createContext<QuoteSubNavContextState | undefined>(undefined);

type QuoteSubNavProviderProps = {
  children: React.ReactNode;
};

const QuoteSubNavContextProvider: React.FC<QuoteSubNavProviderProps> = ({ children }) => {
  const [showComments, setShowComments] = React.useState<boolean>(false);
  const [bulkSelect, setBulkSelect] = React.useState<boolean>(false);
  const [additionalSubnavItems, setAdditionalSubnavItems] = React.useState<SubNavItem[]>([]);
  const [, setSubNavItems] = useSubNav();
  const { quote, isQuoteCreated } = useQuoteContext();
  const { quoteNumber } = useParams();
  const [markCommentsAsViewed] = useMutationMarkCommentsAsViewed();
  const { data: checkForNewCommentsData, refetch: checkForNewComments } = useQueryCheckForNewComments(quote?.id || '', {
    pollInterval: 60000,
    skip: !quote?.id || !quoteNumber,
  });

  // Clear subnav when component unmounts
  useEffect(() => {
    return () => {
      setSubNavItems([]);
    };
  }, [setSubNavItems]);

  const toggleShowComments = useCallback(() => {
    if (quoteNumber) {
      markCommentsAsViewed({
        variables: { quoteId: quote?.id || '' },
      })
        .then(() => checkForNewComments())
        .catch((error) => console.warn(error));
    }

    setShowComments((prev) => !prev);
  }, [setShowComments, markCommentsAsViewed, checkForNewComments, quote, quoteNumber]);

  useEffect(() => {
    setSubNavItems([
      ...additionalSubnavItems,
      {
        type: 'icon',
        tooltip: 'Comments',
        imageData: comments,
        onClick: toggleShowComments,
        showNotification: checkForNewCommentsData?.checkForNewComments.hasNewComments,
        disabled: isQuoteCreated,
      },
    ]);
  }, [additionalSubnavItems, setSubNavItems, toggleShowComments, checkForNewCommentsData, isQuoteCreated]);

  const quotesSubNavContextValue = useMemo(
    () => ({
      showComments,
      setShowComments,
      setAdditionalSubnavItems,
      bulkSelect,
      setBulkSelect,
      toggleShowComments,
    }),
    [bulkSelect, showComments, toggleShowComments]
  );

  return <QuoteSubNavContext.Provider value={quotesSubNavContextValue}>{children}</QuoteSubNavContext.Provider>;
};

const useQuoteSubNavContext = () => {
  const context = useContext(QuoteSubNavContext);

  if (context === undefined) {
    throw new Error('useQuoteSubNavContext was used outside of its Provider');
  }

  return context;
};

export { QuoteSubNavContext, QuoteSubNavContextProvider, useQuoteSubNavContext };
