import React, { useEffect, useState } from 'react';
import { styled, css } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Tooltip from '@mui/material/Tooltip';
import Flex from 'components/Flex/Flex';
import Drawer from '@mui/material/Drawer';
import { opacity } from 'utils/functions';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { useParams } from 'react-router-dom';
import Button from 'components/Button/Button';
import ConfirmationModal from 'routes/Quote/components/ConfirmationModal/ConfirmationModal';

const ModalHeader = styled('div')(
  ({ theme }) => css`
    padding: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 0.0625rem solid ${theme.colors.bevelTop};
    border-left: 0.0625rem solid ${theme.colors.bevelLeft};

    svg {
      font-size: 1.1rem;
    }
  `
);

const ChildContainer = styled('div')<{ $fullHeight?: boolean; $childPadding?: string; childPaddingTop?: string }>(
  ({ $fullHeight, $childPadding, $childPaddingTop }) => css`
    padding: ${$childPadding ? $childPadding : css`2rem`};
    padding-top: ${$childPaddingTop ?? $childPadding ?? css`2rem`};
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    ${$fullHeight &&
    css`
      height: 100%;
    `}
  `
);

const CreateButton = styled(Button)(
  () => css`
    margin-left: auto;
    margin-bottom: 1rem;
  `
);

const CreateButtonContainer = styled('div')(
  () => css`
    padding: 12px 28px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  `
);

const CloseModal = styled(ButtonBase)(
  ({ theme }) => css`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      color: ${theme.colors.white};
      background-color: ${theme.colors.lightBlack};
    }
  `
);

const Title = styled('h3')(
  () => css`
    margin: 0;
    line-height: 1.4;
    font-weight: 700;
    font-size: 1.5rem;
  `
);

const ModalContainer = styled(Drawer)(
  ({ theme, $width = '32rem' }) => css`
    height: 0;

    .MuiPaper-root {
      transition-duration: 447ms !important;
      top: 5rem;
      right: 1rem;
      border-radius: 1rem;
      border-top: 0.0625rem solid ${theme.colors.bevelTop};
      border-left: 0.0625rem solid ${theme.colors.bevelLeft};
      height: calc(100vh - 6rem);
      width: ${theme.breakpoints.down('tablet') ? $width : '100%'};
      box-shadow: -0.1875rem 0 0.5rem ${opacity(theme.colors.black, 0.2)};
      background: ${theme.palette.primary.main};
      max-width: calc(100vw - 2rem);
    }
  `
);

export type DataModalProps = {
  open?: boolean;
  openOverride?: boolean;
  onButtonClick: () => void;
  onTriggerClick?: () => void;
  onCloseCallback?: () => void;
  buttonText: string;
  title: string;
  children: React.ReactNode;
  trigger?: React.ReactNode;
  hideCreate?: boolean;
  hideButton?: boolean;
  triggerButtonStyle?: React.CSSProperties;
  fullHeightChildContainer?: boolean;
  width?: string;
  headerPadding?: string;
  childPadding?: string;
  hideBackdrop?: boolean;
};

const DataModal: React.FC<DataModalProps> = ({
  open,
  openOverride,
  onButtonClick,
  onTriggerClick,
  onCloseCallback,
  children,
  trigger,
  buttonText,
  title,
  hideCreate = false,
  hideButton = false,
  triggerButtonStyle = {},
  fullHeightChildContainer,
  width,
  headerPadding,
  childPadding,
  hideBackdrop = true,
}) => {
  const {
    submitQuoteLoading,
    submitQuote,
    quoteMetrics,
    isQuoteCreated,
    validationError,
    itemsValidationError,
    quoteFlowType,
  } = useQuoteContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);

  // Used to close the modal if the quote changes
  const { quoteNumber } = useParams();

  useEffect(() => {
    if (open !== undefined) {
      setModalOpen(open);
    }
  }, [open]);

  useEffect(() => {
    setModalOpen(false);
  }, [quoteNumber]);

  function handleSubmitQuote() {
    if (quoteMetrics.totalMargin < 15) {
      setIsConfirmationModalOpen(true);
      return;
    }

    submitQuote();
  }

  return (
    <>
      <ButtonBase
        disableRipple
        style={triggerButtonStyle}
        onClick={() => {
          setModalOpen(true);
          onTriggerClick && onTriggerClick();
        }}
      >
        {trigger}
      </ButtonBase>
      <ModalContainer
        $width={width}
        hideBackdrop={hideBackdrop}
        disableScrollLock
        anchor='right'
        open={openOverride || modalOpen}
        onClose={() => {
          setModalOpen(false);
          onCloseCallback && onCloseCallback();
        }}
        ModalProps={{
          slotProps: { backdrop: { invisible: true } },
        }}
      >
        <ModalHeader>
          <CloseModal
            onClick={() => {
              setModalOpen(false);
              onCloseCallback && onCloseCallback();
            }}
          >
            <KeyboardDoubleArrowRightIcon />
          </CloseModal>
          <Flex styles={{ justifyContent: 'space-between', padding: headerPadding || '0.75rem 1.75rem' }}>
            <Title>{title}</Title>
            {!hideButton && (
              <Button variant='outlined' onClick={onButtonClick}>
                {buttonText}
              </Button>
            )}
          </Flex>
        </ModalHeader>
        <ChildContainer
          $childPadding={childPadding || '2rem'}
          $childPaddingTop={'0rem'}
          $fullHeight={fullHeightChildContainer}
        >
          {children}
        </ChildContainer>
        {!hideCreate && (
          <>
            <ConfirmationModal
              open={isConfirmationModalOpen}
              onClose={() => setIsConfirmationModalOpen(false)}
              onConfirm={submitQuote}
              message='Quotes with a margin under 15% will be pending approval'
            />
            <CreateButtonContainer>
              {validationError || itemsValidationError ? (
                <Tooltip
                  title={validationError?.message || itemsValidationError?.message}
                  open={tooltipOpen}
                  arrow
                  placement='top-end'
                >
                  <ButtonBase
                    disableRipple
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                  >
                    <CreateButton disabled variant='outlined' onClick={handleSubmitQuote}>
                      {quoteNumber ? 'Save Updates' : 'Create Quote'}
                    </CreateButton>
                  </ButtonBase>
                </Tooltip>
              ) : (
                <CreateButton
                  disabled={submitQuoteLoading || isQuoteCreated}
                  variant='outlined'
                  onClick={handleSubmitQuote}
                >
                  {'edit' === quoteFlowType ? 'Save Updates' : 'Save Quote'}
                </CreateButton>
              )}

              <LoadingSpinner loading={submitQuoteLoading} />
            </CreateButtonContainer>
          </>
        )}
      </ModalContainer>
    </>
  );
};

export default DataModal;
