import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createColumnHelper, flexRender, getCoreRowModel, PaginationState, useReactTable } from '@tanstack/react-table';
import { Row, Spacer, Table, TableCell, TableHeaderCell } from 'components/TableComponents/TableComponents';
import { useQueryGetTransactionsForClient } from 'hooks/queries/useQueryGetTransactionsForClient/useQueryGetTransactionsForClient';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { LookupType, Transaction } from '__generated__/graphql';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { formatCurrency, getPage, seoFriendly } from 'utils/functions';
import Pagination from 'components/Pagination/Pagination';
import { Alert, AlertTitle, css, styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQueryGetAllTransactionStatuses } from 'hooks/queries/useQueryGetAllTransactionStatuses/useQueryGetAllTransactionStatuses';
import FilterButton from 'components/FilterButton/FilterButton';
import FilterMenu from 'components/FilterMenu/FilterMenu';
import { useQueryEmployeeReference } from 'hooks/queries/useQueryEmployeeReference/useQueryEmployeeReference';
import { useQueryGetDepartments } from 'hooks/queries/useQueryGetDepartments/useQueryGetDepartments';
import { useQueryGetLookup } from 'hooks/queries/useQueryGetLookup/useQueryGetLookup';

const TransactionHistoryFilterButton = styled(FilterButton)(
  () => css`
    float: right;
  `
);

const initTxnHistoryFilters = {
  transactionStatus: '',
  transactionType: '',
  salesCoordinator: '',
  salesRepId: '',
  marketId: '',
};

export type Market = {
  name: string;
  internalId: string;
};

type TransactionHistorySearchFilter = {
  transactionStatus: string;
  transactionType: string;
  salesCoordinator: string;
  salesRepId: string;
  marketId: string;
};

type TransactionHistoryProps = {
  clientId: string;
};

const emptyTransactionArray: Transaction[] = [];

const TransactionHistory: React.FC<TransactionHistoryProps> = ({ clientId }: TransactionHistoryProps) => {
  const theme = useTheme();

  const columnHelper = createColumnHelper<Transaction>();
  const [pagination, setPagination] = useState<PaginationState>({ pageSize: 20, pageIndex: 0 });
  const [pages, setPages] = useState<number>(0);
  const [isFilterDrawOpen, setIsFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<TransactionHistorySearchFilter>(initTxnHistoryFilters);
  const [appliedFilters, setAppliedFilters] = useState<TransactionHistorySearchFilter>(initTxnHistoryFilters);

  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const tabletColumns = useMemo(() => ['date', 'type', 'docnumber', 'amount', 'status', 'project'], []);

  const { data, loading, error } = useQueryGetTransactionsForClient({
    input: { clientId, page: pagination.pageIndex, perPage: pagination.pageSize, ...appliedFilters },
  });

  const { data: statusesData } = useQueryGetAllTransactionStatuses();
  const statuses = useMemo(() => statusesData?.getAllTransactionStatuses?.map((e) => e.status), [statusesData]);

  const { data: employeeData } = useQueryEmployeeReference();
  const [salesReps, salesCoordinators] = useMemo(() => {
    if (employeeData) {
      return [employeeData.employeeReference.salesReps, employeeData.employeeReference.salesCoordinators];
    }
    return [[], []];
  }, [employeeData]);

  const { data: departmentsData } = useQueryGetDepartments();
  const markets = useMemo(
    () =>
      departmentsData?.getDepartments
        .map((department) => {
          return {
            name: department.parent?.name ? `${department.parent.name} : ${department.name}` : `${department.name}`,
            internalId: department.id,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    [departmentsData]
  );

  const { data: transactionTypesData } = useQueryGetLookup({ input: { type: LookupType.TransactionType } });
  const transactionTypeList = useMemo(() => {
    return transactionTypesData ? Object.values(transactionTypesData.getLookup) : [];
  }, [transactionTypesData]);
  const transactionTypeMap = useMemo(() => {
    return transactionTypesData ? transactionTypesData.getLookup : {};
  }, [transactionTypesData]);

  const restorePreviouslySelectedFilters = () => {
    setFilters({ ...appliedFilters });
  };

  const filtersApplied = useMemo(() => {
    return Object.keys(appliedFilters).some(
      (key) =>
        appliedFilters[key as keyof TransactionHistorySearchFilter] !==
        initTxnHistoryFilters[key as keyof TransactionHistorySearchFilter]
    );
  }, [appliedFilters]);

  useEffect(() => {
    if (
      !loading &&
      data?.getTransactionsForClient?.pages !== undefined &&
      data?.getTransactionsForClient?.pages !== pages
    ) {
      setPages(data?.getTransactionsForClient?.pages);
    }
  }, [loading, data?.getTransactionsForClient?.pages, pages]);

  const handlePageChange = useCallback(
    (newPage: number) => setPagination((cur) => ({ ...cur, pageIndex: newPage })),
    []
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((transaction) => transaction.createdDate, {
        id: 'date',
        header: () => <span>Date</span>,
        cell: (data) => (data.getValue() ? dayjs(data.getValue()).format('YYYY-MM-DD') : ''),
      }),
      columnHelper.accessor((transaction) => transaction.type, {
        id: 'type',
        header: () => <span>Type</span>,
        cell: (data) => {
          return transactionTypeMap[data.getValue()] ?? data.getValue();
        },
      }),
      columnHelper.accessor((transaction) => transaction, {
        id: 'docnumber',
        header: () => <span>Document Number</span>,
        cell: (data) => {
          const val = data.getValue();
          if ('Estimate' === val.type) {
            return (
              <Link
                to={`/quote/${seoFriendly(val.client?.name ?? '')}-${val.client?.id}/${val.documentNumber}`}
                style={{ color: theme.colors.hoverBlue, textDecoration: 'none' }}
              >
                {val.documentNumber}
              </Link>
            );
          } else {
            return val.documentNumber;
          }
        },
      }),
      columnHelper.accessor((transaction) => transaction.clientPoNum, {
        id: 'ponum',
        header: () => <span>Client PO#</span>,
        cell: (data) => data.getValue(),
      }),
      columnHelper.accessor((transaction) => transaction.total, {
        id: 'amount',
        header: () => <span>Amount</span>,
        cell: (data) => <div style={{ textAlign: 'right' }}>{formatCurrency(data.getValue() ?? 0)}</div>,
      }),
      columnHelper.accessor((transaction) => transaction.status?.name, {
        id: 'status',
        header: () => <span>Status</span>,
        cell: (data) => data.getValue(),
      }),
      columnHelper.accessor((transaction) => transaction.clientServicesComplete, {
        id: 'servComplete',
        header: () => <span>Client Services Complete</span>,
        cell: (data) => (data.getValue() ? 'Yes' : 'No'),
      }),
      columnHelper.accessor((transaction) => transaction.market?.name, {
        id: 'market',
        header: () => <span>Market</span>,
        cell: (data) => data.getValue(),
      }),
      columnHelper.accessor((transaction) => transaction.memo, {
        id: 'memo',
        header: () => <span>Memo</span>,
      }),
      columnHelper.accessor((transaction) => transaction.projectName, {
        id: 'project',
        header: () => <span>Project Name</span>,
      }),
    ],
    [columnHelper, theme.colors.hoverBlue, transactionTypeMap]
  );

  const transactionTable = useReactTable({
    columns: columns,
    data: data?.getTransactionsForClient.transactions ?? emptyTransactionArray,
    getCoreRowModel: getCoreRowModel(),
    state: { pagination },
  });

  const handleSalesRepFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      salesRepId: event.target.value,
    });
  };

  const handleSalesCoordFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      salesCoordinator: event.target.value,
    });
  };

  const handleMarketFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      marketId: event.target.value,
    });
  };

  const handleTransactionStatusFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      transactionStatus: event.target.value,
    });
  };

  const handleTransactionTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Object.entries(transactionTypeMap).find(([, value]) => value === event.target.value)?.[0] ?? '';
    setFilters({
      ...filters,
      transactionType: newValue,
    });
  };

  const handleAppliedFiltersChange = () => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    setAppliedFilters({ ...filters });
  };

  const clearFilters = () => {
    setFilters(initTxnHistoryFilters);
  };

  if (error) {
    return (
      <Alert color='error'>
        <AlertTitle>There was a problem loading transaction history</AlertTitle>
      </Alert>
    );
  }

  return (
    <>
      <TransactionHistoryFilterButton
        onClick={() => setIsFilterDrawerOpen((prev) => !prev)}
        isFilterApplied={filtersApplied}
      />
      <FilterMenu
        openDrawer={isFilterDrawOpen}
        setOpenDrawer={setIsFilterDrawerOpen}
        clearFilters={clearFilters}
        salesReps={salesReps}
        salesRepFilter={filters.salesRepId}
        handleSalesRepFilterChange={handleSalesRepFilterChange}
        salesCoordinators={salesCoordinators}
        salesCoordFilter={filters.salesCoordinator}
        handleSalesCoordFilterChange={handleSalesCoordFilterChange}
        markets={markets}
        marketFilter={filters.marketId}
        handleMarketFilterChange={handleMarketFilterChange}
        transactionStatuses={statuses?.sort()}
        transactionStatusFilter={filters.transactionStatus}
        handleTransactionStatusFilterChange={handleTransactionStatusFilterChange}
        transactionTypeList={transactionTypeList}
        transactionTypeFilter={filters.transactionType}
        handleTransactionTypeChange={handleTransactionTypeChange}
        handleAppliedFiltersChange={handleAppliedFiltersChange}
        useSalesCoordId={false}
        restorePreviouslySelectedFilters={restorePreviouslySelectedFilters}
      />
      <Table>
        <thead>
          {transactionTable.getHeaderGroups().map((hg) => (
            <tr key={hg.id}>
              <td />
              {hg.headers.map((header) => {
                if (isTablet && !tabletColumns.includes(header.id)) {
                  return;
                }
                return (
                  <th key={header.id}>
                    <TableHeaderCell $start={header.id === 'date'} $end={header.id === 'project'}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHeaderCell>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {loading && (
            <Row>
              <td colSpan={15} align='center'>
                <LoadingSpinner loading={loading} />
              </td>
            </Row>
          )}
          {!loading &&
            transactionTable.getRowModel().rows.map((row) => {
              return (
                <Row key={row.index}>
                  <td />
                  {row.getVisibleCells().map((cell) => {
                    if (isTablet && !tabletColumns.includes(cell.column.id)) {
                      return;
                    }
                    return (
                      <TableCell key={cell.id} $start={cell.column.id === 'date'} $end={cell.column.id === 'project'}>
                        <Spacer>
                          <span style={cell.column.id === 'date' ? { whiteSpace: 'nowrap' } : {}}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </span>
                        </Spacer>
                      </TableCell>
                    );
                  })}
                </Row>
              );
            })}
        </tbody>
      </Table>
      <Pagination
        currentPage={pagination.pageIndex}
        pages={pages}
        onPageChange={handlePageChange}
        perPage={pagination.pageSize}
        onPerPageChange={(pageSize) => {
          setPagination((cur) => ({
            pageIndex: getPage(cur.pageSize, cur.pageIndex, pageSize),
            pageSize,
          }));
        }}
      />
    </>
  );
};

export default TransactionHistory;
