import React from 'react';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';

const Header = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.white};
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    @media screen and (max-width: 991px) {
      font-size: 1rem;
    }
  `
);

type Props = {
  variant: string;
  header: string;
};

const FormHeader: React.FC<Props> = ({ variant, header }) => {
  return <Header variant={variant}>{header}</Header>;
};

export default FormHeader;
