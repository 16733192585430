import { useSubNav } from 'layout/Layout';
import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import add from 'assets/lottie/add.json';
import coin from 'assets/lottie/coin.json';

type QuotesSubNavContextState = {};

const QuotesSubNavContext = React.createContext<QuotesSubNavContextState | undefined>(undefined);

type QuotesSubNavProviderProps = {
  children: React.ReactNode;
};

const QuotesSubNavContextProvider: React.FC<QuotesSubNavProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [, setSubNavItems] = useSubNav();

  // Clear subnav when component unmounts
  useEffect(() => {
    return () => {
      setSubNavItems([]);
    };
  }, [setSubNavItems]);

  useEffect(() => {
    setSubNavItems([
      { type: 'icon', tooltip: 'New Quote', imageData: add, onClick: () => navigate('/create-quote') },
      //TODO: Add back when page is created
      // { type: 'icon', tooltip: 'New Sales Order', imageData: coin, onClick: () => navigate('/create-sales-order') },
    ]);
  }, [setSubNavItems, navigate]);

  const quotesSubNavContextValue = useMemo(() => ({}), []);

  return <QuotesSubNavContext.Provider value={quotesSubNavContextValue}>{children}</QuotesSubNavContext.Provider>;
};

const useQuotesSubNavContext = () => {
  const context = useContext(QuotesSubNavContext);

  if (context === undefined) {
    throw new Error('useQuotesSubNavContext was used outside of its Provider');
  }

  return context;
};

export { QuotesSubNavContext, QuotesSubNavContextProvider, useQuotesSubNavContext };
