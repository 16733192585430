import { SubNavItem, useSubNav } from 'layout/Layout';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import share from 'assets/lottie/share.json';
import send from 'assets/lottie/send.json';
import comments from 'assets/lottie/comments.json';
import duplicate from 'assets/lottie/duplicate.json';
import checkPricing from 'assets/lottie/check-pricing.json';
import check from 'assets/lottie/check.json';
import trash from 'assets/lottie/trash.json';
import { useQueryCheckForNewComments } from 'hooks/queries/useQueryCheckForNewComments/useQueryCheckForNewComments';
import { useSalesOrderContext } from 'contexts/SalesOrderContext/SalesOrderContext';
import useMutationMarkCommentsAsViewed from 'hooks/mutations/useMutationMarkCommentsAsViewed/useMutationMarkCommentsAsViewed';

type SalesOrdersSubNavContextState = {
  bulkSelect?: boolean;
};

const SalesOrdersSubNavContext = React.createContext<SalesOrdersSubNavContextState | undefined>(undefined);

type SalesOrdersSubNavProviderProps = {
  children: React.ReactNode;
};

const SalesOrdersSubNavContextProvider: React.FC<SalesOrdersSubNavProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [, setSubNavItems] = useSubNav();
  const [bulkSelect, setBulkSelect] = React.useState<boolean>(false);
  const [isShareTooltipClicked, setIsShareTooltipClicked] = useState<boolean>(false);
  const [showComments, setShowComments] = React.useState<boolean>(false);
  const { salesOrder, setSalesOrder } = useSalesOrderContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [markCommentsAsViewed] = useMutationMarkCommentsAsViewed();
  const [deleteClicked, setDeleteClicked] = useState<boolean>(false);
  const { data: checkForNewCommentsData, refetch: checkForNewComments } = useQueryCheckForNewComments(
    salesOrder?.id || '',
    {
      pollInterval: 60000,
      skip: !salesOrder?.id || !salesOrder.salesOrderNumber,
    }
  );

  // Clear subnav when component unmounts
  useEffect(() => {
    return () => {
      setSubNavItems([]);
    };
  }, [setSubNavItems]);

  const tooltipText = isShareTooltipClicked ? 'Link Copied!' : 'SHARE FOR REVIEW';

  const handleCopyToClipboard = () => {
    const pageURL = window.location.href;

    navigator.clipboard
      .writeText(pageURL)
      .then(() => {
        setIsShareTooltipClicked(true);
      })
      .catch((error) => {
        error('Failed to copy to clipboard:', error);
      });
  };

  const toggleShowComments = useCallback(() => {
    if (salesOrder?.salesOrderNumber) {
      markCommentsAsViewed({
        variables: { quoteId: salesOrder?.id || '' },
      })
        .then(() => checkForNewComments())
        .catch((error) => console.warn(error));
    }

    setShowComments((prev) => !prev);
  }, [setShowComments, markCommentsAsViewed, checkForNewComments, salesOrder]);

  const toggleBulkSelect = useCallback(() => {
    if (setBulkSelect) {
      setBulkSelect(!bulkSelect);
    }
  }, [bulkSelect, setBulkSelect]);

  const baseSubnavItems: SubNavItem[] = useMemo(() => {
    const bulkSelectItems = !bulkSelect
      ? [{ type: 'icon', tooltip: 'Bulk Select', imageData: check, onClick: toggleBulkSelect }]
      : [
          { type: 'text', value: 'Cancel', onClick: toggleBulkSelect },
          { type: 'icon', tooltip: 'Delete Items', imageData: trash, onClick: () => setDeleteClicked(true) },
        ];

    const items: SubNavItem[] = [
      {
        type: 'fancyicon',
        tooltip: 'CHECK PRICING',
        nudge: false,
        imageData: checkPricing,
      },
      ...(bulkSelectItems as SubNavItem[]),
    ];

    if (salesOrder?.id) {
      items.push(
        {
          type: 'icon',
          tooltip: 'SEND TO CLIENT',
          imageData: send,
          onClick: () => {
            setIsDrawerOpen(true);
          },
        },
        { type: 'icon', tooltip: 'DUPLICATE QUOTE', imageData: duplicate },
        { type: 'icon', tooltip: tooltipText, imageData: share, onClick: handleCopyToClipboard },
        {
          type: 'icon',
          tooltip: 'Comments',
          imageData: comments,
          onClick: toggleShowComments,
          showNotification: checkForNewCommentsData?.checkForNewComments.hasNewComments,
        }
      );
    }

    return items;
  }, [bulkSelect, toggleBulkSelect, checkForNewCommentsData, toggleShowComments, tooltipText, salesOrder?.id]);

  useEffect(() => {
    setSubNavItems(baseSubnavItems);
  }, [
    setSubNavItems,
    baseSubnavItems,
    navigate,
    tooltipText,
    toggleBulkSelect,
    checkForNewCommentsData,
    toggleShowComments,
  ]);

  const salesOrdersSubNavContextValue = useMemo(
    () => ({
      bulkSelect,
      setBulkSelect,
    }),
    [bulkSelect]
  );

  return (
    <SalesOrdersSubNavContext.Provider value={salesOrdersSubNavContextValue}>
      {children}
    </SalesOrdersSubNavContext.Provider>
  );
};

const useSalesOrdersSubNavContext = () => {
  const context = useContext(SalesOrdersSubNavContext);

  if (context === undefined) {
    throw new Error('useSalesOrdersSubNavContext was used outside of its Provider');
  }

  return context;
};

export { SalesOrdersSubNavContext, SalesOrdersSubNavContextProvider, useSalesOrdersSubNavContext };
