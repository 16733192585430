import { Typography } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import { CustomListType } from '__generated__/graphql';
import Flex from 'components/Flex/Flex';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { ItemTableItem } from 'contexts/QuoteContext/useItems';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Maybe } from 'yup';
import { stripHTML } from 'utils/functions';
import { useQueryGetCustomList } from 'hooks/queries/useQueryGetCustomList/useQueryGetCustomList';

const EditableCellInput = styled('textarea')(
  () => css`
    all: unset;
    background: transparent;
    width: 100%;
    resize: none;
    display: block;
    margin-bottom: 10px;
    width: 10rem;
  `
);

type ItemDescriptionCellProps = {
  info: CellContext<
    ItemTableItem,
    {
      id: string;
      leadTime: Maybe<string> | undefined;
      description: Maybe<string> | undefined;
    }
  >;
  untruncateDescription: { [key: string]: boolean };
};

const formatDescription = (description: string) => stripHTML(description.toString()).replace(/<br\s*?>/gi, '\n');
function ItemDescriptionCell({ info }: ItemDescriptionCellProps) {
  const { updateItemField } = useQuoteContext();
  const { leadTime, description: initialDescription } = info.getValue();
  const [description, setDescription] = useState(formatDescription(initialDescription || ''));

  const { data: leadTimeListData } = useQueryGetCustomList({ id: CustomListType.LeadTime });
  const leadTimeOptions = useMemo(
    () => leadTimeListData?.getCustomList.customValueList?.customValue,
    [leadTimeListData]
  );

  const ref = useRef<HTMLTextAreaElement | null>(null);

  // If the description is changed externally, sync this cell state with that
  useEffect(() => {
    setDescription(formatDescription(initialDescription ?? ''));
  }, [initialDescription]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.height = '0px';
    const scrollHeight = ref.current.scrollHeight;
    ref.current.style.height = `${scrollHeight}px`;
  }, [description]);

  return (
    <Flex column gap={0.5}>
      <EditableCellInput
        ref={ref}
        value={description}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
        onBlur={() => {
          updateItemField(info.row.index, 'description', (description ?? '').toString());
        }}
      />

      <Typography variant='subtext'>
        Lead Time: {leadTimeOptions?.find((opt) => `${opt.valueId}` === leadTime)?.value ?? ''}
      </Typography>
    </Flex>
  );
}

export default ItemDescriptionCell;
