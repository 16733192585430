import { CreateSalesOrderInput, EditSalesOrderInput } from '__generated__/graphql';
import { itemMarginFromCostPrice } from 'contexts/QuoteContext/useItems';
import { GetSalesOrderByNumberQuery } from '__generated__/graphql';
import { SalesOrderMetrics } from 'contexts/SalesOrderContext/SalesOrderContext';

type SalesOrder = GetSalesOrderByNumberQuery['getSalesOrderByNumber'];

export const computeDerivedMetrics = (input: CreateSalesOrderInput | EditSalesOrderInput, salesOrder?: SalesOrder) => {
  const metrics: SalesOrderMetrics = {
    subtotal: 0,
    totalMargin: 0,
    grossProfit: 0,
    grossProfitPercent: 0,
    shippingCost: 0,
    tax: 0,
    total: 0,
  };

  let totalCost = 0;

  if (input.items && input.items.length > 0) {
    input.items.forEach((item) => {
      if (item) {
        metrics.subtotal += (item.quantity || 0) * (item.adjCost || item.unitCost || 0);

        totalCost += (item.unitCost || 0) * (item.quantity || 0);
      }
    });

    metrics.totalMargin = totalCost ? itemMarginFromCostPrice(totalCost, metrics.subtotal) : 0;

    metrics.total = metrics.subtotal;

    metrics.grossProfit = metrics.subtotal - totalCost;

    metrics.grossProfitPercent = (metrics.grossProfit * 100) / metrics.subtotal;
  }

  if (input.shippingCost) {
    metrics.shippingCost = input.shippingCost;

    metrics.total += metrics.shippingCost;
  }

  if (input.taxIncluded) {
    metrics.tax = salesOrder?.taxTotal ?? 0;

    metrics.total += metrics.tax;
  }

  return metrics;
};
