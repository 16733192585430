import { CustomListType } from '__generated__/graphql';
import { shipViaOptions as shipViaOptionsLookup } from 'routes/Quote/utils/constants';
import { useClientContext } from 'contexts/ClientContext/ClientContext';
import { useQueryEmployeeReference } from 'hooks/queries/useQueryEmployeeReference/useQueryEmployeeReference';
import { useQueryGetClassifications } from 'hooks/queries/useQueryGetClassifications/useQueryGetClassifications';
import { useQueryGetCustomList } from 'hooks/queries/useQueryGetCustomList/useQueryGetCustomList';
import { useQueryGetDepartments } from 'hooks/queries/useQueryGetDepartments/useQueryGetDepartments';
import { useQueryGetTerms } from 'hooks/queries/useQueryGetTerms/useQueryGetTerms';
import { useDropdownOptions } from 'hooks/util/useDropdownOptions';
import { useMemo } from 'react';

export const useSalesOrderDropdownOptions = () => {
  const { client } = useClientContext();

  const contactOptions = useMemo(() => {
    return (
      client?.contacts?.map((contact) => ({
        id: contact.id,
        label: contact.name || '',
      })) ?? []
    );
  }, [client?.contacts]);

  const { data: termsData } = useQueryGetTerms();
  const termOptions = useDropdownOptions(termsData?.getTerms);

  const { data: employeeReferenceData } = useQueryEmployeeReference();
  const salesCoordinatorOptions = useMemo(() => {
    return (
      employeeReferenceData?.employeeReference.salesCoordinators.map((salesCoordinator) => ({
        id: salesCoordinator.id,
        label: salesCoordinator.fullName,
      })) ?? []
    );
  }, [employeeReferenceData]);

  const salesRepOptiopns = useMemo(() => {
    return (
      employeeReferenceData?.employeeReference.salesReps.map((salesRep) => ({
        id: salesRep.id,
        label: salesRep.fullName,
      })) ?? []
    );
  }, [employeeReferenceData]);

  const { data: departmentsData } = useQueryGetDepartments();
  const marketOptions = useMemo(() => {
    return (
      departmentsData?.getDepartments.map((department) => ({
        id: department.id,
        label: department.parent?.name ? `${department.parent?.name} : ${department.name}` : `${department.name}`,
      })) ?? []
    );
  }, [departmentsData]);

  const addressOptions = useMemo(() => {
    const addresses = client?.addressbooklist?.addressbook;
    return (
      addresses?.map((address) => ({
        id: address.internalId!,
        label: address.label ?? '',
      })) ?? []
    );
  }, [client?.addressbooklist?.addressbook]);

  const { data: freightTermsListData } = useQueryGetCustomList({ id: CustomListType.FreightTerms });
  const freightTermsOptions = useMemo(() => {
    return (
      freightTermsListData?.getCustomList?.customValueList?.customValue?.map((option) => ({
        id: `${option.valueId}`,
        label: option.value,
      })) ?? []
    );
  }, [freightTermsListData]);

  // TBD - need to fetch these from the client data
  const projectTypeOptions: {
    id: string;
    label: string;
  }[] = [];

  const { data: poRequiredData } = useQueryGetCustomList({ id: CustomListType.PoNumberRequired });
  const poRequiredOptions = useDropdownOptions(poRequiredData?.getCustomList.customValueList?.customValue);

  const shipViaOptions = useMemo(() => ({ ...shipViaOptionsLookup }), []);

  const { data: classificationData } = useQueryGetClassifications();
  const classificationOptions = useDropdownOptions(classificationData?.getClassifications);

  const spProjectPricingOptions = useMemo(
    () => [
      { id: '1', label: 'Yes' },
      { id: '2', label: 'No' },
    ],
    []
  );

  return {
    contactOptions,
    termOptions,
    salesRepOptiopns,
    salesCoordinatorOptions,
    marketOptions,
    addressOptions,
    projectTypeOptions,
    poRequiredOptions,
    shipViaOptions,
    classificationOptions,
    freightTermsOptions,
    spProjectPricingOptions,
  };
};
