import React, { FocusEvent, SetStateAction, useState } from 'react';
import { css, styled } from '@mui/material/styles';
import { Button, TextField } from '@mui/material';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import CloseIcon from '@mui/icons-material/Close';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { ItemTableItem } from 'contexts/QuoteContext/useItems';

const WarehouseRow = styled('div')(
  ({ theme, $open }) => css`
    flex: 1;
    background-color: ${$open ? theme.colors.blue : theme.colors.lightInput};
    border-radius: 0.5rem;
    margin-bottom: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      width: 100%;
      font-weight: 600;
      font-size: 0.875rem;
      color: ${theme.palette.text.primary};
      text-transform: capitalize;
      margin: 0;
    }

    p:first-child {
      min-width: 10rem;
      width: fit-content;
      margin-left: 0.25rem;
    }

    p:nth-child(2) {
      padding-right: 2.75rem;
      padding-left: 1rem;
      text-align: right;
    }
  `
);

const InputWrapper = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.input.main};
    padding: 0;
    margin-right: 0.25rem;
    border-radius: 0.75rem;
    margin: 0.25rem;

    input {
      width: 3.1rem;
      font-size: 0.875rem;
      font-weight: 600;
      padding: 0.5rem;
      color: ${theme.palette.text.primary};
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: inner-spin-button;
      margin: 0;
    }
  `
);

const RemoveButton = styled(Button)(
  ({ theme }) => css`
    background-color: ${theme.palette.input.main};
    color: ${theme.colors.white};
    border-radius: 50%;
    min-width: 0;
    padding: 0.75rem;
    width: 1.563rem;
    height: 1.563rem;

    margin-left: 0.3rem;
    border-radius: 1.5rem;
    border: 0.0625rem solid transparent;
    border-top: 0.0625rem solid ${theme.colors.bevelTop};
    border-left: 0.0625rem solid ${theme.colors.bevelLeft};

    &:hover {
      background-color: ${theme.colors.hoverGray};
    }
  `
);

const OuterContainer = styled('div')(
  () => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
  `
);

const Quantity = styled(TextField)(
  ({ theme }) => css`
    background-color: ${theme.palette.input.main};
    border-radius: 0.5rem;
    border: 0.0625rem solid ${theme.palette.input.main};

    &:focus-within {
      border: 0.0625rem solid ${theme.palette.text.primary};
    }

    input {
      width: 3rem;
      border: none;
      height: 2rem;
      font-size: 0.875rem;
      font-weight: 600;
    }

    fieldset {
      border: none;
    }
  `
);

type QuoteItemInventoryProps = {
  values: ItemTableItem;
  index: number;
  location: string;
  totalOnHand: number;
  setValues: (values: SetStateAction<ItemTableItem>, shouldValidate?: boolean | undefined) => void;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

const QuoteItemInventoryInput: React.FC<QuoteItemInventoryProps> = ({
  index,
  location,
  totalOnHand,
  values,
  setValues,
}) => {
  const { updateItemField } = useQuoteContext();

  const [value, setValue] = useState((values?.inventory && values?.inventory[0].quantity) ?? '');

  // Update form
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    setValues((prev) => {
      if (prev.inventory && prev.inventory[0]) {
        return {
          ...prev,
          inventory: [
            {
              ...prev.inventory[0],
              quantity: Number(e.target.value),
            },
          ],
        };
      }

      return prev;
    });
  };

  // Update item
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (values.inventory && values.inventory[0]) {
      updateItemField(index, 'inventory', [{ ...values.inventory[0] }]);
      setValue(values.inventory[0].quantity);
    }
  };

  // Update both form and item
  const handleRemoveWarehouse = () => {
    // Update form
    setValues((prev) => {
      return {
        ...prev,
        inventory: [],
        quantity: 0,
      };
    });

    // Update item
    updateItemField(index, 'inventory', []);
  };

  return (
    <OuterContainer>
      <WarehouseRow>
        <p>{location}</p>
        <p>{totalOnHand}</p>
        <InputWrapper>
          <Quantity
            value={value}
            onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
            onChange={handleChange}
            onBlur={handleBlur}
            name='numberformat'
            id='formatted-numberformat-input'
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
          />
        </InputWrapper>
      </WarehouseRow>
      <RemoveButton onClick={handleRemoveWarehouse}>
        <CloseIcon style={{ fontSize: '1.25rem' }} />
      </RemoveButton>
    </OuterContainer>
  );
};

export default QuoteItemInventoryInput;
