import React, { useState } from 'react';
import PageHeader from 'components/PageHeader/PageHeader';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

const QASpinner: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <>
      <PageHeader variant='h1' header='QASpinner' />
      {/*Using an ugly standard HTML button as the MUI one is hard to see*/}
      <button onClick={() => setLoading(!loading)}>Click here to toggle loading</button>
      <LoadingSpinner loading={loading}>
        <h2>This is the child component. You should only see it if the spinner is not loading.</h2>
      </LoadingSpinner>
    </>
  );
};

export default QASpinner;
