import { styled, css } from '@mui/material/styles';
import SearchResultHeader from '../SearchResultHeader/SearchResultHeader';

const SearchResult = styled(SearchResultHeader)<{ $selected: boolean }>(
  ({ theme, $selected }) => css`
    cursor: pointer;
    background-color: ${$selected === true ? theme.colors.hoverGray : theme.palette.primary.main};
    height: fit-content;
    align-items: flex-start;

    &:hover {
      background-color: ${theme.colors.hoverGray};
    }

    & > div > div {
      padding: 0;
    }

    & > div {
      height: 10px;
      height: fit-content;
      padding: 1.5rem 0.5rem 1.5rem 0.75rem;
      flex: 1;

      & > div {
        white-space: nowrap;
      }
    }

    & > div:last-of-type {
      flex: 2;
    }

    &:first-child {
      border-top: none;
      border-left: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  `
);

export default SearchResult;
