import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  EmployeeReferenceQuery,
  EmployeeReferenceDocument,
  QueryEmployeeReferenceArgs,
  EmployeeReferenceQueryVariables,
} from '__generated__/graphql';

export const useQueryEmployeeReference = (
  input?: QueryEmployeeReferenceArgs,
  options?: QueryHookOptions<EmployeeReferenceQuery, EmployeeReferenceQueryVariables>
): QueryResult<EmployeeReferenceQuery, EmployeeReferenceQueryVariables> => {
  return useQuery(EmployeeReferenceDocument, {
    ...options,
    variables: input ?? { supervisorId: '' },
  });
};
