import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetItemSubSkusDocument, GetItemSubSkusQuery, GetItemSubSkusQueryVariables } from '__generated__/graphql';

export const useQueryGetItemSubSkus = (
  sku: string,
  options?: QueryHookOptions<GetItemSubSkusQuery, GetItemSubSkusQueryVariables>
): QueryResult<GetItemSubSkusQuery, GetItemSubSkusQueryVariables> => {
  return useQuery(GetItemSubSkusDocument, {
    variables: { sku },
    ...options,
  });
};
